import { useFormik } from "formik";
import React, { useMemo, useState } from "react";

import TextInput from "~ui/textInput/TextInput";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import { Button, Radio, FormControlLabel, Tooltip } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";

import SaveModal from "../../../components/sms/saveModal/saveModal";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import byteFunction from "~utils/byteCentury";

import useAlert from "~hooks/useAlert";
import instanceAxios from "~apis/_util";

import Checkbox from "@material-ui/core/Checkbox";

//
import "./send.scss";

const SmsSend = () => {
  const [saveModalOpen, setSaveModalOpen] = React.useState(false);
  const [inputPhoneNumber, setInputPhoneNumber] = React.useState(0);

  const locationHref = window.location.href;
  const counselorHrefCheck = locationHref.includes("/counselor/sms/send");
  const phoneHrefCheck = locationHref.includes("?phone=");

  const [excluded, setExcluded] = React.useState({
    smsSendType: counselorHrefCheck ? false : true,
    nonType: counselorHrefCheck ? false : true,
  });

  const saveModalHandleClose = () => {
    setSaveModalOpen(false);
  };
  // 모달 설정들
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const { openAlert } = useAlert();

  const DialogContent = React.useMemo(
    () =>
      withStyles((theme) => ({
        root: {
          padding: theme.spacing(2),
        },
      }))(MuiDialogContent),
    [],
  );

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const modalFormik = useFormik({
    initialValues: {
      // 보낼 번호
      sender: "",
      // 받는 번호들
      receiver: "",
      // 메시지
      msg: "",

      filePicker: false,

      senderInput: "",
      msg_type: phoneHrefCheck ? "notice" : "ad",
      titleName: "스탠다드치과",
      disabledPhone: "0808811280",
      denialPhone: "0808811280",
    },
    onSubmit(e) {},
  });

  const receiverChange = (e) => {
    setInputPhoneNumber(
      () =>
        Array.from(
          new Set(e.target.value.split(`\n`).filter((x) => x.trim() != "")),
        ).length,
    );
    modalFormik.setFieldValue("receiver", e.target.value);
  };

  const contentInputPreview = (e) => {
    modalFormik.setFieldValue("msg", e.target.value);
  };
  // modalFormik.values.msg_type === "ad" ? <p>(광고){modalFormik.values.titleName === "추가" ? modalFormik.values.titleNameInput : modalFormik.values.titleName}</p> : null
  // modalFormik.values.msg_type === "ad" ? <p>무료거부 {modalFormik.values.denialPhone}</p> : null

  // 모달 설정들

  const textStart =
    modalFormik.values.msg_type === "ad"
      ? `(광고)${
          modalFormik.values.titleName === "추가"
            ? modalFormik.values.titleNameInput
            : modalFormik.values.titleName
        }`
      : "";
  const textEnd =
    modalFormik.values.msg_type === "ad"
      ? `무료거부 ${modalFormik.values.denialPhone}`
      : "";

  const nowMsg =
    modalFormik.values.msg_type === "ad"
      ? textStart + "\n" + modalFormik.values.msg + "\n" + textEnd
      : modalFormik.values.msg;
  const byte = byteFunction(nowMsg);

  async function sendMsg() {
    if (byte > 2000) {
      openAlert("내용은 한글 1,000자(2,000Byte) 까지만 입력 가능합니다.");
      return;
    }

    const typeVali =
      modalFormik.values.msg_type === "ad"
        ? ["receiver", "sender", "titleName", "msg"]
        : ["receiver", "sender", "msg"];

    const titleText =
      modalFormik.values.titleName.trim() === "추가"
        ? modalFormik.values.titleNameInput.trim()
        : modalFormik.values.titleName.trim();

    const senderText =
      modalFormik.values.sender === "추가"
        ? modalFormik.values.senderInput
        : modalFormik.values.sender;

    let v = false;

    for (let templateKey of typeVali) {
      let val = modalFormik.values[templateKey];

      if (templateKey === "sender") {
        val = senderText;
      }

      if (templateKey === "titleName") {
        val = titleText;
      }

      if (val.trim() === "") {
        v = true;
      }
    }

    if (v) {
      openAlert("필수입력 값이 비어있습니다.");
      return;
    }

    const sendValue = {
      sender: senderText,
      receiver: Array.from(
        new Set(
          modalFormik.values.receiver.split(`\n`).filter((x) => x.trim() != ""),
        ),
      ).join(","),
      msg: nowMsg,
      msg_type: modalFormik.values.filePicker
        ? "MMS"
        : byte <= 90
        ? "SMS"
        : "LMS",
    };

    const filterData = await instanceAxios({
      method: "POST",
      url: "/visits/phone-sms-filter",
      data: excluded,
    });

    if (filterData.data.length) {
      const receiverArray = Array.from(
        new Set(
          modalFormik.values.receiver.split(`\n`).filter((x) => x.trim() != ""),
        ),
      ).filter((x) => !filterData.data.find((v) => v.phone == x));
      sendValue.receiver = receiverArray.join(",");
    }

    const FData = new FormData();
    FData.append("sender", senderText);
    FData.append(
      "receiver",
      Array.from(
        new Set(
          modalFormik.values.receiver.split(`\n`).filter((x) => x.trim() != ""),
        ),
      ).join(","),
    );
    FData.append("msg", nowMsg);
    FData.append(
      "msg_type",
      modalFormik.values.filePicker ? "MMS" : byte <= 90 ? "SMS" : "LMS",
    );

    if (modalFormik.values?.filePicker) {
      FData.append("file", modalFormik.values.filePicker);
      FData.append(
        "fileUrl",
        URL.createObjectURL(modalFormik.values.filePicker),
      );
    }

    const axiosData = await instanceAxios({
      method: "post",
      url: "/visits/sms_send",
      data: FData,
      "content-type": `multipart/form-data`,
    });

    let message = axiosData.data.message;

    if (message == "success") {
      message = "정상적으로 메시지를 보냈습니다.";
    }

    openAlert(message);
  }

  React.useEffect(() => {
    const href = window.location.href;

    if (href.includes("?phone=")) {
      const getPhoneData = href.split("?phone=")[1];
      modalFormik.setFieldValue("receiver", getPhoneData);
    }
  }, []);

  const phoneInputReset = () => {
    modalFormik.setFieldValue("receiver", "");
    setInputPhoneNumber(() => 0);
  };

  const selectImage = React.useCallback(async () => {
    const pickerOpts = {
      types: [
        {
          description: "Images",
          accept: {
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false,
    };

    const [fileHandle] = await window.showOpenFilePicker(pickerOpts);
    modalFormik.setFieldValue("filePicker", await fileHandle.getFile());
  }, [modalFormik.values]);

  const resetImage = () => {
    modalFormik.setFieldValue("filePicker", false);
  };

  return (
    <>
      <div className="smsModal">
        <span className="viewTitle">문자 보내기</span>

        <div className="smsSendBox">
          {/* content */}
          <div className="content">
            <div className="left">
              <div className="miniTitle phoneTitleInputs">
                <p>문자 분류</p>
              </div>
            </div>

            <div className="right">
              <div className="typeRadio">
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="msg_type"
                    name="msg_type"
                    defaultValue={modalFormik.values.msg_type}
                    onChange={modalFormik.handleChange}
                  >
                    <FormControlLabel
                      value="ad"
                      control={<Radio color="primary" />}
                      label="광고"
                    />
                    <FormControlLabel
                      value="notice"
                      control={<Radio color="primary" />}
                      label="단순 알림(공지)"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          {/* content */}

          {/* content */}
          <div className="content">
            <div className="left">
              <div className="miniTitle phoneTitleInputs">
                <p>발신자 전화번호</p>
              </div>
              <div className="subTitle">
                {/* <p>발신자 전화번호를 선택하세요.</p> */}
              </div>
            </div>

            <div className="right">
              <div className="smsSelect">
                <FormControl variant="outlined">
                  <InputLabel>선택</InputLabel>
                  <Select
                    name="sender"
                    fullWidth
                    value={modalFormik.values.sender}
                    onChange={modalFormik.handleChange}
                  >
                    <MenuItem value="010-8487-2819">
                      010-8487-2819 / 홍동환
                    </MenuItem>
                    <MenuItem value="010-8300-7823">
                      010-8300-7823 / 신예지
                    </MenuItem>
                    <MenuItem value="추가">+ 직접 입력하기</MenuItem>
                  </Select>
                </FormControl>

                {modalFormik.values.sender === "추가" ? (
                  <TextInput
                    placeholder="알리고에 추가되어있는 전화번호로 입력"
                    name="senderInput"
                    className="senderListInputs"
                    fullWidth
                    value={modalFormik.values.senderInput}
                    onChange={modalFormik.handleChange}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {/* content */}

          {/* content */}

          {modalFormik.values.msg_type === "ad" ? (
            <div className="content">
              <div className="left">
                <div className="miniTitle phoneTitleInputs">
                  <p>무료거부</p>
                </div>
              </div>

              <div className="right">
                <FormControl variant="outlined">
                  <Select
                    name="denialPhone"
                    fullWidth
                    value={modalFormik.values.denialPhone}
                    onChange={modalFormik.handleChange}
                  >
                    <MenuItem value="0808811255">0808811255</MenuItem>
                    <MenuItem value="0808811290">0808811290</MenuItem>
                    <MenuItem value="0808811280">0808811280</MenuItem>
                    <MenuItem value="0808811252">0808811252</MenuItem>
                    {/* <MenuItem value="추가"> */}
                  </Select>
                </FormControl>
              </div>
            </div>
          ) : null}

          {/* content */}

          {/* content */}
          {modalFormik.values.msg_type === "ad" ? (
            <div className="content">
              <div className="left">
                <div className="miniTitle phoneTitleInputs">
                  <p>상호이름</p>
                </div>
              </div>
              <div className="right">
                <FormControl variant="outlined">
                  <Select
                    name="titleName"
                    fullWidth
                    value={modalFormik.values.titleName}
                    onChange={modalFormik.handleChange}
                  >
                    <MenuItem value="추가">+ 직접 입력하기</MenuItem>
                    <MenuItem value="스탠다드치과">스탠다드치과</MenuItem>
                  </Select>
                </FormControl>
                {modalFormik.values.titleName === "추가" ? (
                  <TextInput
                    placeholder="상호명 직접입력"
                    name="titleNameInput"
                    className="senderListInputs"
                    fullWidth
                    value={modalFormik.values.titleNameInput}
                    onChange={modalFormik.handleChange}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
          {/* content */}

          {/* content */}
          <div className="content">
            <div className="left">
              <div className="miniTitle phoneTitleInputs">
                <p>회원 전화번호 입력</p>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveModalButton"
                  onClick={phoneInputReset}
                >
                  전화번호 리셋
                </Button>
              </div>
              <div className="inputNumber">
                <p>입력된 번호 : {inputPhoneNumber}개</p>
              </div>
            </div>

            <div className="right">
              <TextareaAutosize
                placeholder="전화번호 입력"
                minRows={3}
                maxRows={6}
                name="receiver"
                fullWidth
                value={modalFormik.values.receiver}
                onChange={receiverChange}
              />
            </div>
          </div>
          {/* content */}

          {/* content */}

          <div className="content">
            <div className="left">
              <div className="miniTitle">
                <p>파일 첨부</p>
              </div>
            </div>

            <div className="right filePickerButtons">
              <Button
                variant="contained"
                onClick={() => selectImage()}
                color="primary"
              >
                사진 선택하기
              </Button>

              {modalFormik.values?.filePicker && (
                <Button
                  className="resetBtn"
                  variant="contained"
                  onClick={() => resetImage()}
                  color="primary"
                >
                  사진 제거
                </Button>
              )}
            </div>
          </div>

          {/* content */}

          {/* content */}

          {counselorHrefCheck ? null : (
            <div className="content border-style-add-">
              <div className="left">
                <div className="miniTitle phoneTitleInputs">
                  <p>제외 대상</p>
                </div>
                <div className="inputNumber">
                  <p>해당 조건이 있는 전화번호만 제외 대상입니다.</p>
                </div>
              </div>

              <div className="right">
                <div className="chec">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={excluded.smsSendType}
                        onClick={() =>
                          setExcluded((data) => ({
                            ...data,
                            smsSendType: !data.smsSendType,
                          }))
                        }
                        name="smsSendType"
                        color="primary"
                      />
                    }
                    label="문자 거부"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={excluded.nonType}
                        onClick={() =>
                          setExcluded((data) => ({
                            ...data,
                            nonType: !data.nonType,
                          }))
                        }
                        name="nonType"
                        color="primary"
                      />
                    }
                    label="미내원"
                  />
                </div>
              </div>
            </div>
          )}
          {/* content */}

          {/* content */}

          <div className="content">
            <div className="left">
              <div className="miniTitle">
                <p>알림 내용</p>
                <Button
                  variant="contained"
                  onClick={() => setSaveModalOpen(true)}
                  color="primary"
                >
                  문자 저장기능
                </Button>
              </div>
            </div>

            <div className="right">
              <TextareaAutosize
                placeholder="알림 내용을 입력해주세요."
                minRows={4}
                maxRows={8}
                name="msg"
                fullWidth
                value={modalFormik.values.msg}
                onChange={contentInputPreview}
              />
            </div>
          </div>

          {/* content */}

          {/* content */}
          <div className="content">
            <div className="left">
              <div className="miniTitle phoneTitleInputs">
                <p>
                  문자 미리보기 ({byte <= 90 ? "단문메세지" : "장문메세지"})
                </p>
              </div>
            </div>

            <div className="right">
              <div className="smsPreviewMsg-background">
                <div className="smsPreviewMsg">
                  {modalFormik.values?.filePicker && (
                    <img
                      style={{ width: "100%" }}
                      src={URL.createObjectURL(modalFormik.values.filePicker)}
                      alt="imageFilePicker"
                    />
                  )}
                  {modalFormik.values.msg_type === "ad" ? (
                    <p>
                      (광고)
                      {modalFormik.values.titleName === "추가"
                        ? modalFormik.values.titleNameInput
                        : modalFormik.values.titleName}
                    </p>
                  ) : null}
                  <pre>
                    {modalFormik.values.msg.trim() !== "" ? (
                      modalFormik.values.msg.split("\n").map((html, key) => {
                        const length =
                          modalFormik.values.msg.split("\n").length;

                        return (
                          <>
                            {html}
                            {key + 1 === length ? null : <br />}
                          </>
                        );
                      })
                    ) : (
                      <br />
                    )}
                  </pre>
                  {modalFormik.values.msg_type === "ad" ? (
                    <p>무료거부 {modalFormik.values.denialPhone}</p>
                  ) : null}
                </div>
                <div className="bottomByte">
                  <p>
                    {byte.toLocaleString()}/
                    {byte <= 90 ? 90 : (2000).toLocaleString()}byte
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* content */}

          <div className="buttons">
            <Button variant="contained" onClick={sendMsg} color="primary">
              보내기
            </Button>
          </div>
        </div>
      </div>

      <SaveModal
        handleClose={saveModalHandleClose}
        modalState={saveModalOpen}
        msgContent={modalFormik.values.msg}
        smsSendFormik={modalFormik}
      />
    </>
  );
};

export default SmsSend;
