import React from "react";
import produce from "immer";
import { useRecoilState } from "recoil";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSignIn } from "~apis/users";

import "./login.scss";
import { loginUiState, loginData } from "~global/stores/login";

import Button from "~ui/button/Button";
import useAuth from "~/global/hooks/useAuth";

export default function Login({ user }) {
  const [uiState, setUiState] = useRecoilState(loginUiState);
  const [dataState, setDataState] = useRecoilState(loginData);
  const { signOut } = useAuth()

  const handleOk = async () => {
    signOut()
  };

  const handleCancel = () => {
    setUiState(
      produce((v) => {
        v.open = false;
      }),
    );
  };

  return (
    <Dialog open={uiState.open} className="UI-login" maxWidth="xs" fullWidth>
      <DialogTitle>회원정보</DialogTitle>

      <DialogContent>
        <div>이름: {user.name}</div>
        <div>권한: {user.access}</div>
      </DialogContent>

      <DialogActions>
        <Button color="primary" transparent onClick={handleOk}>
          로그아웃
        </Button>

        <Button transparent onClick={handleCancel}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
}
