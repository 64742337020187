import React from "react";
import styled from "styled-components";
import DatePicker, {registerLocale} from "react-datepicker";
import moment from 'moment'

import ko from 'date-fns/locale/ko';
import "./dateInput.scss";
import "react-datepicker/dist/react-datepicker.css";

registerLocale('ko', ko)

const StyledDateInput = styled(DatePicker)`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  border-radius: 0.5rem;
  background-color: white;
  border: none;
  outline: none;
  padding: 0.78rem;
  font: inherit;
  width: 100%;
  text-align: center;
  font-weight: 500;

  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

const StyledDateOriginalInput = styled.input`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  border-radius: 0.5rem;
  background-color: white;
  border: none;
  outline: none;
  padding: 0.78rem;
  font: inherit;
  width: 100%;
  text-align: center;
  font-weight: 500;

  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

export default function DateInput({
  className,
  type,
  name,
  value,
  onChange,
  fontSize,
  style,
}) {
  const dateInstanceValue = value instanceof Date ?
    value :
    (value ? new Date(value) : undefined);
  return (
    <div
      className={`dateInput ${className && className}`}
      style={style && style}
    >
      <StyledDateInput
        locale="ko"
        selected={dateInstanceValue}
        dateFormat={type === "month" ? "yyyy. MM" : "yyyy. MM. dd"}
        onSelect={(date) => {
          onChange({
            target: {
              name,
              value: moment(date).format("YYYY-MM-DD")
            }
          })
        }}
        onChange={(date) => {
          onChange({
            target: {
              name,
              value: date
            }
          })
        }}
        showMonthYearPicker={type === "month"}
      />
    </div>
  );
}
