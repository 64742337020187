import color from "~/global/utils/color.json";

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
}

const chartColorPattern = [
  [255, 99, 132],
  [54, 162, 235],
  [255, 205, 86],
];

const chartColorPatternExtend = chartColorPattern.concat(
  chartColorPattern.reverse().slice(1),
);

export const chartColors = (n, alpha = 1) => {
  return Array.from({ length: n }, (_, i) => {
    const rgb = hexToRgb(color[i % color.length]);
    // const rgb = chartColorPatternExtend[i % chartColorPatternExtend.length];
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
  });
};
