import React from "react";
import produce from "immer";
import Checkbox from "@material-ui/core/Checkbox";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import { MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import SelectInput from "~ui/selectInput/SelectInput";
import Icon from "@material-ui/core/Icon";

import "./counselingViewList.scss";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  .MuiSelect-root {
    color: black;
  }
`;

export const HEADER_COLUMNS = [
  "",
  "#",
  "리콜 요청 날짜",
  "리콜진행수",
  "내원날짜",
  "차트번호",
  "이름",
  "전화번호",
  "나이",
  "상담총액",
  "담당실장",
  "Doctor",
  "치료진행여부",
  "Event DB",
  "수정"
];

function CounselingViewListHeader() {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  return <div className="counselingViewListHeader">{items}</div>;
}

function CounselingViewListItem({
  data,
  isChecked,
  onCheckboxChange,
  onSelectChange,
  headList,
}) {
  const counselorNameList = headList.data.map((head, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={head.name}>
      {head.name}
    </MenuItem>
  ));

  const columnDataList = [
    <Checkbox
      name={String(data.id)}
      checked={Boolean(isChecked)}
      onChange={onCheckboxChange}
      color="secondary"
    />,
    data.id,
    data.recall_request_date
      ? moment(data.recall_request_date).format("YYYY. MM. DD")
      : "-",
    data.recalls_count || 0,
    data.visitDate ? moment(data.visitDate).format("YYYY. MM. DD") : "-",
    data.chartNumber || "-",
    data.name || "-",
    data.phone,
    data.age,
    data.counselingTotal === 0 ? "-" : data.counselingTotal?.toLocaleString?.(),
    <SelectInput
      className="selectType"
      name={String(data.id)}
      value={data.head_name || ""}
      onChange={onSelectChange}
      placeholder="--"
    >
      {counselorNameList}
    </SelectInput>,
    data.doctor_name || "-",
    data.cure || "-",
    data.isEventVisit ? "O" : "X",
    <Link to={`/counselor/counseling/detail/${data.id}`}>
      <Icon>settings</Icon>
    </Link>,
  ];

  const items = columnDataList.map((columnData, i) => (
    <div key={i}>{columnData}</div>
  ));

  return <div className="counselingViewListItem">{items}</div>;
}

export default function CounselingViewList({
  checkedItems,
  setCheckedItems,
  visitsList,
  headList,
  updateVisitsItem,
}) {
  const onCheckboxChange = React.useCallback(
    (event) => {
      const id = parseInt(event.target.name);
      const checked = event.target.checked;

      setCheckedItems(
        produce((draft) => {
          if (checked && !draft.includes(id)) {
            draft.push(id);
          } else if (!checked && draft.includes(id)) {
            draft.splice(draft.indexOf(id), 1);
          }
        }),
      );
    },
    [setCheckedItems],
  );

  const onSelectChange = React.useCallback(
    async (event) => {
      const id = parseInt(event.target.name);
      const headName = event.target.value;
      const head = headList.data.find((x) => x.name === headName);

      if (id) {
        await updateVisitsItem([id, { head: head ? head.id : null }]);
        visitsList.refetch();
      }
    },
    [visitsList, headList, updateVisitsItem],
  );

  const items = visitsList.data.map((visit, i) => (
    <CounselingViewListItem
      key={i}
      data={visit}
      isChecked={checkedItems.includes(visit.id)}
      onCheckboxChange={onCheckboxChange}
      onSelectChange={onSelectChange}
      headList={headList}
    />
  ));

  return (
    <div className="counselingViewList">
      <CounselingViewListHeader />
      {items}
    </div>
  );
}
