import React from "react";
import StatisticsChart_1 from "~/doctor/components/monthlySalesStatistics/statisticsChart_1/StatisticsChart_1";
import StatisticsChart_2 from "~/doctor/components/monthlySalesStatistics/statisticsChart_2/StatisticsChart_2";
import SearchBar from "~/doctor/components/monthlySalesStatistics/searchBar/SearchBar";
import SalesStatisticsList from "~/doctor/components/monthlySalesStatistics/salesStatisticsList/SalesStatisticsList";
import { useGetDoctorList, useGetMonthlySalesStatistics } from "~apis/doctors";
import { useGetHeadList } from "~apis/head";
import qs from "qs";
import moment from "moment";

import "./monthlySalesStatistics.scss";

export default function MonthlySalesStatistics() {
  const [searchQuery, setSearchQuery] = React.useState({
    startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [queryString, setQueryString] = React.useState("");

  const doctorList = useGetDoctorList();
  const headList = useGetHeadList();
  const monthlySalesStatistics = useGetMonthlySalesStatistics(queryString);

  const isFetching =
    doctorList.isFetching ||
    headList.isFetching ||
    monthlySalesStatistics.isFetching;

  const isError =
    doctorList.isError || headList.isError || monthlySalesStatistics.isError;

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_start_date: searchQuery.startDate,
        q_end_date: searchQuery.endDate,
      }),
    );
  }, []);

  const onClickSearch = React.useCallback(() => {
    setQueryString(
      qs.stringify({
        q_start_date: searchQuery.startDate,
        q_end_date: searchQuery.endDate,
      }),
    );
    monthlySalesStatistics.refetch();
  }, [searchQuery, setQueryString, monthlySalesStatistics]);

  return (
    <div className="monthlySalesStatistics">
      <span className="viewTitle">월별 매출 통계</span>
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <StatisticsChart_1 salesData={monthlySalesStatistics.data} />
          <StatisticsChart_2 salesData={monthlySalesStatistics.data} />
          <SearchBar {...{ searchQuery, setSearchQuery, onClickSearch }} />
          <SalesStatisticsList
            {...{
              doctorList,
              headList,
              monthlySalesStatistics,
            }}
          />
        </>
      )}
    </div>
  );
}
