import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Management from "./pages/management/Management";

export default function Admin() {
  return (
    <Switch>
      <Redirect from="/externalCompany/" to="/externalCompany/management" exact />
      <Route component={Management} exact path="/externalCompany/Management" />
      {/* <Route component={파일이름} path="url" /> */}
    </Switch>
  );
}
