import React from "react";
import { Doughnut } from "react-chartjs-2";
import { chartColors } from "~utils/chart";
import { theme } from "~global/assets/js/theme";

import "./statisticsChart_1.scss";

function StatisticsSummary({ evaluationData }) {
  const contractAmount = evaluationData.list.reduce(
    (pre, cur) => pre + cur.total_sales,
    0,
  );
  const counselingAmount = evaluationData.list.reduce(
    (pre, cur) => pre + cur.total_counselling_sales,
    0,
  );

  return (
    <div className="statisticsSummary">
      <div className="summaryRow">
        <div className="summaryItem">
          <div>
            <span>{evaluationData.month}월 계약금액</span>
          </div>
          <div>
            <span>{contractAmount.toLocaleString("ko-KR")}원</span>
          </div>
        </div>
      </div>
      <div className="summaryRow">
        <div className="summaryItem">
          <div>
            <span>{evaluationData.month}월 상담금액</span>
          </div>
          <div>
            <span>{counselingAmount.toLocaleString("ko-KR")}원</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatisticsChart_1({ evaluationData, agency }) {
  const statistics = evaluationData.list
    .map((x) => [
      agency ? x.agency : x.channelName,
      x.total_counselling_sales || 0,
      x.total_sales || 0
    ])

  const doughnutChartData_1 = {
    datasets: [
      {
        data: statistics.map((x) => x[2]),
        backgroundColor: chartColors(statistics.length),
      },
    ],
    labels: statistics.map((x) => x[0]),
  };

  const doughnutChartData_2 = {
    datasets: [
      {
        data: statistics.map((x) => x[1]),
        backgroundColor: chartColors(statistics.length),
      },
    ],
    labels: statistics.map((x) => x[0]),
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: theme.palette.primary,
          boxWidth: 8,
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <div className="statisticsChart_1">
      <StatisticsSummary {...{ evaluationData }} />
      <div className="doughnutChart_1">
        <span>{agency ? '업체' : '채널'}별 계약금액</span>
        <Doughnut data={doughnutChartData_1} options={options} />
      </div>
      <div className="doughnutChart_2">
        <span>{agency ? '업체' : '채널'}별 상담금액</span>
        <Doughnut data={doughnutChartData_2} options={options} />
      </div>
    </div>
  );
}

export default React.memo(StatisticsChart_1);
