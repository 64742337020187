import React, { useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button } from "@material-ui/core";
import { RiFileExcel2Fill } from "react-icons/ri";
import produce from "immer";
import DbInfo from "~/tm/components/totalManagementDetail/dbInfo/DbInfo";
import DbStateManagement from "~/tm/components/totalManagementDetail/dbStateManagement/DbStateManagement";
import RecallRequestDateBar from "~/tm/components/totalManagementDetail/recallRequestDateBar/RecallRequestDateBar";
import RecallManagement from "~/tm/components/totalManagementDetail/recallManagement/RecallManagement";
import CallContentManagement from "~/tm/components/totalManagementDetail/callContentManagement/CallContentManagement";
import { CSVLink } from "react-csv";
import moment from "moment";
import { IoIosSend } from "react-icons/io";

import {
  useGetVisitDetailTm,
  useAddVisits,
  useDeleteVisits,
  useUpdateVisitsItem,
} from "~apis/visits";
import { useGetChannelList, useGetAllChannelList } from "~apis/channels";
import { useGetHeadList } from "~apis/head";
import { useGetCSList } from "~apis/counselors";
import {
  useDeleteRecall_Tm,
  useAddRecall_Tm,
  useUpdateRecall,
} from "~apis/recalls";
import {
  useDeleteCounseling,
  useAddCounseling,
  useUpdateCounseling,
} from "~apis/counselings";
import { useGetDoctorList } from "~apis/doctors";
import useAlert from "~hooks/useAlert";

import instanceAxios from "~apis/_util";

import "./totalManagementDetail.scss";

function Controls({
  uiData,
  setUiData,
  addCounseling,
  history,
  openAlert,
  onClickApply,
  excelUploadBtnClick,
  isEdit,
  phoneData,
  deleteRecall,
  addRecall,
  visitDetailTm,
}) {
  const { openConfirm } = useAlert();

  const onClick = React.useCallback(
    (event) => {
      // if (
      //   10 <= uiData.counselings.length ||
      //   uiData.counselings?.some((x) => x.editMode)
      // ) {
      //   return;
      // }

      // if (!uiData.visit.id) {
      //   openAlert("먼저 DB를 등록해야합니다");
      //   return;
      // }

      const nowDate = new Date();

      const callDateString = `${nowDate.getFullYear()}-${
        nowDate.getMonth() + 1
      }-${nowDate.getDate()}`;

      setUiData(
        produce((draft) => {
          draft.counselings.push({
            callDate: callDateString,
            callTime: `${nowDate.getHours()}:${nowDate.getMinutes()}`,
            calltime: `${nowDate.getHours()}:${nowDate.getMinutes()}`,
            callDatetime:
              callDateString + ` ${nowDate.getHours()}:${nowDate.getMinutes()}`,
            progressContent: "",
            category: "",
            counselingContent: "",
            recallRequestDate: "",
            editMode: true,
          });
        }),
      );
    },
    [uiData, setUiData, openAlert],
  );

  useEffect(() => {
    if (!isEdit && uiData.counselings.length === 0) {
      onClick();
    }
  }, []);

  const phoneOnClickEvent = () => {
    // phoneData
    history.push(`/tm/sms/send?phone=${phoneData}`);
  };

  const addRecallUI = React.useCallback(
    async (event) => {
      if (uiData.recalls.length) {
        const confirm = await openConfirm(
          "정말 리콜 정보를 삭제하시겠습니까?",
          "경고",
        );

        if (confirm) {
          for (const recall of uiData.recalls) {
            if (recall.id) {
              await deleteRecall(recall.id);
            }
          }
          setUiData(
            produce((draft) => {
              draft.recalls.length = 0;
            }),
          );
        }
      } else {
        if (!uiData.visit.id) {
          openAlert("먼저 DB를 등록해야합니다");
          return;
        }

        const nowDate = new Date();
        nowDate.setMonth(nowDate.getMonth() + 1);

        const newData = {
          visit: uiData.visit.id,
          head: null,
          callCount: 1,
          result: false,
          recallDate: nowDate,
          etc: "",
          recallNum: 1,
        };
        await addRecall(newData);
        visitDetailTm.refetch();
      }
    },
    [
      uiData,
      setUiData,
      openConfirm,
      openAlert,
      deleteRecall,
      addRecall,
      visitDetailTm,
    ],
  );

  return (
    <div className="controls">
      {/* {!isEdit && ( */}

      <>
        <Button
          variant="contained"
          color="secondary"
          className="applyButton"
          onClick={onClickApply}
        >
          <IoIosSend size="1.6rem" />
          <span>{!isEdit ? "등록" : "수정"}</span>
        </Button>
      </>

      <>
        <Button
          variant="contained"
          color="primary"
          className="applyButton"
          onClick={phoneOnClickEvent}
        >
          <IoIosSend size="1.6rem" />
          <span>문자 전송</span>
        </Button>
      </>

      {/* )} */}

      {isEdit && (
        <>
          <Button
            variant="contained"
            color="primary"
            className="addButton"
            onClick={onClick}
          >
            <span>상담추가 +</span>
          </Button>

          <Button
            variant="contained"
            color="primary"
            className="addButton"
            onClick={addRecallUI}
          >
            <span>{uiData.recalls.length ? "리콜진행취소" : "리콜관리"}</span>
          </Button>
        </>
      )}

      <Link to="/tm/totalManagement">
        <Button variant="contained" color="primary" className="backButton">
          <span>목록</span>
        </Button>
      </Link>
    </div>
  );
}

export default function TotalManagementDetail() {
  const access = window.localStorage.getItem("user.access");

  const history = useHistory();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const { openAlert } = useAlert();

  const visitDetailTm = useGetVisitDetailTm(id);
  const channelList = useGetAllChannelList();
  const headList = useGetHeadList();
  const csList = useGetCSList();
  const doctorList = useGetDoctorList();

  const { mutateAsync: addVisits } = useAddVisits();
  const { mutateAsync: deleteVisits } = useDeleteVisits();
  const { mutateAsync: updateVisitsItem } = useUpdateVisitsItem();
  const { mutateAsync: deleteRecall } = useDeleteRecall_Tm(id);
  const { mutateAsync: addRecall } = useAddRecall_Tm();
  const { mutateAsync: updateRecall } = useUpdateRecall(id);
  const { mutateAsync: deleteCounseling } = useDeleteCounseling();
  const { mutateAsync: addCounseling } = useAddCounseling();
  const { mutateAsync: updateCounseling } = useUpdateCounseling();

  const [uiData, setUiData] = React.useState({
    visit: {
      inflowDate: null,
      inflowYear: null,
      inflowMonth: null,
      inflowFullDate: null,
      inflowRoute: "",
      inflowRouteDetail: "",
      referralPatientIndex: "",
      name: null,
      phone: "",
      age: 0,
      address1: "",
      address2: "",
      address3: "",
      isRepeatVisit: false,
      counselor: null,
      doctor: null,
      reservationDate: null,
      visitDate: "",
      chartNumber: "",
      cure: "",
      status: null,
      recallRequestDate: null,
      recallComplete: null,
      possibility: 100,
      type: "tm",
    },
    recalls: [],
    counselings: [],
  });

  React.useEffect(async () => {
    if (!visitDetailTm.data) {
      return;
    }

    setUiData(
      produce((draft) => {
        Object.assign(draft, visitDetailTm.data);
      }),
    );
  }, [visitDetailTm.data]);

  const isFetching =
    visitDetailTm.isFetching || channelList.isFetching || headList.isFetching;
  const isError =
    visitDetailTm.isError || channelList.isError || headList.isError;

  const onClickDelete = React.useCallback(async () => {
    if (uiData.visit.id) {
      await deleteVisits(uiData.visit.id);
      history.push("/tm/totalManagement");
    }
  }, [uiData, history, deleteVisits]);

  const onClickApply = React.useCallback(async () => {
    let getData = false;

    if (uiData.visit.phone) {
      getData = (
        await instanceAxios.get(
          `/Visits?type=tm&phone=${uiData.visit.phone}${
            uiData.visit.id ? `id_ne=${uiData.visit.id}` : ""
          }`,
        )
      )[0];
    }

    const newData = produce(uiData.visit, (draft) => {
      if (getData) {
        draft.status = "중복";
        if (getData.counselor) {
          draft.counselor = getData.counselor.id;
        }
      }

      if (!draft.reservationDate) {
        draft.reservationDate = null;
      }
      if (!draft.visitDate) {
        draft.visitDate = null;
      }
      draft.inflowDate = moment(
        `${draft.inflowYear}-${draft.inflowMonth}-${draft.inflowFullDate}`,
      );
      if (!draft.inflowDate._isValid) {
        draft.inflowDate = "";
      }

      if (uiData.recalls?.[0]) {
        draft.status = `부재${uiData.recalls?.[0].callCount}`;
      }
      delete draft.id;
    });

    const requireds = ["inflowYear", "inflowDate", "inflowMonth"];
    const error = requireds.some((v) => newData[v] === "");

    if (error) {
      alert("유입경로(년,월,일)를 모두 입력해주세요.");
      return;
    }

    if (uiData.visit.id) {
      await updateVisitsItem([uiData.visit.id, newData]);
    } else {
      const counseling = uiData.counselings[0];

      const callDatetime = moment(
        `${counseling.callDate} ${counseling.callTime}:00`,
      );

      const newData2 = {
        callDate: counseling.callDate,
        callDatetime: callDatetime._isValid
          ? callDatetime.format("YYYY-MM-DD HH:mm:ss")
          : null,
        progressContent: counseling.progressContent,
        category: counseling.category,
        counselingContent: counseling.counselingContent,
        recallRequestDate: counseling.recallRequestDate || null,
      };

      if (newData2.category === "") {
        alert("상담항목을 선택해주세요.");
        return;
      }

      try {
        const outsideData = {};

        if (access.includes("외부입력")) {
          outsideData.outside = access.replace("외부입력", "");
        }

        const res = await addVisits({ ...newData, ...outsideData });

        newData2.visit = res.id;

        await addCounseling(newData2);

        history.push(`/tm/totalManagement`);
      } catch (error) {
        console.error(error);
      }
    }

    //

    await instanceAxios.post("/visits/allRecallChecking", {
      id: uiData.visit.id,
    });

    visitDetailTm.refetch();

    // history.push("/counselor/counseling");
  }, [uiData, history, updateVisitsItem, addVisits, visitDetailTm]);

  const onClickVisitApply = async () => {
    const data = { status: uiData.visit.status };

    if (uiData.visit.id) {
      await updateVisitsItem([uiData.visit.id, data]);
    } else {
      try {
        const res = await addVisits(data);

        //history.push(`/tm/totalManagement/detail/${res.id}`);
        history.back();
      } catch (error) {
        console.error(error);
      }
    }

    await instanceAxios.post("/visits/allRecallChecking", {
      id: uiData.visit.id,
    });

    visitDetailTm.refetch();
  };

  const onClickRecallApply = async () => {
    const data = { reservationDate: uiData.visit.reservationDate };

    if (!data.reservationDate) {
      alert("내원 예약 날짜를 선택해주세요.");
      return;
    }

    if (uiData.visit.id) {
      await updateVisitsItem([uiData.visit.id, data]);
    } else {
      try {
        const res = await addVisits(data);
        history.push(`/tm/totalManagement/detail/${res.id}`);
      } catch (error) {
        console.error(error);
      }
    }

    await instanceAxios.post("/visits/allRecallChecking", {
      id: uiData.visit.id,
    });

    visitDetailTm.refetch();
  };

  const onClickRecallApplyCanel = async () => {
    const data = { reservationDate: null, status: "예약 취소" };

    if (uiData.visit.id) {
      await updateVisitsItem([uiData.visit.id, data]);
    } else {
      try {
        await addVisits(data);
      } catch (error) {
        console.error(error);
      }
    }

    await instanceAxios.post("/visits/allRecallChecking", {
      id: uiData.visit.id,
    });

    visitDetailTm.refetch();
  };

  useEffect(() => {
    // instanceAxios.post("/visits/allRecallChecking");
  }, []);

  // const getCsvData = React.useCallback(() => {
  //   if (isFetching || isError) {
  //     return [];
  //   }

  //   const data = [];
  //   return data;
  // });

  return (
    <div className="totalManagementDetail">
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <DbInfo
            {...{
              uiData,
              setUiData,
              deleteRecall,
              addRecall,
              channelList,
              csList,
              openAlert,
              visitDetailTm,
              onClickApply,
              isEdit,
              id,
              doctorList,
            }}
          />
          <DbStateManagement
            {...{
              uiData,
              setUiData,
              updateVisitsItem,
              onClickApply,
              onClickVisitApply,
              isEdit,
            }}
          />
          {/* {isEdit && (
            <Button
              variant="contained"
              color="primary"
              className="excelDownButton"
            >
              <CSVLink
                data={getCsvData()}
                filename={"download.csv"}
                target="_blank"
                className="csvLink"
              >
                <RiFileExcel2Fill size="1.5rem" />
                <span>Excel 다운</span>
              </CSVLink>
            </Button>
          )} */}
          {isEdit && (
            <>
              <RecallRequestDateBar
                {...{
                  uiData,
                  setUiData,
                  updateVisitsItem,
                  onClickRecallApply,
                  onClickRecallApplyCanel,
                }}
              />

              <RecallManagement
                {...{
                  uiData,
                  setUiData,
                  deleteRecall,
                  addRecall,
                  updateRecall,
                  openAlert,
                  csList,
                  visitDetailTm,
                  onClickDelete,
                }}
              />
            </>
          )}
          <CallContentManagement
            {...{
              uiData,
              setUiData,
              deleteCounseling,
              addCounseling,
              updateCounseling,
              openAlert,
              visitDetailTm,
              isEdit,
            }}
          />
          <Controls
            {...{
              uiData,
              setUiData,
              addCounseling,
              history,
              openAlert,
              onClickApply,
              isEdit,
              phoneData: uiData.visit.phone,
              deleteRecall,
              addRecall,
              visitDetailTm,
            }}
          />{" "}
        </>
      )}
    </div>
  );
}
