import { atom } from "recoil";

export const overlayLoadingState = atom({
  key: "overlayLoadingState",
  default: {
    open: false,
    text: "잠시만 기다려주세요",
    subText: "",
  },
});

export const alertState = atom({
  key: "alertState",
  default: {
    open: false,
    title: "알림",
    content: "",
    type: "alert",
    result: false,
  },
});
