import React from "react";
import { toast as _toast } from "react-toastify";
import { Icon } from "@material-ui/core";

export default function useToast() {
  /*
   * "react-toastify" 패키지를 사용한 함수입니다
   * - title: toast 제목 (필수)
   * - content: toast 내용 (선택적)
   * - options: toast 옵션 (선택적) (참고: https://fkhadra.github.io/react-toastify/api/toast)
   */
  const toast = (title, content, options = {}, status = "default") =>
    _toast(
      <div className="toastContent">
        {status === "warning" && (
          <Icon color="error" className="warningIcon">
            warning
          </Icon>
        )}

        {status === "error" && <Icon color="error">error</Icon>}

        <div className="message">
          <div className="title">{title}</div>
          <div className="content">{content}</div>
        </div>
      </div>,
      options,
    );

  const warningToast = (title, content, options = {}) =>
    toast(title, content, options, "warning");
  const errorToast = (title, content, options = {}) =>
    toast(title, content, options, "error");

  return { toast, warningToast, errorToast };
}
