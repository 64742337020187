import React from "react";
import produce from "immer";
import DateInput from "~ui/dateInput/DateInput";
import Button from "@material-ui/core/Button";
import { IoIosSend } from "react-icons/io";
import moment from "moment";
import useAlert from "~hooks/useAlert";

import "./visitDateBar.scss";

export default function VisitDateBar({ uiData, setUiData, updateVisitsItem }) {
  const { openAlert } = useAlert();
  const data = uiData.visit;

  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;

      setUiData(
        produce((draft) => {
          draft.visit[name] = value;
        }),
      );
    },
    [setUiData],
  );

  const applyVisitDate = React.useCallback(async () => {
    if (data.id) {
      if (data.visitDate) {
        await updateVisitsItem([data.id, { visitDate: data.visitDate }]);
      }
    } else {
      openAlert("먼저 DB를 등록해야합니다");
    }
  }, [data, updateVisitsItem, openAlert]);

  return (
    <div className="visitDateBar">
      <div>
        <span>내원 예약 날짜 : {data.reservationDate || "-"}</span>
      </div>

      <div>
        <span>내원 날짜</span>
        <DateInput
          className="visitDateInput"
          value={data.visitDate || ""}
          name="visitDate"
          onChange={onChange}
          style={{ width: "31rem", height: "2.95rem" }}
        />
      </div>
      <Button
        variant="contained"
        color="secondary"
        className="addButton"
        onClick={applyVisitDate}
      >
        <IoIosSend size="1.6rem" />
        <span>등록</span>
      </Button>
    </div>
  );
}
