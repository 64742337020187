import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import "./contentView.scss";

import Admin from "~/admin/Admin";
import Doctor from "~/doctor/Doctor";
import Counselor from "~/counselor/Counselor";
import Tm from "~/tm/Tm";
import ExternalCompany from "~/external/ExternalCompany";

export default function ContentView() {
  return (
    <div className="contentWrap">
      <div className="contentView">
        <Switch>
          <Route component={Admin} path={["/admin", "/channel"]} />
          <Route component={Doctor} path="/doctor" />
          <Route component={Counselor} path="/counselor" />
          <Route component={Tm} path="/tm" />
          <Route component={ExternalCompany} path="/externalCompany" />
        </Switch>
      </div>
    </div>
  );
}
