import React, { useState } from "react";
import { RecoilRoot } from "recoil";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "./app.scss";
import "react-toastify/dist/ReactToastify.css";

// Import Material UI Theme
import { MuiTheme } from "~global/assets/js/theme";

// Global UI imports
import AutoScrollTop from "~ui/autoScrollTop/AutoScrollTop";
import OverlayLoading from "~ui/overlayLoading/OverlayLoading";
import Alert from "~ui/alert/Alert";
import ApiErrorMessage from "~ui/apiErrorMessge/ApiErrorMessage";
import Login from "~ui/login/Login";

import Layout from "~ui/layout/Layout";
import ContentView from "~ui/contentView/ContentView";
import SideNavigation from "~ui/sideNavigation/SideNavigation";
import TopNavigation from "~ui/topNavigation/TopNavigation";
import Logo from "~ui/logo/Logo";
import Tools from "~ui/tools/Tools";
import Title from "~ui/title/Title";
import Profile from "~ui/profile/Profile";
import { useGetMe } from "./apis/users";
import instanceAxios from "./apis/_util";
import SignIn from "~/sign/SignIn";
import useAuth from "./hooks/useAuth";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

function AuthPage({ children }) {
  const [userFetch, setUserFetch] = useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await instanceAxios.get("/users/me");

        // const enablePagePath = (() => {switch (response.access) {
        //   case "원장":
        //     return window.location.pathname.indexOf("/doctor") > -1
        //   case "실장":
        //     return window.location.pathname.indexOf("/counselor") > -1
        //   case "TM":
        //     return window.location.pathname.indexOf("/tm") > -1
        //   default:
        //     return true
        // }})()

        if (window.location.pathname === "/") {
          redirectAccessPage(response.access);
        } else {
          setUserFetch(response);
        }
      } catch (e) {
        window.localStorage.removeItem("user.token");
        window.localStorage.removeItem("user.id");
        window.localStorage.removeItem("user.role");
        window.localStorage.removeItem("user.access");
        window.location.href = "/sign-in";
      }
    })();
  }, []);

  function redirectAccessPage(access) {
    switch (access) {
      case "원장":
        window.location.href = "/doctor/monthlySalesStatistics";
        break;

      case "실장":
        window.location.href = "/counselor/counseling";
        break;

      case "마케팅팀":
        window.location.href = "/tm/totalManagement";
        break;

      case "CS팀":
        window.location.href = "/tm/totalManagement";
        break;

      case "TM팀장":
      case "TM팀A":
      case "TM팀B":
      case "TM팀C":
      case "TM팀D":
        window.location.href = "/tm/totalManagement";
        break;

      case "외부입력A":
      case "외부입력B":
        window.location.href = "/tm/totalManagement";
        break;

      default:
        window.location.href = "/admin";
    }
  }

  if (!userFetch) {
    return null;
  } else {
    window.localStorage.setItem("user.access", userFetch.access);
  }

  return (
    <>
      {/* Global UI */}
      <AutoScrollTop />
      <OverlayLoading />
      <Alert />
      <Login user={userFetch} />
      <ApiErrorMessage queryClient={queryClient} />
      <ToastContainer />

      <Layout>
        <Layout.SideFrame>
          <Logo />
          <SideNavigation user={userFetch} />
          <Tools />
        </Layout.SideFrame>

        <Layout.MainFrame>
          <div className="UI-header">
            <Title />

            <div className="right">
              <TopNavigation user={userFetch} />
              <Profile />
            </div>
          </div>

          <ContentView />
        </Layout.MainFrame>
      </Layout>
    </>
  );
}

export default function App() {
  return (
    <div id="app">
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={MuiTheme}>
            <ThemeProvider theme={MuiTheme}>
              <BrowserRouter>
                <Switch>
                  <Route component={SignIn} path="/sign-in" exact />
                  <Route component={AuthPage} path="/" />
                </Switch>
              </BrowserRouter>
            </ThemeProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </div>
  );
}
