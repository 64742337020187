import React from "react";
import produce from "immer";
import Button from "@material-ui/core/Button";
import { IoIosSend } from "react-icons/io";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDeleteBroadcasting } from "~apis/broadcastings";

import "./channelViewControls.scss";

const MAX_PAGE_BUTTON_NUM = 9;

function PageButton({ pageNum, active, onClick }) {
  let name = "page";
  let content = "";

  if (pageNum === "<") {
    name = "back";
    content = (
      <>
        <RiArrowDropLeftLine />
        <RiArrowDropLeftLine />
      </>
    );
  } else if (pageNum === ">") {
    name = "forward";
    content = (
      <>
        <RiArrowDropRightLine />
        <RiArrowDropRightLine />
      </>
    );
  } else {
    if (!Number.isInteger(Number(pageNum))) name = "dots";

    content = <span>{String(pageNum).padStart(2, "0")}</span>;
  }

  const className = classNames("PageButton", {
    active: active,
  });

  return (
    <div
      className={className}
      data-page-num={pageNum}
      name={name}
      onClick={onClick}
    >
      {content}
    </div>
  );
}

function ChannelViewControlPages({ pages, activePage, onClickPageButton }) {
  activePage = Number.isInteger(activePage) ? activePage : 1;
  const range = Math.max(
    5,
    MAX_PAGE_BUTTON_NUM % 2 ? MAX_PAGE_BUTTON_NUM : MAX_PAGE_BUTTON_NUM - 1,
  );
  const halfRange = Math.floor(range / 2);
  let startPageNum = Math.max(1, activePage - halfRange);
  let endPageNum = startPageNum + range - 1;
  let pageNumList = [];

  if (pages < endPageNum) {
    startPageNum = Math.max(1, startPageNum + (pages - endPageNum));
    endPageNum = pages;
  }

  pageNumList = Array.from(
    { length: endPageNum - startPageNum + 1 },
    (_, i) => startPageNum + i,
  );

  if (1 < startPageNum) {
    pageNumList.splice(0, 2, 1, "...");
  }

  if (endPageNum < pages) {
    pageNumList.splice(-2, 2, "...", pages);
  }

  const items = pageNumList.map((pageNum, i) => (
    <PageButton
      key={i}
      pageNum={pageNum}
      onClick={onClickPageButton}
      active={pageNum === activePage}
    />
  ));

  return (
    <div className="channelViewControlPages">
      <PageButton pageNum="<" onClick={onClickPageButton} />
      {items}
      <PageButton pageNum=">" onClick={onClickPageButton} />
    </div>
  );
}

export default function ChannelViewControl({
  checkedItems,
  searchQuery,
  setSearchQuery,
  broadcastingList,
  pages,
  onPageChange,
}) {
  const { mutateAsync: deleteBroadcasting } = useDeleteBroadcasting();
  const onClickPageButton = React.useCallback(
    (event) => {
      const name = event.target.name || event.target.getAttribute("name");
      const pageNum = event.target.dataset.pageNum;
      let pageTo = parseInt(pageNum);

      if (name !== "page") {
        if (name === "back") {
          pageTo = Math.max(1, searchQuery.page - 1);
        } else if (name === "forward") {
          pageTo = Math.min(pages, searchQuery.page + 1);
        } else {
          return;
        }
      }

      setSearchQuery(
        produce((draft) => {
          draft.page = pageTo;
        }),
      );
      onPageChange(pageTo);
    },
    [pages, searchQuery, setSearchQuery, onPageChange],
  );
  const deleteCheckedItems = React.useCallback(async () => {
    if (!checkedItems.length) {
      return;
    }
    for (const broadcasting_id of checkedItems) {
      await deleteBroadcasting(broadcasting_id);
    }
    broadcastingList.refetch();
  }, [checkedItems, broadcastingList, deleteBroadcasting]);

  const urlAllResult = new URL(window.location.href).searchParams.get("date");

  return (
    <div className="channelViewControl">
      <ChannelViewControlPages
        pages={pages}
        activePage={searchQuery.page}
        onClickPageButton={onClickPageButton}
      />
      <Link
        to={`/channel/addChannel/detail${urlAllResult ? "?date=all" : ""}`}
        className="addButtonLink"
      >
        <Button variant="contained" color="primary" className="addButton">
          <IoIosSend size="1.8rem" />
          <span>추가</span>
        </Button>
      </Link>
      <Button
        variant="contained"
        color="primary"
        className="deleteButton"
        onClick={deleteCheckedItems}
      >
        <MdClose size="2rem" />
        <span>선택삭제</span>
      </Button>
    </div>
  );
}
