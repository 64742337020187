import React from "react";
import { Route } from "react-router-dom";
import SideNavigationItem from "./sideNavigationItem/SideNavigationItem";
import SITE_MAP from "~global/assets/js/siteMap";

import "./sideNavigation.scss";

export default function SideNavigation({ user }) {
  const items = Object.keys(SITE_MAP).map((page, i) => (
    <Route key={i} path={`/${page}/`}>
      {SITE_MAP[page][1].filter(x => {
        const menuName = Array.isArray(x[1]) ? x[1][0] : x[1];

        switch (user.access) {
          case 'CS팀':
            return !menuName.includes('통계')

          case 'TM팀D':
            return !['총 DB관리', '미내원 고객관리'].includes(menuName)
          
          case '외부입력A':
          case '외부입력B':
            return ['총 DB관리', '상담환자 DB'].includes(menuName)
        
          default:
            return true;
        }
      }).map((menu, j) => (
        <SideNavigationItem
          key={j}
          icon={menu[0]}
          text={menu[1]}
          to={`/${page}/${menu[2]}`}
        />
      ))}
    </Route>
  ));

  return <div className="sideNavigation">{items}</div>;
}
