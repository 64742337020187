import React from "react";
import produce from "immer";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextInput from "~ui/textInput/TextInput";
import SelectInput from "~ui/selectInput/SelectInput";
import { FaSearch } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { searchQueryState } from "~admin/stores/channel";

import "./search.scss";

export default function Search(Data) {
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);
  const [state, setState] = React.useState({...searchQuery})

  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      setState(
        produce((draft) => {
          draft[name] = event.target.value;
        }),
      );
    },
    [setState],
  );


  const Items = Data.SearchData.map((v, i) => {
    return ( <MenuItem key={v} selected={i == 0} value={v}> {v} </MenuItem> );
  });


  return (
    <div className="GlobalsearchBar" style={{ width: "47rem" }}>
      <div>
        <span>Search</span>
      </div>
      <div>
      <SelectInput
        className="selectType"
        value={Data.SearchData[0]}
        onChange={onChange}
        name="category"
      >
        {Items}
      </SelectInput>
      </div>
      <div>
      <TextInput
        className="patientNameInput"
        name="keyword"
        value={state.keyword}
        onChange={onChange}
        style={{ width: "20.1rem", height: "2.68rem" }}
      />
      </div>
      
      <Button variant="contained" color="secondary" className="searchButton" onClick={() => {
        setSearchQuery({...state})
      }}>
        
        <span>{Data.SearchButton.Text}</span>
        <FaSearch size="2.2rem" />
        
      </Button>
    </div>
  );
}