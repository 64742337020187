import React from "react";
import { Route } from "react-router-dom";
import TopNavigationItem from "./topNavigationItem/TopNavigationItem";
import SITE_MAP from "~global/assets/js/siteMap";

import "./topNavigation.scss";

export default function TopNavigation({ user }) {
  const items = Object.keys(SITE_MAP).filter((page) => {
    switch (user.access) {
      case '총관리자':
        return true;

      case '원장':
        return ['doctor', 'channel'].includes(page);

      case '실장':
        return ['counselor', 'channel'].includes(page);

      case '마케팅팀':
        return ['tm', 'channel'].includes(page);
      
      case 'CS팀':
        return ['counselor', 'tm'].includes(page);
      
      case 'TM팀장':
        return ['tm', 'channel', 'admin'].includes(page);
      
      case 'TM팀A':
        return ['tm'].includes(page);

      case 'TM팀B':
        return ['tm'].includes(page);

      case 'TM팀C':
        return ['tm'].includes(page);

      case 'TM팀D':
        return ['tm'].includes(page);
    
      case '외부입력A':
        return ['tm', 'counselor'].includes(page);
      
      case '외부입력B':
        return ['tm', 'counselor'].includes(page);
        
      default:
        return false
    }
  }).map((page, i) => (
    <TopNavigationItem
      key={i}
      text={SITE_MAP[page][0]}
      basePath={`/${page}/`}
      to={`/${page}/`}
    />
  ));

  return <div className="topNavigation">{items}</div>;
}
