import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "~/global/components/tamplete/title/title";
import EditForm from "~/global/components/tamplete/editForm/EditForm";
import { useGetDoctorItem } from "~apis/doctors";

import "./AddDoctorEdit.scss";

export default function AddDoctorEdit() {
  const { id } = useParams();
  let { isFetching, isError, data } = useGetDoctorItem(id);

  if (isFetching) return <div style={{ marginTop: "2rem" }}>로딩 중...</div>;

  const inputs = [
    { label: "Doctors", type: "text", value: data.name, name: "name" },
  ];

  const Form = id ? "editdoctor" : "adddoctor";

  return (
    !isError && (
      <div className="addDoctorEdit">
        <PageTitle title="원장 추가" />
        <EditForm idx={id} form={Form} {...{ inputs }} />
      </div>
    )
  );
}
