import React from "react";
import moment from "moment";
import "moment/locale/ko";

import "./sendRequestLists.scss";

const SendRequestLists = ({ sendData }) => {
  const listItemMake = (v) => {
    const previewData = [
      ["보낸시간", moment(v.reg_date).format("YYYY.MM.DD / A h시 mm분")],
      [
        "발신번호",
        v.sender.replace(/^([0-9]{3})([0-9]{4})([0-9]{4})$/, "$1-$2-$3"),
      ],
      ["전송건수", `${v.sms_count}건`],
      [
        "성공/실패",
        `${+v.sms_count - +v.fail_count} / ${v.fail_count} 건${
          +v.fail_count <= 0 ? "" : "(자동환불완료)"
        }`,
      ],
    ];

    return (
      <div className="item">
        <div className="left">
          <p>문자 내역</p>
          <pre className="text">{v.msg}</pre>
        </div>

        <div className="right">
          {previewData.map((x) => (
            <div>
              <p>{x[0]}</p>
              <p>{x[1]}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="sendRequestList">
      {sendData?.list?.map((v) => listItemMake(v))}
    </div>
  );
};

export default SendRequestLists;
