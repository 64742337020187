import React from "react";

import SelectInput from "~ui/selectInput/SelectInput";
import { Button, MenuItem } from "@material-ui/core";
import StatisticsChart_1 from "~/tm/components/statisticsManagement/statisticsChart_1/StatisticsChart_1";
import StatisticsChart_2 from "~/tm/components/statisticsManagement/statisticsChart_2/StatisticsChart_2";
import SearchBar from "~/tm/components/statisticsManagement/searchBar/SearchBar";
import StatisticsManagementList from "~/tm/components/statisticsManagement/statisticsManagementList/StatisticsManagementList";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useGetAgencyEvaluation } from "~apis/channels";
import { CSVLink } from "react-csv";
import qs from "qs";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { csvDataState } from "~/global/stores/recoil";
import { useGetCSList } from "~apis/counselors";

import "./statisticsAgencyManagement.scss";

//channelEvaluation과 동일
export default function StatisticsAgencyManagement() {
  const [searchQuery, setSearchQuery] = React.useState({
    year: moment().year(),
    month: moment().month() + 1,
    counselor: "",
    sortType: "ASC",
  });
  const { data: csList = [], isFetching: csIsFetching } = useGetCSList();
  const [queryString, setQueryString] = React.useState("");
  const csvData = useRecoilValue(csvDataState);

  const channelEvaluation = useGetAgencyEvaluation(queryString);

  const isFetching = channelEvaluation.isFetching;
  const isError = channelEvaluation.isError;

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_year: searchQuery.year,
        q_month: searchQuery.month,
        counselor: searchQuery.counselor,
        q_sortType: searchQuery.sortType,
      }),
    );
  }, []);

  const onClickSearch = React.useCallback(
    (sortType = searchQuery?.sortType) => {
      setQueryString(
        qs.stringify({
          q_year: searchQuery.year,
          q_month: searchQuery.month,
          counselor: searchQuery.counselor,
          q_sortType: sortType,
        }),
      );
      channelEvaluation.refetch();
    },
    [searchQuery, setQueryString, channelEvaluation],
  );

  return (
    <div className="statisticsManagement">
      <div className="viewTitle">월별 업체 DB 통계</div>
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <StatisticsChart_1 evaluationData={channelEvaluation.data} agency />
          <StatisticsChart_2 evaluationData={channelEvaluation.data} agency />
          <div className="searchBarArea">
            <div>
              <SearchBar
                {...{ searchQuery, setSearchQuery, onClickSearch }}
                searchLabel="업체평가"
              />
            </div>
          </div>

          <StatisticsManagementList
            evaluationData={channelEvaluation.data}
            csvDataLength={csvData.length}
            agency
          />

          <div className="excelDownButtonWrap">
            <Button
              variant="contained"
              color="primary"
              className="excelDownButton"
            >
              <CSVLink
                data={csvData}
                filename={"download.csv"}
                target="_blank"
                className="csvLink"
              >
                <RiFileExcel2Fill size="1.5rem" />
                <span>Excel 다운</span>
              </CSVLink>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
