import React from "react";

import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { FaClipboardList } from "react-icons/fa";
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import produce from "immer";

import "./searchBar.scss";

export default function SearchBar({
  searchQuery,
  setSearchQuery,
  onClickSearch,
  subFields,
  searchLabel = "채널평가",
}) {
  const now = new Date();
  const nowYear = now.getFullYear();

  const onDateInputChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setSearchQuery(
        produce((draft) => {
          draft[name] = value;
        }),
      );
    },
    [setSearchQuery],
  );

  const yearItems = [...Array(100).keys()].map((i) => {
    const year = nowYear - i;
    return (
      <MenuItem style={{ fontSize: "1rem" }} key={year} value={year}>
        {year}년
      </MenuItem>
    );
  });

  const monthItems = [...Array(12).keys()].map((i) => {
    const month = i + 1;
    return (
      <MenuItem style={{ fontSize: "1rem" }} key={month} value={month}>
        {month.toString().padStart(2, "0")}월
      </MenuItem>
    );
  });

  const channelSortBtn = React.useCallback(
    (sortType) => {
      setSearchQuery(
        produce((draft) => {
          draft.sortType = sortType;
        }),
      );

      onClickSearch(sortType);
    },
    [setSearchQuery],
  );

  return (
    <>
      <div className="searchBar">
        <span>월별 정리</span>
        <SimpleSelectInput
          className="yearSelectInput"
          value={searchQuery.year}
          name="year"
          onChange={onDateInputChange}
          style={{ width: "16.5rem", height: "3rem" }}
        >
          {yearItems}
        </SimpleSelectInput>
        <span>~</span>
        <SimpleSelectInput
          className="monthSelectInput"
          value={searchQuery.month}
          name="month"
          onChange={onDateInputChange}
          style={{ width: "16.5rem", height: "3rem" }}
        >
          {monthItems}
        </SimpleSelectInput>

        {subFields}

        <Button
          variant="contained"
          color="secondary"
          className="searchButton"
          onClick={() => onClickSearch()}
        >
          <span>{searchLabel}</span>
        </Button>
      </div>

      <div className="searchBar">
        <span>업체 정렬</span>

        <Button
          variant="contained"
          color="secondary"
          className="channelSortBtn"
          onClick={() => channelSortBtn("ASC")}
        >
          <BsArrowUp size="1.3rem" />
          <span>오름차순</span>
        </Button>

        <Button
          variant="contained"
          color="secondary"
          className="channelSortBtn"
          onClick={() => channelSortBtn("DESC")}
        >
          <BsArrowDown size="1.3rem" />
          <span>내림차순</span>
        </Button>
      </div>
    </>
  );
}
