import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import backgroundImage from "~background/background2.png";
import PlanningOptions from "~/admin/components/addChannelDetail/planningOptions/PlanningOptions";
import ChannelDataForm from "~/admin/components/addChannelDetail/channelDataForm/ChannelDataForm";
import ChannelBroadcastings from "~/admin/components/addChannelDetail/broadcastList/BroadcastList";
import { IoIosSend } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import produce from "immer";
import moment from "moment";

import {
  useAddBroadcasting,
  useDeleteBroadcasting,
  useUpdateBroadcasting,
  useGetBroadcastingItem,
} from "~apis/broadcastings";
import { useGetChannelList } from "~apis/channels";
import { useGetDoctorList } from "~apis/doctors";
import qs from "qs";
import useAlert from "~hooks/useAlert";

import "./addChannelDetail.scss";

function Controls({ onClickDelete, onClickEdit, onClickApply, isEdit }) {
  return (
    <div className="controls">
      {isEdit && (
        <>
          <Button
            variant="contained"
            color="primary"
            className="deleteButton"
            onClick={onClickDelete}
          >
            <MdClose size="2rem" />
            <span>삭제</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="editButton"
            onClick={onClickEdit}
          >
            <FaClipboardList size="1.6rem" />
            <span>수정</span>
          </Button>
        </>
      )}

      {/* {!isEdit && (
        <Button
          variant="contained"
          color="primary"
          className="applyButton"
          onClick={onClickApply}
        >
          <IoIosSend size="1.8rem" />
          <span>등록</span>
        </Button>
      )} */}
    </div>
  );
}

export default function AddChannelDetail() {
  const history = useHistory();
  const { id } = useParams();
  const { openAlert } = useAlert();

  const isEdit = Boolean(id);

  const broadcastingItem = useGetBroadcastingItem(id);
  const channelList = useGetChannelList();
  const doctorList = useGetDoctorList();

  const { mutateAsync: addBroadcasting } = useAddBroadcasting();
  const { mutateAsync: deleteBroadcasting } = useDeleteBroadcasting();
  const { mutateAsync: updateBroadcasting } = useUpdateBroadcasting();

  const urlGet = new URL(window.location.href).searchParams.get("date");

  const [uiData, setUiData] = React.useState({
    cost: "",
    initialFilmingCost: "",
    minute: 0,
    progress: true,
    next: true,
    note: "",
    weeklyRound: 0,
    weeklyCount: 0,
    channel: { agency: "", channelName: "" },
    appearDoctors: [
      {
        broadcastingDate: urlGet ? "2015-01" : moment().format("YYYY-MM"),
        doctor: null,
        editMode: true,
      },
    ],
  });

  React.useEffect(() => {
    if (!broadcastingItem.data) {
      return;
    }
    setUiData(
      produce((draft) => {
        Object.assign(draft, broadcastingItem.data);
        if (!draft.appearDoctors.length) {
          draft.appearDoctors = [
            {
              broadcastingDate: moment().format("YYYY-MM"),
              doctor: null,
              editMode: true,
            },
          ];
        }
      }),
    );
  }, [broadcastingItem.data]);

  const isFetching =
    broadcastingItem.isFetching ||
    channelList.isFetching ||
    doctorList.isFetching;

  const isError =
    broadcastingItem.isError || channelList.isError || doctorList.isError;
  // ========

  const onClickDelete = React.useCallback(async () => {
    if (uiData.id) {
      await deleteBroadcasting(uiData.id);
      history.push("/channel/channelManagement");
    }
  }, [uiData, history, deleteBroadcasting]);

  const onClickEdit = React.useCallback(async () => {
    if (!uiData.appearDoctors.some((appearDoctor) => appearDoctor.id)) {
      openAlert("방송을 등록해야 합니다");
      return;
    }
    if (!uiData.channel.id) {
      openAlert("채널을 선택해야 합니다");
      return;
    }

    if (uiData.id) {
      const newData = produce(uiData, (draft) => {
        draft.channel =
          draft.channel && draft.channel.id ? draft.channel.id : null;
        delete draft.id;
        delete draft.appearDoctors;
      });

      if (uiData.id) {
        await updateBroadcasting([uiData.id, newData]);
      } else {
        const result = await addBroadcasting(newData);
        history.push(`/channel/channelManagement/${result.id}`);
      }
    }
  }, [uiData, history, updateBroadcasting, addBroadcasting, openAlert]);

  const onClickApply = React.useCallback(async () => {
    if (!uiData.appearDoctors.some((appearDoctor) => appearDoctor.id)) {
      openAlert("방송을 등록해야 합니다");
      return;
    }
    if (!uiData.channel.id) {
      openAlert("채널을 선택해야 합니다");
      return;
    }

    const newData = produce(uiData, (draft) => {
      draft.channel =
        draft.channel && draft.channel.id ? draft.channel.id : null;

      delete draft.id;
      delete draft.appearDoctors;
    });

    if (uiData.id) {
      await updateBroadcasting([uiData.id, newData]);
    } else {
      try {
        await addBroadcasting(newData);
      } catch (error) {
        console.error(error);
      }
    }

    history.push("/channel/channelManagement");
  }, [uiData, history, updateBroadcasting, addBroadcasting, openAlert]);

  return (
    <div className="addChannelDetail">
      <span className="viewTitle">채널 리스트</span>
      {!isFetching && !isError && (
        <PlanningOptions {...{ uiData, setUiData }} />
      )}
      <img src={backgroundImage} alt="" className="backgroundImage" />
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <ChannelDataForm {...{ uiData, setUiData, channelList }} />
          <ChannelBroadcastings
            {...{ uiData, setUiData, doctorList, addBroadcasting, urlGet }}
          />
          <Controls {...{ onClickDelete, onClickEdit, onClickApply, isEdit }} />
        </>
      )}
    </div>
  );
}
