import React, { useState } from "react";
import produce from "immer";
import Checkbox from "@material-ui/core/Checkbox";
import TextInput from "~ui/textInput/TextInput";
import Select from "@material-ui/core/Select";
import SelectInput from "~ui/selectInput/SelectInput";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import { useDeleteChannel } from "~apis/channels";
import { useDeleteDoctor } from "~apis/doctors";
import { useDeleteCS } from "~apis/counselors";
import { useDeleteHead } from "~apis/head";
import { useDeleteUser } from "~apis/users";
import { useUpdateVisits } from "~apis/visits";
import { useHistory } from "react-router";
import { Route, Link } from "react-router-dom";
import TableAction from "~/global/components/ui/tableAction/TableAction";
import { useFormik } from "formik";

import { useGetChannelList } from "~apis/channels";

// 추가
import Button from "@material-ui/core/Button";
import { MdArrowDownward, MdSearch } from "react-icons/md";
// 추가

import "./table.scss";

export default function Table(Data) {
  let ShowAllitemCounts = Data?.ShowAllitemCounts || "true";
  const history = useHistory();

  const { mutateAsync: DelChannel } = useDeleteChannel(),
    { mutateAsync: DelDoctor } = useDeleteDoctor(),
    { mutateAsync: DelCS } = useDeleteCS(),
    { mutateAsync: DelHead } = useDeleteHead(),
    { mutateAsync: DelUser } = useDeleteUser();
  const { mutateAsync: UpdateVisit } = useUpdateVisits();

  async function VisitUpdate(idx, value) {
    await UpdateVisit({ counselor: value, idx: idx });
  }

  async function DelEvent(idx) {
    if (Data.Type == "Channel") await DelChannel(idx);
    if (Data.Type == "Doctor") await DelDoctor(idx);
    if (Data.Type == "CS") await DelCS(idx);
    if (Data.Type == "Head") await DelHead(idx);
    if (Data.Type == "User") await DelUser(idx);

    if (Data?.refetch) await Data?.refetch();
  }

  const [state, setState] = React.useState({
    id: "",
  });

  const [tmName, setTmName] = React.useState(null);

  const tmNameChange = (e) => {
    const value = e.target.value;
    setTmName((x) => value);
  };

  const onChangeValue = (e, name) => {
    setState(
      produce((v) => {
        if (Data.value) {
          const find = Data?.value.indexOf(e.target.value);

          if (find == -1) Data.value.push(e.target.value);
          else Data.value.splice(find, 1);
        }
      }),
    );
  };

  React.useEffect(() => {}, [state]);

  const [detailData, setDetailData] = React.useState({});
  const onChange = React.useCallback(
    (event, idx) => {
      const name = event.target.name;
      const type = event.target.type;
      const classList = event.target.classList;
      const value = event.target.value;

      if (name == "TM") VisitUpdate(idx, value);

      setDetailData(
        produce((draft) => {
          draft[name] = value;
        }),
      );
    },
    [setDetailData],
  );

  let TableHeadetml = "";
  let returnTableHtml = "";

  const notSearchArr = ["수정", ""];

  // ------- 수정

  const channelList = useGetChannelList();

  React.useEffect(() => {
    var url = new URL(window.location.href);
    var urlSearchPrams = url.searchParams;

    if (Data.useFilter && Array.from(urlSearchPrams).length) {
      const filter = Array.from(urlSearchPrams).reduce((acc, [key, value]) => {
        if (value.trim().length) {
          acc[key] = value;
        }

        return acc;
      }, {});

      Array.from(urlSearchPrams).forEach(([key, value]) => {
        formik.setFieldValue(key, value);
      });

      Data?.onFilterSearch?.(filter);
    }
  }, []);

  const formik = useFormik({
    initialValues: Data.TableTitle.reduce(
      (acc, x) => ({ ...acc, [x]: [] }),
      {},
    ),
    onSubmit: (values) => {
      const phone = values.phone;
      delete values.phone;

      const filter = Object.entries(values).reduce((acc, [key, value]) => {
        if (typeof value == "string" ? value.trim().length : value.length) {
          acc[key] = value;
        }

        return acc;
      }, {});
    
      // const testFilter = Object.entries({...filter}).filter((val, key) => {
      //   return val[0] !== "page";  
      // });

      // if (Data?.setAllSearch) {
      //   Data?.setAllSearch?.(testFilter.length ? true : false);
      // }

      filter.phoneFilter = phone;

      // if (testFilter.length) {
      //   filter.phoneFilter = true;
      // }

      const history_filter = Object.entries(values).filter(([key, value]) =>
        typeof value == "string" ? value.trim().length : value.length,
      );

      let nowURL = new URL(window.location.href);

      for (let searchValue of nowURL.searchParams.entries()) {
        nowURL.searchParams.delete(searchValue[0]);
      }

      if (history_filter.length) {
        history_filter.forEach(([key, getURL]) =>
          nowURL.searchParams.set(key, getURL),
        );
      }

      nowURL.searchParams.set("page", 1);
      history.push("?" + new URLSearchParams(nowURL.search).toString());

      Data?.onFilterSearch?.(filter);
    },
  });

  const routeList = ["TV 광고", "업체광고", "오프라인광고", "스탠다드치과"].map(
    (name, i) => (
      <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
        {name}
      </MenuItem>
    ),
  );
  // const companyNames = [...new Set(channelList.data.map((x) => x.agency))];
  // const _company = channelList.data.find(v => v.channelName === data.inflowRouteDetail)?.agency

  const channelNames = [
    ...new Set((channelList.data || []).map((x) => x.channelName)),
  ];

  const routeDetailList = channelNames.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const resetFormik = (value) => {
    formik.setFieldValue("경로", value);
    formik.setFieldValue("세부경로", "");
  };

  // 필터별로 셀렉트 추가
  const FilterInputs = (x) => {
    switch (x) {
      case "담당실장":
        return (
          <Select
            className="tmSelectBoxStyled"
            multiple
            fullWidth
            value={
              typeof formik.values[x] == "string"
                ? formik.values[x].split(",")
                : formik.values[x]
            }
            name={x}
            onChange={formik.handleChange}
          >
            {Data.head}
          </Select>
        );

      case "Doctor":
        return (
          <Select
            className="tmSelectBoxStyled"
            multiple
            fullWidth
            value={
              typeof formik.values[x] == "string"
                ? formik.values[x].split(",")
                : formik.values[x]
            }
            name={x}
            onChange={formik.handleChange}
          >
            {Data.doctor}
          </Select>
        );

      case "TM":
        return (
          <Select
            className="tmSelectBoxStyled"
            multiple
            fullWidth
            value={
              typeof formik.values[x] == "string"
                ? formik.values[x].split(",")
                : formik.values[x]
            }
            name={x}
            onChange={formik.handleChange}
          >
            {Data.TMdata}
          </Select>
        );

      case "광고":
        return (
          <Select
            className="tmSelectBoxStyled"
            fullWidth
            value={formik.values[x]}
            name={x}
            onChange={formik.handleChange}
          >
            <MenuItem value={""}>없음</MenuItem>
            {routeList}
          </Select>
        );

      case "업체":
        return (
          <>
            {
              <Select
                className="tmSelectBoxStyled"
                fullWidth
                value={formik.values[x]}
                name={x}
                onChange={formik.handleChange}
              >
                <MenuItem value={""}>없음</MenuItem>
                {routeDetailList}
              </Select>
            }
          </>
        );

      default:
        return (
          <input
            style={{ padding: "5px" }}
            value={formik.values[x]}
            name={x}
            onChange={formik.handleChange}
          />
        );
    }

    // 필터별로 셀렉트 추가
  };

  // ------ 수정

  const [sort, setSort] = useState([
    Data?.sort?.[0] || "최초 DB 수집일자",
    Data?.sort?.[1] || "desc",
  ]);

  const changeSort = (field) => {
    let _sort = [];

    if (sort[0] === field) {
      _sort = [field, sort[1] === "desc" ? "asc" : "desc"];
    } else {
      _sort = [field, "desc"];
    }

    setSort(_sort);
    Data?.onSort?.({ field, dir: _sort[1] });
  };

  const tmAllCheck = () => {
    if (
      (Data?.checkedItems && Data.checkedItems.length) ===
      (Data?.TableData && Data.TableData.length)
    ) {
      Data.setCheckedItems([]);
    } else {
      Data.setCheckedItems(() => Data.TableData.map((x) => x[0].value));
    }
  };

  let TableHtml = Data.TableTitle.map((x, i) =>
    x === "$filterButton" ? (
      <td key={i}>
        <Button
          type="submit"
          className="filterButton"
          color="primary"
          variant="contained"
          size="small"
          disableRipple
          disableElevation
        >
          필터적용
        </Button>
        {/*  */}
        <div className="allRadioSel">
          <Checkbox
            checked={
              (Data?.checkedItems && Data.checkedItems.length) ===
              (Data?.TableData && Data.TableData.length)
            }
            onClick={tmAllCheck}
          />
        </div>
      </td>
    ) : (
      <td key={i} className={x === "수정/삭제" && "actionTitle"}>
        <span style={x === "수정/삭제" ? { margin: "0" } : {}}>
          {!Data.useFilter && x}
          {Data.useFilter && !notSearchArr.includes(x) && (
            <Button
              className="tmSortBtn"
              onClick={() => changeSort(x)}
              disableRipple
            >
              {x}
              {sort[0] === x && (
                <MdArrowDownward
                  style={{
                    transform: `rotate(${sort[1] === "desc" ? "0" : "180"}deg)`,
                  }}
                />
              )}
            </Button>
          )}
        </span>

        {!notSearchArr.includes(x) && Data.useFilter ? FilterInputs(x) : <></>}
      </td>
    ),
  );

  Data.TableTitle.map((x, i) => {
    TableHeadetml = (
      <tr key={i} className="GlobalTableHeader">
        {TableHtml}
      </tr>
    );
  });

  const phoneSearchClick = (phone) => {
    formik.handleReset();

    formik.setFieldValue("전화번호", phone);
    formik.setFieldValue("phone", true);

    formik.submitForm("test");
    return;
  };

  const patientSearch = (status) => {
    // formik.handleReset();

    // formik.setFieldValue("상태", status);
    // formik.submitForm();
    return;
  };

  const makeSpanItem = (y, x, j) => {
    //
    if (y === "예약완료") {
      return (
        <span
          style={{
            marginLeft: "1rem",
            color: "red",
          }}
        >
          {y}
        </span>
      );
    }
    //

    if (y === "예약 취소") {
      return (
        <span
          style={{
            marginLeft: "1rem",
            color: "blue",
          }}
        >
          {y}
        </span>
      );
    }

    if (j === 13 && x[7] === "중복") {
      return (
        <span
          style={{
            // marginLeft: "1rem",
            color: "blue",
            cursor: "pointer",
          }}
          onClick={() => phoneSearchClick(y)}
        >
          {y}
        </span>
      );
    }

    if (Data.counselingDB && j === 4 && x[4] === "구환") {
      return (
        <span
          style={{
            marginLeft: "1rem",
            color: x[4] === "구환" ? "blue" : "black",
            cursor: "pointer",
          }}
          // onClick={() => patientSearch(x[4])}
        >
          {y}
        </span>
      );
    }

    return (
      <span
        style={{
          // width: "110%",
          marginLeft: ".3rem",
          color: "black",
        }}
      >
        {y}
      </span>
    );
  };

  let DataHtml = Data.TableData.map((x, i) => {
    let DataInnerHtml = x.map((y, j) => {
      if (typeof y == "string" || typeof y == "number") {
        if (Data.TableTitle[j] === "치료진행여부" && y === "미진행") {
          return (
            <td key={j}>
              <span style={{ marginLeft: "1rem", color: "red" }}>{y}</span>
            </td>
          );
        }

        if (y == "EditButtons") {
          let url = document.location.href.split("//")[1].split("/");
          url.shift();
          url = url.join("/");
          const editurl = `/${url.split("?")[0]}/edit/${x[1]}`;
          // 주소관리
          return (
            <td key={j}>
              <TableAction url={editurl} deleteItem={() => DelEvent(x[1])} />
            </td>
          );
        }

        return (
          <td
            style={
              typeof Data.TableStyle != "undefined"
                ? { width: `${Data.TableStyle[j - 1]}%` }
                : {}
            }
            key={j}
          >
            {" "}
            {makeSpanItem(y, x, j)}{" "}
          </td>
        );
      } else if (y instanceof Array) {
        return (
          <td key={j} {...y[1]}>
            {" "}
            <span>{y[0]}</span>{" "}
          </td>
        );
      }

      if (y == null)
        return (
          <td key={j}>
            {" "}
            <span style={{ marginLeft: "1rem" }}>-</span>{" "}
          </td>
        );

      switch (y.type) {
        case "input":
          switch (y.typeOption) {
            case "checkbox":
              return (
                <td style={{ width: "60px", textAlign: "center" }}>
                  <Checkbox
                    key={j}
                    value={x[0].value}
                    className="selCheckBoxLists"
                    name={String(y.name)}
                    style={{ width: "40px", height: "40px", margin: "0 auto" }}
                    onClick={
                      y.onClick
                        ? y.onClick
                        : (e) => {
                            onChangeValue(e, y.name);
                          }
                    }
                    checked={y.checked}
                  />
                </td>
              );
              break;
            default:
              return (
                <td>
                  <TextInput
                    value={y.value}
                    placeholder={y.placeholder}
                    key={j}
                    name={String(y.name)}
                    onChange={y.onChange ? y.onChange : null}
                    style={{ width: "100%", height: "1.5rem" }}
                    inputClassName={y.inputClassName}
                  />
                </td>
              );
              break;
          }
          break;

        case "select":
          let SelValue = y?.selValue?.[0];

          const value = detailData?.[y.name]
            ? detailData[y.name]
            : y?.value
            ? y.value
            : SelValue
            ? typeof SelValue == "object"
              ? SelValue.name
              : SelValue
            : "";

          const selectInputProps = {};

          if (value) {
            selectInputProps.value = value;
          }

          return (
            <td>
              <SelectInput
                key={j}
                className="selectType"
                name={String(y.name)}
                onChange={
                  y?.onChange ? y.onChange : (event) => onChange(event, x[1])
                }
                {...selectInputProps}
              >
                {typeof y?.selValue?.[0] == "object" &&
                  y.selValue.map((x, i) => {
                    return (
                      <MenuItem key={i} selected={y.value == x.id} value={x.id}>
                        {" "}
                        {x.name}{" "}
                      </MenuItem>
                    );
                  })}

                {typeof y?.selValue?.[0] != "object" &&
                  y.selValue.map((x, i) => (
                    <MenuItem key={i} selected={i == 0} value={x}>
                      {" "}
                      {x}{" "}
                    </MenuItem>
                  ))}
              </SelectInput>
            </td>
          );
          break;
        default:
          return y;
          break;
      }
    });

    returnTableHtml = (
      <tr key={i} className="GlobalTableItem">
        {DataInnerHtml}
      </tr>
    );

    return returnTableHtml;
  });

  return (
    <form
      className="GTF"
      onSubmit={(e) => {
        e.preventDefault();

        if (Data.TableTitle.find((x) => x === "$filterButton"))
          formik.submitForm();
      }}
    >
      {ShowAllitemCounts && Data?.count ? (
        <p
          className={
            window.location.pathname.includes("/counselor/counseling")
              ? "counselingGTF"
              : ""
          }
        >
          총 {typeof Data?.count == "number" ? Data?.count.toLocaleString() : 0}
          개
        </p>
      ) : (
        <></>
      )}
      <table className="GlobalTable">
        <thead>{TableHeadetml}</thead>
        <tbody>
          {Data.isFetching ? (
            <tr>
              <td>로딩중...</td>
            </tr>
          ) : (
            DataHtml
          )}
        </tbody>
      </table>
    </form>
  );
}
