import { atom } from "recoil";

export const broadcastingsState = atom({
  key: "broadcastingsState",
  default: [],
});

export const broadcastingsCountState = atom({
  key: "broadcastingsCountState",
  default: 0,
});

export const channelsState = atom({
  key: "channelsState",
  default: [],
});

export const channelsCountState = atom({
  key: "channelsCountState",
  default: 0,
});

export const doctorsState = atom({
  key: "doctorsState",
  default: [],
});

export const doctorsCountState = atom({
  key: "doctorsCountState",
  default: 0,
});

export const appearDoctorsState = atom({
  key: "appearDoctorsState",
  default: [],
});

export const appearDoctorsCountState = atom({
  key: "appearDoctorsCountState",
  default: 0,
});

export const visitsState = atom({
  key: "visitsState",
  default: [],
});

export const visitsCountState = atom({
  key: "visitsCountState",
  default: 0,
});

export const csvDataState = atom({
  key: "csvDataState",
  default: [],
});
