import React from "react";
import produce from "immer";
import DateInput from "~ui/dateInput/DateInput";
import Button from "@material-ui/core/Button";
import { IoIosSend, IoIosClose } from "react-icons/io";

import "./recallRequestDateBar.scss";

export default function RecallRequestDateBar({
  uiData,
  setUiData,
  updateVisitsItem,
  onClickRecallApply,
  onClickRecallApplyCanel,
}) {
  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const type = event.target.type;
      const value = event.target.value;

      setUiData(
        produce((draft) => {
          draft.visit[name] = value;
        }),
      );
    },
    [setUiData],
  );

  return (
    <div className="recallRequestDateBar">
      <div>
        {/* <span>최근 재상담 요청 날짜 : {uiData.visit.recallRequestDate}</span> */}
      </div>
      <div>
        <span>내원 예약 날짜</span>
        <DateInput
          className="reservationDateInput"
          value={uiData.visit.reservationDate || ""}
          name="reservationDate"
          onChange={onChange}
          style={{ width: "31rem", height: "2.95rem" }}
        />
      </div>
      <Button
        variant="contained"
        color="secondary"
        className="applyButton"
        onClick={onClickRecallApply}
      >
        <IoIosSend size="1.6rem" />
        <span>적용</span>
      </Button>

      {uiData.visit.reservationDate && (
        <Button
        variant="contained"
        color="secondary"
        className="applyButton"
        onClick={onClickRecallApplyCanel}
      >
        <IoIosClose size="2rem" />
        <span>예약 취소</span>
      </Button>
      )}
    </div>
  );
}
