import React from "react";

import "./title.scss";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Title() {
  const now = new Date();
  const dateText =
    MONTHS[now.getMonth()] +
    " " +
    now.getDate().toString().padStart(2, "0") +
    ", " +
    now.getFullYear();

  return (
    <div className="title">
      <span>STANDARD CRM</span>
      <br />
      <span>{dateText}</span>
    </div>
  );
}
