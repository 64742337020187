import React from "react";
import { Radio, FormControlLabel } from "@material-ui/core";
import produce from "immer";

import "./planningOptions.scss";

export default function PlanningOptions({ uiData, setUiData }) {
  const onRadioChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const checked = event.target.checked;
      const value = event.target.value;
      setUiData(
        produce((draft) => {
          draft[name] = value === "Y";
        }),
      );
    },
    [setUiData],
  );

  return (
    <div className="planningOptions">
      <div name="progress">
        <span>진행</span>
        <div className="radioGroup">
          <FormControlLabel
            value="Y"
            label="Yes"
            control={
              <Radio
                checked={uiData.progress}
                onChange={onRadioChange}
                color="secondary"
                name="progress"
                size="small"
              />
            }
          />
          <span>|</span>
          <FormControlLabel
            value="N"
            label="No"
            control={
              <Radio
                checked={!uiData.progress}
                onChange={onRadioChange}
                color="secondary"
                name="progress"
                size="small"
              />
            }
          />
        </div>
      </div>
      <div name="next">
        <span>향후</span>
        <div className="radioGroup">
          <FormControlLabel
            value="Y"
            label="Yes"
            control={
              <Radio
                checked={uiData.next}
                onChange={onRadioChange}
                color="secondary"
                name="next"
                size="small"
              />
            }
          />
          <span>|</span>
          <FormControlLabel
            value="N"
            label="No"
            control={
              <Radio
                checked={!uiData.next}
                onChange={onRadioChange}
                color="secondary"
                name="next"
                size="small"
              />
            }
          />
        </div>
      </div>
    </div>
  );
}
