import React, { useEffect } from "react";
import produce from "immer";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import TextInput from "~ui/textInput/TextInput";
import Button from "@material-ui/core/Button";
import { FaSearch } from "react-icons/fa";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import koLocale from "date-fns/locale/ko";

import ReactTooltip from "react-tooltip";

import { ko } from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import moment from "moment";

import { DateRangePicker } from "materialui-daterange-picker";

import "./searchBar.scss";
import { Tooltip } from "@material-ui/core";

export default function SearchBar({
  searchQuery,
  setSearchQuery,
  onClickSearch,
  headList,
  subFields,
}) {
  const [once, setOnce] = React.useState(false);
  
  const parseData = window.localStorage.getItem("counselingDate")
    ? JSON.parse(window.localStorage.getItem("counselingDate"))
    : false;

  const [state, setState] = React.useState([
    {
      startDate: parseData
        ? new Date(parseData.startDate)
        : new Date(searchQuery.startDate),
      endDate: parseData
        ? new Date(parseData.endDate)
        : new Date(searchQuery.endDate),
      key: "selection",
    },
  ]);

  const onChange = React.useCallback(
    (event, getName) => {
      const name = event?.target ? event.target.name : getName;
      const type = event?.target ? event.target.type : "";
      const classList = event?.target ? event.target.classList : "";
      const value = event?.target
        ? classList && classList.contains("numberInput")
          ? event.target.value.replace(/[^0-9]/g, "")
          : event.target.value
        : event;

      setSearchQuery(
        produce((draft) => {
          draft[name] = value;
        }),
      );
    },
    [setSearchQuery],
  );

  //
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});

  const dateRangeChange = () => setDateRangeOpen(!dateRangeOpen);
  //

  const rangeChange = (range) => {
    onChange(moment(range.startDate).format("YYYY-MM-DD"), "startDate");
    onChange(moment(range.endDate).format("YYYY-MM-DD"), "endDate");

    window.localStorage.setItem(
      "counselingDate",
      JSON.stringify({
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      }),
    );
  };

  const onClickSetdate = async (type = "day") => {
    const date = new Date();
    const searchbutton = document.querySelector(".searchButton");

    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const weekStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay(),
    );
    const weekEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (6 - date.getDay()),
    );

    if (type == "month")
      await rangeChange({ startDate: monthStart, endDate: monthEnd });
    else if (type == "week")
      await rangeChange({ startDate: weekStart, endDate: weekEnd });
    else if  (type == "all") {
      setOnce(true);
      onChange(moment(new Date(date.getFullYear() - 200, date.getMonth(), 1)).format("YYYY-MM-DD"), "startDate");
      onChange(moment(new Date(date.getFullYear() + 200, date.getMonth(), 1)).format("YYYY-MM-DD"), "endDate");
    }
    else await rangeChange({ startDate: date, endDate: date });

    searchbutton.click();
  };

  useEffect(() => {
    if (once) return;

    if (window.localStorage.getItem("counselingDate")) {
      const parseData = JSON.parse(
        window.localStorage.getItem("counselingDate"),
      );

      setState([
        {
          startDate: new Date(parseData.startDate),
          key: "selection",
          endDate: new Date(parseData.endDate),
        },
      ]);
    }

    // window.setTimeout(() => {
    //   document.querySelector(`#counselingSearchButton`).click();
    // }, 100);
  }, [once]);

  const DataRangeChangeEvent = (item) => {
    const range = item.selection;

    onChange(moment(range.startDate).format("YYYY-MM-DD"), "startDate");
    onChange(moment(range.endDate).format("YYYY-MM-DD"), "endDate");

    window.localStorage.setItem(
      "counselingDate",
      JSON.stringify({
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      }),
    );

    setState([item.selection]);
  };

  const counselorNameList = headList.data.map((cs, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={cs.name}>
      {cs.name}
    </MenuItem>
  ));
  return (
    <div className="searchBar">
      <div>
        <span>실장</span>
        <SimpleSelectInput
          className="selectCounselorName"
          name="counselorName"
          value={searchQuery.counselorName}
          onChange={onChange}
          style={{ width: "8.1rem", height: "2.68rem" }}
          placeholder="--"
        >
          {counselorNameList}
        </SimpleSelectInput>
      </div>
      <div>
        <span>상담환자 이름</span>
        <TextInput
          className="patientNameInput"
          name="patientName"
          value={searchQuery.patientName}
          onChange={onChange}
          placeholder="이름"
          style={{ width: "8.1rem", height: "2.68rem" }}
        />
      </div>
      <div>
        <span>차트번호</span>
        <TextInput
          className="chartNumberInput"
          inputClassName="numberInput"
          name="chartNumber"
          value={searchQuery.chartNumber}
          onChange={onChange}
          placeholder="차트번호"
          style={{ width: "9rem", height: "2.68rem" }}
        />
      </div>
      <div>
        <span>기간검색</span>

        <div className="DateInputs">
          <div className="inputs">
            <div data-tip data-for="daterange" data-event="click">
              <TextInput
                onClick={dateRangeChange}
                readOnly
                value={parseData ? parseData.startDate : searchQuery.startDate}
              />
              <p>~</p>
              <TextInput
                onClick={dateRangeChange}
                readOnly
                value={parseData ? parseData.endDate : searchQuery.endDate}
              />
            </div>
            <ReactTooltip
              id="daterange"
              place="bottom"
              effect="solid"
              type="light"
              globalEventOff="click"
              clickable
            >
              <DateRange
                ranges={state}
                onChange={DataRangeChangeEvent}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                locale={ko}
              />
            </ReactTooltip>
          </div>
          <div className="dateRangePicker-box">
            {/* <DateRangePicker
              initialDateRange={{
                startDate: lsDateParse?.startDate || searchQuery.startDate,
                endDate: lsDateParse?.endDate || searchQuery.endDate,
              }}
              style={{ width: "1000px" }}
              closeOnClickOutside={false}
              open={dateRangeOpen}
              onChange={rangeChange}
            /> */}
          </div>
          <div className="ButtonDateFreeSet">
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickSetdate}
            >
              당일
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClickSetdate("week")}
            >
              주별
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClickSetdate("month")}
            >
              월별
            </Button>
            <Tooltip title="두번 눌러야 적용 됩니다 (날짜가 안바뀌어도 실제론 적용됩니다)">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onClickSetdate("all")}
                >
                전체
              </Button>
            </Tooltip>
          </div>
        </div>

        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
          <DatePicker
            format="yyyy/MM/dd"
            onChange={(e) => onChange(e, "startDate")}
            className="startDateInput"
            value={searchQuery.startDate}
            style={{ width: "5.5rem", height: "2.68rem" }}
          />
        
          <span style={{ margin: "0 1rem" }}>~</span>
          <DatePicker
            format="yyyy/MM/dd"
            onChange={(e) => onChange(e, "endDate")}
            className="endDateInput"
            value={searchQuery.endDate}
            style={{ width: "5.5rem", height: "2.68rem" }}
          />
        </MuiPickersUtilsProvider> */}
      </div>

      {subFields}
      <Button
        id="counselingSearchButton"
        variant="contained"
        color="secondary"
        className="searchButton"
        onClick={onClickSearch}
      >
        <span>Search</span>
        <FaSearch size="2.2rem" />
      </Button>
    </div>
  );
}
