import React, { useState } from 'react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { Box, Typography, TextField, Button } from '@material-ui/core'
import useAuth from '~hooks/useAuth';
import useOverlayLoading from '~hooks/useOverlayLoading';
import instanceAxios from "~apis/_util";

export default function Login() {
  const { signIn } = useAuth()
  const [open, close] = useOverlayLoading()
  const [userFetch, setUserFetch] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      if (!Object.values(values).every(v => v)) {
        alert('아이디와 비밀번호를 모두 입력해주세요.')
        return;
      }

      try {
        open()
        const response = await signIn(values.email, values.password)
        close()

        if (response.error) {
          alert('아이디 또는 비밀번호를 다시 확인해주세요.')
          return false
        }

        redirectAccessPage(response.data.user.access)
      } catch (e) {
        console.error(e)
        close()
        alert('아이디 또는 비밀번호를 다시 확인해주세요.')
      }
    }
  })

  function redirectAccessPage(access) {
    switch (access) {
      case '원장':
        window.location.href = '/doctor/monthlySalesStatistics'
        break

      case '실장':
        window.location.href = '/counselor/counseling'
        break
          
      case '마케팅팀':
        window.location.href = '/tm/totalManagement'
        break

      case 'CS팀':
        window.location.href = '/tm/totalManagement'
        break

      case 'TM팀장':
      case 'TM팀A':
      case 'TM팀B':
      case 'TM팀C':
      case 'TM팀D':
        window.location.href = '/tm/totalManagement'
        break
      
      case '외부입력A':
      case '외부입력B':
        window.location.href = '/tm/totalManagement'
        break

      default:
        window.location.href = '/admin'
    }
  }

  React.useEffect(() => {
    (async () => {
      try {
        const response = await instanceAxios.get("/users/me");
        redirectAccessPage(response.access)
      } catch (e) {
        setUserFetch(true)
      }
    })()
  }, [])

  if (!userFetch) {
    return null;
  }

  return (
    <Box style={{ background: "#f2f2f2" }} width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Box style={{ background: "#fff" }} width={500} padding={3}>
        <Box marginBottom={2}>
          <Typography variant="h5">스탠다드CRM 로그인</Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box marginBottom={2}>
            <TextField
              variant="outlined"
              name="email"
              fullWidth
              label="아이디"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </Box>
          <Box marginBottom={2}>
            <TextField
              variant="outlined"
              name="password"
              fullWidth
              type="password"
              label="비밀번호"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" marginBottom={2}>
            <Button variant="outlined" type="submit" color="primary">로그인</Button>       
          </Box>
        </form>
      </Box>
    </Box>
  )
}