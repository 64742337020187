import React, { useEffect } from "react";
import produce from "immer";
import MenuItem from "@material-ui/core/MenuItem";
import SelectInput from "~ui/selectInput/SelectInput";
import Button from "@material-ui/core/Button";
import { FaSearch } from "react-icons/fa";
import moment from "moment";

import "./searchBar.scss";
import { useLocation, useHistory } from "react-router-dom";

export default function SearchBarRe({
  channelList,
  doctorList,
  searchQuery,
  setSearchQuery,
  onClickSearch,
  broadcastingList,
}) {
  const location = useLocation();
  const history = useHistory();
  const isAll = location.search.includes("date=all");
  const now = new Date();
  const nowYear = now.getFullYear();

  useEffect(() => {
    if (isAll) {
      setSearchQuery(
        produce((draft) => {
          draft.year = 2015;
          draft.month = 1;
        }),
      );
      onClickSearch();
    } else {
      setSearchQuery(
        produce((draft) => {
          draft.year = moment().year();
          draft.month = moment().month() + 1;
        }),
      );
      onClickSearch();
    }
  }, [isAll]);

  useEffect(() => {
    onClickSearch();
  }, [searchQuery.year === 2015]);

  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      setSearchQuery(
        produce((draft) => {
          draft[name] = event.target.value;
        }),
      );
    },
    [setSearchQuery],
  );

  const yearItems = [...Array(100).keys()].map((i) => {
    const year = nowYear - i;
    return (
      <MenuItem style={{ fontSize: "1rem" }} key={year} value={year}>
        {year}
      </MenuItem>
    );
  });
  const monthItems = [...Array(12).keys()].map((i) => {
    const month = i + 1;
    return (
      <MenuItem style={{ fontSize: "1rem" }} key={month} value={month}>
        {month.toString().padStart(2, "0")}
      </MenuItem>
    );
  });
  const channelNameList = channelList.isFetching
    ? []
    : Array.from(new Set(channelList.data?.map((x) => x.channelName)));
  const channelItems = channelNameList.map((v, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={v}>
      {v}
    </MenuItem>
  ));
  const agencyList = channelList.isFetching
    ? []
    : Array.from(new Set(channelList.data?.map((x) => x.agency)));
  const agencyItems = agencyList.map((v, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={v}>
      {v}
    </MenuItem>
  ));
  const roiItems = ["5 이상", "5 미만"].map((v, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={v}>
      {v}
    </MenuItem>
  ));

  const adLists = Array.from(new Set(channelList.data?.map((v) => v.ad))).map(
    (v, i) => (
      <MenuItem style={{ fontSize: "1rem" }} key={i} value={v}>
        {v}
      </MenuItem>
    ),
  );

  const doctorLists = doctorList.data?.map((v, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={v.id}>
      {v.name}
    </MenuItem>
  ));

  // const adLists = Array.from(new Set(channelList.data.map((v) => v.ad))).map(
  //   (x, i) => (
  //     <MenuItem style={{ fontSize: "1rem" }} key={i} value={x}>
  //       {x}
  //     </MenuItem>
  //   ),
  // );

  return (
    <>
      <div className="searchBar">
        <div>
          <span>Monthly</span>
          <br />
          <span>Arrangement</span>
        </div>

        {!isAll && (
          <>
            <SelectInput
              className="selectYear"
              name="year"
              value={searchQuery.year}
              onChange={onChange}
              fontSize="2rem"
              style={{ marginLeft: "1rem" }}
            >
              {yearItems}
            </SelectInput>
            <SelectInput
              className="selectMonth"
              name="month"
              value={searchQuery.month}
              onChange={onChange}
              fontSize="2rem"
              style={{ marginLeft: "1rem" }}
            >
              {monthItems}
            </SelectInput>
          </>
        )}

        <SelectInput
          className="selectAd"
          name="ad"
          value={searchQuery.ad}
          onChange={onChange}
          placeholder="- 광고명 -"
          fontSize="1rem"
          style={{ marginLeft: "1rem" }}
        >
          {adLists}
        </SelectInput>

        <SelectInput
          className="selectAgency"
          name="agency"
          value={searchQuery.agency}
          onChange={onChange}
          placeholder="- 업체 -"
          style={{ marginLeft: "1rem" }}
        >
          {agencyItems}
        </SelectInput>

        <SelectInput
          className="selectChannel"
          name="channel"
          value={searchQuery.channel}
          onChange={onChange}
          placeholder="- 채널명 -"
          fontSize="1rem"
          style={{ marginLeft: "1rem" }}
        >
          {channelItems}
        </SelectInput>

        <SelectInput
          className="selectROI"
          name="roi"
          value={searchQuery.roi}
          onChange={onChange}
          placeholder="- ROI 기준 -"
          style={{ marginLeft: "1rem" }}
        >
          {roiItems}
        </SelectInput>

        <SelectInput
          className="selectDoctor"
          name="doctor"
          value={searchQuery.doctor}
          onChange={onChange}
          placeholder="- Doctor -"
          fontSize="1rem"
          style={{ marginLeft: "1rem" }}
        >
          {doctorLists}
        </SelectInput>

        <div
          style={{
            marginTop: ".2rem",
            marginLeft: "1rem",
            fontSize: "1rem",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          전체 평균 ROI
          <br />
          {Number(broadcastingList.data?.roi || 0).toFixed(1)}
        </div>

        <Button
          variant="contained"
          color="secondary"
          className="searchButton"
          onClick={onClickSearch}
        >
          <span>Search</span>
          <FaSearch size="2.2rem" />
        </Button>
      </div>

      {!isAll && (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: "1rem" }}
          onClick={() => {
            history.push("?date=all");
          }}
          disableElevation
        >
          전체 채널평가
        </Button>
      )}

      {isAll && (
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: "1rem" }}
          onClick={() => {
            history.push("/channel/channelManagement");
          }}
          disableElevation
        >
          기간 채널관리
        </Button>
      )}
    </>
  );
}
