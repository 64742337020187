import React from "react";
import produce from "immer";
import { useRecoilState } from "recoil";
import PageTitle from "~/global/components/tamplete/title/title";
import Table from "~/global/components/tamplete/table/table";
import Search from "~/global/components/tamplete/search/search";
import Pazing from "~/global/components/tamplete/pazing/Pazing";
import ControlsButton from "~/global/components/tamplete/controlsbutton/ControlsButton";
import { searchQueryState } from "~admin/stores/channel";
import backgroundImage from "~background/background2.png";
import { useGetChannelList, useGetChannelListCount } from "~apis/channels";
import qs from "qs";

import "./AddChannelMain.scss";

export default function AddChannelMain() {
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);

  const getUrl = new URL(window.location.href).searchParams.get("sub");

  const queryString = qs.stringify({
    _start: (searchQuery.page - 1) * 10,
    _limit: 10,
    _where: {
      ad_contains: getUrl ? getUrl : "",
      _or: [
        { channelName_contains: searchQuery.keyword },
        { agency_contains: searchQuery.keyword },
      ],
    },
  });
  const channelList = useGetChannelList(queryString);
  const channelListCount = useGetChannelListCount(queryString);

  const isFetching = channelList.isFetching || channelListCount.isFetching;
  const isError = channelList.isError || channelListCount.isError;
  const pages = Math.ceil(channelListCount.data / 10);

  const SearchData = ["전체"];
  const SearchButton = { Text: "Search" };
  const TableTitle = [
    "",
    "#",
    "채널명",
    "업체",
    "비용",
    "기간",
    "주회차",
    "주수",
    "진행",
    "참고사항",
    "수정/삭제",
  ];
  const TableData = [];
  const TableStyle = [3, 10, 10, 60, 10];
  const [checkedItems, setCheckedItems] = React.useState([]);

  let value = [];

  const mainTitle = getUrl ? getUrl : "채널 리스트";

  return (
    <div className="addChannelMain">
      <div className="mainTitleDiv">
        <div className="item">
          <PageTitle title={mainTitle} />
          <Search {...{ SearchData, SearchButton }} />
        </div>
        <div className="item">
          <img src={backgroundImage} alt="" className="backgroundImage" />
        </div>
      </div>
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}

      {!isFetching && !isError && (
        <>
          {channelList.data.map((x) => {
            TableData.push([
              {
                type: "input",
                typeOption: "checkbox",
                name: "id",
                value: x.id,
                checked: checkedItems.includes(x.id),
                onClick: (event) => {
                  setCheckedItems(
                    produce((draft) => {
                      const checked = event.target.checked;
                      if (checked && !draft.includes(x.id)) {
                        draft.push(x.id);
                      } else if (!checked && draft.includes(x.id)) {
                        draft.splice(draft.indexOf(x.id), 1);
                      }
                    }),
                  );
                },
              },
              x.id,
              x.channelName,
              x.agency,
              Number(x.cost || 0).toLocaleString(),
              x.start ? x.start + " ~ " + x.end : "-",
              x.weeklyRound,
              x.weeklyCount,
              x.progress ? "Y" : "N",
              x.note,
              "EditButtons",
            ]);
          })}

          <Table
            count={channelListCount.data}
            // TableStyle={TableStyle}
            value={value}
            refetch={channelList.refetch}
            Type="Channel"
            {...{ TableTitle, TableData }}
          />
          <Pazing
            pages={pages}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </>
      )}

      <ControlsButton
        url="/channel/addChannel/add"
        type="Channel"
        refetch={channelList.refetch}
        setCheckedItems={setCheckedItems}
        DelList={checkedItems}
      />
    </div>
  );
}
