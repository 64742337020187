import React from "react";

import "./logo.scss";

export default function Logo() {
  return (
    <div className="logo">
      <span>스탠다드치과</span>
      <br />
      <span>STANDARD DENTAL CLINIC</span>
    </div>
  );
}
