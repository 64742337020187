import React from "react";
import moment from "moment";

import { Button } from "@material-ui/core";
import jsonToCSV from "~/global/utils/jsonToCsv";

import "./dbStatisticsList.scss";

const HEADER_COLUMNS = [
  "년/월",
  "월/일",
  "상담진행 환자 수",
  "진행환자 수",
  "총 상담금액",
  "총 계약금액",
  "임플란트",
  "교정",
  "심미보철",
  "일반",
];

function findSalesData(datas, salesData, type) {
  return datas.filter((doctorVal, i) => {
    return salesData
      .map((salesVal) => {
        const dd = salesVal?.[type];
        if (type === "heads") {
        }

        return dd
          ? Object.values(dd)
              .map((vv) => vv.id)
              .includes(doctorVal.id)
          : false;
      })
      .some((someVal) => someVal);
  });
}

function DbStatisticsListHeader({ doctors, heads, targetDataset, salesData }) {
  if (targetDataset === "weekly") {
    HEADER_COLUMNS[0] = "년/월";
    HEADER_COLUMNS[1] = "월/일";
  } else if (targetDataset === "monthly") {
    HEADER_COLUMNS[0] = "년";
    HEADER_COLUMNS[1] = "월";
  } else if (targetDataset === "yearly") {
    HEADER_COLUMNS[0] = "년";
    HEADER_COLUMNS[1] = "월";
  }

  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  const doctorNames = doctors.map((x) => x.name);
  const headNames = heads.map((x) => x.name);

  const doctorsColumn = findSalesData(doctors, salesData, "doctors")
    .map((x) => x.name)
    .map((column, i) => (
      <div key={i}>
        <span style={{ color: "#009ad6" }}>{column}</span>
      </div>
    ));

  const headsColumn = findSalesData(heads, salesData, "heads")
    .map((x) => x.name)
    .map((column, i) => (
      <div key={i}>
        <span style={{ color: "#fc9700" }}>{column}</span>
      </div>
    ));

  return (
    <div className="dbStatisticsListHeader">
      {items}
      <div className="dynamicColumn">{doctorsColumn}</div>
      <div className="dynamicColumn">{headsColumn}</div>
    </div>
  );
}

function UpDownArrow({ arrow }) {
  if (arrow === 1) {
    return (
      <svg
        className="arrowUp"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 16 16"
      >
        <path d="M0 16l16 0 -8-16z"></path>
      </svg>
    );
  } else if (arrow === -1) {
    return (
      <svg
        className="arrowDown"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 16 16"
      >
        <path d="M0 0l8 16 8-16z"></path>
      </svg>
    );
  } else return <></>;
}

function DbStatisticsListItem({
  data,
  doctors,
  heads,
  targetDataset,
  salesData,
}) {
  const doctorNames = doctors.map((x) => x.name);
  const doctorItems = findSalesData(doctors, salesData, "doctors")
    .map((x) => x.name)
    .map((doctorName, i) => {
      const doctor = data.doctors && data.doctors[doctorName];
      if (doctor) {
        return (
          <div key={i}>
            <span>{doctor.count} / <span style={{color: "blue"}}>{doctor.pr_count}</span> 명</span>
            <span
              className={
                1 === 1 ? "percentUp" : true === -1 ? "percentDown" : ""
              }
            >
              ({doctor.percent.toFixed()}%)
            </span>
          </div>
        );
      } else {
        return <div key={i}>-</div>;
      }
    });

  const headNames = heads.map((x) => x.name);
  const headItems = findSalesData(heads, salesData, "heads")
    .map((x) => x.name)
    .map((headName, i) => {
      const head = data.heads && data.heads[headName];
      if (head) {
        return (
          <div key={i}>
            <span>{head.count}  / <span style={{color: "blue"}}>{head.pr_count}</span> 명</span>
            <span
              className={
                1 === 1 ? "percentUp" : true === -1 ? "percentDown" : ""
              }
            >
              ({head.percent.toFixed()}%)
            </span>
          </div>
        );
      } else {
        return <div key={i}>-</div>;
      }
    });

  const dateColumn =
    targetDataset === "weekly"
      ? [
          `${data.year}. ${data.month}`,
          `${data.week_of_month}주 (${moment(data.week_start).format(
            "MM.DD",
          )}~${moment(data.week_end).format("MM.DD")})`,
        ]
      : targetDataset === "monthly"
      ? [data.year, `${data.month}월`]
      : targetDataset === "yearly"
      ? [data.year, "01-01 ~ 12-31"]
      : [moment(data.date).format("YYYY. MM"), moment(data.date).date()];


  const previewInterval = data?.previewInterval;
  console.log(previewInterval);

  let counseling_count_updown = 0;
  if ( +data?.previewInterval?.counseling_count < +data?.counseling_count) {
    counseling_count_updown = 1;
  } else if (+data?.previewInterval?.counseling_count > +data?.counseling_count) {
    counseling_count_updown = -1;
  } else {
    counseling_count_updown = 0;
  }

  let progress_count_updown = 0;
  if ( +data?.previewInterval?.progress_count < +data?.progress_count) {
    progress_count_updown = 1;
  } else if (+data?.previewInterval?.progress_count > +data?.progress_count) {
    progress_count_updown = -1;
  } else {
    progress_count_updown = 0;
  }

  let counseling_amount_updown = 0;
  if ( data?.previewInterval?.counseling_amount < data?.counseling_amount) {
    counseling_amount_updown = 1;
  } else if (data?.previewInterval?.counseling_amount > data?.counseling_amount) {
    counseling_amount_updown = -1;
  } else {
    counseling_amount_updown = 0;
  }

  let progress_amount_updown = 0;
  if ( data?.previewInterval?.progress_amount < data?.progress_amount) {
    progress_amount_updown = 1;
  } else if (data?.previewInterval?.progress_amount > data?.progress_amount) {
    progress_amount_updown = -1;
  } else {
    progress_amount_updown = 0;
  }

  function persentNum(preview, now) {
    return `${((now - preview) / preview * 100).toFixed(1)}%`;
  }


  const columnDataList = [
    ...dateColumn,
    <>
      <span>{data.counseling_count}명 ({persentNum(+previewInterval?.counseling_count, +data?.counseling_count)})</span>
      <UpDownArrow arrow={counseling_count_updown} />
    </>,
    <>
      <span>{data?.progress_count}명 ({persentNum(+previewInterval?.progress_count, +data?.progress_count)})</span>
      <UpDownArrow arrow={progress_count_updown} />
    </>,
    <>
      <span>{(data?.counseling_amount || 0).toLocaleString("ko-KR")} ({persentNum(previewInterval?.counseling_amount, data?.counseling_amount)})</span>
      <UpDownArrow arrow={counseling_amount_updown} />
    </>,
    <>
      <span>
        {(data?.progress_amount ? +data?.progress_amount : 0).toLocaleString(
          "ko-KR",
        )} ({persentNum(previewInterval?.progress_amount, data?.progress_amount)})
      </span>
      <UpDownArrow arrow={progress_amount_updown} />
    </>,
    data.category1_count,
    data.category2_count,
    data.category3_count,
    data.category4_count,
  ];

  const items = columnDataList.map((columnData, i) => (
    <div key={i}>{columnData}</div>
  ));

  return (
    <div className="dbStatisticsListItem">
      {items}
      <div className="dynamicColumn">{doctorItems}</div>
      <div className="dynamicColumn">{headItems}</div>
    </div>
  );
}

function DbStatisticsListTotal({ doctors, heads, salesData }) {
  const itemDataList = Array(10).fill("");
  itemDataList.fill(0, 2, 6);
  salesData.forEach((x) => {
    itemDataList[2] += parseInt(x.counseling_count) || 0;
    itemDataList[3] += parseInt(x.progress_count) || 0;
    itemDataList[4] += parseInt(x.counseling_amount) || 0;
    itemDataList[5] += parseInt(x.progress_amount) || 0;
  });

  itemDataList[2] = `${itemDataList[2]}명`;
  itemDataList[3] = `${itemDataList[3]}명`;
  itemDataList[4] = itemDataList[4].toLocaleString("ko-KR");
  itemDataList[5] = itemDataList[5].toLocaleString("ko-KR");

  const items = itemDataList.map((itemData, i) => (
    <div key={i}>{itemData}</div>
  ));

  const dynamicItems = Array(
    findSalesData(doctors, salesData, "doctors").length +
      findSalesData(heads, salesData, "heads").length,
  )
    .fill("")
    .map((_, i) => <div key={i}></div>);

  return (
    <div className="dbStatisticsListTotal">
      {items}
      <div className="dynamicColumn">{dynamicItems}</div>
    </div>
  );
}

export default function DbStatisticsList({
  doctorList,
  headList,
  salesStatistics,
  targetDataset,
}) {
  const items = salesStatistics.data.map((salesData, i) => (
    <DbStatisticsListItem
      key={i}
      data={salesData}
      doctors={doctorList.data}
      salesData={salesStatistics.data}
      heads={headList.data}
      targetDataset={targetDataset}
    />
  ));

  const excelDown = (getData) => {
    const salesData = getData.salesData;
    const targetDataset = getData.targetDataset;

    const findSalesHeads = findSalesData(getData.heads, salesData, "heads");
    const findSalesDoctor = findSalesData(
      getData.doctors,
      salesData,
      "doctors",
    );

    const k = [
      ...HEADER_COLUMNS,
      ...Object.values(findSalesDoctor).map((x) => x.name),
      ...Object.values(findSalesHeads).map((x) => x.name),
    ];

    const listMap = salesData.map((data) => {
      const dateColumn =
        targetDataset === "weekly"
          ? [
              `${data.year}. ${data.month}`,
              `${data.week_of_month}주 (${moment(data.week_start).format(
                "MM.DD",
              )}~${moment(data.week_end).format("MM.DD")})`,
            ]
          : targetDataset === "monthly"
          ? [data.year, `${data.month}월`]
          : targetDataset === "yearly"
          ? [data.year, "01-01 ~ 12-31"]
          : [moment(data.date).format("YYYY. MM"), moment(data.date).date()];

      return [
        ...dateColumn,
        data.counseling_count,
        data.progress_count,
        (data.counseling_amount || 0).toLocaleString("ko-KR"),
        (data.payment ? +data.payment : 0).toLocaleString("ko-KR"),
        data.category1_count,
        data.category2_count,
        data.category3_count,
        data.category4_count,
        ...findSalesDoctor
          .map((x) => x.name)
          .map((doctorName) => {
            const doctor = data.doctors && data.doctors[doctorName];
            if (doctor) {
              return `${doctor.count} / ${doctor.pr_count}` + "명";
            } else {
              return "";
            }
          }),
        ...findSalesHeads
          .map((x) => x.name)
          .map((headName) => {
            const head = data.heads && data.heads[headName];
            if (head) {
              return `${head.count} / ${head.pr_count}` + "명";
            } else {
              return "";
            }
          }),
      ].reduce(
        (acc, dataVal, dataKey) => ({ ...acc, [k[dataKey]]: dataVal }),
        {},
      );
    });

    jsonToCSV(listMap, "통계", true);
  };

  return (
    <>
      <div className="dbStatisticsList">
        <div className="top">
          <p>원장</p>
          <p>실장</p>
        </div>
        <DbStatisticsListHeader
          doctors={doctorList.data}
          heads={headList.data}
          targetDataset={targetDataset}
          salesData={salesStatistics.data}
        />
        {items}
        <DbStatisticsListTotal
          doctors={doctorList.data}
          heads={headList.data}
          salesData={salesStatistics.data}
        />

        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              excelDown({
                targetDataset,
                doctors: doctorList.data,
                heads: headList.data,
                salesData: salesStatistics.data,
              })
            }
          >
            Excel 다운
          </Button>
        </div>
      </div>
    </>
  );
}
