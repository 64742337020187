import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "~/global/components/tamplete/title/title";
import EditForm from "~/global/components/tamplete/editForm/EditForm";
import { useGetChannelItem, useGetChannelList } from "~apis/channels";

import "./AddChannelEdit.scss";

export default function AddChannelEdit() {
  const { id } = useParams();
  let { isFetching, isError, data } = useGetChannelItem(id);
  const channelList = useGetChannelList();

  if (isFetching) return <div style={{ marginTop: "2rem" }}>로딩 중...</div>;

  const inputs = [
    {
      label: "광고명",
      type: "_select",
      value: data ? data.ad : "",
      options: [
        { label: "TV 광고", value: "TV 광고" },
        { label: "업체광고", value: "업체광고" },
        { label: "오프라인광고", value: "오프라인광고" },
        { label: "HYDJ", value: "HYDJ" },
      ],
      name: "ad",
    },
    {
      label: "업체",
      type: "text",
      value: data ? data.agency : "",
      name: "agency",
    },
    {
      label: "채널명",
      type: "text",
      value: data ? data.channelName : "",
      name: "channelName",
    },
    {
      label: "기간",
      type: "daterange",
      value: [data ? data.start : null, data ? data.end : null],
      name: ["start", "end"],
    },
    {
      label: "주회차",
      type: "number",
      value: data ? data.weeklyRound : 0,
      name: "weeklyRound",
    },
    {
      label: "주수",
      type: "number",
      value: data ? data.weeklyCount : 0,
      name: "weeklyCount",
    },
    {
      label: "비용",
      type: "number",
      value: data ? data.cost : 0,
      name: "cost",
    },
    {
      label: "시간 (Min)",
      type: "number",
      value: data ? data.minute : 0,
      name: "minute",
    },
    {
      label: "참고사항",
      type: "text",
      value: data ? data.note : "",
      name: "note",
    },
    {
      label: "내용",
      type: "textarea",
      value: data ? data.content : "",
      name: "content",
    },
  ];

  const Form = id ? "editchannel" : "addchannel";

  return (
    !isError && (
      <div className="addChannelEdit">
        <PageTitle title="채널 리스트" />
        <EditForm
          idx={id}
          form={Form}
          next={data?.next || false}
          progress={data?.progress || false}
          {...{ inputs }}
        />
      </div>
    )
  );
}
