import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";

import "./selectInput.scss";

const StyledSelect = styled(Select)`
  color: white;
  fill: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "2rem")};
  border-radius: 0.5rem;

  :hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
  .MuiSelect-select:focus {
    background-color: unset;
  }
  .MuiSelect-icon {
    color: white;
    transform: scale(1.8) translate(-0.2rem, -1px);
  }
  .MuiInputBase-root.MuiInput-root {
    padding-right: 0;
  }

  .MuiSelect-root {
    padding-left: 0.8rem;
    padding-right: 2.4rem;
  }
`;

export default function SelectInput({
  className,
  name,
  value,
  onChange,
  children,
  placeholder,
  fontSize,
  style,
}) {
  return (
    <div className={`selectInput ${className}`} style={style}>
      <FormControl>
        <StyledSelect
          name={name}
          value={value}
          onChange={onChange}
          disableUnderline
          displayEmpty={Boolean(placeholder)}
          fontSize={fontSize ? fontSize : "1rem"}
        >
          {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
          {children}
        </StyledSelect>
      </FormControl>
    </div>
  );
}
