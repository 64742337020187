import { createMuiTheme } from "@material-ui/core/styles";
import * as materialColors from "@material-ui/core/colors";

export const chroma = "500";
export const colors = Object.keys(materialColors)
  .filter((x) => x !== "common")
  .reduce((acc, key) => ({ ...acc, [key]: materialColors[key][chroma] }), {});

export const theme = {
  palette: {
    ...colors,
    black: "#1e1e1e",
    primary: "#96b0e4", //4a43da
    secondary: "#375ca4", //ffea00
    error: "#ec3232",
    background: "#f1f5fb",
  },
};

export const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.palette.primary,
    },
    secondary: {
      main: theme.palette.secondary,
    },
    error: {
      main: theme.palette.error,
    },
    background: {
      default: theme.palette.background,
    },
  },
  typography: {
    fontFamily: ['"NotoSansKR", "Roboto", sans-serif'],
  },
});

const defaultReturn = {
  chroma,
  colors,
  theme,
  MuiTheme,
};

export default defaultReturn;
