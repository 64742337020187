import React from "react";
import DateInput from "~ui/dateInput/DateInput";
import { FaSearch } from "react-icons/fa";
import produce from "immer";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import koLocale from "date-fns/locale/ko";
import { alpha } from "@material-ui/core/styles";
import SelectInput from "~ui/selectInput/SelectInput";
import { Button, MenuItem } from "@material-ui/core";

import "./searchBar.scss";
import moment from "moment";

export default function SearchBar({
  searchQuery,
  setSearchQuery,
  onClickSearch,
  targetDataset,
}) {
  const onDateInputChange = React.useCallback(
    (event, getName) => {
      const name = event?.target ? event.target.name : getName;
      const value = event?.target ? event.target.value : event;
      setSearchQuery(
        produce((draft) => {
          draft[name] = value;
        }),
      );
    },
    [setSearchQuery],
  );

  return (
    <div className="searchBar">
      <span>기간검색</span>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
        <DatePicker
          format={
            targetDataset === "yearly"
              ? "yyyy"
              : targetDataset === "monthly"
              ? "yyyy/MM"
              : "yyyy/MM/dd"
          }
          views={
            targetDataset === "yearly"
              ? ["year"]
              : targetDataset === "monthly"
              ? ["year", "month"]
              : ["year", "month", "date"]
          }
          onChange={(e) => onDateInputChange(e, "startDate")}
          className="startDateInput"
          value={searchQuery.startDate}
          style={{ width: "9rem", height: "2.68rem" }}
        />

        <span style={{ margin: "0 1rem" }}>~</span>
        <DatePicker
          format={
            targetDataset === "yearly"
              ? "yyyy"
              : targetDataset === "monthly"
              ? "yyyy/MM"
              : "yyyy/MM/dd"
          }
          views={
            targetDataset === "yearly"
              ? ["year"]
              : targetDataset === "monthly"
              ? ["year", "month"]
              : ["year", "month", "date"]
          }
          onChange={(e) => onDateInputChange(e, "endDate")}
          className="endDateInput"
          value={searchQuery.endDate}
          style={{ width: "9rem", height: "2.68rem", marginLeft: "0" }}
        />
      </MuiPickersUtilsProvider>
      <div>
        <SelectInput
          className="selectName"
          name="repeatVisit"
          value={searchQuery.repeatVisit}
          placeholder="-매출-"
          onChange={onDateInputChange}
          style={{
            height: "3rem",
          }}
        >
          <MenuItem value="신환">신환계약매출</MenuItem>
          <MenuItem value="구환">구환계약매출</MenuItem>
          <MenuItem value="신환+구환">신환+구환 계약매출</MenuItem>
        </SelectInput>
      </div>
      <Button
        variant="contained"
        color="secondary"
        className="searchButton"
        onClick={onClickSearch}
      >
        <span>Search</span>
        <FaSearch size="2.2rem" />
      </Button>
    </div>
  );
}
