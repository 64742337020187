import React from "react";
import SearchBar from "~/admin/components/channelManagement/searchBar/SearchBar";
import SearchBarRe from "~/admin/components/channelManagement/searchBar/SearchBarRe";
import ChannelView from "~/admin/components/channelManagement/channelView/ChannelView";
import backgroundImage from "~background/background2.png";
import {
  useGetBroadcastingList,
  useGetBroadcastingListCount,
} from "~apis/broadcastings";
import { useGetChannelList } from "~apis/channels";
import { useGetDoctorList } from "~apis/doctors";
import qs from "qs";
import moment from "moment";

import "./channelManagement.scss";

export default function ChannelManagement() {
  const [searchQuery, setSearchQuery] = React.useState({
    year: moment().year(),
    month: moment().month() + 1,
    channel: "",
    ad: "",
    channelName: "",
    agency: "",
    roi: "",
    sort: "ASC",
    order_by: "",
    page: 1,
    doctor: "",
  });
  const [queryString, setQueryString] = React.useState("");

  const broadcastingList = useGetBroadcastingList(queryString);
  const broadcastingListCount = useGetBroadcastingListCount(queryString);
  const channelList = useGetChannelList();
  const doctorList = useGetDoctorList();

  const isFetching =
    broadcastingList.isFetching ||
    broadcastingList.isFetching ||
    channelList.isFetching;
  const isError =
    broadcastingList.isError || broadcastingList.isError || channelList.isError;
  const pages = Math.ceil(broadcastingListCount.data / 10);

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_year: searchQuery.year,
        q_month: searchQuery.month,
        q_doctor: searchQuery.doctor,
        q_channelName: searchQuery.channel,
        q_agency: searchQuery.agency,
        q_ad: searchQuery.ad,
        q_roi: searchQuery.roi ? (searchQuery.roi == "5 이상" ? 1 : 2) : 0,
        q_sort: searchQuery.sort,
        q_order_by: searchQuery.order_by,
        q_page: searchQuery.page,
      }),
    );
  }, []);

  const onClickSearch = React.useCallback(() => {
    setQueryString(
      qs.stringify({
        q_year: searchQuery.year,
        q_month: searchQuery.month,
        q_doctor: searchQuery.doctor,
        q_channelName: searchQuery.channel,
        q_agency: searchQuery.agency,
        q_ad: searchQuery.ad,
        q_roi: searchQuery.roi ? (searchQuery.roi == "5 이상" ? 1 : 2) : 0,
        q_sort: searchQuery.sort,
        q_order_by: searchQuery.order_by,
        q_page: searchQuery.page,
      }),
    );
    broadcastingList.refetch();
  }, [searchQuery, setQueryString, broadcastingList]);

  const onPageChange = React.useCallback(
    (page) => {
      setQueryString(
        qs.stringify({
          q_year: searchQuery.year,
          q_month: searchQuery.month,
          q_doctor: searchQuery.doctor,
          q_channelName: searchQuery.channel,
          q_agency: searchQuery.agency,
          q_ad: searchQuery.ad,
          q_roi: searchQuery.roi ? (searchQuery.roi == "5 이상" ? 1 : 2) : 0,
          q_sort: searchQuery.sort,
          q_order_by: searchQuery.order_by,
          q_page: page,
        }),
      );
      broadcastingList.refetch();
    },
    [searchQuery, setQueryString, broadcastingList],
  );

  return (
    <div className="channelManagement">
      <span className="viewTitle">채널 관리</span>
      <div className="head">
        {/* <SearchBar
          {...{ channelList, searchQuery, setSearchQuery, onClickSearch }}
        /> */}

        <SearchBarRe
          {...{
            channelList,
            doctorList,
            searchQuery,
            setSearchQuery,
            onClickSearch,
            broadcastingList
          }}
        />
        <img
          src={backgroundImage}
          alt=""
          style={{ marginTop: "-2rem" }}
          className="backgroundImage"
        />
      </div>
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <p style={{ textAlign: "right", marginTop: "2rem" }}>
            총 {broadcastingListCount.data}개
          </p>
          <ChannelView
            {...{
              searchQuery,
              setSearchQuery,
              broadcastingList,
              pages,
              onPageChange,
            }}
          />
        </>
      )}
    </div>
  );
}
