import { atom } from "recoil";

export const searchQueryState = atom({
  key: "counselingSearchQueryState",
  default: {
    counselorName: "신예지",
    patientName: "",
    chartNumber: "",
    startDate: "2020-01-01",
    endDate: "2021-01-01",
    page: 1,
  },
});

const testData_counselingListState = {
  pages: 100,
  list: [
    {
      id: 1,
      data1: "",
      data2: 0,
      data3: "",
      data4: "",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 0,
      data9: "",
      data10: "",
      data11: "",
      data12: false,
    },
    {
      id: 2,
      data1: "",
      data2: 0,
      data3: "",
      data4: "",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 0,
      data9: "",
      data10: "",
      data11: "",
      data12: false,
    },
    {
      id: 3,
      data1: "2021-05-21",
      data2: 2,
      data3: "2021-03-21",
      data4: "21062104",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 0,
      data9: "신예지",
      data10: "김원장",
      data11: "",
      data12: false,
    },
    {
      id: 4,
      data1: "2021-05-21",
      data2: 1,
      data3: "2021-03-21",
      data4: "21062104",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 0,
      data9: "신예지",
      data10: "김원장",
      data11: "",
      data12: true,
    },
    {
      id: 5,
      data1: "",
      data2: 0,
      data3: "2021-03-21",
      data4: "21062104",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 200000,
      data9: "신예지",
      data10: "김원장",
      data11: "진행",
      data12: true,
    },
    {
      id: 6,
      data1: "",
      data2: 0,
      data3: "2021-03-21",
      data4: "21062104",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 200000,
      data9: "신예지",
      data10: "김원장",
      data11: "환불",
      data12: true,
    },
    {
      id: 7,
      data1: "2021-05-21",
      data2: 0,
      data3: "2021-03-21",
      data4: "21062104",
      data5: "이동주",
      data6: "010-2222-1234",
      data7: 21,
      data8: 500000,
      data9: "신예지",
      data10: "김원장",
      data11: "진행",
      data12: true,
    },
  ],
};

export const counselingListState = atom({
  key: "counselingListState",
  default: testData_counselingListState,
});
