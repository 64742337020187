//  json형식 파일이름 true
export default function jsonToCSV(JSONData, ReportTitle, ShowLabel) {
  const arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
  let CSV = "";
  CSV += ReportTitle + "\r\n\n";
  if (ShowLabel) {
    let row = "";
    for (const index in arrData[0]) {
      row += index + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  }
  for (let i = 0; i < arrData.length; i++) {
    let row = "";
    for (const index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }
    row.slice(0, row.length - 1);
    CSV += row + "\r\n";
  }
  if (CSV === "") {
    alert("Invalid data");
    return;
  }
  let fileName = "";
  fileName += ReportTitle.replace(/ /g, "_");
  const uri = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(CSV);
  const link = document.createElement("a");
  link.href = uri;
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
