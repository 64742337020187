import React from "react";
import produce from "immer";
import DateInput from "~ui/dateInput/DateInput";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import TimeField from "react-simple-timefield";
import TextInput from "~ui/textInput/TextInput";
import { MenuItem, Button } from "@material-ui/core";
import styled from "styled-components";
import moment from "moment";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import "./callContentManagement.scss";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  padding-left: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.5);

  .MuiSelect-root {
    color: black;
    font-size: 0.75rem;
    font-weight: normal;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 1rem;
    justify-content: center;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    transform: scale(1.3) translate(0.1rem, 0);
  }
`;

const StyledTimeField = styled(TimeField)`
  text-align: center;
`;

const HEADER_COLUMNS = [
  ["", "전화날짜", "전화시간", "진행내용 및 비고", "상담항목", "상담내용"],
  ["재상담 요청날짜"],
];

function CallContentManagementHeader({ isHead }) {
  const items = HEADER_COLUMNS.map((columnList, i) => (
    <div key={i} className={clsx("columnWrapper", isHead && "isHead")}>
      {columnList
        .filter((x) => !isHead || x !== "상담항목")
        .map((column, j) => (
          <div key={j}>
            <span>{column}</span>
          </div>
        ))}
    </div>
  ));

  return <div className="callContentManagementHeader">{items}</div>;
}

function CallContentManagementItem({
  uiData,
  onChange,
  onEditModeClick,
  onDeleteClick,
  onCompleteClick,
  isEdit,
  isHead,
}) {
  const typeList = [
    "임플란트",
    "교정",
    "인비절라인",
    "보철치료",
    "라미네이트",
    "일반진료",
  ].map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const items = uiData.counselings.map((data, i) => (
    <div className={`rowWrapper ${data.editMode ? "editMode" : ""}`} key={i}>
      <div className={clsx("columnWrapper", isHead && "isHead")}>
        <span>{i + 1}차 상담</span>
        <DateInput
          className="callDateInput"
          name={`${i}-callDate`}
          value={data.callDate || ""}
          onChange={onChange}
          style={{ height: "2rem" }}
        />
        <TextField
          type="time"
          className="callDateTimeInputs"
          name={`${i}-callTime`}
          value={data.calltime || ""}
          onChange={onChange}
          style={{ width: "auto" }}
        />
        <TextInput
          value={data.progressContent || ""}
          name={`${i}-progressContent`}
          onChange={onChange}
        />
        {!isHead && (
          <StyledSimpleSelectInput
            className="typeSelectInput"
            value={data.category || ""}
            name={`${i}-category`}
            onChange={onChange}
            fontSize="0.75rem"
          >
            {typeList}
          </StyledSimpleSelectInput>
        )}
        <textarea
          value={data.counselingContent || ""}
          name={`${i}-counselingContent`}
          onChange={onChange}
        />
      </div>
      <div className="columnWrapper">
        <DateInput
          className="recallRequestDateInput"
          value={data.recallRequestDate || ""}
          name={`${i}-recallRequestDate`}
          onChange={onChange}
          style={{ height: "2rem" }}
        />
      </div>
      <div className="columnWrapper">
        {isEdit && (
          <div className="editBox">
            {
              <>
                <Button
                  variant="contained"
                  color="primary"
                  className="editButton"
                  onClick={() => {
                    onEditModeClick(i, true, true);
                  }}
                >
                  <span>{data.id ? "수정" : "추가"}</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="deleteButton"
                  onClick={() => {
                    onDeleteClick(i);
                  }}
                >
                  <span>삭제</span>
                </Button>
                <Button
                  style={{
                    width: "calc(100% - 0.3rem)",
                    marginLeft: "0rem",
                    marginTop: "0.3rem",
                  }}
                  variant="contained"
                  color="primary"
                  className="completeButton"
                  onClick={() => {
                    data.id
                      ? onCompleteClick(i)
                      : alert("상담을 먼저 추가해주세요");
                  }}
                >
                  <span>
                    재상담 완료{" "}
                    {uiData.counselings[i].RecallComplete == 1 ? "취소" : ""}
                  </span>
                </Button>
              </>
            }
          </div>
        )}
      </div>
    </div>
  ));

  return <div className="callContentManagementItem">{items}</div>;
}

export default function CallContentManagement({
  uiData,
  setUiData,
  deleteCounseling,
  addCounseling,
  updateCounseling,
  openAlert,
  visitDetailTm,
  visitDetailHead,
  isEdit,
}) {
  const isHead = Boolean(visitDetailHead);
  const visitData = visitDetailTm || visitDetailHead;

  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      const [rowIdx, dataIdx] = name.split("-");

      setUiData(
        produce((draft) => {
          draft.counselings[rowIdx][dataIdx] = value;

          if (dataIdx === "callTime") {
            draft.counselings[rowIdx]["callTime"] = value;
            draft.counselings[rowIdx]["calltime"] = value;
          }
        }),
      );
    },
    [setUiData],
  );

  const onEditModeClick = React.useCallback(
    async (rowIdx, editMode, edit = false) => {
      const row = uiData.counselings[rowIdx];

      if (edit && uiData.visit.id) {
        const callDatetime = moment(`${row.callDate} ${row.calltime}`);

        const newData = {
          callDatetime: callDatetime._isValid
            ? callDatetime.format("YYYY-MM-DD HH:mm:ss")
            : null,
          progressContent: row.progressContent,
          category: row.category,
          counselingContent: row.counselingContent,
          recallRequestDate: row.recallRequestDate || null,
          visit: uiData.visit.id,
          isHead: row.isHead || false,
        };

        if (row.id) {
          const data = Object.entries(newData).reduce((acc, [key, value]) => {
            if (value) {
              return { ...acc, [key]: value };
            }

            return { ...acc };
          }, {});

          await updateCounseling([row.id, data]);
        } else {
          await addCounseling(newData);
        }

        visitData.refetch();
      }

      setUiData(
        produce((draft) => {
          draft.counselings[rowIdx].editMode = editMode;
        }),
      );
    },
    [uiData, setUiData, addCounseling, updateCounseling, visitData],
  );

  const onDeleteClick = React.useCallback(
    async (rowIdx) => {
      const row = uiData.counselings[rowIdx];

      if (row?.id) {
        await deleteCounseling(row.id);
        visitData.refetch();
      }

      setUiData(
        produce((draft) => {
          draft.counselings.splice(rowIdx, 1);
        }),
      );
    },
    [uiData, setUiData, deleteCounseling, visitData],
  );

  const onCompleteClick = React.useCallback(
    async (rowIdx) => {
      const row = uiData.counselings[rowIdx];

      const data = { ...row, recallComplete: row.RecallComplete == 1 ? 0 : 1 };

      if (row?.id) {
        await updateCounseling([row.id, data]);
        visitData.refetch();
      }

      setUiData(
        produce((draft) => {
          draft.counselings[rowIdx]["RecallComplete"] = 1;
        }),
      );
    },
    [uiData, setUiData, updateCounseling, visitData],
  );

  uiData.counselings.forEach((x, i) => {
    onEditModeClick(i, true);
  });

  return (
    <div className="callContentManagement">
      <div className="splitBlocks">
        <div className="splitBlock">콜내용 / 내원 상담 내용</div>
        <div className="splitBlock" style={{ fontSize: "18px" }}>
          재상담 ( 가망 DB )
        </div>
      </div>
      <CallContentManagementHeader isHead={isHead} />
      <CallContentManagementItem
        isHead={isHead}
        uiData={uiData}
        onChange={onChange}
        onEditModeClick={onEditModeClick}
        onDeleteClick={onDeleteClick}
        onCompleteClick={onCompleteClick}
        isEdit={isEdit}
      />
    </div>
  );
}
