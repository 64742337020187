import React from "react";
import produce from "immer";
import { Radio, FormControlLabel, Button } from "@material-ui/core";
import TextInput from "~ui/textInput/TextInput";
import DateInput from "~ui/dateInput/DateInput";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { IoIosSend } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { getNextMonth } from "~utils/date";
import useAlert from "~hooks/useAlert";

import moment from "moment";

import "./recallManagement.scss";
import instanceAxios from "~/global/apis/_util";
import { IoConstructOutline } from "react-icons/io5";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  .MuiSelect-root {
    color: black;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    transform: scale(1.3) translate(-0.1rem, 0);
  }
`;

// const HEADER_COLUMNS = ["담당실장", "리콜날짜", "당일 콜 횟수", "결과", "비고"];
const HEADER_COLUMNS = ["리콜날짜", "당일 콜 횟수", "", "비고"];

function ManagementBar({ uiData, onCloseClick }) {
  const [addDate, SetAddDate] = React.useState("");
  let reservationDate = uiData.visit.reservationDate;
  let textContent = "리콜";

  if (reservationDate) {
    reservationDate = new Date(reservationDate).setHours(0, 0, 0, 0);
    const nowDate = new Date().setHours(0, 0, 0, 0);
    if (reservationDate < nowDate) {
      textContent = "내원 예약 후 미방문 고객 콜 관리";
    }
  }

  return (
    <div className="managementBar">
      <span>{textContent}</span>

      <div className="closeIcon" onClick={onCloseClick}>
        <MdClose size="1.5rem" />
      </div>
    </div>
  );
}

function RecallManagementHeader() {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  return (
    <div className="recallManagementHeader">
      <div className="rowWrapper">
        <span></span>
        <div className="rowItem">{items}</div>
      </div>
    </div>
  );
}

function RecallManagementItem({
  data,
  onChange,
  csList,
  updateRecall,
  onApplyClick,
  onClickDelete,
  visitDetailTm,
}) {
  // const counselorNameList = (csList.data || []).map((cs, i) => (
  //   <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={cs.id}>
  //     {cs.name}
  //   </MenuItem>
  // ));

  const recallMakeFn = React.useCallback(() => {
    // const recallData = data[0];

    // const visitData = visitDetailTm.data.visit;

    // const recallDate = new Date(recallData.recallDate);
    // const nowDate = new Date();

    // if (
    //   recallDate > nowDate &&
    //   !recallData.result &&
    //   [1, 2, 3].includes(recallData.callCount) &&
    //   visitData.recallPossible != 60
    // ) {
    //   instanceAxios
    //     .put(`/visits/${visitData.id}`, {
    //       recallPossible: 60,
    //       status: `부재${recallData.callCount}`,
    //     })
    //     .then((x) => {
    //       visitDetailTm.refetch();
    //     });
    // }

    // if (
    //   recallDate <= nowDate &&
    //   !recallData.result &&
    //   recallData.callCount === 3 &&
    //   visitData.recallPossible != 30
    // ) {
    //   nowDate.setMonth(nowDate.getMonth() + 1);
    //   instanceAxios
    //     .put(`/visits/${visitData.id}`, {
    //       recallPossible: 30,
    //       status: `부재${recallData.callCount}`,
    //     })
    //     .then((x) => {
    //       instanceAxios
    //         .put(`/recalls/${recallData.id}`, {
    //           callCount: 4,
    //           recallNum: 2,
    //           recallDate: nowDate,
    //         })
    //         .then((vv) => {
    //           visitDetailTm.refetch();
    //         });
    //     });
    // }

    // if (
    //   recallDate <= nowDate &&
    //   !recallData.result &&
    //   recallData.callCount === 6 &&
    //   visitData.recallPossible != 0
    // ) {
    //   instanceAxios
    //     .put(`/visits/${visitData.id}`, {
    //       recallPossible: 0,
    //       status: `부재${recallData.callCount}`,
    //     })
    //     .then((x) => {
    //       visitDetailTm.refetch();
    //     });
    // }

    return data.map((rowData, i) => (
      <div className="rowWrapper" key={i}>
        <span>리콜 {rowData.recallNum}차</span>
        <div className="rowItem">
          {/* <StyledSimpleSelectInput
            className="counselorSelectInput"
            name={`${i}-head`}
            value={rowData.head || ""}
            onChange={onChange}
            fontSize="0.75rem"
          >
            {counselorNameList}
          </StyledSimpleSelectInput> */}

          <DateInput
            className="recallDateInput"
            value={moment(rowData.recallDate).format("YYYY-MM-DD") || ""}
            name={`${i}-recall_date`}
            onChange={() => {}}
            fontSize="0.75rem"
            style={{}}
          />

          <div className="dailyCallCount">
            {[1, 2, 3]
              .map((v) => v + Math.floor((rowData.callCount - 1) / 3) * 3)
              .map((v, j) => (
                <div key={j}>
                  <FormControlLabel
                    value={v}
                    label={`부재${v}`}
                    control={
                      <Radio
                        checked={rowData.callCount == v}
                        onChange={onChange}
                        color="secondary"
                        name={`${i}-callCount`}
                        size="small"
                      />
                    }
                  />
                </div>
              ))}
          </div>

          <div className="callResult">
            {/* {[true, false].map((v, j) => (
              <div key={j}>
                <FormControlLabel
                  value={v}
                  label={v ? "성공" : "실패"}
                  control={
                    <Radio
                      checked={String(rowData.result) == String(v)}
                      onChange={onChange}
                      color="secondary"
                      name={`${i}-result`}
                      size="small"
                    />
                  }
                />
              </div>
            ))} */}
          </div>

          <div>
            <TextInput
              className="memoInput"
              name={`${i}-etc`}
              value={rowData.etc || ""}
              onChange={onChange}
            />
            <Button
              variant="contained"
              color="primary"
              className="memoAddButton"
              onClick={() => onApplyClick(i)}
            >
              <span>등록</span>
            </Button>
          </div>
        </div>
      </div>
    ));
  }, [visitDetailTm]);

  const items = recallMakeFn();
  // items.push(
  //   <div className="rowWrapper" key={items.length}>
  //     <span>DB 폐기 여부</span>
  //     <div className="rowItem dropDb">
  //       <span>
  //         문자에 대해 더 이상 원하지 않는다고 하는 고객들 DB는 폐기합니다.
  //       </span>
  //       <Button
  //         variant="contained"
  //         className="dropButton"
  //         onClick={onClickDelete}
  //       >
  //         <span>DB 폐기</span>
  //       </Button>
  //     </div>
  //   </div>,
  items.push(
    <div className="rowWrapper" key={items.length}>
      <span>설명</span>
      <div className="rowItem dropDb introduceRedText">
        <span>부재3까지 전화실패후 한달뒤 30% 가망에 들어갑니다</span>
      </div>
    </div>,
  );

  return <div className="recallManagementItem">{items}</div>;
}

export default function RecallManagement({
  uiData,
  setUiData,
  deleteRecall,
  addRecall,
  updateRecall,
  openAlert,
  csList,
  visitDetailTm,
  onClickDelete,
}) {
  const { openConfirm } = useAlert();

  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      const [rowIdx, dataIdx] = name.split("-");

      setUiData(
        produce((draft) => {
          draft.recalls[rowIdx][dataIdx] = value;
        }),
      );
    },
    [setUiData],
  );

  const onCloseClick = React.useCallback(
    async (event) => {
      const confirm = await openConfirm(
        "정말 리콜 정보를 삭제하시겠습니까?",
        "경고",
      );

      if (confirm) {
        for (const recall of uiData.recalls) {
          if (recall.id) {
            await deleteRecall(recall.id);
          }
        }
        setUiData(
          produce((draft) => {
            draft.recalls.length = 0;
          }),
        );
      }
    },
    [uiData, setUiData, openConfirm, deleteRecall],
  );

  const onApplyClick = React.useCallback(
    async (rowIdx) => {
      const row = uiData.recalls[rowIdx];

      const nowDate = new Date();
      nowDate.setMonth(nowDate.getMonth() + 1);

      const newData = {
        ...row,
        recallDate: nowDate,
      };

      await updateRecall([
        row.id,
        { recallDate: nowDate, callCount: row.callCount, etc: row.etc },
      ]);

      await instanceAxios.put(`/visits/${visitDetailTm.data.visit.id}`, {
        status: `부재${row.callCount}`,
      });

      visitDetailTm.refetch();
    },
    [uiData, updateRecall, visitDetailTm],
  );

  return uiData.recalls.length ? (
    <div className="recallManagement">
      <ManagementBar uiData={uiData} onCloseClick={onCloseClick} />
      <RecallManagementHeader />
      <RecallManagementItem
        data={uiData.recalls}
        onChange={onChange}
        csList={csList}
        updateRecall={updateRecall}
        visitDetailTm={visitDetailTm}
        onApplyClick={onApplyClick}
        onClickDelete={onClickDelete}
      />
    </div>
  ) : (
    <></>
  );
}
