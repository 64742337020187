import React from "react";
import produce from "immer";
import TextInput from "~ui/textInput/TextInput";
import MenuItem from "@material-ui/core/MenuItem";
import SelectInput from "~ui/selectInput/SelectInput";
import { useRecoilState } from "recoil";
import { searchQueryState } from "~admin/stores/channel";

import "./Management.scss";

export default function Management() {
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);
  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      setSearchQuery(
        produce((draft) => {
          draft[name] = event.target.value;
        }),
      );
    },
    [setSearchQuery],
  );

  const TableTitle = ["년도", "월", "일", "경로", "소개자 및 비고"];
  const TableTitle1 = [
    "이름",
    "전화번호",
    "나이",
    " ",
    "거주지역",
    " ",
    "신규여부",
    "가망",
    "리콜/문자 필요여부",
    "TM",
  ];
  const TableTitle2 = ["내원예약날짜", "내원여부", "차트번호", "치료진행여부"];
  const Data = [
    "서울시",
    "부산시",
    "대구시",
    "인천시",
    "광주시",
    "대전시",
    "울산시",
    "세종시",
    "경기도",
    "강원도",
    "충청북도",
    "충청남도",
    "전라북도",
    "전라남도",
    "경상북도",
    "경상남도",
    "제주도",
  ];
  const Data1 = ["강남구"];
  const Data2 = ["상일동"];
  const Data3 = ["신규DB"];
  const Data4 = ["김진우"];

  const Items = Data.map((x, i) => (
    <MenuItem key={x} value={x}>
      {" "}
      {x}{" "}
    </MenuItem>
  ));
  const Items1 = Data1.map((x, i) => (
    <MenuItem key={x} value={x}>
      {" "}
      {x}{" "}
    </MenuItem>
  ));
  const Items2 = Data2.map((x, i) => (
    <MenuItem key={x} value={x}>
      {" "}
      {x}{" "}
    </MenuItem>
  ));
  const Items3 = Data3.map((x, i) => (
    <MenuItem key={x} value={x}>
      {" "}
      {x}{" "}
    </MenuItem>
  ));
  const Items4 = Data4.map((x, i) => (
    <MenuItem key={x} value={x}>
      {" "}
      {x}{" "}
    </MenuItem>
  ));

  return (
    <div className="management">
      <div className="managementTitle">DB 유입경로</div>
      <div className="managementTitle">DB 고객 정보</div>
      <div className="managementTitle">내원 기록</div>
      <div>
        <table className="GlobalTable">
          <thead className="GlobalTableHeader" style={{background: "none"}}>
            <tr>
              {TableTitle.map((x, i) => (
                <td key={i}>
                  <span>{x}</span>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="GlobalTableItem" style={{background: "none"}}>
            <tr>
              <td style={{width:"20%"}}><TextInput /></td>
              <td style={{width:"15%"}}><TextInput /></td>
              <td style={{width:"15%"}}><TextInput /></td>
              <td style={{width:"20%"}}><TextInput /></td>
              <td style={{width:"30%"}}><TextInput /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <table className="GlobalTable">
          <thead className="GlobalTableHeader" style={{background: "none"}} >
            <tr>
              {TableTitle1.map((x) => (
                <td>
                  <span>{x}</span>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="GlobalTableItem" style={{background: "none"}}>
            <tr>

              <td style={{width:"9%"}}><TextInput /></td>
              <td style={{width:"13%"}}><TextInput /></td>
              <td style={{width:"7%"}}><TextInput /></td>
              
              <td style={{width:"10%"}}>
                <SelectInput
                  className="selectType"
                  name="year"
                  value={Data[0]}
                  onChange={onChange}
                >
                  {Items}
                </SelectInput>
              </td>
              
              <td style={{width:"10%"}}>
                <SelectInput
                  className="selectType"
                  name="year"
                  value={Data1[0]}
                  onChange={onChange}
                >
                  {Items1}
                </SelectInput>
              </td>
              
              <td style={{width:"10%"}}>
                <SelectInput
                  className="selectType"
                  name="year"
                  value={Data2[0]}
                  onChange={onChange}
                >
                  {Items2}
                </SelectInput>
              </td>
              
              <td style={{width:"10%"}}>
                <SelectInput
                  className="selectType"
                  name="new"
                  value={Data3[0]}
                  onChange={onChange}
                >
                  {Items3}
                </SelectInput>
              </td>
              <td style={{width:"8%"}}><TextInput /></td>
              <td style={{width:"13%"}}>
                <div className="TMButton">리콜진행취소</div>
              </td>

              
              <td style={{width:"10%"}}>
                <SelectInput
                  className="selectType"
                  name="TM"
                  value={Data4[0]}
                  onChange={onChange}
                >
                  {Items4}
                </SelectInput>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <div>
        <table className="GlobalTable">
          <thead className="GlobalTableHeader" style={{background: "none"}}>
            <tr>
              {TableTitle2.map((x) => (
                <td>
                  <span>{x}</span>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="GlobalTableItem" style={{background: "none"}}>
            <tr>
              <td><TextInput /></td>
              <td><TextInput /></td>
              <td><TextInput /></td>
              <td><TextInput /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
