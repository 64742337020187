import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ChannelManagement from "./pages/channelManagement/ChannelManagement";
import AddChannel from "./pages/addChannelMain/AddChannelMain";
import AddChannelEdit from "./pages/addChannelEdit/AddChannelEdit";
import AddChannelDetail from "./pages/addChannelDetail/AddChannelDetail";
import AddDoctor from "./pages/addDoctor/AddDoctor";
import AddDoctorEdit from "./pages/addDoctorEdit/AddDoctorEdit";
import AddCS from "./pages/addCS/AddCS";
import AddCSEdit from "./pages/addCSEdit/AddCSEdit";
import AddCounselor from "./pages/addCounselor/AddCounselor";
import AddCounselorEdit from "./pages/addCounselorEdit/AddCounselorEdit";
import AddUser from "./pages/addUser/AddUser";
import AddUserEdit from "./pages/addUserEdit/AddUserEdit";

export default function Admin() {
  return (
    <Switch>
      <Redirect from="/channel/" to="/channel/channelManagement" exact />
      <Redirect from="/admin/" to="/admin/addUser" exact />
      <Route
        component={ChannelManagement}
        exact
        path="/channel/channelManagement"
      />
      <Route
        component={AddChannelDetail}
        exact
        path={["/channel/addChannel/detail", "/channel/addChannel/detail/:id"]}
      />
      <Route component={AddChannel} exact path="/channel/addChannel" />
      <Route component={AddChannelEdit} exact path="/channel/addChannel/add" />
      <Route
        component={AddChannelEdit}
        exact
        path="/channel/addChannel/edit/:id"
      />

      <Route component={AddDoctor} exact path="/admin/addDoctor" />
      <Route component={AddDoctorEdit} exact path="/admin/addDoctor/add" />
      <Route component={AddDoctorEdit} exact path="/admin/addDoctor/edit/:id" />

      <Route component={AddCS} exact path="/admin/addCS" />
      <Route component={AddCSEdit} exact path="/admin/addCS/add" />
      <Route component={AddCSEdit} exact path="/admin/addCS/edit/:id" />

      <Route component={AddCounselor} exact path="/admin/addCounselor" />
      <Route
        component={AddCounselorEdit}
        exact
        path="/admin/addCounselor/add"
      />
      <Route
        component={AddCounselorEdit}
        exact
        path="/admin/addCounselor/edit/:id"
      />

      <Route component={AddUser} exact path="/admin/addUser" />
      <Route component={AddUserEdit} exact path="/admin/addUser/add" />
      <Route component={AddUserEdit} exact path="/admin/addUser/edit/:id" />
      {/* <Route component={파일이름} path="url" /> */}
    </Switch>
  );
}
