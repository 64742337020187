import React from "react";
import styled from "styled-components";

import "./textInput.scss";

const StyledTextInput = styled.input`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  border-radius: 0.3rem;
  background-color: white;
  border: none;
  outline: none;
  padding: 0.78rem;
  font: inherit;
  font-weight: 500;
  width: 100%;
  height: 100%;

  &::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`;

export default function TextInput({
  type,
  className,
  inputClassName,
  name,
  value,
  onChange,
  onClick,
  fontSize,
  style,
  placeholder,
}) {
  return (
    <div
      className={`textInput ${className && className}`}
      style={style && style}
    >
      <StyledTextInput
        type={type ? type : "text"}
        name={name}
        value={value}
        onChange={onChange}
        onClick={onClick}
        fontSize={fontSize ? fontSize : "1rem"}
        placeholder={placeholder && placeholder}
        className={inputClassName && inputClassName}
      ></StyledTextInput>
    </div>
  );
}
