import React from "react";
import { useRecoilState } from "recoil";
import PageTitle from "~/global/components/tamplete/title/title";
import Table from "~/global/components/tamplete/table/table";
import Search from "~/global/components/tamplete/search/search";
import Pazing from "~/global/components/tamplete/pazing/Pazing";
import ControlsButton from "~/global/components/tamplete/controlsbutton/ControlsButton";
import { searchQueryState } from "~admin/stores/channel";
import backgroundImage from "~background/background2.png";
import { useGetHeadList, useGetHeadListCount } from "~apis/head";
import qs from "qs";

import "./AddCounselor.scss";

export default function AddCounselor() {
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);

  const queryString = qs.stringify({
    _start: (searchQuery.page - 1) * 10,
    _limit: 10,
    name_contains: searchQuery.keyword,
  });
  const headList = useGetHeadList(queryString);
  const headListCount = useGetHeadListCount(queryString);

  const isFetching = headList.isFetching || headListCount.isFetching;
  const isError = headList.isError || headListCount.isError;
  const pages = Math.ceil(headListCount.data / 10);

  const SearchData = ["전체"];
  const SearchButton = { Text: "Search" };
  const TableTitle = ["", "#", "실장", "수정/삭제"];
  const TableData = [];
  const TableStyle = [20, 70, 10];
  let [value, setValue] = React.useState([]);

  const checkEvent = (e) => {
    const getId = e.target.value;
    const checked = e.target.checked;
    let dataVal = [...value, getId];

    if (!checked && dataVal.includes(getId)) {
      dataVal = dataVal.filter((x) => x != getId);
    }

    setValue(() => {
      return [...dataVal];
    });
  };
  return (
    <div className="addCounselor">
      <div className="mainTitleDiv">
        <div className="item">
          <PageTitle title="실장 추가" />
          <Search {...{ SearchData, SearchButton }} />
        </div>

        <div className="item">
          <img src={backgroundImage} alt="" className="backgroundImage" />
        </div>
      </div>

      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}

      {!isFetching && !isError && (
        <>
          {headList.data.map((x) => {
            TableData.push([
              {
                type: "input",
                typeOption: "checkbox",
                onClick: checkEvent,
                value: x.id,
                name: "id",
              },
              x.id,
              x.name,
              "EditButtons",
            ]);
          })}

          <Table
            count={headListCount.data}
            TableStyle={TableStyle}
            value={value}
            Type="Head"
            refetch={headList.refetch}
            {...{ TableTitle, TableData }}
          />

          <Pazing
            pages={pages}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </>
      )}

      <ControlsButton
        url="/admin/addCounselor/add"
        type="Head"
        refetch={headList.refetch}
        DelList={value}
      />
    </div>
  );
}
