import { atom } from "recoil";

const now = new Date();
const nowYear = now.getFullYear();
const nowMonth = now.getMonth();

export const searchQueryState = atom({
  key: "channelSearchQueryState",
  default: {
    year: nowYear,
    month: nowMonth,
    channel: "",
    agency: "",
    roi: "",
    sort: "",
    keyword: "",
    orderBy: "",
    page: 1,
    startDate: "",
    endDate: "",
  },
});

const testData_channelListState = {
  pages: 100,
  list: [
    {
      id: 1,
      data1: "진행",
      data2: "O",
      data3: "A TV",
      data4: 1,
      data5: 1,
      data6: 33,
      data7: 33,
      data8: 33,
      data9: 33,
      data10: 12,
      data11: "A 업체",
      data12: 2000000,
      data13: 8,
      data14: 4,
      data15: 2000000,
      data16: 30,
      data17: "생방송",
    },
    {
      id: 2,
      data1: "진행",
      data2: "O",
      data3: "B TV",
      data4: 1,
      data5: 1,
      data6: 33,
      data7: 33,
      data8: 33,
      data9: 33,
      data10: 12,
      data11: "B 업체",
      data12: 2000000,
      data13: 8,
      data14: 4,
      data15: 2000000,
      data16: 30,
      data17: "생방송",
    },
    {
      id: 3,
      data1: "중단",
      data2: "O",
      data3: "C TV",
      data4: 1,
      data5: 1,
      data6: 33,
      data7: 33,
      data8: 33,
      data9: 33,
      data10: 12,
      data11: "C 업체",
      data12: 2000000,
      data13: 8,
      data14: 4,
      data15: 2000000,
      data16: 30,
      data17: "생방송",
    },
  ],
};

export const channelListState = atom({
  key: "channelListState",
  default: testData_channelListState,
});
