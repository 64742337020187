import React from "react";
import { FiUsers } from "react-icons/fi";
import useLogin from "~hooks/useLogin";

import "./profile.scss";

export default function Profile() {
  const { openLogin } = useLogin();
  return (
    <div className="profile">
      <div className="circle" onClick={openLogin}>
        <FiUsers size="2.8rem" />
      </div>
    </div>
  );
}
