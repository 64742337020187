import React from "react";
import ChannelViewList from "./channelViewList/ChannelViewList";
import ChannelViewControl from "./channelViewControls/ChannelViewControls";

import "./channelView.scss";

export default function ChannelView({
  searchQuery,
  setSearchQuery,
  broadcastingList,
  pages,
  onPageChange,
}) {
  const [checkedItems, setCheckedItems] = React.useState([]);
  React.useEffect(() => {
    setCheckedItems([]);
  }, [broadcastingList.data.list]);

  return (
    <div className="channelView">
      <ChannelViewList
        {...{
          checkedItems,
          setCheckedItems,
          broadcastingList,
          searchQuery,
          setSearchQuery,
        }}
      />
      <ChannelViewControl
        {...{
          checkedItems,
          searchQuery,
          setSearchQuery,
          broadcastingList,
          pages,
          onPageChange,
        }}
      />
    </div>
  );
}
