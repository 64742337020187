import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Counseling from "./pages/counseling/Counseling";
import Customer_reservation from "./pages/customer_reservation/Customer_reservation";
import CounselingDetail from "./pages/counselingDetail/CounselingDetail";
import DbStatistics from "./pages/dbStatistics/DbStatistics";
import IntroduceStatistics from "./pages/introduceStatistics/IntroduceStatistics";
import StatisticsManagementDay2Day from "../tm/pages/statisticsManagementDay2Day/StatisticsManagementDay2Day";
import StatisticsManagement from "../tm/pages/statisticsManagement/StatisticsManagement";
import StatisticsAgencyManagement from "../tm/pages/statisticsAgencyManagement/StatisticsAgencyManagement";

import Sms from "../tm/pages/sms/Sms";
import SmsSend from "../tm/pages/sms/send/send";
import SmsList from "../tm/pages/sms/list/sendList";
import SendRequest from "../tm/pages/sms/sendRequest/sendRequest";

export default function Counselor() {
  return (
    <Switch>
      <Redirect from="/counselor/" to="/counselor/counseling" exact />
      <Route component={Counseling} path="/counselor/counseling" exact />
      <Route
        component={Counseling}
        path="/counselor/counseling/customer_reservation"
        exact
      />
      <Route
        component={CounselingDetail}
        path={[
          "/counselor/counseling/detail",
          "/counselor/counseling/detail/:id",
        ]}
        exact
      />
      <Route
        component={DbStatistics}
        path={[
          "/counselor/dailyStatistics",
          "/counselor/weeklyStatistics",
          "/counselor/monthlyStatistics",
          "/counselor/yearlyStatistics",
        ]}
      />

      <Route
        component={IntroduceStatistics}
        path={["/counselor/introduceStatistics"]}
      />

      <Redirect
        from="/counselor/statisticsManagement/"
        to="/counselor/StatisticsManagement/period"
        exact
      />

      <Route
        component={StatisticsManagementDay2Day}
        exact
        path="/counselor/statisticsManagement/period"
      />
      <Route
        component={StatisticsManagement}
        exact
        path="/counselor/statisticsManagement/monthly"
      />
      <Route
        component={StatisticsAgencyManagement}
        exact
        path="/counselor/statisticsManagement/agency/monthly"
      />

      <Route component={SmsSend} exact path="/counselor/sms" />
      <Route component={SmsSend} exact path="/counselor/sms/send" />
      <Route component={SmsList} exact path="/counselor/sms/list" />
      <Route component={SendRequest} exact path="/counselor/sms/sendRequest" />
    </Switch>
  );
}
