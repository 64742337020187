import React from "react";
import clsx from "clsx";

import "./button.scss";

export default function Button({
  className,
  color = "black",
  transparent,
  children,
  ...props
}) {
  const classNameResult = clsx(
    "UI-button",
    `UI-button_${color}`,
    transparent && "UI-button_transparent",
    className,
  );

  return (
    <button className={classNameResult} {...props}>
      {children}
    </button>
  );
}
