import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";
import qs from "qs";

// 의사 추가
export const useAddDoctor = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/doctors", data);

      return res;
    },
    {
      loadingMessage: "의사 정보를 추가 중입니다",
      successMessage: "의사가 추가되었습니다",
    },
  );
};

// 의사 삭제
export const useDeleteDoctor = () => {
  return useLoadingMutation(
    async (doctorID) => {
      const res = await axios.delete(`/doctors/${doctorID}`);

      return res;
    },
    {
      loadingMessage: "의사 삭제 중입니다",
      successMessage: "의사가 삭제 되었습니다",
    },
  );
};

// 의사 수정
export const useUpdateDoctor = (doctorID) => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.put(`/doctors/${doctorID}`, data);
      return res;
    },
    {
      loadingMessage: "의사 정보를 수정 중입니다",
      successMessage: "의사가 수정되었습니다",
    },
  );
};

// Doctor 아이템 조회
export const useGetDoctorItem = (queryString = "", options) => {
  return useQuery(
    ["Doctor", queryString],
    async () => {
      const data = await axios.get(
        `/Doctors${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Doctor 목록 조회
export const useGetDoctorList = (queryString = "", options) => {
  return useQuery(
    ["Doctor", queryString],
    async () => {
      const data = await axios.get(
        `/Doctors${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Doctor 목록 아이템 수 조회
export const useGetDoctorListCount = (queryString = "", options) => {
  return useQuery(
    ["DoctorCount", queryString],
    async () => {
      const data = await axios.get(
        `/Doctors/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 통계
export const useGetMonthlySalesStatistics = (queryString = "", options) => {
  return useQuery(
    ["MonthlySalesStatistics", queryString],
    async () => {
      const data = await axios.get(
        `/Doctors/monthlySalesStatistics${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};
