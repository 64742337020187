import React from "react";
import { Doughnut } from "react-chartjs-2";
import { chartColors } from "~utils/chart";
import { theme } from "~global/assets/js/theme";

import "./statisticsChart_1.scss";

function StatisticsSummary({ salesData }) {
  let numberOfCounselings = 0;
  let numberOfPatients = 0;
  let totalCounselingAmount = 0;
  let totalContractAmount = 0;
  for (const data of salesData) {
    numberOfCounselings += parseInt(data.counseling_count) || 0;
    numberOfPatients += parseInt(data.progress_count) || 0;
    totalCounselingAmount += parseInt(data.counseling_amount) || 0;
    totalContractAmount += parseInt(data.progress_amount) || 0;
  }

  return (
    <div className="statisticsSummary">
      <div className="summaryRow">
        <div className="summaryItem">
          <div>
            <span>상담진행수</span>
          </div>
          <div>
            <span>{numberOfCounselings}개</span>
          </div>
        </div>
        <div className="summaryItem">
          <div>
            <span>진행환자수</span>
          </div>
          <div>
            <span>{numberOfPatients}명</span>
          </div>
        </div>
      </div>
      <div className="summaryRow">
        <div className="summaryItem">
          <div>
            <span>총 상담금액</span>
          </div>
          <div>
            <span>{totalCounselingAmount.toLocaleString("ko-KR")}원</span>
          </div>
        </div>
        <div className="summaryItem">
          <div>
            <span>총 계약금액</span>
          </div>
          <div>
            <span>{totalContractAmount.toLocaleString("ko-KR")}원</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatisticsChart_1({ salesData }) {
  const counselorStatistics = {};
  const doctorsStatistics = {};

  for (const data of salesData) {
    if (data.doctors) {
      for (const [key, value] of Object.entries(data.doctors)) {
        if (doctorsStatistics[key]) {
          doctorsStatistics[key] += value.count;
        } else {
          doctorsStatistics[key] = value.count;
        }
      }
    }
    if (data.heads) {
      for (const [key, value] of Object.entries(data.heads)) {
        if (counselorStatistics[key]) {
          counselorStatistics[key] += value.count;
        } else {
          counselorStatistics[key] = value.count;
        }
      }
    }
  }

  const doughnutChartData_1 = {
    datasets: [
      {
        data: Object.values(counselorStatistics),
        backgroundColor: chartColors(Object.values(counselorStatistics).length),
      },
    ],
    labels: Object.keys(counselorStatistics),
  };

  const doughnutChartData_2 = {
    datasets: [
      {
        data: Object.values(doctorsStatistics),
        backgroundColor: chartColors(Object.values(doctorsStatistics).length),
      },
    ],
    labels: Object.keys(doctorsStatistics),
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          color: theme.palette.primary,
          boxWidth: 8,
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <div className="statisticsChart_1">
      <div className="doughnutChart_1">
        <span className="subtitle">실장</span>
        <Doughnut data={doughnutChartData_1} options={options} />
      </div>
      <div className="doughnutChart_2">
        <span className="subtitle">원장</span>
        <Doughnut data={doughnutChartData_2} options={options} />
      </div>
      <StatisticsSummary {...{ salesData }} />
    </div>
  );
}

export default React.memo(StatisticsChart_1);
