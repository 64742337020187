import React from "react";
import { useHistory, useParams } from "react-router-dom";
import VisitDateBar from "~/counselor/components/counselingDetail/visitDateBar/VisitDateBar";
import PatientInfo from "~/counselor/components/counselingDetail/patientInfo/PatientInfo";
import CounselingInfo from "~/counselor/components/counselingDetail/counselingInfo/CounselingInfo";
import ProgressManagement from "~/counselor/components/counselingDetail/progressManagement/ProgressManagement";
import RecallManagement from "~/counselor/components/counselingDetail/recallManagement/RecallManagement";
import PageControls from "~/counselor/components/counselingDetail/pageControls/PageControls";
import CallContentManagement from "~/tm/components/totalManagementDetail/callContentManagement/CallContentManagement";

import {
  useGetVisitDetailHead,
  useAddVisits,
  useDeleteVisits,
  useUpdateVisitsItem,
  useAddVisitsLog,
} from "~apis/visits";
import { useGetChannelList, useGetAllChannelList } from "~apis/channels";
import { useGetHeadList } from "~apis/head";
import { useGetDoctorList } from "~apis/doctors";
import {
  useDeleteRecall_Head,
  useAddRecall_Head,
  useUpdateRecall,
} from "~apis/recalls";

import {
  useDeleteCounseling,
  useAddCounseling,
  useUpdateCounseling,
} from "~apis/counselings";

import useAlert from "~hooks/useAlert";
import produce from "immer";
import moment from "moment";

import instanceAxios from "~apis/_util";
// /visits?phone=${phone}
// .lengt

import "./counselingDetail.scss";

export default function CounselingDetail() {
  const access = window.localStorage.getItem("user.access");

  const history = useHistory();
  const { id } = useParams();
  const isEdit = Boolean(id);
  const { openAlert } = useAlert();

  const visitDetailHead = useGetVisitDetailHead(id);
  const channelList = useGetAllChannelList();
  const headList = useGetHeadList();
  const doctorList = useGetDoctorList();

  const { mutateAsync: addVisits } = useAddVisits();
  const { mutateAsync: deleteVisits } = useDeleteVisits();
  const { mutateAsync: updateVisitsItem } = useUpdateVisitsItem();
  const { mutateAsync: addVisitsLogs } = useAddVisitsLog();
  const { mutateAsync: deleteRecall } = useDeleteRecall_Head();
  const { mutateAsync: addRecall } = useAddRecall_Head();
  const { mutateAsync: deleteCounseling } = useDeleteCounseling();
  const { mutateAsync: addCounseling } = useAddCounseling();
  const { mutateAsync: updateCounseling } = useUpdateCounseling();

  const [phoneCheck, setPhoneCheck] = React.useState(true);

  const [uiData, setUiData] = React.useState({
    visit: {
      reservationDate: "",
      visitDate: "",
      chartNumber: "",
      phone: "",
      age: 0,
      name: "",
      address1: "",
      address3: "",
      inflowRoute: "",
      inflowRouteDetail: "",
      isEventVisit: false,
      referralPatientIndex: null,
      referralPatientVisitRoute: null,
      cure: "",
      cc: "",
      counseling: "",
      counselingContent: "",
      counselingTotal: 1,
      payment: "",
      head: 0,
      doctor: 0,
      counselingInfo: null,
      progressInfo: "",
      counselingResult: null,
      stopMemo: null,
      type: "head",
    },
    recalls: [],
    counselings: [],
  });
  const { mutateAsync: updateRecall } = useUpdateRecall(uiData.visit.id);

  React.useEffect(() => {
    if (!visitDetailHead.data) {
      return;
    }

    setUiData(
      produce((draft) => {
        Object.assign(draft, visitDetailHead.data);
        //newData.counselingTotal = (newData.counselingTotal.replace(/[^0-9]/g, "") * 1);

        if (!draft.recalls.length) {
          draft.recalls = [];
        }
      }),
    );
  }, [visitDetailHead.data]);

  const isFetching =
    visitDetailHead.isFetching ||
    channelList.isFetching ||
    headList.isFetching ||
    doctorList.isFetching;

  const isError =
    visitDetailHead.isError ||
    channelList.isError ||
    headList.isError ||
    doctorList.isError;

  const onClickDelete = React.useCallback(async () => {
    if (uiData.visit.id) {
      await deleteVisits(uiData.visit.id);
      history.push("/counselor/counseling");
    }
  }, [uiData, history, deleteVisits]);

  const onClickApply = React.useCallback(async () => {
    // 중복체크
    // const getTellData = await new Promise((res) =>
    //   instanceAxios(`/visits?phone=${uiData.visit.phone}`).then((v) => res(v)),
    // );

    const newData = produce(uiData.visit, (draft) => {
      if (!draft.counselingTotal) {
        draft.counselingTotal = null;
      }

      if (!draft.reservationDate) {
        draft.reservationDate = null;
      }
      if (!draft.visitDate) {
        draft.visitDate = null;
      }

      delete draft.id;
    });

    const totalCost = newData?.counselingTotal && newData.counselingTotal;

    if (uiData.visit.id) {
      let sqlPushData = {};
      if (uiData.visit.type === "tm") {
        sqlPushData.type = "all";
      }

      await updateVisitsItem([
        uiData.visit.id,

        {
          ...newData,
          counseling: uiData.visit.counseling,
          counselingTotal: totalCost,
          ...sqlPushData,
        },
      ]);
      // await addVisitsLogs({
      //   counselingTotal: totalCost - visitDetailHead.data.visit.counselingTotal,
      //   visit: uiData.visit.id,
      // });
    } else {
      try {
        const outsideData = {};

        if (access.includes("외부입력")) {
          outsideData.outside = access.replace("외부입력", "");
        }

        const res = await addVisits({ ...newData, ...outsideData, visitDate : new Date(), });

        // await addVisitsLogs({
        //   counselingTotal: totalCost,
        //   visit: res.id,
        // });

        history.push(`/counselor/counseling/detail/${res.id}`);
      } catch (error) {
        console.error(error);
      }
    }

    visitDetailHead.refetch();
    // history.push("/counselor/counseling");
  }, [uiData, history, updateVisitsItem, addVisits, visitDetailHead]);

  const onClickCounseling = React.useCallback(
    (event) => {
      // if (
      //   10 <= uiData.counselings.length ||
      //   uiData.counselings?.some((x) => x.editMode)
      // ) {
      //   return;
      // }

      // if (!uiData.visit.id) {
      //   openAlert("먼저 DB를 등록해야합니다");
      //   return;
      // }

      const nowDate = new Date();

      const callDateString = `${nowDate.getFullYear()}-${
        nowDate.getMonth() + 1
      }-${nowDate.getDate()}`;

      setUiData(
        produce((draft) => {
          draft.counselings.push({
            callDate: callDateString,
            callTime: `${nowDate.getHours()}:${nowDate.getMinutes()}`,
            calltime: `${nowDate.getHours()}:${nowDate.getMinutes()}`,
            callDatetime:
              callDateString + ` ${nowDate.getHours()}:${nowDate.getMinutes()}`,
            progressContent: "",
            category: "",
            counselingContent: "",
            recallRequestDate: "",
            editMode: true,
            isHead: true,
          });
        }),
      );
    },
    [uiData, setUiData, openAlert],
  );

  const phoneBtnClick = (e) => {
    let v = e.target.value;

    if (!v) {
      v = e.target.parentNode.value;
    }

    //visits?phone=${phone}
    instanceAxios(`/visits?phone=${v}`).then(async (x) => {
      setPhoneCheck(x?.length);

      const getTellData = await new Promise((res) =>
        instanceAxios(`/visits?phone=${uiData.visit.phone}`).then((v) =>
          res(v),
        ),
      );

      if (
        x?.length &&
        !getTellData &&
        window.confirm(
          "전화번호가 같은 데이터가 이미 존재합니다. 수정페이지로 이동하시겠습니까?",
        )
      ) {
        history.push(`/counselor/counseling/detail/${x[0].id}`);
        return;
      } else {
        alert(
          "전화번호가 같은 데이터가 존재하지 않습니다.\n신규등록을 계속 진행해주세요.",
        );
      }
    });
  };

  const editcheck = uiData.visit.id;

  const getCsvData = React.useCallback(() => {
    if (isFetching || isError) {
      return [];
    }

    const head = headList.data.find((x) => x.id == uiData.visit.head);
    const doctor = doctorList.data.find((x) => x.id == uiData.visit.doctor);
    const data = [
      ["내원 예약 날짜", "내원 날짜"],
      [(uiData.visit.reservationDate, uiData.visit.visitDate)],
      [],
      [
        "No",
        "차트번호",
        "구환",
        "이름",
        "전화번호",
        "나이",
        "거주지역",
        "경로",
        "업체",
        "세부경로",
        "이벤트 내원",
        "소개환자 성함/차트번호",
        "소개환자 내원경로",
      ],
      [
        uiData.visit.id,
        uiData.visit.chartNumber,
        uiData.visit.name,
        uiData.visit.isRepeatVisit ? "O" : "X",
        uiData.visit.phone,
        uiData.visit.age,
        [
          uiData.visit.address1,
          uiData.visit.address3,
        ].join(" "),
        uiData.visit.inflowRoute,
        uiData.visit.inflowRouteDetail,
        uiData.visit.isEventVisit ? "O" : "X",
        uiData.visit.referralPatientIndex,
        uiData.visit.referralPatientVisitRoute,
      ],
      [],
      [
        "치료진행여부",
        "상담항목",
        "CC",
        "상담내용",
        "상담총액",
        "수납내역",
        "담당실장",
        "Doctor",
      ],
      [
        uiData.visit.cure,
        uiData.visit.counseling,
        uiData.visit.cc,
        uiData.visit.counselingContent,
        uiData.visit.counselingTotal,
        uiData.visit.payment,
        head ? head.name : "",
        doctor ? doctor.name : "",
      ],
      [],
      ["상담여부", "진행여부", "상담 진행 결과", "상담 진행 결과 메모"],
      [
        ["상담불필요", "보철", "IT", "교정"]
          .filter(
            (_, i) =>
              uiData.visit.counselingInfo &&
              uiData.visit.counselingInfo[i] === "1",
          )
          .join(", "),
        ["보험", "IT", "미백/보톡스", "일반교정", "인비절라인"]
          .filter(
            (_, i) =>
              uiData.visit.progressInfo && uiData.visit.progressInfo[i] === "1",
          )
          .join(", "),
        uiData.visit.counselingResult,
        uiData.visit.stopMemo,
      ],
      [],
      ["", "담당실장", "리콜날짜", "달일 콜 횟수", "결과", "비고"],
    ];

    for (const i of uiData.recalls.keys()) {
      const recall = uiData.recalls[i];
      data.push([
        `리콜 ${i + 1}차`,
        recall.head_name,
        recall.recall_date,
        recall.callCount,
        recall.result ? "성공" : "실패",
        recall.etc,
      ]);
    }

    return data;
  }, [uiData, headList, doctorList, isFetching]);

  const onClickCopy = async () => {
    if (
      !window.confirm(
        "해당 환자의 DB를 복제하시겠습니까?\n복제된 DB는 자동으로 구환 처리 및 내원날짜가 오늘로 변경됩니다.",
      )
    ) {
      return false;
    }

    let visit = await instanceAxios.get(`/visits/${uiData.visit.id}`);
    const counselings = visit.counselings;

    visit.head = visit?.head?.id;
    visit.doctor = visit?.doctor?.id;
    visit.counselor = visit?.counselor?.id;
    visit.isRepeatVisit = true;
    visit.visitDate = moment().format("YYYY-MM-DD");

    delete visit.visits_logs;
    delete visit.counselorRecalls;
    delete visit.doctorRecalls;
    delete visit.counselings;
    delete visit.id;

    const copyedVisitData = await addVisits(visit);

    for await (let counseling of counselings) {
      counseling.visit = copyedVisitData.id;
      await addCounseling(counseling);
    }

    history.replace(`/counselor/counseling/detail/${copyedVisitData.id}`);
    alert("복제된 DB로 이동합니다");
  };

  const phoneClick = () => {
    history.push(`/counselor/sms/send?phone=${uiData.visit.phone}`);
  };

  return (
    <div className="counselingDetail">
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <VisitDateBar {...{ uiData, setUiData, updateVisitsItem }} />

          <div className="splitBlock">고객 DB 정보</div>
          <PatientInfo
            {...{
              uiData,
              setUiData,
              channelList,
              phoneBtnClick,
              setPhoneCheck,
            }}
          />

          <div className="splitBlock">상담 DB 정보</div>
          <CounselingInfo {...{ uiData, setUiData, headList, doctorList }} />

          <div className="splitBlocks">
            <div className="splitBlock">상담여부</div>
            <div className="splitBlock">진행여부</div>
            <div className="splitBlock">상담 진행 결과</div>
          </div>
          <ProgressManagement {...{ uiData, setUiData }} />
          {/* <RecallManagement
            {...{
              uiData,
              setUiData,
              headList,
              openAlert,
              deleteRecall,
              addRecall,
              updateRecall,
            }}
          /> */}
          {isEdit && (
            <CallContentManagement
              {...{
                uiData,
                setUiData,
                deleteCounseling,
                addCounseling,
                updateCounseling,
                openAlert,
                visitDetailHead,
                isEdit,
              }}
            />
          )}
          <PageControls
            {...{
              onClickDelete,
              onClickApply,
              onClickCounseling,
              onClickCopy,
              phoneClick,
              getCsvData,
              editcheck,
              isEdit,
            }}
          />
        </>
      )}
    </div>
  );
}
