import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import styled from "styled-components";

import "./simpleSelectInput.scss";

const StyledSelect = styled(Select)`
  color: ${({ theme }) => theme.palette.secondary.main};
  fill: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "2rem")};
  border-radius: 0.5rem;
  font-weight: 600;

  /* :hover {
    background-color: rgba(255, 255, 255, 0.15);
  } */
  .MuiSelect-select:focus {
    background-color: unset;
  }
  .MuiSelect-icon {
    color: ${({ theme }) => theme.palette.secondary.main};
    transform: scale(1.8) translate(-0.4rem, 1px);
  }
  .MuiInputBase-root.MuiInput-root {
    padding-right: 0;
  }

  .MuiSelect-root {
    padding-left: 1.2rem;
    padding-right: 2.6rem;
  }
`;

export default function SimpleSelectInput({
  className,
  name,
  value,
  onChange,
  children,
  placeholder,
  fontSize,
  style,
}) {
  return (
    <div className={`simpleSelectInput ${className}`} style={style}>
      <FormControl>
        <StyledSelect
          name={name}
          value={value}
          onChange={onChange}
          disableUnderline
          displayEmpty={Boolean(placeholder)}
          fontSize={fontSize ? fontSize : "1rem"}
        >
          {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
          {children}
        </StyledSelect>
      </FormControl>
    </div>
  );
}
