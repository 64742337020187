import React from "react";

const SendRequestPageBtn = ({
  sendData,
  sendPage,
  setSendPage,
  reSearch,
  nextClick,
  prevClick,
}) => {
  return (
    <>
      {sendPage > 1 ? <button onClick={prevClick}>이전 페이지</button> : null}

      <p>{sendPage} 페이지</p>

      {sendData.next_yn == "Y" ? (
        <button onClick={nextClick}>다음 페이지</button>
      ) : null}
    </>
  );
};

export default SendRequestPageBtn;
