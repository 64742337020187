import React from "react";
import produce from "immer";
import { Radio, FormControlLabel, Button } from "@material-ui/core";
import TextInput from "~ui/textInput/TextInput";
import DateInput from "~ui/dateInput/DateInput";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { IoIosSend } from "react-icons/io";
import { MdClose } from "react-icons/md";
import moment from "moment";

import "./recallManagement.scss";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  .MuiSelect-root {
    color: black;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    transform: scale(1.3) translate(-0.1rem, 0);
  }
`;

const HEADER_COLUMNS = ["담당실장", "리콜날짜", "당일 콜 횟수", "결과", "비고"];

function ManagementBar({ data, onAddClick }) {
  const [addDate, SetAddDate] = React.useState(
    moment().add(1, "month").format("YYYY-MM-DD"),
  );
  return (
    <div className="managementBar">
      <span>내원 상담 후 리콜 관리 [</span>
      <span>리콜 날짜 : {data.length ? data[0].recall_date : "-"}</span>
      <span>]</span>
      <DateInput
        className="addDateInput"
        value={addDate}
        name="visitDate"
        onChange={(e) => {}} //SetAddDate(e.target.value)
        style={{ width: "25rem", height: "auto" }}
      />
      <Button
        variant="contained"
        color="secondary"
        className={"addButton" + (data.length ? " removeMode" : "")}
        onClick={() => onAddClick(addDate)}
      >
        <IoIosSend size="1.6rem" />
        <span>{data.length ? "리콜 데이터 폐기" : "리콜 추가등록"}</span>
      </Button>
    </div>
  );
}

function RecallManagementHeader() {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  return (
    <div className="recallManagementHeader">
      <div className="rowWrapper">
        <span></span>
        <div className="rowItem">{items}</div>
      </div>
    </div>
  );
}

function RecallManagementItem({ data, onChange, headList, onClickApply }) {
  const counselorNameList = headList.data.map((head, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={head.name}>
      {head.name}
    </MenuItem>
  ));

  const items = data.map((rowData, i) => (
    <div className="rowWrapper" key={i}>
      <span>리콜 {i + 1}차</span>
      <div className="rowItem">
        <StyledSimpleSelectInput
          className="counselorSelectInput"
          name={`${i}-head_name`}
          value={rowData.head_name || ""}
          onChange={onChange}
          fontSize="0.75rem"
          placeholder="--"
        >
          {counselorNameList}
        </StyledSimpleSelectInput>

        <DateInput
          className="recallDateInput"
          value={rowData.recall_date}
          name={`${i}-recall_date`}
          onChange={() => {}}
          fontSize="0.75rem"
          style={{}}
        />

        <div className="dailyCallCount">
          {[1, 2, 3].map((v, j) => (
            <div key={j}>
              <FormControlLabel
                value={v}
                label={`Call ${v}회`}
                control={
                  <Radio
                    checked={rowData.callCount == v}
                    onChange={onChange}
                    color="secondary"
                    name={`${i}-callCount`}
                    size="small"
                  />
                }
              />
            </div>
          ))}
        </div>

        <div className="callResult">
          {[true, false].map((v, j) => (
            <div key={j}>
              <FormControlLabel
                value={v}
                label={v ? "성공" : "실패"}
                control={
                  <Radio
                    checked={rowData.result == v}
                    onChange={onChange}
                    color="secondary"
                    name={`${i}-result`}
                    size="small"
                  />
                }
              />
            </div>
          ))}
        </div>

        <div>
          <TextInput
            className="memoInput"
            name={`${i}-etc`}
            value={rowData.etc || ""}
            onChange={onChange}
          />
          <Button
            variant="contained"
            color="primary"
            className="memoAddButton"
            onClick={() => onClickApply(i)}
          >
            <span>등록</span>
          </Button>
          <div
            className="deleteIcon"
            // onClick={() => onDeleteClick(i)}
          >
            <MdClose size="1.5rem" />
          </div>
        </div>
      </div>
    </div>
  ));

  return <div className="recallManagementItem">{items}</div>;
}

export default function RecallManagement({
  uiData,
  setUiData,
  headList,
  openAlert,
  deleteRecall,
  addRecall,
  updateRecall,
}) {
  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      let value = event.target.value;
      const [rowIdx, dataName] = name.split("-");
      if (dataName === "result") {
        value = value === "true";
      } else if (dataName === "callCount") {
        value = parseInt(value);
      }
      setUiData(
        produce((draft) => {
          draft.recalls[rowIdx][dataName] = value;
        }),
      );
    },
    [setUiData],
  );

  const onAddClick = React.useCallback(
    async (addDate) => {
      // 폐기
      if (uiData.recalls.length) {
        for (const recall of uiData.recalls) {
          if (recall.id) {
            await deleteRecall(recall.id);
          }
        }
        setUiData({ ...uiData, recalls: [] });
        return;
      }

      if (uiData.visit.id) {
        const head_id = headList.data.find(
          (x) => x.name === uiData.visit.head_name,
        );
        const newData = {
          visit: uiData.visit.id,
          head: head_id || null,
          callCount: 1,
          result: 0,
          etc: "",
        };

        const res = await addRecall(newData);

        setUiData(
          produce((draft) => {
            draft.recalls.push({
              id: res.recall_id,
              head_name: uiData.visit.head_name || "",
              callCount: 1,
              result: false,
              etc: "",
              recall_date: addDate,
            });
          }),
        );
      } else {
        openAlert("먼저 DB를 등록해야합니다");
      }
    },
    [uiData, setUiData, headList, openAlert, deleteRecall, addRecall],
  );

  const onClickApply = React.useCallback(
    async (idx) => {
      const recall = uiData.recalls[idx];
      if (!recall.id) {
        return;
      }

      const head_id = headList.data.find((x) => x.name === recall.head_name);
      const newData = {
        visit: uiData.visit.id,
        head: head_id || null,
        callCount: recall.callCount,
        result: recall.result,
        etc: recall.etc,
      };

      await updateRecall([recall.id, newData]);
    },
    [uiData, headList, updateRecall],
  );

  const onDeleteClick = React.useCallback(
    (rowIdx) => {
      setUiData(
        produce((draft) => {
          draft.recalls.splice(rowIdx, 1);
        }),
      );
    },
    [setUiData],
  );

  return (
    <div className="recallManagement">
      <ManagementBar data={uiData.recalls} onAddClick={onAddClick} />
      {uiData.recalls.length ? <RecallManagementHeader /> : <></>}
      <RecallManagementItem
        data={uiData.recalls}
        onChange={onChange}
        headList={headList}
        onDeleteClick={onDeleteClick}
        onClickApply={onClickApply}
      />
    </div>
  );
}
