import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import MonthlySalesStatistics from "./pages/monthlySalesStatistics/MonthlySalesStatistics";
import ChannelEvaluation from "./pages/channelEvaluation/ChannelEvaluation";
import StatisticsManagementDay2Day from "../tm/pages/statisticsManagementDay2Day/StatisticsManagementDay2Day";
import StatisticsManagement from "../tm/pages/statisticsManagement/StatisticsManagement";
import StatisticsAgencyManagement from "../tm/pages/statisticsAgencyManagement/StatisticsAgencyManagement";

import Sms from "../tm/pages/sms/Sms";
import SmsSend from "../tm/pages/sms/send/send";
import SmsList from "../tm/pages/sms/list/sendList";
import SendRequest from "../tm/pages/sms/sendRequest/sendRequest";

export default function Doctor() {
  return (
    <Switch>
      <Redirect from="/doctor/" to="/doctor/monthlySalesStatistics" exact />
      <Route
        component={MonthlySalesStatistics}
        path="/doctor/monthlySalesStatistics"
      />
      <Route component={ChannelEvaluation} path="/doctor/channelEvaluation" />

      <Redirect
        from="/doctor/statisticsManagement/"
        to="/doctor/StatisticsManagement/period"
        exact
      />

      <Route
        component={StatisticsManagementDay2Day}
        exact
        path="/doctor/statisticsManagement/period"
      />
      <Route
        component={StatisticsManagement}
        exact
        path="/doctor/statisticsManagement/monthly"
      />

      <Route
        component={StatisticsAgencyManagement}
        exact
        path="/doctor/statisticsManagement/agency/monthly"
      />

      <Route component={SmsSend} exact path="/doctor/sms" />
      <Route component={SmsSend} exact path="/doctor/sms/send" />
      <Route component={SmsList} exact path="/doctor/sms/list" />
      <Route component={SendRequest} exact path="/doctor/sms/sendRequest" />
    </Switch>
  );
}
