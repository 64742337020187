import { atom } from "recoil";

export const loginUiState = atom({
  key: "loginUiState",
  default: {
    open: false,
    id: "",
    pw: "",
  },
});

export const loginData = atom({
  key: "loginDataState",
  default: {},
});
