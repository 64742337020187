import React, { useEffect } from "react";
import instanceAxios from "~apis/_util";

import { Button } from "@material-ui/core";
import { useFormik } from "formik";

import { FaSearch } from "react-icons/fa";

import moment from "moment";

import useAlert from "~hooks/useAlert";

// import { DateRangePicker, DateRange } from "materialui-daterange-picker";

import ReactTooltip from "react-tooltip";
import { ko } from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";

import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
// import CloseIcon from '@material-ui/icon/Close';
//
import { useUpdateCounseling } from "~apis/counselings";
import SendListView from "../../../components/sms/list/sendListView";
// import SendRequestPageBtn from "../../../components/sms/sendRequest/sendRequestPageBtn";
// 추가

import division from "~json/administrative_division.json";
import TextInput from "~/global/components/ui/textInput/TextInput";

import "./smsList.scss";

const SmsList = () => {
  const [smsListData, setSmsListData] = React.useState([]);

  const { openAlert } = useAlert();

  const phoneDatas = [
    ["010-8487-2819", "홍동환"],
    ["010-8300-7823", "신예지"],
  ];

  const searchQuery = useFormik({
    initialValues: {
      phoneNumber: "선택",
      phoneNumberInput: "",
    },
  });

  const searchAxios = React.useCallback(async (phoneNumber = false) => {
    const axiosData = await instanceAxios.get(
      `/sender-lists?sender=${phoneNumber || searchQuery.values.phoneNumber}`,
    );

    return axiosData;
  });

  const reSearch = React.useCallback(async (phoneNumber = false) => {
    const axiosData = await searchAxios(phoneNumber);
    setSmsListData(axiosData);
  });

  const searchSubmit = (e) => {
    let phoneNumber = searchQuery.values.phoneNumber;
    let v = false;

    if (phoneNumber === "입력") {
      phoneNumber = searchQuery.values.phoneNumberInput;
    }

    if (phoneNumber === "선택" || phoneNumber.trim() === "") {
      v = true;
    }

    if (v) {
      openAlert("선택하거나, 입력하지 않으셨습니다.");
      return true;
    }

    reSearch(phoneNumber);
  };

  return (
    <div className="sendRequest">
      <span className="viewTitle">발신번호 / 발송자 보기</span>

      <div className="dbStateManagement">
        <div>
          <div>
            <span>발신자 번호 검색</span>
          </div>

          <div className="DateGroup">
            <div className="DateInputs sendListInputSelect">
              <div className="sel">
                <Select
                  name="phoneNumber"
                  value={searchQuery.values.phoneNumber}
                  onChange={searchQuery.handleChange}
                >
                  <MenuItem value="선택">선택</MenuItem>
                  {phoneDatas.map((v) => (
                    <MenuItem value={v[0]}>
                      {v[0]} / {v[1]}
                    </MenuItem>
                  ))}
                  <MenuItem value="입력">직접 입력</MenuItem>
                </Select>
              </div>

              <div className="phoneInput">
                {searchQuery.values.phoneNumber === "입력" ? (
                  <TextInput
                    name="phoneNumberInput"
                    placeholder="전화번호 입력."
                    value={searchQuery.values.phoneNumberInput}
                    onChange={searchQuery.handleChange}
                  />
                ) : null}
              </div>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className="searchButton"
              onClick={searchSubmit}
            >
              <span>Search</span>
              <FaSearch size="1.7rem" />
            </Button>
          </div>
        </div>
      </div>

      <SendListView smsListData={smsListData} />

      <div className="requestControllBtn"></div>
      <div className="Controls"></div>
    </div>
  );
};

export default SmsList;
