import React from "react";
import produce from "immer";
import { useRecoilState } from "recoil";
import { loginUiState } from "~global/stores/login";

let promiseResolver = null;

export default function useLogin() {
  const [state, setState] = useRecoilState(loginUiState);

  const openLogin = () =>
    new Promise((res) => {
      promiseResolver = res;

      setState({
        open: true,
      });
    });

  const closeLogin = () => {
    setState(
      produce((v) => {
        v.open = false;
      }),
    );
  };

  React.useEffect(() => {
    if (!state.open && promiseResolver) {
      promiseResolver(null);
      promiseResolver = null;
    }
  }, [state.open]);

  return { openLogin, closeLogin };
}
