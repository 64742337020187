import React from "react";
import { useLocation } from "react-router-dom";
import StatisticsChart_1 from "~/counselor/components/dbStatistics/statisticsChart_1/StatisticsChart_1";
import StatisticsChart_2 from "~/counselor/components/dbStatistics/statisticsChart_2/StatisticsChart_2";
import SearchBar from "~/counselor/components/dbStatistics/searchBar/SearchBar";
import DbStatisticsList from "~/counselor/components/dbStatistics/dbStatisticsList/DbStatisticsList";
import qs from "qs";
import moment from "moment";

import { useGetDoctorList } from "~apis/doctors";
import {
  useGetHeadList,
  useGetDailyStatistics,
  useGetWeeklyStatistics,
  useGetMonthlyStatistics,
  useGetYearlyStatistics,
} from "~apis/head";

import "./dbStatistics.scss";

const repeatVisitValue = {
  "": "",
  신환: false,
  구환: true,
  "신환+구환": "",
};

const labels = {
  daily: "일",
  weekly: "주",
  monthly: "월",
  yearly: "연",
};

export default function DbStatistics() {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = React.useState({
    startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    repeatVisit: "",
  });
  const [queryString, setQueryString] = React.useState("");

  const queryStringSelect = [null, null, null, null];
  let targetDataset = "daily";
  if (location.pathname === "/counselor/weeklyStatistics") {
    targetDataset = "weekly";
    queryStringSelect[1] = queryString;
  } else if (location.pathname === "/counselor/monthlyStatistics") {
    targetDataset = "monthly";
    queryStringSelect[2] = queryString;
  } else if (location.pathname === "/counselor/yearlyStatistics") {
    targetDataset = "yearly";
    queryStringSelect[3] = queryString;
  } else {
    queryStringSelect[0] = queryString;
  }

  const doctorList = useGetDoctorList();
  const headList = useGetHeadList();
  const dailySalesStatistics = useGetDailyStatistics(queryStringSelect[0]);
  const dailyWeeklyStatistics = useGetWeeklyStatistics(queryStringSelect[1]);
  const dailyMonthlyStatistics = useGetMonthlyStatistics(queryStringSelect[2]);
  const dailyyearlyStatistics = useGetYearlyStatistics(queryStringSelect[3]);

  const isFetching =
    doctorList.isFetching ||
    headList.isFetching ||
    dailySalesStatistics.isFetching ||
    dailyWeeklyStatistics.isFetching ||
    dailyMonthlyStatistics.isFetching ||
    dailyyearlyStatistics.isFetching;

  const isError =
    doctorList.isError ||
    headList.isError ||
    dailySalesStatistics.isError ||
    dailyWeeklyStatistics.isError ||
    dailyMonthlyStatistics.isError ||
    dailyyearlyStatistics.isError;

  const salesStatistics =
    targetDataset === "weekly"
      ? dailyWeeklyStatistics
      : targetDataset === "monthly"
      ? dailyMonthlyStatistics
      : targetDataset === "yearly"
      ? dailyyearlyStatistics
      : dailySalesStatistics;

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_start_date: searchQuery.startDate,
        q_end_date: searchQuery.endDate,
        isRepeatVisit: repeatVisitValue[searchQuery.repeatVisit],
      }),
    );
  }, []);

  const onClickSearch = React.useCallback(() => {
    setQueryString(
      qs.stringify({
        q_start_date: searchQuery.startDate,
        q_end_date: searchQuery.endDate,
        isRepeatVisit: repeatVisitValue[searchQuery.repeatVisit],
      }),
    );
    salesStatistics.refetch();
  }, [searchQuery, setQueryString, salesStatistics]);

  return (
    <div className="dbStatistics">
      <span className="viewTitle">{labels[targetDataset]}별 DB 통계</span>
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <StatisticsChart_1 salesData={salesStatistics.data} />
          <StatisticsChart_2
            salesData={salesStatistics.data}
            targetDataset={targetDataset}
          />
          <SearchBar
            {...{ searchQuery, setSearchQuery, onClickSearch, targetDataset }}
          />

          <DbStatisticsList
            {...{
              doctorList,
              headList,
              salesStatistics,
              targetDataset,
            }}
          />
        </>
      )}
    </div>
  );
}
