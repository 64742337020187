import React from "react";
import classNames from "classnames";
import { cumulativeAverage } from "~utils/calculate";
import { useSetRecoilState } from "recoil";
import { csvDataState } from "~/global/stores/recoil";
import produce from "immer";

import "./channelEvaluationList.scss";

const HEADER_COLUMNS = [
  "년/월",
  [["채널"], ["광고", "업체", "채널"]],
  "Doctor",
  [["DB매출"], ["총매출", "직접매출", "간접매출"]],
  "DB비용",
  "DB갯수",
  "예약",
  "내원",
  "시술",
  "소개",
  "1인당 매출",
  "ROI",
  "DB당 단가",
  [["총매출"], ["총매출", "직접매출", "간접매출"]],
  "예약성공률",
  [
    ["내원성공률", "시술성공률"],
    ["DB대비", "예약대비", "DB당", "내원당"],
  ],
  "DB당 소개비율",
];

function ChannelEvaluationListHeader() {
  const items = HEADER_COLUMNS.map((column, i) => {
    if (column instanceof Array) {
      return (
        <div
          key={i}
          className="splitColumn"
          style={{ gridColumn: `span ${column[1].length}` }}
        >
          {column.map((columnRow, j) => {
            return (
              <div key={j} className="columnRow">
                {columnRow.map((columnRowItem, k) => (
                  <div key={k}>
                    <span>{columnRowItem}</span>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div key={i}>
          <span>{column}</span>
        </div>
      );
    }
  });

  return <div className="channelEvaluationListHeader">{items}</div>;
}

function ChannelEvaluationListItem({ data }) {
  const setCsvData = useSetRecoilState(csvDataState);

  const columnDataList = [
    `${data.year}. ${data.month}`,
    data.ad,
    data.agency,
    data.channelName,
  ].concat(
    data.broadcasting_progress
      ? [
          data.doctor_name || "-",
          (data.db_sales || 0).toLocaleString("ko-KR"),
          (data.db_direct_sales || 0).toLocaleString("ko-KR"),
          (parseInt(data.db_indirect_sales) || 0).toLocaleString("ko-KR"),
          data.total_cost || 0,
          data.total_count,
          data.reservation_count,
          data.visit_count,
          data.progress_count,
          data.referral_count,
          (data.sales_per_db || 0).toLocaleString("ko-KR"),
          data.roi ? data.roi.toFixed(1) : "-",
          (data.cost_per_db || 0).toLocaleString("ko-KR"),
          (data.total_sales || 0).toLocaleString("ko-KR"),
          (data.total_direct_sales || 0).toLocaleString("ko-KR"),
          (parseInt(data.total_indirect_sales) || 0).toLocaleString("ko-KR"),
          (data.reservation_success_rate || 0).toFixed(1),
          (data.db_visit_success_rate || 0).toFixed(1),
          (data.reservation_visit_success_rate || 0).toFixed(1),
          (data.db_progress_success_rate || 0).toFixed(1),
          (data.visit_progress_success_rate || 0).toFixed(1),
          (data.referral_per_db_rate || 0).toFixed(1),
        ]
      : Array(22).fill("-"),
  );

  setCsvData(
    produce((v) => {
      v.push(columnDataList);
    }),
  );

  const items = columnDataList.map((columnData, i) => (
    <div key={i}>{columnData}</div>
  ));

  const className = classNames("channelEvaluationListItem", {
    disabledItem: data.broadcasting_progress === 0,
  });

  return <div className={className}>{items}</div>;
}

function ChannelEvaluationListTotal({ evaluationData }) {
  const itemDataList = Array(22).fill("");
  itemDataList[0] = `${evaluationData.year}년 / ${evaluationData.month}월 / 전체합계 :`;
  itemDataList.fill(0, 1, 22);

  evaluationData.list.forEach((x, i) => {
    if (x.broadcasting_progress) {
      itemDataList[1] += parseInt(x.db_sales) || 0;
      itemDataList[2] += parseInt(x.db_direct_sales) || 0;
      itemDataList[3] += parseInt(x.db_indirect_sales) || 0;
      itemDataList[4] += parseInt(x.total_cost) || 0;
      itemDataList[5] += parseInt(x.total_count) || 0;
      itemDataList[6] += parseInt(x.reservation_count) || 0;
      itemDataList[7] += parseInt(x.visit_count) || 0;
      itemDataList[8] += parseInt(x.progress_count) || 0;
      itemDataList[9] += parseInt(x.referral_count) || 0;
      itemDataList[10] += parseInt(x.sales_per_db) || 0;
      itemDataList[11] += parseFloat(x.roi) || 0;
      itemDataList[12] += parseInt(x.cost_per_db) || 0;
      itemDataList[13] += parseInt(x.total_sales) || 0;
      itemDataList[14] += parseInt(x.total_direct_sales) || 0;
      itemDataList[15] += parseInt(x.total_indirect_sales) || 0;
      itemDataList[16] += parseFloat(x.reservation_success_rate) || 0;
      itemDataList[17] += parseFloat(x.db_visit_success_rate) || 0;
      itemDataList[18] += parseFloat(x.reservation_visit_success_rate) || 0;
      itemDataList[19] += parseFloat(x.db_progress_success_rate) || 0;
      itemDataList[20] += parseFloat(x.visit_progress_success_rate) || 0;
      itemDataList[21] += parseFloat(x.referral_per_db_rate) || 0;
    }
  });

  const items = itemDataList.map((itemData, i) => (
    <div key={i}>{0 < i ? itemData.toLocaleString("ko-KR") : itemData}</div>
  ));

  return <div className="channelEvaluationListTotal">{items}</div>;
}

function getColumns(arr = HEADER_COLUMNS) {
  return arr.reduce(
    (pre, cur) => pre.concat(cur instanceof Array ? getColumns(cur[1]) : cur),
    [],
  );
}

export default React.memo(
  function ChannelEvaluationList({ evaluationData, csvDataLength }) {
    const setCsvData = useSetRecoilState(csvDataState);

    setCsvData([getColumns()]);

    const items = evaluationData.list.map((data, i) => (
      <ChannelEvaluationListItem key={i} data={data} />
    ));

    return (
      <div className="channelEvaluationList">
        <ChannelEvaluationListHeader />
        {items}
        <ChannelEvaluationListTotal {...{ evaluationData }} />
      </div>
    );
  },
  (prev, now) => prev.csvDataLength === now.csvDataLength,
);
