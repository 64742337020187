import { Link } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import styled from 'styled-components'

const TableActionStyle = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;

  svg {
    color: #8aa1d7;
    cursor: pointer;
  }

  .bar {
    background: #8aa1d7;
    width: 1px;
    height: 1rem;
    margin: 0 .3rem 0 .5rem;
  }

  .close, .bar {
    position: relative;
    top: -1.5px;
  }
`


export default function TableAction({ url, deleteItem }) {
  return (
    <TableActionStyle>
      <Link to={url} className="addButtonLink">
        <IoSettingsSharp size="1.25rem" />
      </Link>
      <div className="bar"></div>
      <MdClose
        size="1.55rem"
        onClick={deleteItem}
        className="close"
      />
    </TableActionStyle>
  )
}