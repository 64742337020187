import React, { useEffect } from "react";
import instanceAxios from "~apis/_util";
import produce from "immer";
import MenuItem from "@material-ui/core/MenuItem";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import TextInput from "~ui/textInput/TextInput";
import Button from "@material-ui/core/Button";
import { FaSearch } from "react-icons/fa";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import koLocale from "date-fns/locale/ko";

import ReactTooltip from "react-tooltip";

import { ko } from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import moment from "moment";

import { DateRangePicker } from "materialui-daterange-picker";

import "./searchBox.scss";

export default function IntroduceStatistics({ introFormik, introInfo }) {
  // instanceAxios("/visit/")
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});

  const dateRangeChange = () => setDateRangeOpen(!dateRangeOpen);
  //

  const rangeChange = (range) => {
    window.localStorage.setItem(
      "introDate",
      JSON.stringify({
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      }),
    );
  };

  const onClickSetdate = async (type = "day") => {
    const date = new Date();
    const searchbutton = document.querySelector(".searchButton");

    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const weekStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay(),
    );
    const weekEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (6 - date.getDay()),
    );

    if (type == "month") {
      introFormik.setValues({
        startDate: moment(monthStart).format("YYYY-MM-DD"),
        endDate: moment(monthEnd).format("YYYY-MM-DD"),
      });

      setState([
        {
          key: "selection",
          startDate: monthStart,
          endDate: monthEnd,
        },
      ]);
    } else if (type == "week") {
      introFormik.setValues({
        startDate: moment(weekStart).format("YYYY-MM-DD"),
        endDate: moment(weekEnd).format("YYYY-MM-DD"),
      });

      setState([
        {
          key: "selection",
          startDate: weekStart,
          endDate: weekEnd,
        },
      ]);
    } else {
      introFormik.setValues({
        startDate: moment(date).format("YYYY-MM-DD"),
        endDate: moment(date).format("YYYY-MM-DD"),
      });

      setState([
        {
          key: "selection",
          startDate: date,
          endDate: date,
        },
      ]);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("introDate")) {
      const parseData = JSON.parse(window.localStorage.getItem("introDate"));

      introFormik.setValues({
        startDate: parseData.startDate,
        endDate: parseData.endDate,
      });

      setState([
        {
          startDate: new Date(parseData.startDate),
          key: "selection",
          endDate: new Date(parseData.endDate),
        },
      ]);
    }

    // window.setTimeout(() => {
    //   document.querySelector(`#counselingSearchButton`).click();
    // }, 100);
  }, []);

  const DataRangeChangeEvent = (item) => {
    const range = item.selection;

    introFormik.setFieldValue(
      "startDate",
      moment(range.startDate).format("YYYY-MM-DD"),
    );
    introFormik.setFieldValue(
      "endDate",
      moment(range.endDate).format("YYYY-MM-DD"),
    );

    window.localStorage.setItem(
      "introDate",
      JSON.stringify({
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      }),
    );

    setState([item.selection]);
  };

  return (
    <div className="searchBar">
      <div>
        <span>차트번호</span>
        <TextInput
          className="chartNumberInput"
          inputClassName="numberInput"
          name="chNumber"
          value={introFormik.values.chartNumber}
          // onInput={}
          onChange={introFormik.handleChange}
          placeholder="차트번호"
          style={{ width: "9rem", height: "2.68rem" }}
        />
      </div>
      <div>
        <span>기간검색</span>

        <div className="DateInputs">
          <div className="inputs">
            <div data-tip data-for="daterange" data-event="click">
              <TextInput value={introFormik.values.startDate} />
              <p>~</p>
              <TextInput value={introFormik.values.endDate} />
            </div>
            <ReactTooltip
              id="daterange"
              place="bottom"
              effect="solid"
              type="light"
              globalEventOff="click"
              clickable
            >
              <DateRange
                ranges={state}
                onChange={DataRangeChangeEvent}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                locale={ko}
              />
            </ReactTooltip>
          </div>
          <div className="dateRangePicker-box"></div>
          <div className="ButtonDateFreeSet">
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickSetdate}
            >
              당일
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClickSetdate("week")}
            >
              주별
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onClickSetdate("month")}
            >
              월별
            </Button>
          </div>
        </div>
      </div>

      <div>
        <div className="introInfo">
          <p>
            소개환자수 :{" "}
            {introInfo
              .map((x) => x.referCount * 1)
              .reduce((acc, val) => acc + val, 0)}
            명
          </p>
          <p>
            총 상담금액 :{" "}
            {introInfo
              .map((x) => x.referTotal)
              .reduce((acc, val) => acc + val, 0)
              .toLocaleString()}
            원
          </p>
        </div>
      </div>

      <Button
        id="counselingSearchButton"
        variant="contained"
        color="secondary"
        className="searchButton"
        onClick={introFormik.handleSubmit}
      >
        <span>Search</span>
        <FaSearch size="2.2rem" />
      </Button>
    </div>
  );
}
