import { IoStatsChartSharp, IoMail } from "react-icons/io5";
import { FaClipboardList, FaUser } from "react-icons/fa";

var SITE_MAP = {
  channel: [
    "채널관리",
    [
      [<IoStatsChartSharp />, "채널 관리", "channelManagement"],
      [
        <FaClipboardList />,
        [
          "채널 리스트",
          ["- TV 광고", "TV 광고"],
          ["- 업체광고", "업체광고"],
          ["- 오프라인광고", "오프라인광고"],
          ["- HYDJ", "HYDJ"],
        ],
        "addChannel",
      ],
    ],
  ],
  doctor: [
    "원장 관리",
    [
      [<IoStatsChartSharp />, "월별 매출 통계", "monthlySalesStatistics"],
      // [<FaClipboardList />, "채널 평가", "channelEvaluation"],
      [
        <FaClipboardList />,
        [
          "통계관리",
          ["- 기간별 DB 통계", "period", true],
          ["- 월별 채널 DB 통계", "monthly", true],
          ["- 월별 업체 DB 통계", "agency/monthly", true],
        ],
        "statisticsManagement",
      ],
      [
        <IoMail />,
        [
          "문자 관리",
          ["- 문자 보내기", "send", true],
          ["- 발신번호/발송자", "list", true],
          ["- 전송결과보기", "sendRequest", true],
        ],
        "sms",
      ],
    ],
  ],
  counselor: [
    "실장팀 관리",
    [
      [
        <FaClipboardList />,
        [
          "상담환자 DB",
          ["- 금일 재상담 환자DB", "today_re"],
          ["- 내원 예약 환자DB", "customer_reservation"],
        ],
        "counseling",
      ],
      [<IoStatsChartSharp />, "일별 DB 통계", "dailyStatistics"],
      [<IoStatsChartSharp />, "주별 DB 통계", "weeklyStatistics"],
      [<IoStatsChartSharp />, "월별 DB 통계", "monthlyStatistics"],
      [<IoStatsChartSharp />, "연별 DB 통계", "yearlyStatistics"],
      [<IoStatsChartSharp />, "소개환자 통계", "introduceStatistics"],
      [
        <FaClipboardList />,
        [
          "통계관리",
          ["- 기간별 DB 통계", "period", true],
          ["- 월별 채널 DB 통계", "monthly", true],
          ["- 월별 업체 DB 통계", "agency/monthly", true],
        ],
        "statisticsManagement",
      ],
      [
        <IoMail />,
        [
          "문자 관리",
          ["- 문자 보내기", "send", true],
          ["- 발신번호/발송자", "list", true],
          ["- 전송결과보기", "sendRequest", true],
        ],
        "sms",
      ],
    ],
  ],
  tm: [
    "TM팀 관리",
    [
      [
        <IoStatsChartSharp />,
        [
          "총 DB관리",
          ["- 금일 상담 DB", "today"],
          ["- 오늘 재상담 DB", "today_re"],
          ["- 80% 가망 DB", "80"],
          ["- 60% 가망 DB", "60"],
          ["- 30% 가망 DB", "30"],
          ["- 0% 가망 DB", "0"],
          ["- 관리환자 DB", "manage"],
          ["- 예약환자관리", "reservation"],
        ],
        "totalManagement",
      ],
      [<FaUser />, ["미내원 고객관리"], "nonVisitedCustomerManagement"],
      [
        <FaClipboardList />,
        [
          "통계관리",
          ["- 기간별 DB 통계", "period", true],
          ["- 월별 채널 DB 통계", "monthly", true],
          ["- 월별 업체 DB 통계", "agency/monthly", true],
        ],
        "statisticsManagement",
      ],
      [
        <IoMail />,
        [
          "문자 관리",
          ["- 문자 보내기", "send", true],
          ["- 발신번호/발송자", "list", true],
          ["- 전송결과보기", "sendRequest", true],
        ],
        "sms",
      ],
    ],
  ],
  admin: [
    "의료진/스탭/설정",
    [
      [<FaUser />, "원장 추가", "addDoctor"],
      [<FaUser />, "TM 추가", "addCS"],
      [<FaUser />, "실장 추가", "addCounselor"],
      [<FaUser />, "사용자 추가", "addUser"],
    ],
  ],
  // externalCompany: [
  //   "외부업체",
  //   [[<IoStatsChartSharp />, "외부업체", "management"]],
  // ],
};

export default SITE_MAP;
