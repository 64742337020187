import useAlert from "~hooks/useAlert";
import { useSignIn, useRegister } from "~apis/users";

// strapi 메시지 ID를 한국어 메시지로 선언
const messages = {
  "Auth.form.error.email.provide": "아이디(이메일)를 입력해주세요",
  "Auth.form.error.password.provide": "비밀번호를 입력해주세요",
  "Auth.form.error.invalid": "아이디 또는 비밀번호가 올바르지 않습니다",
  "Auth.form.error.confirmed": "이메일 인증이 완료되지 않았습니다",
  "Auth.form.error.blocked": "차단된 계정입니다",
  "Auth.form.error.password.local":
    "간편 로그인으로 생성된 계정입니다\n계정 생성시 사용한 간편 로그인을 사용해주세요",
  "provider.disabled":
    "해당 간편 로그인은 비활성화된 상태입니다\n관리자에게 문의해주세요",
  "Auth.form.error.code.provide": "코드가 올바르지 않습니다",
  "Auth.form.error.password.matching": "비밀번호가 일치하지 않습니다",
  "Auth.form.error.params.provide": "올바르지 않은 데이터를 전달받았습니다",
  "Auth.form.error.email.format": "이메일 형식이 올바르지 않습니다",
  "Auth.advanced.allow_register":
    "회원가입은 현재 비활성화된 상태입니다\n관리자에게 문의해주세요",
  "Auth.form.error.password.format":
    "비밀번호는 '$'기호를 3개 이상 포함 할 수 없습니다",
  "Auth.form.error.role.notFound":
    "기본 역할을 찾을 수 없습니다\n관리자에게 문의해주세요",
  "Auth.form.error.email.taken": "이미 사용중인 이메일입니다",
  "Auth.form.error.username.taken": "이미 사용중인 이름입니다",
  "Auth.form.error.user.not-exist": "해당 이메일은 존재하지 않습니다",
};

export default function useAuth() {
  const { openAlert } = useAlert();
  const { mutateAsync: _signIn } = useSignIn();
  const { mutateAsync: _register } = useRegister();

  /*
   * 로그인 처리 함수
   * - email: 로그인 할 계정 이메일
   * - password: 로그인 할 계정 비밀번호
   *
   * 반환은 항상 객체로 반환 됩니다
   * 오류 확인은 객체안에 error를 확인하시면 됩니다
   *
   * 오류 메시지는 기본적으로 표시되니 변경을 원하시면 openAlert 함수 사용 부분을 수정하시면 됩니다
   */
  const signIn = async (identifier, password) => {
    const res = await _signIn({ identifier, password });

    if (!res.error) {
      window.localStorage.setItem("user.token", res.jwt);
      window.localStorage.setItem("user.id", res.user.id);
      window.localStorage.setItem("user.role", res.user.role.type);

      return {
        error: false,
        data: res,
      };
    } else {
      openAlert(messages[res.messageID]);

      return {
        error: true,
        messageID: res.messageID,
        message: messages[res.messageID],
      };
    }
  };

  /*
   * 회원가입 처리 함수
   * - data: 회원 정보 객체 (파일이 있을 경우에도 객체로 보내주세요)
   * - continueSignIn: 회원가입 끝나고 바로 로그인 처리
   *
   * 반환은 항상 객체로 반환 됩니다
   * 오류 확인은 객체안에 error를 확인하시면 됩니다
   *
   * 오류 메시지는 기본적으로 표시되니 변경을 원하시면 openAlert 함수 사용 부분을 수정하시면 됩니다
   */
  const signUp = async (data, continueSignIn = false) => {
    const hasFile = Object.values(data).some(
      (fieldValue) => fieldValue instanceof Blob,
    );
    let body;

    if (hasFile) {
      const dataJSON = {};
      body = new FormData();

      for (const [key, value] of Object.entries(data)) {
        if (value instanceof Blob) {
          body.append(`files.${key}`, value);
        } else {
          dataJSON[key] = value;
        }
      }

      body.append("data", JSON.stringify(dataJSON));
    } else {
      body = data;
    }

    const res = await _register(body);

    if (!res.error) {
      if (continueSignIn) {
        await signIn(data.email, data.password);
      }

      return {
        error: false,
        data: res.data,
      };
    } else {
      openAlert(messages[res.messageID]);

      return {
        error: true,
        messageID: res.messageID,
        message: messages[res.messageID],
      };
    }
  };

  /*
   * 로그아웃 처리 함수
   */
  const signOut = async () => {
    window.localStorage.removeItem("user.token");
    window.localStorage.removeItem("user.id");
    window.localStorage.removeItem("user.role");
    window.localStorage.removeItem("user.access");

    await openAlert("정상적으로 로그아웃 되었습니다");

    window.location.href = "/";
  };

  const isSignIn = () => {
    return Boolean(
      window.localStorage["user.token"] || window.sessionStorage["user.token"],
    );
  };

  const isAdmin = () => {
    return (
      window.localStorage["user.role"] === "admin" ||
      window.sessionStorage["user.role"] === "admin"
    );
  };

  const getSignInUser = () => {
    if (!isSignIn()) {
      return null;
    }

    return window.localStorage.getItem("user.id");
  };

  return { signIn, signUp, signOut, isSignIn, getSignInUser, isAdmin };
}
