import React from "react";
import styled from "styled-components";

import "./datePicker.scss";

const StyledDateInput = styled.input`
  color: white;
  fill: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.palette.primary.main};
  border: none;
  outline: none;
  padding: 0.78rem;
  font: inherit;
  width: 100%;

  :hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  ::-webkit-calendar-picker-indicator {
    background: white;
    border-radius: 0.5rem;
    opacity: 0;
  }
  ::-webkit-calendar-picker-indicator:hover {
    opacity: 0.1;
  }
`;

export default function DatePicker({
  className,
  name,
  value,
  onChange,
  fontSize,
  style,
}) {
  return (
    <div
      className={`datePicker ${className && className}`}
      style={style && style}
    >
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
      <StyledDateInput
        type="month"
        name={name}
        value={value}
        onChange={onChange}
        fontSize={fontSize ? fontSize : "1rem"}
      ></StyledDateInput>
    </div>
  );
}
