import React, { useEffect, useMemo, useState } from "react";
import produce from "immer";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { ko } from "react-date-range/dist/locale";
import ReactTooltip from "react-tooltip";

import koLocale from "date-fns/locale/ko";

import Table from "~/global/components/tamplete/table/table";
import DateInput from "~ui/dateInput/DateInput";
import PageTitle from "~/global/components/tamplete/title/title";
import Pazing from "~/global/components/tamplete/pazing/Pazing";
import ControlsButton from "~/global/components/tamplete/controlsbutton/ControlsButton";
import { FaSearch } from "react-icons/fa";
import {
  useGetVisitsListTm,
  useGetVisitsListTmCount,
  useUpdateVisitsItem,
  useDeleteVisits,
} from "~apis/visits";

import { useGetCSList } from "~apis/counselors";

import { OutTable, ExcelRenderer } from "react-excel-renderer";

import qs from "qs";
import moment from "moment";
import { DateRange } from "react-date-range";
import { useFormik } from "formik";

import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  Button,
  Radio,
  FormControlLabel,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
// import CloseIcon from '@material-ui/icon/Close';
import { useQuery } from "react-query";
//
// 추가
// import axios from "axios";
import instanceAxios from "~apis/_util";
import { useUpdateCounseling } from "~apis/counselings";
// 추가

import "./TotalManagement.scss";
import division from "~json/administrative_division.json";
import TextInput from "~/global/components/ui/textInput/TextInput";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
// 모달

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

// 모달

const a = [{ 상담항목: "stateus" }];

const searchQueryToExcelFilter = (search, filter) => {
  return {};
};

export default function TotalManagement() {
  const access = window.localStorage.getItem("user.access");

  const location = useLocation();
  const history = useHistory();
  const nonVisited = location.pathname === "/tm/nonVisitedCustomerManagement";
  const { sub } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [quickEditID, setQuickEditID] = useState(null);

  const [filter, setFilter] = useState();
  const [sort, setSort] = useState({ field: "최초 DB 수집일자", dir: "desc" });

  let nowURL = new URL(window.location.href);

  const testURL = React.useMemo(
    () => nowURL.searchParams.get("page"),
    [nowURL, nowURL.searchParams.get("page")],
  );

  const [searchQuery, setSearchQuery] = React.useState({
    status: "",
    startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    page: testURL * 1 || 1,
    sub,
    nonVisited: nonVisited,
  });
  
  const [queryString, setQueryString] = React.useState("");
  const [checkedItems, setCheckedItems] = React.useState([]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // const [inputValue, inputChangeList] = React.useState({});

  let value = [];
  // DelList=value

  const csList = useGetCSList();

  const visitsListTm = useGetVisitsListTm(
    qs.stringify({
      ...qs.parse(queryString),
      filter: { ...filter },
      sort,
      sub,
      pp: testURL,
    }),
  );
  const visitsListTmCount = useGetVisitsListTmCount(
    qs.stringify({
      ...qs.parse(queryString),
      filter: { ...filter },
      sort,
      sub,
      pp: testURL,
    }),
  );

  const { mutateAsync: updateCounseling } = useUpdateCounseling();

  const { mutateAsync: updateVisitsItem } = useUpdateVisitsItem();
  const { mutateAsync: deleteVisits } = useDeleteVisits();

  const isFetching =
    csList.isFetching ||
    visitsListTm.isFetching ||
    visitsListTmCount.isFetching;
  const isError =
    csList.isError || visitsListTm.isError || visitsListTmCount.isError;
  const pages = Math.ceil(visitsListTmCount.data / 20);
  const pageTitle = (() => {
    switch (sub) {
      case "today":
        return "금일 상담 DB";
      case "today_re":
        return "오늘 재상담 DB";
      case "80":
        return "80% 가망 DB";
      case "60":
        return "60% 가망 DB";
      case "30":
        return "30% 가망 DB";
      case "0":
        return "0% 가망 DB";
      case "manage":
        return "관리환자 DB";
      case "reservation":
        return "예약환자관리";
      default:
        return "총 DB관리";
    }
  })();

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_status: searchQuery.status,
        q_start_date: searchQuery.startDate,
        q_end_date: searchQuery.endDate,
        q_page: searchQuery.page,
        q_sub: searchQuery.sub,
        nonVisited: nonVisited,
      }),
    );
  }, [nonVisited, sub]);

  const onSubChange = React.useCallback(
    (subData) => {
      console.log(subData, sub);
      setQueryString(
        qs.stringify({
          q_status: searchQuery.status,
          q_start_date: searchQuery.startDate,
          q_end_date: searchQuery.endDate,
          q_page: searchQuery.page,
          q_sub: sub,
          nonVisited: searchQuery.nonVisited,
        }),
      );
      visitsListTm.refetch();
    },
    [searchQuery, setQueryString, visitsListTm],
  );

  React.useEffect(() => {
    onSubChange(sub);
  }, [sub]);

  const onChange = React.useCallback(
    (event, getName) => {
      const name = event?.target ? event?.target?.name : getName;
      const value = event?.target ? event?.target?.value : event;

      setSearchQuery(
        produce((draft) => {
          draft[name] = value;
        }),
      );
    },
    [setSearchQuery],
  );

  const onClickSearch = React.useCallback(() => {
    setQueryString(
      qs.stringify({
        q_status: searchQuery.status,
        q_start_date: searchQuery.startDate,
        q_end_date: searchQuery.endDate,
        q_page: searchQuery.page,
        q_sub: searchQuery.sub,
        nonVisited: false,
      }),
    );
    visitsListTm.refetch();
  }, [searchQuery, setQueryString, visitsListTm]);

  const onPageChange = React.useCallback(
    (page) => {
      setQueryString(
        qs.stringify({
          q_status: searchQuery.status,
          q_start_date: searchQuery.startDate,
          q_end_date: searchQuery.endDate,
          q_page: page,
          q_sub: searchQuery.sub,
          nonVisited: false,
        }),
      );
      visitsListTm.refetch();
    },
    [searchQuery, setQueryString, visitsListTm],
  );

  const deleteCheckedItems = React.useCallback(async () => {
    console.log(checkedItems);
    if (!checkedItems.length) {
      return;
    }
    for (const visit_id of checkedItems) {
      await deleteVisits(visit_id);
      setCheckedItems([]);
    }

    visitsListTm.refetch();
  }, [checkedItems, visitsListTm, deleteVisits]);

  const changeNameCheckedItems = React.useCallback(async () => {
    if (!checkedItems.length) {
      return;
    }

    for (const visit_id of checkedItems) {
      const cs = csList.data.find((x) => x.name == tmName);

      await updateVisitsItem([visit_id, { counselor: cs?.id || null }]);
    }

    setCheckedItems((_) => []);
    setTmName((_) => null);

    handleClose();
    visitsListTm.refetch();
  }, [checkedItems, visitsListTm, updateVisitsItem]);

  const PROGRESS_TYPES = [
    "추후상담",
    "오후상담 예정",
    "저녁상담 예정",
    "전원오프",
    "결번",
    "중복",
    "전화주기로",
    "타치과치료",
    "관리환자",
    "전화상담거절",
    "상담 후 보류",
    "예약 취소",
    "관심없음",
    "통화중",
    "J.S",
    "비용부담",
    "착신정지",
    "허수",
    "문의한적없음",
    "문자관리",
    "부재1",
    "부재2",
    "부재3",
    "부재4",
    "부재5",
    "부재6",
    "예약완료",
    "검색안내",
    "계획없음",
  ];

  const TableTitle = [
    "$filterButton",
    "수정",
    "#",
    "최초 DB 수집일자",
    "상담항목",
    "재상담",
    "가망여부",
    "상태",
    "예약날짜",
    "TM",
    "원장",
    "세부경로",
    "이름",
    "전화번호",
    "나이",
    "지역",
    "상담총액",
    "차트번호",
    "진행여부",
    "최근 상담일",
    "내원",
    "문자 거부",
  ];

  const typeList = [
    "임플란트",
    "교정",
    "인비절라인",
    "보철치료",
    "라미네이트",
    "일반진료",
  ];

  // 모달
  const [tmName, setTmName] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  // 테이블 수정을 위한 스테이트
  const [addressSel, setAddressSel] = React.useState({});
  const [defaultValue, setDefaultValue] = React.useState({});

  const inputChangeEvent = (e) => {
    let [name, value] = [e.target.name, e.target.value];

    if (name === "phone")
      value = String(value)
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    setDefaultValue((acc) => ({ ...acc, [name]: value }));
  };

  const updateTableItem = async (x, isQuickEdit) => {
    if (isQuickEdit && x.id == defaultValue.id) {
      const data = await instanceAxios.get(
        `/counselings?visit=${x.id}&_limit=1&_sort=id:DESC`,
      );

      const changeDataObj = Object.assign(
        ...[...document.querySelectorAll(".editManagementInput")].map(
          (vv, kk) => ({ [vv.name]: vv.value || null }),
        ),
      );
      Object.assign(
        changeDataObj,
        { ...addressSel },
        { status: defaultValue.status },
      );

      if (data.length) {
        await updateCounseling([
          data[0].id,
          {
            category: defaultValue.category,
            recallRequestDate: defaultValue.recallRequestDate,
          },
        ]);
      }
      await updateVisitsItem([x.id, changeDataObj]);
      visitsListTm.refetch();
    }

    if (x.id !== defaultValue.id) {
      let AddressData = [x.address1, x.address2, x.address3].filter(
        (vvalue) => vvalue,
      );
      AddressData = AddressData.length
        ? Object.assign(
            ...AddressData.map((vvalue, kk) => ({
              [`address${kk + 1}`]: vvalue,
            })),
          )
        : {};

      setAddressSel(AddressData);
    }

    setQuickEditID(isQuickEdit ? null : x.id);

    // 일단 모든 데이터 스테이트 저장
    const xObjData = Object.assign(
      ...Object.entries(x).map((vv, kk) => ({ [vv[0]]: vv[1] })),
    );
    setDefaultValue(xObjData);
    // 일단 모든 데이터 스테이트 저장
  };

  // 테이블 수정을 위한 스테이트

  const handleClickOpen = () => {
    if (!checkedItems.length) return;

    setTmName((_) => null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const excelUploadBtnClick = async () => {
    try {
      const files = await (await window.showOpenFilePicker())[0].getFile();

      ExcelRenderer(files, async (err, resp) => {
        if (err) {
        } else {
          const datas = [...resp.rows].slice(1).map((values) => {
            values = Array(22)
              .fill()
              .map((empty, key) => values[key] || null);

            return {
              data: {
                inflowDate: values[0],
                inflowRoute: values[1],
                inflowRouteDetail: values[2],
                inflowRouteDetailContent: values[3],
                // referralPatientIndex: values[3],
                name: values[5],
                phone: values[6],
                age: values[7],
                address1: values[8],
                address2: values[9],
                address3: values[10],
                isRepeatVisit: values[11],
                // status: values[11],
                counselor: values[12],
                doctor: values[4],
                reservationDate: values[16],
                chartNumber: values[17],
              },
              counseling: {
                callDatetime: values[18],
                progressContent: values[14],
                category: values[13],
                counselingContent: values[15],
                recallRequestDate: values[19],
              },
            };
          });

          const test = await instanceAxios.post("/visits/excel_upload", datas);

          visitsListTm.refetch();
          alert("업로드가 정상적으로 완료 되었습니다.");
        }
      });
    } catch {
      alert("알 수 없는 오류입니다.");
    }
  };

  const tmNameListHtml = (csList.data || []).map((x) => {
    return <MenuItem value={x.name}>{x.name}</MenuItem>;
  });

  const tmNameChange = (e) => {
    const value = e.target.value;
    setTmName((x) => value);
  };

  // 모달
  const ButtonFreeSet = "Excel&Seldel&nameChange";

  const TableData =
    isFetching || isError
      ? []
      : (visitsListTm.data || []).map((x) => {
          const isQuickEdit = x.id === quickEditID;
          // 조건문 눌러져있으면 true 아니면 false

          return [
            {
              type: "input",
              typeOption: "checkbox",
              name: "id",
              value: x.id,
              checked: checkedItems.includes(x.id),
              onClick: (event) => {
                setCheckedItems(
                  produce((draft) => {
                    const checked = event.target.checked;
                    if (checked && !draft.includes(x.id)) {
                      draft.push(x.id);
                    } else if (!checked && draft.includes(x.id)) {
                      draft.splice(draft.indexOf(x.id), 1);
                    }
                  }),
                );
              },
            },
            <td style={{ textAlign: " center", paddingRight: "12px" }}>
              <Link to={`/tm/totalManagement/detail/${x.id}`}>
                <Icon>settings</Icon>
              </Link>
            </td>,
            <td>
              <Tooltip
                title={isQuickEdit ? "눌러서 수정 완료" : "즉시 수정 활성화"}
                placement="right"
                onClick={() => updateTableItem(x, isQuickEdit)}
              >
                <Button
                  color={isQuickEdit ? "primary" : "default"}
                  variant={isQuickEdit ? "contained" : "outlined"}
                  disableElevation
                >
                  {isQuickEdit ? "변경사항 저장" : x.id}
                </Button>
              </Tooltip>

              {isQuickEdit ? (
                <Tooltip
                  title="취소"
                  placement="right"
                  onClick={async (e) => {
                    setQuickEditID(null);
                  }}
                >
                  <Button color={"default"} variant={"outlined"}>
                    취소
                  </Button>
                </Tooltip>
              ) : null}
            </td>,
            moment(x.inflowDate).format("YYYY-MM-DD"),
            x.category
              ? isQuickEdit
                ? {
                    type: "select",
                    name: "category",
                    onChange: (e) => {
                      const [name, value] = [e.target.name, e.target.value];
                      setDefaultValue((acc) => ({ ...acc, [name]: value }));
                    },
                    selValue: typeList,
                    value: defaultValue["category"],
                  }
                : x.category
              : "-",
            isQuickEdit && x.category ? (
              <DateInput
                name="recallRequestDate"
                style={{ width: "7.5rem" }}
                onChange={(e) => {
                  const [name, value] = [e.target.name, e.target.value];
                  setDefaultValue((acc) => ({ ...acc, [name]: value }));
                }}
                value={defaultValue.recallRequestDate || ""}
              />
            ) : (
              x.recallRequestDate || "-"
            ),
            `${x.possibility}%`,
            // 상태
            isQuickEdit
              ? {
                  type: "select",
                  value: defaultValue.status || null,
                  onChange: inputChangeEvent,
                  selValue: ["", ...PROGRESS_TYPES],
                  name: "status",
                }
              : x.reservationDate
              ? "예약완료"
              : x.status,
              x?.reservationDate ? moment(x.reservationDate).format("YYYY-MM-DD") : "-",
            // 상태
            {
              type: "select",
              name: "TM",
              selValue: ["없음", ...csList.data.map((cs) => cs.name)],
              value: x.tm_name || "없음",
              onChange: async (e) => {
                const cs = csList.data.find((x) => x.name == e.target.value);

                await updateVisitsItem([x.id, { counselor: cs?.id || null }]);
                visitsListTm.refetch();
              },
            },
            x.doctor_name,
            isQuickEdit
              ? {
                  type: "input",
                  onChange: inputChangeEvent,
                  value: defaultValue.inflowRouteDetailContent || "",
                  inputClassName: "editManagementInput",
                  name: "inflowRouteDetailContent",
                }
              : x.inflowRouteDetailContent,
            isQuickEdit
              ? {
                  type: "input",
                  onChange: inputChangeEvent,
                  value: defaultValue.name || "",
                  inputClassName: "editManagementInput",
                  name: "name",
                }
              : x.name,
            isQuickEdit
              ? {
                  type: "input",
                  onChange: inputChangeEvent,
                  value: defaultValue.phone || "",
                  inputClassName: "editManagementInput",
                  name: "phone",
                }
              : x.phone,
            isQuickEdit
              ? {
                  type: "input",
                  onChange: inputChangeEvent,
                  value: defaultValue.age || "",
                  inputClassName: "editManagementInput",
                  name: "age",
                }
              : x.age
              ? `${x.age}세`
              : "-",
            x.address1 ? `${x.address1}` : "-",
            x.counselingTotal
              ? `${Number(x.counselingTotal).toLocaleString()}`
              : "-",
            // (x.reservationDate ? 'o' : 'x'),
            x?.chartNumber || "-",
            x?.cure || "-",
            x.updated_at_format
              ? moment(x.updated_at_format).format("YYYY-MM-DD")
              : "-",
            x.visitDate ? "내원" : "미내원",
            x.smsSetting ? "O" : "X",
          ];
        });

  const handleFilterSearch = (value) => {
    setFilter(value);
  };

  const handleSort = (value) => {
    setSort(value);
  };

  //
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});

  const dateRangeChange = () => setDateRangeOpen(!dateRangeOpen);
  //

  const rangeChange = (range) => {
    onChange(moment(range.startDate).format("YYYY-MM-DD"), "startDate");
    onChange(moment(range.endDate).format("YYYY-MM-DD"), "endDate");

    window.localStorage.setItem(
      "tmSearchDate",
      JSON.stringify({
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      }),
    );
  };

  const onClickSetdate = async (type = "day") => {
    const date = new Date();
    const searchbutton = document.querySelector(".searchButton");

    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const weekStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - date.getDay(),
    );
    const weekEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (6 - date.getDay()),
    );

    if (type == "month")
      await rangeChange({ startDate: monthStart, endDate: monthEnd });
    else if (type == "week")
      await rangeChange({ startDate: weekStart, endDate: weekEnd });
    else if  (type == "all") {
      onChange(moment(new Date(date.getFullYear() - 200, date.getMonth(), 1)).format("YYYY-MM-DD"), "startDate");
      onChange(moment(new Date(date.getFullYear() + 200, date.getMonth(), 1)).format("YYYY-MM-DD"), "endDate");
    }
    else await rangeChange({ startDate: date, endDate: date });

    searchbutton.click();
  };

  useEffect(() => {
    instanceAxios.post("/visits/allRecallChecking").then((eData) => {
      console.log(eData);
    });

    if (window.localStorage.getItem("tmSearchDate")) {
      const parseData = JSON.parse(window.localStorage.getItem("tmSearchDate"));

      onChange(parseData.startDate, "startDate");
      onChange(parseData.endDate, "endDate");

      setState([
        {
          startDate: new Date(parseData.startDate),
          key: "selection",
          endDate: new Date(parseData.endDate),
        },
      ]);

      try {
        window.setTimeout(() => {
          document.querySelector(`#TMSearchBtn`).click();
        });
      } catch {
        console.log("settimeError!");
      }
    }
  }, []);

  const DataRangeChangeEvent = (item) => {
    const range = item.selection;

    onChange(moment(range.startDate).format("YYYY-MM-DD"), "startDate");
    onChange(moment(range.endDate).format("YYYY-MM-DD"), "endDate");

    window.localStorage.setItem(
      "tmSearchDate",
      JSON.stringify({
        startDate: moment(range.startDate).format("YYYY-MM-DD"),
        endDate: moment(range.endDate).format("YYYY-MM-DD"),
      }),
    );

    setState([item.selection]);
  };

  const lsDateParse = window.localStorage.getItem("tmSearchDate")
    ? JSON.parse(window.localStorage.getItem("tmSearchDate"))
    : false;

  if (["TM팀D"].includes(access)) {
    window.location.replace("/tm/StatisticsManagement/period");
    return null;
  }

  return (
    <>
      <div className="totalManagement">
        <div className="viewTitle">
          총 DB관리{pageTitle == "총 DB관리" ? "" : " - " + pageTitle}
        </div>
        <>
          <div className="dbStateManagement">
            <div>
              <div>
                <span>항목선택</span>
              </div>

              <div className="radioGroup">
                {PROGRESS_TYPES.map((typeName, i) => (
                  <FormControlLabel
                    key={i}
                    value={typeName}
                    label={typeName}
                    control={
                      <Radio
                        checked={searchQuery.status === typeName}
                        onChange={onChange}
                        onClick={(event) => {
                          if (event.target.checked) {
                            setSearchQuery({ ...searchQuery, status: "" });
                          }
                        }}
                        color="secondary"
                        name="status"
                        size="small"
                      />
                    }
                  />
                ))}
              </div>
            </div>

            <div>
              <div>
                <span>기간검색</span>
              </div>

              <div className="DateGroup">
                <div className="DateInputs">
                  <div className="inputs">
                    <div data-tip data-for="daterange" data-event="click">
                      <TextInput
                        onClick={dateRangeChange}
                        value={searchQuery.startDate}
                      />
                      <p>~</p>
                      <TextInput
                        onClick={dateRangeChange}
                        value={searchQuery.endDate}
                      />
                    </div>
                    <ReactTooltip
                      id="daterange"
                      place="bottom"
                      effect="solid"
                      type="light"
                      globalEventOff="click"
                      clickable
                    >
                      <DateRange
                        ranges={state}
                        onChange={DataRangeChangeEvent}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        direction="horizontal"
                        locale={ko}
                      />
                    </ReactTooltip>
                  </div>
                  <div className="dateRangePicker-box">
                    {/* <DateRangePicker
                      initialDateRange={{
                        startDate:
                          lsDateParse?.startDate || searchQuery.startDate,
                        endDate: lsDateParse?.endDate || searchQuery.endDate,
                      }}
                      closeOnClickOutside={false}
                      open={dateRangeOpen}
                      onChange={rangeChange}
                    /> */}
                  </div>
                </div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onClickSetdate}
                >
                  당일
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClickSetdate("week")}
                >
                  주별
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onClickSetdate("month")}
                >
                  월별
                </Button>
                <Tooltip title="두번 눌러야 적용 됩니다">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => onClickSetdate("all")}
                  >
                    전체
                  </Button>
                </Tooltip>

                <Button
                  variant="contained"
                  color="secondary"
                  className="searchButton"
                  id="TMSearchBtn"
                  onClick={onClickSearch}
                >
                  <span>Search</span>
                  <FaSearch size="1.7rem" />
                </Button>
              </div>
            </div>
          </div>

          {/* <div className="Title">
            <PageTitle title={pageTitle} />
          </div> */}

          <div className="Tables tm">
            <Table
              TMdata={tmNameListHtml}
              count={visitsListTmCount.data}
              value={value}
              Type="TM"
              nowPage={searchQuery.q_page}
              onFilterSearch={handleFilterSearch}
              onSort={handleSort}
              {...{
                setCheckedItems,
                checkedItems,
                TableTitle,
                TableData,
                isFetching,
                VisitsListTmData: visitsListTm.data,
                useFilter: true,
              }}
            />
          </div>
          <div className="Buttons">
            <Pazing
              pages={pages}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onPageChange={onPageChange}
            />

            <ControlsButton
              {...{
                ButtonFreeSet,
                deleteCheckedItems,
                handleClickOpen,
                excelUploadBtnClick,
                access,
                tmQuery: {
                  query: qs.stringify({
                    ...qs.parse(queryString),
                    filter,
                    sort,
                    limit: true,
                  }),
                },
              }}
              // setCheckedItems={setCheckedItems}
              type="TM"
              refetch={visitsListTm.refetch}
              // DelList={checkedItems}
            />
          </div>
        </>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{ width: "500px" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          다중 이름 변경
        </DialogTitle>
        <DialogContent dividers>
          <Select fullWidth value={tmName} onChange={tmNameChange}>
            <MenuItem value={null}>없음</MenuItem>
            {tmNameListHtml}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            취소
          </Button>
          <Button onClick={changeNameCheckedItems} color="primary" autoFocus>
            수정
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
