import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

// CS 추가
export const useAddCS = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/Counselors   ", data);

      return res;
    },
    {
      loadingMessage: "CS 정보를 추가 중입니다",
      successMessage: "CS 정보가 추가되었습니다",
    },
  );
};

// CS 삭제
export const useDeleteCS = () => {
  return useLoadingMutation(
    async (CSID) => {
      const res = await axios.delete(`/Counselors/${CSID}`);

      return res;
    },
    {
      loadingMessage: "CS 삭제 중입니다",
      successMessage: "CS가 삭제 되었습니다",
    },
  );
};

// CS 수정
export const useUpdateCS = (CSID) => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.put(`/Counselors/${CSID}`, data);
      return res;
    },
    {
      loadingMessage: "CS 정보를 수정 중입니다",
      successMessage: "CS가 수정되었습니다",
    },
  );
};

// CS 아이템 조회
export const useGetCSItem = (queryString = "", options) => {
  return useQuery(
    ["CS", queryString],
    async () => {
      const data = await axios.get(
        `/Counselors${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// CS 목록 조회
export const useGetCSList = (queryString = "", options) => {
  return useQuery(
    ["CS", queryString],
    async () => {
      const data = await axios.get(
        `/Counselors${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// CS 목록 조회
export const useGetCSListCount = (queryString = "", options) => {
  return useQuery(
    ["CSCount", queryString],
    async () => {
      const data = await axios.get(
        `/Counselors/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};
