import React from "react";

import "./layout.scss";

const Layout = ({ children }) => {
  return <div className="layout">{children}</div>;
};

Layout.SideFrame = ({ children }) => {
  return <div className="sideFrame">{children}</div>;
};

Layout.MainFrame = ({ children }) => {
  return <div className="mainFrame">{children}</div>;
};

export default Layout;
