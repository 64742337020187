import React from "react";
import produce from "immer";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import TextInput from "~ui/textInput/TextInput";
import Checkbox from "@material-ui/core/Checkbox";
import { MenuItem, Button, Tooltip } from "@material-ui/core";
import SelectInput from "~ui/selectInput/SelectInput";

import styled from "styled-components";
import { useQuery } from "react-query";

import instanceAxios from "~apis/_util";

import "./patientInfo.scss";
import division from "~json/administrative_division.json";
import { useHistory } from "react-router-dom";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  .MuiSelect-root {
    color: black;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    transform: scale(1.3) translate(-0.1rem, 0);
  }
`;

const HEADER_COLUMNS = [
  "No",
  "차트번호",
  "구환",
  "이름",
  "전화번호",
  "나이",
  "지역",
  "유입경로",
  "광고별 분류",
  "업체별 분류",
  "채널별 분류",
  "이벤트 내원",
  "소개환자 차트번호",
  "소개환자 내원경로",
];

function PatientInfoHeader() {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  return <div className="patientInfoHeader">{items}</div>;
}

function PatientInfoItem({
  data,
  setUiData,
  channelList,
  onChange,
  saveChannelInput,
  phoneBtnClick,
}) {
  const history = useHistory();
  const [sidoListData, setSidoListData] = React.useState(Object.keys(division));
  const [sigunguListData, setSigunguList] = React.useState(
    division[data.address1] ? Object.keys(division[data.address1]) : [],
  );
  const [eupmyundongListData, setEupmyundongList] = React.useState(
    division[data.address1] && division[data.address1][data.address2]
      ? division[data.address1][data.address2]
      : [],
  );

  const { data: patientData = null, isFetching } = useQuery(
    ["PatientName", data.referralPatientIndex],
    async () => {
      const res = await instanceAxios.get(
        `/visits?chartNumber=${data.referralPatientIndex}`,
      );

      if (res.length) {
        return res[0];
      } else {
        return null;
      }
    },
    {
      enabled: Boolean(data.referralPatientIndex),
    },
  );

  const { data: channelPreview = null, previewFetch } = useQuery(
    ["PatientName", data.referralPatientVisitRoute],
    async () => {
      const res = await instanceAxios.post(`/channels/inputSearch`, {
        input: data.referralPatientVisitRoute,
      });

      if (res.length) {
        return res[0];
      } else {
        return null;
      }
    },
    {
      enabled: Boolean(data.referralPatientVisitRoute),
    },
  );

  const channelData = channelList.data;

  const companyNames = [...new Set(channelList.data.map((x) => x.agency))];
  const _company = channelData.find(
    (v) => v.channelName === data.inflowRouteDetail,
  )?.agency;

  const channelNames = [
    ...new Set(
      channelList.data
        .filter((v) => v.agency === data.company || v.agency === _company)
        .map((x) => x.channelName),
    ),
  ];

  const onChangeAddress = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      if (name === "address1") {
        setSigunguList(division[value] ? Object.keys(division[value]) : []);
        setEupmyundongList([]);
        setUiData(
          produce((draft) => {
            draft.visit.address2 = "";
            draft.visit.address3 = "";
          }),
        );
      }
      if (name === "address2") {
        setEupmyundongList(
          division[data.address1][value] ? division[data.address1][value] : [],
        );
        setUiData(
          produce((draft) => {
            draft.visit.address3 = "";
          }),
        );
      }
      onChange(event);
    },
    [data, setSigunguList, setEupmyundongList, onChange],
  );

  const sidoList = sidoListData.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));
  const sigunguList = sigunguListData.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));
  const eupmyundongList = eupmyundongListData.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const routeDetailList = channelNames.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const routeList = Array.from(
    new Set(["TV 광고", "업체광고", "오프라인광고", "HYDJ"]),
  ).map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const companyList = data?.inflowRoute
    ? Array.from(
        new Set(
          channelData
            ?.filter((listValue) => listValue.ad === data.inflowRoute)
            .map((listValue) => listValue.agency),
        ),
      )
    : [];

  const inflowRouteDetailList = companyList.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const contentArrayList = data?.inflowRouteDetail
    ? Array.from(
        new Set(
          channelData
            ?.filter((listValue) => listValue.agency === data.inflowRouteDetail)
            .map((listValue) => listValue.channelName),
        ),
      )
    : [];

  const inflowRouteDetailContentList = contentArrayList.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const selectChangeEvent = (e) => {
    onChange(e);
  };

  const channelMake = (data) => {
    return <MenuItem value={data.channelName}>{data.channelName}</MenuItem>;
  };

  const columnDataList = [
    data.id,
    <TextInput
      name="chartNumber"
      inputClassName="numberInput"
      value={data.chartNumber || ""}
      onChange={onChange}
    />,
    <Checkbox
      name="isRepeatVisit"
      checked={data.isRepeatVisit || false}
      onChange={onChange}
      color="secondary"
    />,
    <TextInput name="name" value={data.name || ""} onChange={onChange} />,
    <>
      <div className="phoneInputDiv">
        <TextInput
          name="phone"
          inputClassName="phoneNumberInput"
          value={data.phone || ""}
          onChange={onChange}
        />
        {/* {data.phone ? (
          <Button onClick={phoneBtnClick} value={data.phone}>
            중복체크
          </Button>
        ) : null} */}
        {/* <button style={{ border: 0, background: "transparent" }}>검색</button> */}
      </div>
    </>,
    <TextInput
      name="age"
      inputClassName="numberInput"
      value={data.age || ""}
      onChange={onChange}
    />,
    <TextInput
      name="address1"
      value={data.address1 || ""}
      onChange={onChange}
    />,
  
    <TextInput
      name="address3"
      value={data.address3 || ""}
      onChange={onChange}
    />,
    // <StyledSimpleSelectInput
    //   className="sidoSelectInput"
    //   name="address1"
    //   value={data.address1 || ""}
    //   onChange={onChangeAddress}
    //   fontSize="0.75rem"
    //   placeholder="--"
    // >
    //   {sidoList}
    // </StyledSimpleSelectInput>,
    // <StyledSimpleSelectInput
    //   className="sigunguSelectInput"
    //   name="address2"
    //   value={data.address2 || ""}
    //   onChange={onChangeAddress}
    //   fontSize="0.75rem"
    //   placeholder="--"
    // >
    //   {sigunguList}
    // </StyledSimpleSelectInput>,
    // <StyledSimpleSelectInput
    //   className="eupmyundongSelectInput"
    //   name="address3"
    //   value={data.address3 || ""}
    //   onChange={onChangeAddress}
    //   fontSize="0.75rem"
    //   placeholder="--"
    // >
    //   {eupmyundongList}
    // </StyledSimpleSelectInput>,
    <StyledSimpleSelectInput
      className="routeSelectInput"
      name="inflowRoute"
      value={data.inflowRoute || ""}
      onChange={(e) => {
        setUiData(
          produce((draft) => {
            draft.visit.inflowRouteDetail = "";
          }),
        );
        onChange(e);
      }}
      fontSize="0.75rem"
      placeholder="--"
    >
      {routeList}
    </StyledSimpleSelectInput>,
    <StyledSimpleSelectInput
      className="routeDetailSelectInput"
      name="inflowRouteDetail"
      value={data.inflowRouteDetail}
      onChange={(e) => {
        setUiData(
          produce((draft) => {
            draft.visit.inflowRouteDetail = "";
          }),
        );

        onChange(e);
      }}
      fontSize="0.75rem"
      placeholder="--"
    >
      {inflowRouteDetailList}
    </StyledSimpleSelectInput>,
    <StyledSimpleSelectInput
      className="routeDetailSelectInput"
      name="inflowRouteDetailContent"
      value={data.inflowRouteDetailContent || ""}
      onChange={onChange}
      fontSize="0.75rem"
      placeholder="--"
    >
      {inflowRouteDetailContentList}
    </StyledSimpleSelectInput>,
    <Checkbox
      name="isEventVisit"
      checked={data.isEventVisit || false}
      onChange={onChange}
      color="secondary"
    />,
    <div className="referralPatientIndexDiv">
      <TextInput
        name="referralPatientIndex"
        value={data.referralPatientIndex || ""}
        onChange={onChange}
      />

      {Boolean(data.referralPatientIndex) && (
        <Tooltip title="클릭시 상세보기로 이동">
          <Button
            onClick={() => {
              if (patientData) {
                history.push(`/counselor/counseling/detail/${patientData.id}`);
              }
            }}
          >
            {isFetching && "로딩중"}
            {!isFetching && (
              <>
                소개 환자명: {patientData ? patientData.name : "차트번호 오류"}
              </>
            )}
          </Button>
        </Tooltip>
      )}
    </div>,
    // channelPreview
    // previewFetch
    <div className="referralPatientVisitRouteDiv">
      <TextInput
        name="referralPatientVisitRoute"
        value={patientData?.inflowRouteDetailContent || ""}
        onChange={onChange}
        readOnly
      />
      {
        // Boolean(data.referralPatientVisitRoute) & channelPreview?.length ? (
        //   <SelectInput
        //     className="previewInputsSelect"
        //     name="previewSelectBox"
        //     onChange={onChange}
        //     placeholder="- 자동 입력 -"
        //   >
        //     {channelPreview.map((x) => channelMake(x))}
        //   </SelectInput>
        // ) : null
      }
    </div>,
  ];

  const items = columnDataList.map((columnData, i) => (
    <div key={i}>{columnData}</div>
  ));

  return <div className="patientInfoItem">{items}</div>;
}

export default function PatientInfo({
  uiData,
  setUiData,
  channelList,
  phoneBtnClick,
  setPhoneCheck,
}) {
  const [saveChannelInput, setSaveChannelInput] = React.useState(
    uiData.visit.referralPatientVisitRoute,
  );

  const onChange = React.useCallback(
    async (event) => {
      const name = event.target.name;
      const type = event.target.type;
      const classList = event.target.classList;
      let value = event.target.value;

      if (name === "previewSelectBox") {
        setUiData(
          produce((draft) => {
            draft.visit.referralPatientVisitRoute = value;
          }),
        );

        return;
      }

      if (name === "phone") {
        setPhoneCheck(true);
        value = value
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      }

      if (type === "checkbox") value = event.target.checked;
      else if (classList && classList.contains("numberInput"))
        value = value.replace(/[^0-9]/g, "");
      else if (classList && classList.contains("phoneNumberInput"))
        value = value.replace(/[^0-9|-]/g, "");

      setUiData(
        produce((draft) => {
          draft.visit[name] = value;
        }),
      );
    },
    [setUiData],
  );

  return (
    <div className="patientInfo">
      <PatientInfoHeader />
      <PatientInfoItem
        data={uiData.visit}
        setUiData={setUiData}
        channelList={channelList}
        onChange={onChange}
        saveChannelInput={saveChannelInput}
        phoneBtnClick={phoneBtnClick}
        setPhoneCheck={setPhoneCheck}
      />
    </div>
  );
}
