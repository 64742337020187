import { useQuery } from "react-query";
import axios, { API_HOST, useLoadingMutation } from "./_util";

// 소셜 로그인
export const useProviderSignIn = () => {
  return useLoadingMutation(async ({ provider, access_token }) => {
    const res = await axios.get(`${API_HOST}/auth/${provider}/callback`, {
      params: {
        access_token,
      },
    });

    return res;
  });
};

// 아이템
export const useGetUserItem = (queryString = "", options) => {
  return useQuery(
    ["User", queryString],
    async () => {
      const data = await axios.get(
        `/Users${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록
export const useUsers = () => {
  return useLoadingMutation(async (queryString = "") => {
    const res = await axios.post(
      `${API_HOST}/auth/local${queryString.length ? `/${queryString}` : ""}`,
    );

    return res;
  });
};

export const useGetUserList = (queryString = "", options) => {
  return useQuery(
    ["User", queryString],
    async () => {
      const data = await axios.get(
        `/Users${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

export const useGetUserListCount = (queryString = "", options) => {
  return useQuery(
    ["UserCount", queryString],
    async () => {
      const data = await axios.get(
        `/Users/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 로그인
export const useSignIn = () => {
  return useLoadingMutation(async ({ identifier, password }) => {
    const res = await axios.post(`${API_HOST}/auth/local`, {
      identifier: identifier,
      password: password,
    });

    return res;
  });
};

// 회원가입
export const useRegister = () => {
  return useLoadingMutation(async ({ username, password }) => {
    const data = {
      username: username,
      email: username + "@standardcrm.com",
      password: password,
    };
    const res = await axios.post(`${API_HOST}/auth/local/register`, data);

    return res;
  });
};

export const useAddUser = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/auth/local/register", data);

      return res;
    },
    {
      loadingMessage: "사용자 정보를 추가 중입니다",
      successMessage: "사용자가 추가되었습니다",
    },
  );
};

export const useDeleteUser = () => {
  return useLoadingMutation(
    async (usersID) => {
      const res = await axios.delete(`/users/${usersID}`);

      return res;
    },
    {
      loadingMessage: "사용자 삭제 중입니다",
      successMessage: "사용자가 삭제 되었습니다",
    },
  );
};

export const useUpdateUser = (usersID) => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.put(`/users/${usersID}`, data);
      return res;
    },
    {
      loadingMessage: "사용자 정보를 수정 중입니다",
      successMessage: "사용자가 수정되었습니다",
    },
  );
};

// 로그인 한 유저 정보 가져오기
export const useGetMe = (options) => {
  return useQuery('/users/me', async () => {
    const res = await axios.get(`${API_HOST}/users/me`)

    return res
  }, options)
}