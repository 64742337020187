import React from "react";
import produce from "immer";
import { Radio } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TextInput from "~ui/textInput/TextInput";

import "./progressManagement.scss";

const HEADER_COLUMNS = [
  ["상담불필요", "보철", "IT", "교정", "합계"],
  ["보철", "IT", "미백/보톡스", "일반교정", "인비절라인"],
  ["진행", "보험", "미진행"],
  ["비고 및 치료관련 메모"],
];

function ProgressManagementHeader() {
  const items = HEADER_COLUMNS.map((columnList, i) => (
    <div key={i}>
      {columnList.map((column, j) => (
        <div key={j}>
          <span>{column}</span>
        </div>
      ))}
    </div>
  ));

  return <div className="progressManagementHeader">{items}</div>;
}

function ProgressManagementItem({ data, onChange }) {
  const items = [];

  const counselingInfo = Array.from((data.counselingInfo || "").padEnd(4, "0"))
    .map((x) => parseInt(x))
    .slice(0, 4);
  items.push(
    <div key={0}>
      {counselingInfo
        .map((_, i) => (
          <div key={i}>
            <Checkbox
              name="counselingInfo"
              value={i}
              checked={Boolean(counselingInfo[i])}
              onChange={(e) => {
                counselingInfo[i] = Number(e.target.checked);
                onChange(e, counselingInfo.join(""));
              }}
              color="secondary"
            />
          </div>
        ))
        .concat(
          <div key={4}>{counselingInfo.filter((x) => x === 1).length}</div>,
        )}
    </div>,
  );

  const progressInfo = Array.from((data.progressInfo || "").padEnd(5, "0"))
    .map((x) => parseInt(x))
    .slice(0, 5);
  items.push(
    <div key={1}>
      {Array(5)
        .fill(0)
        .map((_, i) => (
          <div key={i}>
            <Checkbox
              name="progressInfo"
              value={i}
              checked={Boolean(progressInfo[i])}
              onChange={(e) => {
                progressInfo[i] = Number(e.target.checked);
                onChange(e, progressInfo.join(""));
              }}
              color="secondary"
            />
          </div>
        ))}
    </div>,
  );

  items.push(
    <div key={2}>
      {HEADER_COLUMNS[2].map((column, i) => (
        <div key={i}>
          <Radio
            checked={column === data.counselingResult}
            onChange={onChange}
            color="secondary"
            name="counselingResult"
            value={column}
            size="small"
          />
        </div>
      ))}
    </div>,
  );

  items.push(
    <div key={3}>
      <TextInput
        name="stopMemo"
        value={data.stopMemo || ""}
        onChange={onChange}
      />
    </div>,
  );

  return <div className="progressManagementItem">{items}</div>;
}

export default function ProgressManagement({ uiData, setUiData }) {
  const onChange = React.useCallback(
    (event, etc) => {
      const name = event.target.name;
      const type = event.target.type;
      const classList = event.target.classList;
      let value = event.target.value;

      if (type === "checkbox") {
        // value = event.target.checked;
        value = event.data;
      } else if (classList && classList.contains("numberInput"))
        value = value.replace(/[^0-9]/g, "");
      else if (classList && classList.contains("phoneNumberInput"))
        value = value.replace(/[^0-9|-]/g, "");

      setUiData(
        produce((draft) => {
          if (type === "checkbox") {
            draft.visit[name] = etc;
          } else {
            draft.visit[name] = value;
          }
        }),
      );
    },
    [setUiData],
  );

  return (
    <div className="progressManagement">
      <ProgressManagementHeader />
      <ProgressManagementItem data={uiData.visit} onChange={onChange} />
    </div>
  );
}
