import moment from "moment";

/*
 * 타임스탬프를 원하는 포맷으로 변경 (기본 YYYY.MM.DD)
 * - timestamp: 0000-00-00T00:00:00Z00 포맷 형식의 타임스탬프
 * - foramt: 변경할 포맷 문자열 (참고: https://momentjs.com/docs/#/parsing/string-format/)
 */
export const format = (timestamp, foramt = "YYYY.MM.DD") => {
  return moment(timestamp).format(foramt);
};

export const getNextMonth = (dateStr) => {
  let [year, month, day] = dateStr.split("-").map((x) => Number(x));
  if (month === 12) {
    year += 1;
    month = 1;
  } else {
    month += 1;
  }
  day = Math.min(day, new Date(year, month, 0).getDate());

  return `${String(year).padStart(4, "0")}-${String(month).padStart(
    2,
    "0",
  )}-${String(day).padStart(2, "0")}`;
};
