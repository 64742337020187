import React from "react";
import classNames from "classnames";
import { useSetRecoilState } from "recoil";
import { csvDataState } from "~/global/stores/recoil";
import produce from "immer";

import "./statisticsManagementList.scss";
import { useLocation } from "react-router";

const format = (number, toFixed) => {
  try {
    if (toFixed === undefined) {
      return Number(number || 0).toLocaleString();
    } else {
      return Number(number || 0).toFixed(toFixed).replace(/\.0$/, "");
    }
  } catch (error) {
    return 0
  }
}

const toNumber = (number) => {
  try {
    return Number(number || 0)
  } catch (error) {
    return 0
  }
}

const dataToTableData = (data, agency = false) => {
  const total = toNumber(data.db_direct_sales) + toNumber(data.db_indirect_sales); // 총매출

  return [
    `${data.year}. ${data.month}`, // 년. 월
    data.ad, // 광고
    data.agency, // 업체
    data.channelName, // 채널
    agency ? '' : data.doctor_name, // Doctor
    format(total), // 총매출
    format(data.db_direct_sales), // 직접매출
    format(data.db_indirect_sales), // 간접매출
    format(data.total_cost), // DB비용
    format(data.total_count), // DB갯수
    format(data.reservation_count), // 예약
    format(data.visit_count), // 내원
    format(data.progress_count), // 시술
    format(data.referral_count), // 소개
    format(total / data.progress_count), // 1인당 매출
    format(total / data.total_cost, 1), // ROI
    format(data.total_cost / data.total_count), // DB당 단가
    format(total / data.total_count), // 총매출 (DB당)
    format(data.db_direct_sales / data.total_count), // 직접매출 (DB당)
    format(data.db_indirect_sales / data.total_count ), // 간접매출 (DB당)
    format(data.total_count / data.reservation_count * 100, 1) + '%', // 예약성공률
    format(data.total_count / data.visit_count * 100, 1) + '%', // 내원성공률 (DB대비)
    format(data.total_count / data.reservation_count * 100, 1) + '%', // 내원성공률 (예약대비)
    format(data.total_count / data.progress_count * 100, 1) + '%', // 시술성공률 (DB당)
    format(data.visit_count / data.progress_count * 100, 1) + '%', // 시술성공률 (내원당)
    format(data.total_count / data.referral_count * 100, 1) + '%', // DB당 소개비율
  ]
}

function StatisticsManagementListHeader({ isAgency, HEADER_COLUMNS }) {
  const items = HEADER_COLUMNS.map((column, i) => {
    if (column instanceof Array) {
      return (
        <div
          key={i}
          className="splitColumn"
          style={{ gridColumn: `span ${column[1].length}` }}
        >
          {column.map((columnRow, j) => {
            return (
              <div key={j} className="columnRow">
                {columnRow.map((columnRowItem, k) => (
                  <div key={k}>
                    <span>{columnRowItem}</span>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div key={i}>
          <span>
            {column === "채널" ? (isAgency ? "업체" : column) : column}
          </span>
        </div>
      );
    }
  });

  return <div className="statisticsManagementListHeader">{items}</div>;
}

function StatisticsManagementListItem({ data, agency }) {
  const setCsvData = useSetRecoilState(csvDataState);

  const columnDataList = dataToTableData(data, agency);

  setCsvData(
    produce((v) => {
      v.push(columnDataList);
    }),
  );

  const items = columnDataList.map((columnData, i) => (
    <div key={i}>{columnData}</div>
  ));

  const className = classNames("statisticsManagementListItem", {
    disabledItem: data.broadcasting_progress === 0,
  });

  return <div className={className}>{items}</div>;
}

function StatisticsManagementListTotal({ evaluationData }) {
  const list = Array(24).fill('');
  list[0] = `${evaluationData.year}년 / ${evaluationData.month}월 / 전체합계 :`;
  list.fill(0, 3, 24);

  evaluationData.list.forEach((x, i) => {
    const total = toNumber(x.db_direct_sales) + toNumber(x.db_indirect_sales); // 총매출

    list[3] += total; // 총매출
    list[4] += toNumber(x.db_direct_sales); // 직접매출
    list[5] += toNumber(x.db_indirect_sales); // 간접매출
    list[6] += toNumber(x.total_cost); // DB비용
    list[7] += toNumber(x.total_count); // DB갯수
    list[8] += toNumber(x.reservation_count); // 예약
    list[9] += toNumber(x.visit_count); // 내원
    list[10] += toNumber(x.progress_count); // 시술
    list[11] += toNumber(x.referral_count); // 소개

  });

  list[12] = '' // 1인당 매출
  list[13] = format(list[3] / list[6], 1) // ROI
  list[14] = format(list[6] / list[7]) // DB당 단가
  list[15] = format(list[3] / list[7]) // 총매출 (DB당)
  list[16] = format(list[4] / list[7]) // 직접매출 (DB당)
  list[17] = format(list[5] / list[7]) // 간접매출 (DB당)
  list[18] = format(list[7] / list[8] * 100, 1) + '%' // 예약성공률
  list[19] = format(list[7] / list[9] * 100, 1) + '%' // 내원성공율 (DB대비)
  list[20] = format(list[7] / list[8] * 100, 1) + '%' // 내원성공율 (예약대비)
  list[21] = format(list[7] / list[10] * 100, 1) + '%' // 시술성공율 (DB당)
  list[22] = format(list[9] / list[10] * 100, 1) + '%' // 시술성공율 (내원당)
  list[23] = format(list[7] / list[11] * 100, 1) + '%' // DB당 소개비율


  const items = list.map((itemData, i) => (
    <div key={i}>{0 < i ? itemData.toLocaleString("ko-KR") : itemData}</div>
  ));

  return <div className="statisticsManagementListTotal">{items}</div>;
}

function getColumns(arr) {
  return arr.reduce(
    (pre, cur) => pre.concat(cur instanceof Array ? getColumns(cur[1]) : cur),
    [],
  );
}

export default React.memo(
  function StatisticsManagementList({ evaluationData, csvDataLength, agency }) {
    const location = useLocation();
    const setCsvData = useSetRecoilState(csvDataState);

    const HEADER_COLUMNS = [
      "년/월",
      [["채널"], ["광고", "업체", agency ? '-' : "채널"]],
      agency ? '-' : "Doctor",
      [["DB매출"], ["총매출", "직접매출", "간접매출"]],
      "DB비용",
      "DB갯수",
      "예약",
      "내원",
      "시술",
      "소개",
      "1인당 매출",
      "ROI",
      "DB당 단가",
      [["총매출"], ["총매출", "직접매출", "간접매출"]],
      "예약성공률",
      [
        ["내원성공률", "시술성공률"],
        ["DB대비", "예약대비", "DB당", "내원당"],
      ],
      "DB당 소개비율",
    ];

    setCsvData([getColumns(HEADER_COLUMNS)]);

    const items = evaluationData.list.map((data, i) => (
      <StatisticsManagementListItem agency={agency} key={i} data={data} />
    ));

    return (
      <div className="statisticsManagementList">
        <StatisticsManagementListHeader
          HEADER_COLUMNS={HEADER_COLUMNS}
          isAgency={location.pathname.includes("agency")}
        />
        {items}
        <StatisticsManagementListTotal {...{ evaluationData }} />
      </div>
    );
  },
  (prev, now) => prev.csvDataLength === now.csvDataLength,
);
