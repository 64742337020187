import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";
import { updateCounselingPossibility } from "./counselings";

export const updateTMRecallPossibility = async (visitID) => {
  // const possibilityValues = [60, 30, 0];
  // const count = await axios.get(`/recalls/count/tm/${visitID}`);
  // if (!count) {
  //   await updateCounselingPossibility(visitID, false);
  //   return false;
  // }
  // const possibilityValue = possibilityValues[count - 1];
  // await axios.put(`/visits/${visitID}`, { possibility: possibilityValue });
};

// Recall_Head 추가
export const useAddRecall_Head = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/recalls/create_head", data);

      return res;
    },
    {
      loadingMessage: "리콜 정보를 추가 중입니다",
      successMessage: "리콜 정보가 추가되었습니다",
    },
  );
};

// Recall_Tm 추가
export const useAddRecall_Tm = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/recalls/create_tm", data);
      await updateTMRecallPossibility(data.visit);

      return res;
    },
    {
      loadingMessage: "리콜 정보를 추가 중입니다",
      successMessage: "리콜 정보가 추가되었습니다",
    },
  );
};

// Recall_Head 삭제
export const useDeleteRecall_Head = () => {
  return useLoadingMutation(
    async (recallId) => {
      const res = await axios.delete(`/recalls/delete_head/${recallId}`);

      return res;
    },
    {
      loadingMessage: "리콜 삭제 중입니다",
      successMessage: "리콜이 삭제 되었습니다",
    },
  );
};

// Recall_Tm 삭제
export const useDeleteRecall_Tm = (visitID) => {
  return useLoadingMutation(
    async (recallId) => {
      const res = await axios.delete(`/recalls/delete_tm/${recallId}`);
      await updateTMRecallPossibility(visitID);

      return res;
    },
    {
      loadingMessage: "리콜 삭제 중입니다",
      successMessage: "리콜이 삭제 되었습니다",
    },
  );
};

// Recall 수정
export const useUpdateRecall = (visitID) => {
  return useLoadingMutation(
    async ([recallId, data]) => {
      const res = await axios.put(`/recalls/${recallId}`, data);
      await updateTMRecallPossibility(visitID);
      return res;
    },
    {
      loadingMessage: "리콜 정보를 수정 중입니다",
      successMessage: "리콜이 수정되었습니다",
    },
  );
};

// Recall 아이템 조회
export const useGetRecallItem = (queryString = "", options) => {
  return useQuery(
    ["Recall", queryString],
    async () => {
      const data = await axios.get(
        `/recalls${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};
