import React from "react";
import produce from "immer";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import classNames from "classnames";

import { useHistory } from "react-router";

import "./Pazing.scss";

const MAX_PAGE_BUTTON_NUM = 9;

function PageButton({ pageNum, active, onClick }) {
  let name = "page";
  let content = "";

  switch (pageNum) {
    case "<":
      name = "back";
      content = (
        <>
          <RiArrowDropLeftLine />
        </>
      );
      break;
    case ">":
      name = "forward";
      content = (
        <>
          <RiArrowDropRightLine />
        </>
      );

      break;
    case "<<":
      name = "back_10";
      content = (
        <>
          <RiArrowDropLeftLine />
          <RiArrowDropLeftLine />
        </>
      );
      break;
    case ">>":
      name = "forward_10";
      content = (
        <>
          <RiArrowDropRightLine />
          <RiArrowDropRightLine />
        </>
      );

      break;
    default:
      if (!Number.isInteger(Number(pageNum))) name = "dots";

      content = <span>{String(pageNum).padStart(2, "0")}</span>;
      break;
  }

  const className = classNames("PageButton", {
    active: active,
  });

  return (
    <div
      className={className}
      data-page-num={pageNum}
      name={name}
      onClick={onClick}
    >
      {content}
    </div>
  );
}

function ControlPages({ pages, activePage, onClickPageButton }) {
  activePage = Number.isInteger(activePage) ? activePage : 1;
  const range = Math.max(
    5,
    MAX_PAGE_BUTTON_NUM % 2 ? MAX_PAGE_BUTTON_NUM : MAX_PAGE_BUTTON_NUM - 1,
  );
  const halfRange = Math.floor(range / 2);
  let startPageNum = Math.max(1, activePage - halfRange);
  let endPageNum = startPageNum + range - 1;
  let pageNumList = [];

  if (pages < endPageNum) {
    startPageNum = Math.max(1, startPageNum + (pages - endPageNum));
    endPageNum = pages;
  }

  pageNumList = Array.from(
    { length: endPageNum - startPageNum + 1 },
    (_, i) => startPageNum + i,
  );

  if (1 < startPageNum) {
    pageNumList.splice(0, 2, 1, "...");
  }

  if (endPageNum < pages) {
    pageNumList.splice(-2, 2, "...", pages);
  }

  const items = pageNumList.map((pageNum, i) => (
    <PageButton
      key={i}
      pageNum={pageNum}
      onClick={onClickPageButton}
      active={pageNum === activePage}
    />
  ));

  return (
    <div className="ControlPages">
      {pageNumList.length > 0 && (
        <>
          <PageButton pageNum="<<" onClick={onClickPageButton} />
          <PageButton pageNum="<" onClick={onClickPageButton} />
          {items}
          <PageButton pageNum=">" onClick={onClickPageButton} />
          <PageButton pageNum=">>" onClick={onClickPageButton} />
        </>
      )}
    </div>
  );
}

export default function GlobalControl({
  pages,
  searchQuery,
  setSearchQuery,
  onPageChange,
}) {
  const history = useHistory();

  const onClickPageButton = React.useCallback(
    (event) => {
      const name = event.target.name || event.target.getAttribute("name");
      const pageNum = event.target.dataset.pageNum;
      let pageTo = parseInt(pageNum);

      if (name !== "page") {
        switch (name) {
          case "back":
            pageTo = Math.max(1, searchQuery.page - 1);
            break;
          case "forward":
            pageTo = Math.min(pages, searchQuery.page + 1);
            break;
          case "back_10":
            pageTo = Math.min(pages, searchQuery.page - 10);
            break;
          case "forward_10":
            pageTo = Math.min(pages, searchQuery.page + 10);
            break;
          default:
            return;
            break;
        }
      }

      let nowURL = new URL(window.location.href);

      // for (let searchValue of nowURL.searchParams.entries()) {
      //   nowURL.searchParams.delete(searchValue[0]);
      // }

      nowURL.searchParams.set("page", pageTo);

      history.push("?" + new URLSearchParams(nowURL.search).toString());

      // Data?.onFilterSearch?.(filter);
      setSearchQuery(
        produce((draft) => {
          draft.page = pageTo;
        }),
      );
      onPageChange?.(pageTo);
    },
    [pages, searchQuery, setSearchQuery, onPageChange],
  );

  return (
    <div className="GlobalControl">
      <ControlPages
        pages={pages}
        activePage={searchQuery.page}
        onClickPageButton={onClickPageButton}
      />
    </div>
  );
}
