import React from "react";
import Button from "@material-ui/core/Button";
import { Radio, FormControlLabel } from "@material-ui/core";
import { IoIosSend } from "react-icons/io";
import produce from "immer";
import styled from "styled-components";

import "./dbStateManagement.scss";

const PROGRESS_TYPES = [
  "추후상담",
  "오후상담 예정",
  "저녁상담 예정",
  "전원오프",
  "결번",
  "중복",
  "전화주기로",
  "타치과치료",
  "관리환자",
  "전화상담거절",
  "상담 후 보류",
  "예약 취소",
  "관심없음",
  "통화중",
  "J.S",
  "비용부담",
  "착신정지",
  "허수",
  "문의한적없음",
  "문자관리",
  "부재1",
  "부재2",
  "부재3",
  "부재4",
  "부재5",
  "부재6",
  "검색안내",
  "계획없음",
];

export default function DbStateManagement({
  uiData,
  setUiData,
  updateVisitsItem,
  onClickApply,
  onClickVisitApply,
  isEdit,
}) {
  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const type = event.target.type;
      const classList = event.target.classList;
      const value = event.target.value;

      setUiData(
        produce((draft) => {
          draft.visit.status = value;
        }),
      );
    },
    [setUiData],
  );

  return (
    <div className="dbStateManagement">
      <div className="radioGroup">
        {PROGRESS_TYPES.map((typeName, i) => (
          <FormControlLabel
            key={i}
            value={typeName}
            label={typeName}
            control={
              <Radio
                checked={uiData.visit.status === typeName}
                onChange={onChange}
                onClick={(e) => {
                  if (e.target.checked) {
                    setUiData(
                      produce((draft) => {
                        draft.visit.status = "";
                      }),
                    );
                  }
                }}
                color="secondary"
                name="dbState"
                size="small"
              />
            }
          />
        ))}
      </div>

      {isEdit && (
        <Button
          variant="contained"
          color="secondary"
          className="applyButton"
          onClick={onClickVisitApply}
        >
          <IoIosSend size="1.6rem" />
          <span>적용</span>
        </Button>
      )}
    </div>
  );
}
