import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import NonVisitedTotalManagement from "./pages/nonVisitedTotalManagement/NonVisitedTotalManagement";
import TotalManagement from "./pages/totalManagement/TotalManagement";
import TotalManagementDetail from "./pages/totalManagementDetail/TotalManagementDetail";
import StatisticsManagementDay2Day from "./pages/statisticsManagementDay2Day/StatisticsManagementDay2Day";
import StatisticsManagement from "./pages/statisticsManagement/StatisticsManagement";
import StatisticsAgencyManagement from "./pages/statisticsAgencyManagement/StatisticsAgencyManagement";
import Sms from "./pages/sms/Sms";
import SmsSend from "./pages/sms/send/send";
import SmsList from "./pages/sms/list/sendList";
import SendRequest from "./pages/sms/sendRequest/sendRequest";

export default function Counselor() {
  return (
    <Switch>
      <Redirect from="/tm/" to="/tm/totalManagement" exact />
      <Route exact component={TotalManagement} path="/tm/totalManagement" />
      <Route exact component={TotalManagement} path={["/tm/totalManagement"]} />
      <Route
        exact
        component={NonVisitedTotalManagement}
        path={["/tm/nonVisitedCustomerManagement"]}
      />
      <Route
        exact
        component={TotalManagementDetail}
        path={["/tm/totalManagement/detail", "/tm/totalManagement/detail/:id"]}
      />
      <Redirect
        from="/tm/statisticsManagement/"
        to="/tm/StatisticsManagement/period"
        exact
      />

      <Route
        component={StatisticsManagementDay2Day}
        exact
        path="/tm/statisticsManagement/period"
      />
      <Route
        component={StatisticsManagement}
        exact
        path="/tm/statisticsManagement/monthly"
      />

      <Route
        component={StatisticsAgencyManagement}
        exact
        path="/tm/statisticsManagement/agency/monthly"
      />

      <Route component={SmsSend} exact path="/tm/sms" />
      <Route component={SmsSend} exact path="/tm/sms/send" />
      <Route component={SmsList} exact path="/tm/sms/list" />
      <Route component={SendRequest} exact path="/tm/sms/sendRequest" />
    </Switch>
  );
}
