import React from "react";
import { useQueryClient } from "react-query";

import useAlert from "~hooks/useAlert";
import useAuth from "~hooks/useAuth";

/*
 * 오류 메시지
 *
 * {
 *   [errorID]: 'errorMessage'
 * }
 *
 * 값에 {message}라는 placeholder를 사용시 message가 있을 경우 mesasge로 변환
 */
export const messages = {
  // strapi
  "Auth.form.error.email.provide": "아이디(이메일)를 입력해주세요",
  "Auth.form.error.password.provide": "비밀번호를 입력해주세요",
  "Auth.form.error.invalid": "아이디 또는 비밀번호가 올바르지 않습니다",
  "Auth.form.error.confirmed": "이메일 인증이 완료되지 않았습니다",
  "Auth.form.error.blocked": "차단된 계정입니다",
  "Auth.form.error.password.local":
    "간편 로그인으로 생성된 계정입니다\n계정 생성시 사용한 간편 로그인을 사용해주세요",
  "provider.disabled":
    "해당 간편 로그인은 비활성화된 상태입니다\n관리자에게 문의해주세요",
  "Auth.form.error.code.provide": "코드가 올바르지 않습니다",
  "Auth.form.error.password.matching": "비밀번호가 일치하지 않습니다",
  "Auth.form.error.params.provide": "올바르지 않은 데이터를 전달받았습니다",
  "Auth.form.error.email.format": "이메일 형식이 올바르지 않습니다",
  "Auth.advanced.allow_register":
    "회원가입은 현재 비활성화된 상태입니다\n관리자에게 문의해주세요",
  "Auth.form.error.password.format":
    "비밀번호는 '$'기호를 3개 이상 포함 할 수 없습니다",
  "Auth.form.error.role.notFound":
    "기본 역할을 찾을 수 없습니다\n관리자에게 문의해주세요",
  "Auth.form.error.email.taken": "이미 사용중인 아이디입니다",
  "Auth.form.error.username.taken": "이미 사용중인 이름입니다",
  "Auth.form.error.user.not-exist": "해당 이메일은 존재하지 않습니다",
  "Auth.form.error.not.dorm":
    "배정된 고시원이 없습니다\n관리자에게 문의해주세요",
  // custom
  "Enter.term.error": "{message}",
};

export const throwErrorMessage = (errorID, message) => {
  throw {
    customErrorResponse: {
      id: errorID,
      ...(message ? { message } : {}),
    },
  };
};

const replacePlaceholderMessage = (string, message) => {
  return String(string).replace(/\{message\}/g, message || "");
};

export default function ApiErrorMessage() {
  const queryClient = useQueryClient();
  const { openAlert } = useAlert();
  const { signOut } = useAuth();

  const handleErrorAlert = React.useCallback((error) => {
    if (!error) {
      openAlert("서버가 응답하지 않습니다");
      return false;
    }

    if (error.customErrorResponse) {
      error = error.customErrorResponse;

      const message = replacePlaceholderMessage(
        messages[error.id],
        error.message,
      );

      openAlert(message, "요청 오류");

      return false;
    }

    switch (error.status) {
      case 400:
        // strapi validation error message
        if (error.data.message === "ValidationError") {
          openAlert(
            "잘못된 요청입니다\n입력하신 값들이 올바른지 확인해주세요",
            "요청 오류",
          );

          console.error(
            "[Strapi] ValidationError",
            error.data.data.errors,
            error,
          );

          break;
        }

        // strapi bad request message
        if (
          Array.isArray(error.data.message) &&
          Array.isArray(error.data.message[0].messages)
        ) {
          const errorItem = error.data.message[0].messages[0];
          const message = replacePlaceholderMessage(
            messages[errorItem.id],
            errorItem.message,
          );

          openAlert(message, "요청 오류");

          console.error("[Strapi] Bad Request", error);

          break;
        }

        // etc error
        openAlert("잘못된 요청입니다", "요청 오류");
        console.error("[Strapi] Bad Request", error);
        break;

      case 401:
        signOut();
        openAlert(
          "인증 정보가 올바르지 않습니다\n자동으로 로그아웃 처리됩니다",
          "요청 오류",
        );
        break;

      case 403:
        const token =
          window.localStorage["user.token"] ||
          window.sessionStorage["user.token"];

        openAlert(token ? "접근 권한이 없습니다" : "로그인 후 이용해주세요");
        break;

      case 429:
        openAlert(
          "너무 많은 요청을 보냈습니다\n잠시 후 다시 시도해주세요",
          "요청 오류",
        );
        break;

      default:
        openAlert("알수없는 오류가 발생했습니다");
        console.log(error);
        break;
    }
  }, []);

  queryClient.setDefaultOptions({
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      onError: handleErrorAlert,
    },

    mutations: {
      onError: handleErrorAlert,
    },
  });

  return null;
}
