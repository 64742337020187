import React from "react";
import produce from "immer";
import { Link, useLocation } from "react-router-dom";
import TextInput from "~ui/textInput/TextInput";
import SearchBar from "~/counselor/components/counseling/searchBar/SearchBar";
import CounselingView from "~/counselor/components/counseling/counselingView/CounselingView";
// import { useGetCSList } from "~apis/counselors";
import styled from "styled-components";
import { useGetHeadList } from "~apis/head";
import { useGetDoctorList } from "~apis/doctors";

import Icon from "@material-ui/core/Icon";
import SelectInput from "~ui/selectInput/SelectInput";
import { MenuItem } from "@material-ui/core";
import Pazing from "~/global/components/tamplete/pazing/Pazing";
import ControlsButton from "~/global/components/tamplete/controlsbutton/ControlsButton";
import Table from "~/global/components/tamplete/table/table";

import instanceAxios from "~apis/_util";
import { OutTable, ExcelRenderer } from "react-excel-renderer";

import {
  useGetVisitsList,
  useUpdateVisitsItem,
  useDeleteVisits,
  useGetVisitsListCount,
} from "~apis/visits";

import { useDeleteCounseling } from "~apis/counselings";

import qs from "qs";
import moment from "moment";

import "./customer_reservation.scss";

const Div = styled.div`
  .counseling {
    .searchBar {
      > div {
        > span {
          font-size: 1rem;
          white-space: nowrap;
        }
      }

      .startDateInput,
      .endDateInput {
        width: 5rem !important;
      }
    }

    .searchBar > div:nth-child(n + 2) {
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }
`;

export default function Customer_reservation() {
  const location = useLocation();
  const { sub } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [filter, setFilter] = React.useState({});

  const [queryString, setQueryString] = React.useState("");
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState({
    counselorName: "",
    patientName: "",
    chartNumber: "",
    doctor: "",
    startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    page: 1,
    startCost: 0,
    endCost: 9999999999999999,
    sub: sub,
  });
  const [sort, setSort] = React.useState({ field: "내원날짜", dir: "desc" });

  const doctorList = useGetDoctorList();
  const headList = useGetHeadList();
  const visitsList = useGetVisitsList(
    qs.stringify({ ...qs.parse(queryString), filter, sort, q_sub: sub }),
  );
  const visitsListCount = useGetVisitsListCount(
    qs.stringify({ ...qs.parse(queryString), filter, sort, q_sub: sub }),
  );

  const { mutateAsync: updateVisitsItem } = useUpdateVisitsItem();
  const { mutateAsync: deleteVisits } = useDeleteVisits();

  const isFetching =
    headList.isFetching || visitsList.isFetching || visitsListCount.isFetching;
  const isError =
    headList.isError || visitsList.isError || visitsListCount.isError;
  const pages = Math.ceil(visitsListCount.data / 20);
  let value = [];

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_counselorName: searchQuery.counselorName,
        q_patientName: searchQuery.patientName,
        q_chartNumber: searchQuery.chartNumber,
        q_startDate: searchQuery.startDate,
        q_endDate: searchQuery.endDate,
        q_page: searchQuery.page,
        q_startCost: searchQuery.startCost || 0,
        q_endCost: searchQuery.endCost || 9999999999999999,
        q_sub: searchQuery.sub,
      }),
    );
  }, [sub]);

  const onClickSearch = React.useCallback(() => {
    setQueryString(
      qs.stringify({
        q_counselorName: searchQuery.counselorName,
        q_patientName: searchQuery.patientName,
        q_chartNumber: searchQuery.chartNumber,
        q_startDate: searchQuery.startDate,
        q_endDate: searchQuery.endDate,
        q_page: searchQuery.page,
        q_startCost: searchQuery.startCost || 0,
        q_endCost: searchQuery.endCost || 9999999999999999,
        q_sub: searchQuery.sub,
      }),
    );
    visitsList.refetch();
  }, [searchQuery, setQueryString, visitsList]);

  const onPageChange = React.useCallback(
    (page) => {
      setQueryString(
        qs.stringify({
          q_counselorName: searchQuery.counselorName,
          q_patientName: searchQuery.patientName,
          q_chartNumber: searchQuery.chartNumber,
          q_startDate: searchQuery.startDate,
          q_endDate: searchQuery.endDate,
          q_page: page,
          q_sub: searchQuery.sub,
        }),
      );
      visitsList.refetch();
    },
    [searchQuery, setQueryString, visitsList],
  );

  const onSelectChange = React.useCallback(
    async (event) => {
      const id = parseInt(event.target.name);
      const headName = event.target.value;
      const head = headList.data.find((x) => x.name === headName);

      if (id) {
        await updateVisitsItem([id, { head: head ? head.id : null }]);
        visitsList.refetch();
      }
    },
    [visitsList, headList, updateVisitsItem],
  );

  const counselorNameList = (headList.data || []).map((head, i) => (
    <MenuItem style={{ fontSize: "1rem" }} key={i} value={head.name}>
      {head.name}
    </MenuItem>
  ));

  const doctorNameList = (doctorList.data || []).map((x) => (
    <MenuItem value={x.name}>{x.name}</MenuItem>
  ));

  const TableTitle = [
    "$filterButton",
    "수정",
    "내원날짜",
    "차트번호",
    "상태",
    "이름",
    "지역",
    "전화번호",
    "나이",
    "내원경로",
    "상담총액",
    "실장",
    "Doctor",
    "치료진행여부",
    "Event DB",
    "상담항목",
  ];

  const TableData =
    isFetching || isError
      ? []
      : (visitsList.data || []).map((x) => {
          return [
            {
              type: "input",
              typeOption: "checkbox",
              name: "id",
              value: x.id,
              checked: checkedItems.includes(x.id),
              onClick: (event) => {
                setCheckedItems(
                  produce((draft) => {
                    const checked = event.target.checked;
                    if (checked && !draft.includes(x.id)) {
                      draft.push(x.id);
                    } else if (!checked && draft.includes(x.id)) {
                      draft.splice(draft.indexOf(x.id), 1);
                    }
                  }),
                );
              },
            },
            <td style={{ textAlign: " center", paddingRight: "12px" }}>
              <Link to={`/counselor/counseling/detail/${x.id}`}>
                <Icon>settings</Icon>
              </Link>
            </td>,
            x.visitDate ? moment(x.visitDate).format("YYYY. MM. DD") : "-",
            x.chartNumber ? x.chartNumber : "-",
            x.isRepeatVisit ? "구환" : "신환",
            x.name || "-",
            x?.Address1 || "-",
            x.phone || "-",
            x.age || "-",
            x.InflowRouteDetail || "-",
            x.counselingTotal === 0
              ? "-"
              : x.counselingTotal?.toLocaleString?.(),
            <SelectInput
              className="selectType"
              name={String(x.id)}
              value={x.head_name || ""}
              onChange={onSelectChange}
              placeholder="--"
              style={{ marginTop: "0.8rem" }}
            >
              {counselorNameList}
            </SelectInput>,
            x.doctor_name || "-",
            x.cure || "-",
            x.isEventVisit ? "O" : "X",
            x.counseling,
          ];
        });

  const handleFilterSearch = (value) => {
    setFilter(value);
  };
  const handleSort = (value) => {
    setSort(value);
  };
  //

  const counselingDeleteChecked = React.useCallback(async () => {
    if (!checkedItems.length) {
      return;
    }
    for (const counselId of checkedItems) {
      await deleteVisits(counselId);
    }
    visitsList.refetch();
  }, [checkedItems, visitsList, deleteVisits]);

  //

  const counselingExcelBtn = React.useCallback(async () => {
    const files = await (await window.showOpenFilePicker())[0].getFile();

    const getText = (arr) => {
      return arr.filter((item) => Boolean(item[0]))?.[0]?.[1] || null;
    };

    const getCheckboxValue = (arr) => {
      return arr.map((item) => Number(Boolean(item))).join("");
    };

    const getCost = (value) => {
      return (isNaN(Number(value)) ? 0 : Number(value)) * 10000;
    };

    ExcelRenderer(files, async (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        const data = resp.rows
          .slice(2)
          .filter((item) => item[0] || item[1] || item[3] || item[4])
          .map((item) => {
            const isInsurance = String(item[10]).includes("보험");

            if (true) {
            }

            return {
              visitDate: item[0],
              chartNumber: item[1],
              isRepeatVisit: Boolean(item[2]),
              name: item[3],
              phone: item[4],
              inflowRoute: item[5],
              referralPatientIndex: item[6],
              referralPatientVisitRoute: item[7],
              cc: item[8],
              counselingContent: item[9],
              counselingTotal: getCost(item[10]),
              payment: getCost(item[11]),
              head: item[12],
              doctor: item[13],
              cure: isInsurance ? "보험비용" : item[14],
              stopMemo: item[15],
              address1: item[16],
              address2: item[17],
              counselingResult: getText([
                [item[18], "미진행"],
                [item[19], "진행"],
                [item[20], "보험"],
              ]),
              counselingInfo: getCheckboxValue([
                item[21],
                item[22],
                item[23],
                item[24],
              ]),
              progressInfo: getCheckboxValue([
                item[25],
                item[26],
                item[27],
                item[28],
                item[29],
              ]),
              type: "head",
            };
          });

        await instanceAxios.post("/visits/counsel_excel_upload", data);

        visitsList.refetch();
        // document.querySelector(`#TMSearchBtn`).click();
      }
    });
  });

  return (
    <div>
      <div className="counseling">
        <span className="viewTitle">상담환자 DB</span>
        {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
        {!isFetching && !isError && (
          <>
            <SearchBar
              {...{ searchQuery, setSearchQuery, onClickSearch, headList }}
              subFields={
                <div style={{ marginRight: "1rem" }}>
                  <TextInput
                    className="patientNameInput"
                    name="startCost"
                    placeholder="상담총액"
                    onChange={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        startCost: e.target.value,
                      });
                    }}
                    style={{ width: "8.1rem", height: "2.68rem" }}
                  />
                  <span style={{ margin: "0 .6rem" }}>~</span>
                  <TextInput
                    className="patientNameInput"
                    name="endCost"
                    placeholder="상담총액"
                    onChange={(e) => {
                      setSearchQuery({
                        ...searchQuery,
                        endCost: e.target.value,
                      });
                    }}
                    style={{ width: "8.1rem", height: "2.68rem" }}
                  />
                </div>
              }
            />

            <Table
              count={visitsListCount.data}
              value={value}
              head={counselorNameList}
              doctor={doctorNameList}
              onFilterSearch={handleFilterSearch}
              onSort={handleSort}
              sort={[sort.field, sort.dir]}
              {...{
                TableTitle,
                TableData,
                isFetching,
                setCheckedItems,
                checkedItems,
                useFilter: true,
              }}
            />
            <div className="Buttons">
              <Pazing
                pages={pages}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onPageChange={onPageChange}
              />
              {/* <ControlsButton
                ButtonFreeSet="CounselingPage"
                type="counselor"
                refetch={visitsList.refetch}
                {...{
                  counselingDeleteChecked,
                  counselingExcelBtn,
                }}
              /> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
