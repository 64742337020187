import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

// axios.defaults.headers.common["Authorization"] = "jwt";
// 채널 추가
export const useAddChannel = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/Channels", data);

      return res;
    },
    {
      loadingMessage: "채널 정보를 추가 중입니다",
      successMessage: "채널이 추가되었습니다",
    },
  );
};

// 채널 삭제
export const useDeleteChannel = () => {
  return useLoadingMutation(
    async (channelsID) => {
      const res = await axios.delete(`/Channels/${channelsID}`);

      return res;
    },
    {
      loadingMessage: "채널 삭제 중입니다",
      successMessage: "채널이 삭제 되었습니다",
    },
  );
};

// 채널 수정
export const useUpdateChannel = (channelsID) => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.put(`/Channels/${channelsID}`, data);
      return res;
    },
    {
      loadingMessage: "채널 정보를 수정 중입니다",
      successMessage: "채널이 수정되었습니다",
    },
  );
};

// Channel 아이템 조회
export const useGetChannelItem = (queryString = "", options) => {
  return useQuery(
    ["Channels", queryString],
    async () => {
      const data = await axios.get(
        `/Channels${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Channel 목록 조회
export const useGetChannelList = (queryString = "", options) => {
  return useQuery(
    ["Channels", queryString],
    async () => {
      const data = await axios.get(
        `/Channels${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

export const useGetAllChannelList = (queryString = "", options) => {
  return useQuery(
    ["Channels", queryString],
    async () => {
      const data = await axios.post(
        `/Channels/allData${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Channel 목록 아이템 수 조회
export const useGetChannelListCount = (queryString = "", options) => {
  return useQuery(
    ["ChannelsCount", queryString],
    async () => {
      const data = await axios.get(
        `/Channels/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Channel 평가 조회
export const useGetChannelEvaluation = (queryString = "", options) => {
  return useQuery(
    ["ChannelEvaluation", queryString],
    async () => {
      const data = await axios.get(
        `/Channels/evaluation${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 실장팀에서 사용
// Channel 평가 조회
export const useGetChannelEvaluation2 = (queryString = "", options) => {
  return useQuery(
    ["ChannelEvaluation2", queryString],
    async () => {
      const data = await axios.get(
        `/Channels/evaluation_ver2${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};

// Channel 평가 조회
export const useGetAgencyEvaluation = (queryString = "", options) => {
  return useQuery(
    ["AgencyEvaluation", queryString],
    async () => {
      const data = await axios.get(
        `/Channels/agencyEvaluation${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};

// Channel 평가 조회 실장팀
export const useGetAgencyEvaluation2 = (queryString = "", options) => {
  return useQuery(
    ["AgencyEvaluation_ver2", queryString],
    async () => {
      const data = await axios.get(
        `/Channels/agencyEvaluation_ver2${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};

// 중복 체크
export const isCheckFn = (array, key) => {
  let check = [];
  return array.filter((x) => {
    if (check.includes(x[key])) {
      return;
    }

    check.push(x[key]);

    return x;
  });
};
