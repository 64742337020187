import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

// Visits 추가
export const useAddVisits = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/Visits   ", data);

      return res;
    },
    {
      loadingMessage: "DB 정보를 추가 중입니다",
      successMessage: "DB 정보가 추가되었습니다",
    },
  );
};

// Visits 삭제
export const useDeleteVisits = () => {
  return useLoadingMutation(
    async (VisitsID) => {
      const res = await axios.delete(`/visits/${VisitsID}`);

      return res;
    },
    {
      loadingMessage: "DB 삭제 중입니다",
      successMessage: "DB가 삭제 되었습니다",
    },
  );
};

// Visits 수정
export const useUpdateVisits = (VisitsID) => {
  return useLoadingMutation(
    async (data) => {
      const idx = data?.idx ? data.idx : VisitsID;
      delete data.idx;

      const res = await axios.put(`/visits/${idx}`, data);
      return res;
    },
    {
      loadingMessage: "DB 정보를 수정 중입니다",
      successMessage: "DB가 수정되었습니다",
    },
  );
};

// Visits 아이템 조회
export const useGetVisitsItem = (queryString = "", options) => {
  return useQuery(
    ["Visits", queryString],
    async () => {
      const data = await axios.get(
        `/visits${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

export const useGetStatisticsManagement = (queryString = {}, options) => {
  return useQuery(
    ["Visits", queryString],
    async () => {
      const data = await axios.post(
        `/visits/statisticsManagement`,
        queryString,
      );

      return data;
    },
    options,
  );
};

// Visits 아이템 조회
export const useGetCounseling = (query = "", options) => {
  return useQuery(
    ["Visits", query],
    async () => {
      const data = await axios.post(`/visits/counselingSearchBoard`, query);
      return data;
    },
    options,
  );
};

export const useGetVisitsPeriod = (queryString = "", options) => {
  return useQuery(
    ["VisitsPeriod", queryString],
    async () => {
      const data = await axios.get(
        `/visits/period${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Visits 아이템 조회
export const useGetVisitsCount = (queryString = "") => {
  return useQuery(["visits/cnt", queryString], async () => {
    const data = await axios.get(
      `/visits/count${queryString.length ? `?${queryString}` : ""}`,
    );

    return data;
  });
};

export const useGetVisitsTotal = (queryString = "") => {
  return useQuery(["visits/tm/total", queryString], async () => {
    const data = await axios.get(
      `/visits/tm/total${queryString.length ? `?${queryString}` : ""}`,
    );
    return data;
  });
};

// 목록 조회
export const useGetVisitsList = (queryString = "", options) => {
  return useQuery(
    ["VisitsList", queryString],
    async () => {
      const data = await axios.get(
        `/visits/list${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 조회
export const useGetVisitsListTm = (queryString = "", options) => {
  return useQuery(
    ["VisitsListTm", queryString],
    async () => {
      const data = await axios.get(
        `/visits/list_tm${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 아이템 수 조회
export const useGetVisitsListCount = (queryString = "", options) => {
  return useQuery(
    ["VisitsListCount", queryString],
    async () => {
      const data = await axios.get(
        `/visits/list/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 아이템 수 조회
export const useGetVisitsListTmCount = (queryString = "", options) => {
  return useQuery(
    ["VisitsListTmCount", queryString],
    async () => {
      const data = await axios.get(
        `/visits/list_tm/count${queryString.length ? `?${queryString}` : ""}`,
      );
      return data;
    },
    options,
  );
};

export const useGetVisitsTodayCount = (queryString = "", options) => {
  return useQuery(
    [queryString],
    async () => {
      const data = await axios.get(`/visits/list_tm/today_count`);

      const data2 = await axios.get(`/visits/head/today_count`);

      return {
        tm: data,
        head: data2,
      };
    },
    options,
  );
};

export const useUpdateVisitsItem = () => {
  return useLoadingMutation(
    async ([visitsID, data]) => {
      const res = await axios.put(`/visits/${visitsID}`, data);
      return res;
    },
    {
      loadingMessage: "DB 정보를 수정 중입니다",
      successMessage: "DB가 수정되었습니다",
    },
  );
};

export const useAddVisitsLog = () => {
  return useLoadingMutation(async (data) => {
    const res = await axios.post(`/visits-logs`, data);
    return res;
  });
};

// 아이템 조회
export const useGetVisitDetailHead = (id, options) => {
  return useQuery(
    ["VisitDetailHead", id],
    async () => {
      if (!id) {
        return null;
      }

      const data = await axios.get(`/visits/detail_head${id ? `/${id}` : ""}`);
      return data;
    },
    options,
  );
};

// 아이템 조회
export const useGetVisitDetailTm = (id, options) => {
  return useQuery(
    ["VisitDetailTm", id],
    async () => {
      if (!id) {
        return null;
      }

      const data = await axios.get(`/visits/detail_tm${id ? `/${id}` : ""}`);
      return data;
    },
    options,
  );
};
