import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

// 추가
export const useAddAppearDoctor = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/appear-doctors", data);

      return res;
    },
    {
      loadingMessage: "방송 정보를 추가 중입니다",
      successMessage: "방송이 추가되었습니다",
    },
  );
};

// 삭제
export const useDeleteAppearDoctor = () => {
  return useLoadingMutation(
    async (appearDoctorID) => {
      const res = await axios.delete(`/appear-doctors/${appearDoctorID}`);

      return res;
    },
    {
      loadingMessage: "방송 삭제 중입니다",
      successMessage: "방송이 삭제 되었습니다",
    },
  );
};

// 수정
export const useUpdateAppearDoctor = () => {
  return useLoadingMutation(
    async ([appearDoctorID, data]) => {
      const res = await axios.put(`/appear-doctors/${appearDoctorID}`, data);
      return res;
    },
    {
      loadingMessage: "방송 정보를 수정 중입니다",
      successMessage: "방송이 수정되었습니다",
    },
  );
};

// 아이템 조회
export const useGetAppearDoctorItem = (queryString = "", options) => {
  return useQuery(
    ["Channels", queryString],
    async () => {
      const data = await axios.get(
        `/appear-doctors${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 조회
export const useGetAppearDoctorList = (queryString = "", options) => {
  return useQuery(
    ["AppearDoctors", queryString],
    async () => {
      const data = await axios.get(
        `/appear-doctors${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 아이템 수 조회
export const useGetAppearDoctorListCount = (queryString = "", options) => {
  return useQuery(
    ["AppearDoctorsCount", queryString],
    async () => {
      const data = await axios.get(
        `/appear-doctors/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};
