import React from "react";
import { useHistory } from "react-router-dom";
import { Radio, Button, FormControlLabel } from "@material-ui/core";
import produce from "immer";
import DatePicker from "~ui/datePicker/DatePicker";
import { FaPlus } from "react-icons/fa";
import classNames from "classnames";
import {
  useAddAppearDoctor,
  useDeleteAppearDoctor,
  useUpdateAppearDoctor,
} from "~apis/appearDoctors";
import moment from "moment";
import useAlert from "~hooks/useAlert";

import "./broadcastList.scss";

const HEADER_COLUMNS = ["방송날짜", "원장님 추가"];

function BroadcastListHeader() {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  return <div className="broadcastListHeader">{items}</div>;
}

function BroadcastListItem({
  data,
  doctors,
  onRadioChange,
  onDatePickerChange,
  createNewBroadcast,
  enableEditMode,
  applyItem,
  deleteItem,
  isLast,
  urlGet,
  idx,
}) {
  const selectDate = (
    <DatePicker
      value={moment(urlGet ? "2015-01" : data.broadcastingDate).format(
        "YYYY-MM",
      )}
      // value={moment(data.broadcastingDate).format("YYYY-MM")}
      name={String(idx)}
      onChange={onDatePickerChange}
    />
  );

  const selectDoctor = (
    <div className="radioGroup">
      {doctors.map((doctor, i) => (
        <div key={i}>
          <FormControlLabel
            value={doctor.id}
            label={doctor.name}
            control={
              <Radio
                checked={doctor.id === data.doctor}
                onChange={onRadioChange}
                color="secondary"
                name={String(idx)}
                size="small"
              />
            }
          />
        </div>
      ))}
    </div>
  );

  const editmenu = data.editMode ? (
    <div className="editMenu">
      <Button
        variant="contained"
        color="primary"
        className="addButton"
        onClick={() => applyItem(idx)}
      >
        <span>등록</span>
      </Button>
    </div>
  ) : (
    <div className="editMenu">
      <Button
        variant="contained"
        color="primary"
        className="editButton"
        onClick={() => enableEditMode(idx)}
      >
        <span>수정</span>
      </Button>
      {/* <Button
        variant="contained"
        color="primary"
        className="deleteButton"
        onClick={() => deleteItem(idx)}
      >
        <span>삭제</span>
      </Button> */}
    </div>
  );

  const newBroadcast = isLast ? (
    <div className="newBroadcast">
      <Button
        variant="contained"
        color="primary"
        className="addButton"
        onClick={createNewBroadcast}
      >
        <span>출연 원장 추가</span>
        <FaPlus size="0.6rem" />
      </Button>
    </div>
  ) : (
    ""
  );

  const className = classNames("broadcastListItem", {
    editMode: data.editMode,
  });

  return (
    <div className={className}>
      {/* <span>{idx + 1}차</span> */}
      {selectDate}
      {selectDoctor}
      {editmenu}
      {/* {newBroadcast} */}
    </div>
  );
}

export default function BroadcastList({
  uiData,
  setUiData,
  doctorList,
  addBroadcasting,
  urlGet,
}) {
  const history = useHistory();
  const { openAlert } = useAlert();

  const { mutateAsync: addAppearDoctor } = useAddAppearDoctor();
  const { mutateAsync: deleteAppearDoctor } = useDeleteAppearDoctor();
  const { mutateAsync: updateAppearDoctor } = useUpdateAppearDoctor();

  const onDatePickerChange = React.useCallback(
    (event) => {
      const idx = Number(event.target.name);
      const value = event.target.value;
      setUiData(
        produce((draft) => {
          draft.appearDoctors[idx].broadcastingDate = value;
        }),
      );
    },
    [setUiData],
  );

  const onRadioChange = React.useCallback(
    (event) => {
      const idx = Number(event.target.name);
      const doctor = event.target.value;
      setUiData(
        produce((draft) => {
          draft.appearDoctors[idx].doctor = Number(doctor);
        }),
      );
    },
    [setUiData],
  );

  const createNewBroadcast = React.useCallback(
    (event) => {
      setUiData(
        produce((draft) => {
          if (draft.appearDoctors.every((x) => !x.editMode)) {
            draft.appearDoctors.push({
              broadcasting: draft.id,
              broadcastingDate: moment().format("YYYY-MM"),
              doctor: null,
              editMode: true,
            });
          }
        }),
      );
    },
    [setUiData],
  );

  const enableEditMode = React.useCallback(
    (idx) => {
      setUiData(
        produce((draft) => {
          if (draft.appearDoctors.every((x) => !x.editMode)) {
            draft.appearDoctors[idx].editMode = true;
          }
        }),
      );
    },
    [setUiData],
  );

  const applyItem = React.useCallback(
    async (idx) => {
      if (!uiData.channel.id) {
        openAlert("채널을 선택해야 합니다");
        return;
      }

      const item = uiData.appearDoctors[idx];
      let broadcastingId = uiData.id;
      let newBroadcasting = false;
      if (!broadcastingId) {
        const newData = produce(uiData, (draft) => {
          draft.channel =
            draft.channel && draft.channel.id ? draft.channel.id : null;
          delete draft.id;
          delete draft.appearDoctors;
        });

        const result = await addBroadcasting(newData);
        broadcastingId = result.id;
        newBroadcasting = true;
        setUiData({ ...uiData, id: broadcastingId });
      }

      if (item) {
        if (item.id) {
          await updateAppearDoctor([
            item.id,
            {
              broadcastingDate: moment(item.broadcastingDate).format(
                "YYYY-MM-DD",
              ),
              doctor: Number(item.doctor),
            },
          ]);
        } else {
          const result = await addAppearDoctor({
            broadcastingDate: moment(item.broadcastingDate).format(
              "YYYY-MM-DD",
            ),
            doctor: Number(item.doctor),
            broadcasting: broadcastingId,
          });
          setUiData(
            produce((draft) => {
              const draftItem = draft.appearDoctors[idx];
              draftItem.id = result.id;
              draftItem.doctor = result.doctor.id;
            }),
          );
        }

        setUiData(
          produce((draft) => {
            const draftItem = draft.appearDoctors[idx];
            draftItem.editMode = false;
          }),
        );
      }

      if (newBroadcasting) {
        history.push(`/channel/addChannel/detail/${broadcastingId}`);
      }
    },
    [
      uiData,
      setUiData,
      history,
      addAppearDoctor,
      updateAppearDoctor,
      addBroadcasting,
      openAlert,
    ],
  );

  const deleteItem = React.useCallback(
    async (idx) => {
      if (uiData.appearDoctors.length <= 1) {
        return;
      }
      const id = uiData.appearDoctors[idx].id;

      if (id) {
        await deleteAppearDoctor(id);
      }

      setUiData(
        produce((draft) => {
          draft.appearDoctors.splice(idx, 1);
        }),
      );
    },
    [uiData, setUiData, deleteAppearDoctor],
  );

  const items = uiData.appearDoctors.map((data, i, array) => (
    <BroadcastListItem
      key={i}
      data={data}
      doctors={doctorList.data}
      onRadioChange={onRadioChange}
      onDatePickerChange={onDatePickerChange}
      createNewBroadcast={createNewBroadcast}
      enableEditMode={enableEditMode}
      applyItem={applyItem}
      deleteItem={deleteItem}
      isLast={i === array.length - 1}
      urlGet={urlGet}
      idx={i}
    />
  ));

  return (
    <div className="broadcastList">
      <BroadcastListHeader />
      {items}
    </div>
  );
}
