import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useGetVisitsTodayCount } from "~apis/visits";

import "./sideNavigationItem.scss";

export default function SideNavigationItem({ icon, text, to }) {
  const history = useHistory();
  const access = window.localStorage.getItem("user.access");

  const { data } = useGetVisitsTodayCount();

  let subItems = [];
  if (text instanceof Array) {
    if (!access.includes("외부입력")) {
      subItems = text.slice(1);
    }
    text = text[0];
  }

  return (
    <div className="sideNavigationItem">
      <NavLink to={to}>
        {icon} {text}
      </NavLink>
      {subItems.map((subItem, i) => (
        <div
          key={i}
          className="subItem"
          style={{ position: "relative" }}
          onClick={() => {
            history.push(
              to + (subItem[2] ? `/${subItem[1]}` : `?sub=${subItem[1]}`),
            );
          }}
        >
          {subItem[0]}
          {(data?.tm || 0) > 0 && subItem[1] == "today" ? (
            <>
              <span
                style={{
                  width: "1rem",
                  height: "1rem",
                  backgroundColor: "red",
                  position: "absolute",
                  top: "0rem",
                  display: "flex",
                  borderRadius: "25px",
                  fontSize: "0.75rem",
                  right: "3.4rem",
                  lineHeight: "1",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                }}
              >
                {data.tm}
              </span>
            </>
          ) : (
            <></>
          )}

          {subItem[0] === "- 금일 재상담 환자DB" && (data?.head || 0) > 0 && (
            <span
              style={{
                width: "1rem",
                height: "1rem",
                backgroundColor: "red",
                position: "absolute",
                top: "0rem",
                display: "flex",
                borderRadius: "25px",
                fontSize: "0.75rem",
                right: "1rem",
                lineHeight: "1",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
              }}
            >
              {data.head}
            </span>
          )}
        </div>
      ))}
    </div>
  );
}
