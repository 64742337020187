import React from "react";
import ToolsItem from "./toolsItem/ToolsItem";
import { AiFillInfoCircle } from "react-icons/ai";
import { IoSettingsSharp } from "react-icons/io5";

import "./tools.scss";

export default function Tools() {
  return (
    <div className="tools">
      <ToolsItem icon={<AiFillInfoCircle size="1.75rem" />} text="Support" />
      <ToolsItem icon={<IoSettingsSharp size="1.75rem" />} text="Settings" />
    </div>
  );
}
