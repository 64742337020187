import React from "react";
import { Doughnut } from "react-chartjs-2";
import { chartColors } from "~utils/chart";
import { theme } from "~global/assets/js/theme";

import "./statisticsChart_1.scss";
import { constSelector } from "recoil";

function StatisticsSummary({ evaluationData }) {
  const contractAmount = evaluationData.list.reduce(
    (pre, cur) => (cur.broadcasting_progress ? pre + cur.total_sales : pre),
    0,
  );
  const counselingAmount = evaluationData.list.reduce(
    (pre, cur) =>
      cur.broadcasting_progress ? pre + cur.total_counselling_sales : pre,
    0,
  );

  return (
    <div className="statisticsSummary">
      <div className="summaryRow">
        <div className="summaryItem">
          <div>
            <span>{evaluationData.month}월 계약금액</span>
          </div>
          <div>
            <span>{contractAmount.toLocaleString("ko-KR")}원</span>
          </div>
        </div>
      </div>
      <div className="summaryRow">
        <div className="summaryItem">
          <div>
            <span>{evaluationData.month}월 상담금액</span>
          </div>
          <div>
            <span>{counselingAmount.toLocaleString("ko-KR")}원</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatisticsChart_1({ evaluationData }) {
  const statistics = evaluationData.list
    .map((x) => [
      x.channelName,
      x.total_counselling_sales || 0,
      x.total_sales || 0,
      x.broadcasting_progress,
    ])
    .filter((x) => x[1] && x[3] === 1);

  const doughnutChartData_1 = {
    datasets: [
      {
        data: statistics.map((x) => x[2]),
        backgroundColor: chartColors(statistics.length),
      },
    ],
    labels: statistics.map((x) => x[0]),
  };

  const doughnutChartData_2 = {
    datasets: [
      {
        data: statistics.map((x) => x[1]),
        backgroundColor: chartColors(statistics.length),
      },
    ],
    labels: statistics.map((x) => x[0]),
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      /* title: {
        display: true,
        text: '채널별 계약금액',
        position: 'top',
        align: 'start',
        fullSize: true,
        color: '#375ca4',
        font: {
          size: '16px',
          family: 'SDGothic'
        }
      }, */
      legend: {
        position: "right",
        labels: {
          color: theme.palette.primary,
          boxWidth: 12,
          boxHeight: 12,
          font: {
            weight: "bold",
          },
        },
      },
    },
  };

  return (
    <div className="statisticsChart_1">
      <StatisticsSummary {...{ evaluationData }} />
      <div className="doughnutChart_1">
        <span>채널별 계약금액</span>
        <Doughnut data={doughnutChartData_1} options={options} />
      </div>
      <div className="doughnutChart_2">
        <span>채널별 상담금액</span>
        <Doughnut data={doughnutChartData_2} options={options} />
      </div>
    </div>
  );
}

export default React.memo(StatisticsChart_1);
