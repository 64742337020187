import { useFormik } from "formik";
import React from "react";

import TextInput from "~ui/textInput/TextInput";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
// import { Button, Radio, FormControlLabel, Tooltip } from "@material-ui/core";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import byteFunction from "~utils/byteCentury";

import useAlert from "~hooks/useAlert";
import instanceAxios from "~apis/_util";

import "./saveModal.scss";

const SaveList = ({ msgContent, handleClose }) => {
  const { openAlert } = useAlert();

  const [tmList, setTmList] = React.useState([]);

  const saveFormik = useFormik({
    initialValues: {
      content: msgContent,
      category: "saved",
      counselor: "선택",
    },

    onSubmit(e) {
      let vali = false;

      if (
        saveFormik.values.content.trim() === "" ||
        saveFormik.values.counselor === "선택"
      ) {
        vali = true;
      }

      if (vali) {
        openAlert("비어있는값이 존재합니다.");
        return;
      }

      instanceAxios.post("/sms-msgs", saveFormik.values).then((x) => {
        openAlert("메시지를 저장했습니다.");

        // handleClose();
      });
    },
  });

  React.useEffect(async () => {
    setTmList(await instanceAxios("/counselors"));
  }, []);

  return (
    <>
      <div className="smsListItem">
        <div className="msgContent">
          <div className="miniTitle">
            <p>내용</p>
          </div>
          <TextareaAutosize
            placeholder="저장할 내용을 입력해주세요."
            minRows={8}
            maxRows={8}
            name="content"
            fullWidth
            value={saveFormik.values.content}
            onChange={saveFormik.handleChange}
          ></TextareaAutosize>
        </div>

        <div className="TMSelect">
          <div className="miniTitle">
            <p>TM</p>
          </div>

          <Select
            fullWidth
            value={saveFormik.values.counselor}
            name="counselor"
            onChange={saveFormik.handleChange}
          >
            <MenuItem value="선택">선택</MenuItem>
            {tmList.map((x, key) => (
              <MenuItem value={x.id}>{x.name}</MenuItem>
            ))}
          </Select>
        </div>

        <div className="categoryBox">
          <div className="miniTitle">
            <p>분류</p>
          </div>

          <div className="categorySel">
            <Select
              fullWidth
              value={saveFormik.values.category}
              name="category"
              onChange={saveFormik.handleChange}
            >
              <MenuItem value="saved">저장된 문구</MenuItem>
              <MenuItem value="ad">광고 문자</MenuItem>
              <MenuItem value="way">오시는 길 안내</MenuItem>
              <MenuItem value="reser">예약 후 안내</MenuItem>
            </Select>
          </div>
        </div>

        <div
          className="smsBottomButton "
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={saveFormik.handleSubmit}
          >
            저장하기
          </Button>
        </div>
      </div>
    </>
  );
};
//

const LoadListHtml = ({ smsList, smsSendFormik, handleClose }) => {
  const { openAlert } = useAlert();

  function loadMsg(e, id) {
    smsSendFormik.setFieldValue(
      "msg",
      document.querySelector(`.saveItemTextarea_${id}`).value,
    );
  }

  function delData(id) {
    instanceAxios.delete(`/sms-msgs/${id}`).then((x) => {
      openAlert("메시지를 삭제했습니다.");
      document.querySelector(`#smsCategorySearchButton`).click();
    });
  }

  function editData(id) {
    instanceAxios
      .put(`/sms-msgs/${id}`, {
        content: document.querySelector(`.saveItemTextarea_${id}`).value,
      })
      .then((x) => {
        openAlert("메시지를 수정했습니다.");
        document.querySelector(`#smsCategorySearchButton`).click();
      });
  }

  function smsListItemHtml(data, key) {
    return (
      <div className="item">
        <div className="top">
          <p>{data.counselor.name}</p>
          <Button onClick={(e) => loadMsg(e, data.id)}>적용</Button>
        </div>
        <TextareaAutosize
          className={`saveItemTextarea_${data.id}`}
          minRows={6}
          maxRows={6}
        >
          {data.content}
        </TextareaAutosize>

        <div>
          <div className="buttonsGroup">
            <Button onClick={() => editData(data.id)}>수정</Button>
            <Button onClick={() => delData(data.id)}>삭제</Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="smsShowList">
        {smsList.map((x, key) => smsListItemHtml(x, key))}
      </div>
    </>
  );
};

// 불러오기
const LoadList = ({ handleClose, smsSendFormik }) => {
  const { openAlert } = useAlert();
  const [tmList, setTmList] = React.useState([]);

  const [smsList, setSmsList] = React.useState([]);

  const searchFormik = useFormik({
    initialValues: {
      category: "saved",
      counselor: "선택",
    },

    onSubmit(e) {
      let vali = false;

      if (searchFormik.values.counselor === "선택") {
        vali = true;
      }

      if (vali) {
        openAlert("비어있는값이 존재합니다.");
        return;
      }

      instanceAxios
        .get(
          `/sms-msgs?category=${searchFormik.values.category}&counselor=${searchFormik.values.counselor}`,
        )
        .then((x) => {
          setSmsList(x);
        });
    },
  });

  React.useEffect(async () => {
    setTmList(await instanceAxios("/counselors"));
  }, []);

  return (
    <>
      <div className="miniTitle">
        <p>검색</p>
      </div>
      <div className="smsSearchForm">
        <div className="select">
          <p>카테고리</p>
          <Select
            name="category"
            value={searchFormik.values.category}
            onChange={searchFormik.handleChange}
          >
            <MenuItem value="saved">저장된 문구</MenuItem>
            <MenuItem value="ad">광고 문자</MenuItem>
            <MenuItem value="way">오시는 길 안내</MenuItem>
            <MenuItem value="reser">예약 후 안내</MenuItem>
          </Select>
        </div>
        <div className="select">
          <p>TM 성함</p>
          <Select
            name="counselor"
            value={searchFormik.values.counselor}
            onChange={searchFormik.handleChange}
          >
            <MenuItem value="선택">선택</MenuItem>
            {tmList.map((x) => (
              <MenuItem value={x.id}>{x.name}</MenuItem>
            ))}
          </Select>
        </div>

        <Button
          id="smsCategorySearchButton"
          onClick={searchFormik.handleSubmit}
        >
          검색
        </Button>
      </div>

      <div className="miniTitle">
        <p>저장한 문구들</p>
      </div>

      <div class="smsListTable">
        <LoadListHtml
          handleClose={handleClose}
          smsList={smsList}
          smsSendFormik={smsSendFormik}
        />
      </div>
    </>
  );
};

const SmsDialog = ({ handleClose, modalState, msgContent, smsSendFormik }) => {
  const [tabStatus, setTabStatus] = React.useState("save");

  // 모달 설정들
  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const { openAlert } = useAlert();

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <Icon>close</Icon>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = React.useMemo(
    () =>
      withStyles((theme) => ({
        root: {
          padding: theme.spacing(2),
        },
      }))(MuiDialogContent),
    [],
  );

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const modalFormik = useFormik({
    initialValues: {
      // 보낼 번호
      sender: "",
      // 받는 번호들
      receiver: "",
      // 메시지
      msg: "",

      msg_type: "ad",
      titleName: "스탠다드치과",
      disabledPhone: "0808811280",
      denialPhone: "0808811280",
    },
    onSubmit(e) {},
  });

  const receiverChange = (e) => {
    modalFormik.setFieldValue("receiver", e.target.value);
  };

  const contentInputPreview = (e) => {
    modalFormik.setFieldValue("msg", e.target.value);
  };
  // modalFormik.values.msg_type === "ad" ? <p>(광고){modalFormik.values.titleName === "추가" ? modalFormik.values.titleNameInput : modalFormik.values.titleName}</p> : null
  // modalFormik.values.msg_type === "ad" ? <p>무료거부 {modalFormik.values.denialPhone}</p> : null

  // 모달 설정들

  const textStart =
    modalFormik.values.msg_type === "ad"
      ? `(광고)${
          modalFormik.values.titleName === "추가"
            ? modalFormik.values.titleNameInput
            : modalFormik.values.titleName
        }`
      : "";
  const textEnd =
    modalFormik.values.msg_type === "ad"
      ? `무료거부 ${modalFormik.values.denialPhone}`
      : "";

  const nowMsg =
    modalFormik.values.msg_type === "ad"
      ? textStart + "\n" + modalFormik.values.msg + "\n" + textEnd
      : modalFormik.values.msg;
  const byte = byteFunction(nowMsg);

  async function sendMsg() {
    if (byte > 2000) {
      openAlert("내용은 한글 1,000자(2,000Byte) 까지만 입력 가능합니다.");
      return;
    }

    const typeVali =
      modalFormik.values.msg_type === "ad"
        ? ["receiver", "sender", "titleName", "msg"]
        : ["receiver", "sender", "msg"];

    const titleText =
      modalFormik.values.titleName.trim() === "추가"
        ? modalFormik.values.titleNameInput.trim()
        : modalFormik.values.titleName.trim();

    let v = false;

    for (let templateKey of typeVali) {
      let val = modalFormik.values[templateKey];

      if (templateKey === "titleName") {
        val = titleText;
      }

      if (val.trim() === "") {
        v = true;
      }
    }

    if (v) {
      openAlert("필수입력 값이 비어있습니다.");
      return;
    }

    const sendValue = {
      sender: modalFormik.values.sender,
      receiver: Array.from(
        new Set(modalFormik.values.receiver.replace(/[\n ]/g, "").split(",")),
      ).join(","),
      msg: nowMsg,
      msg_type: byte <= 90 ? "SMS" : "LMS",
    };

    const axiosData = await instanceAxios({
      method: "post",
      url: "/visits/sms_send",
      data: sendValue,
    });
  }

  function clickSaveTab(status) {
    setTabStatus(status);
  }

  return (
    <>
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={modalState}
        className="saveContentModal"
      >
        <DialogTitle
          style={{ width: "500px" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          알림 보내기
        </DialogTitle>
        <DialogContent className="smsModalContent">
          <div className="smsListItem">
            <div className="tabList">
              <Button
                className={tabStatus == "save" ? "item active" : "item"}
                onClick={() => clickSaveTab("save")}
              >
                <p>저장</p>
              </Button>
              <Button
                className={tabStatus == "load" ? "item active" : "item"}
                onClick={() => clickSaveTab("load")}
              >
                <p>불러오기</p>
              </Button>
            </div>
          </div>

          <div className="smsListItem">
            {tabStatus == "save" ? (
              <SaveList handleClose={handleClose} msgContent={msgContent} />
            ) : (
              <LoadList
                handleClose={handleClose}
                smsSendFormik={smsSendFormik}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SmsDialog;
