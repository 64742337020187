import React from "react";
import produce from "immer";
import { IoSettingsSharp } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import Checkbox from "@material-ui/core/Checkbox";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { Integer } from "~utils/types";
import { useDeleteBroadcasting } from "~apis/broadcastings";
import TableAction from "~/global/components/ui/tableAction/TableAction";

import "./channelViewList.scss";
import moment from "moment";

const toNumber = (number) => {
  try {
    return Number(number || 0);
  } catch (error) {
    return 0;
  }
};

const HEADER_COLUMNS = [
  "",
  "#",
  "방송날짜",
  "진행",
  "향후",
  "광고",
  "업체",
  "채널명",
  "원장",
  "평균 ROI",
  "평균 DB당 단가",
  "총주수",
  "주회차",
  "주수",
  "비용",
  "시간(min)",
  "참고사항",
  "수정/삭제",
];

function ChannelViewListHeader({ isAll }) {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{isAll ? (column === "진행" ? "" : column) : column}</span>
    </div>
  ));

  return <div className="channelViewListHeader">{items}</div>;
}

function ChannelViewListItemEditBox({ id, deleteItem }) {
  return (
    <div className="channelViewListItemEditBox">
      <Link to={`/admin/addChannel/detail/${id}`} className="addButtonLink">
        <IoSettingsSharp size="1.25rem" />
      </Link>
      <svg width="1px" height="1.5rem">
        <line y1="0" y2="1.5rem" />
      </svg>
      <MdClose
        size="1.55rem"
        onClick={() => {
          deleteItem(id);
        }}
      />
    </div>
  );
}

function ChannelViewListItem({
  data,
  isChecked,
  onCheckboxChange,
  deleteItem,
  isAll,
}) {
  const columnDataList = [
    <Checkbox
      name={String(data.broadcasting_id)}
      checked={Boolean(isChecked)}
      onChange={onCheckboxChange}
      color="secondary"
    />,
    data.broadcasting_id,
    moment(data.broadcastingDate).format("YYYY-MM"),
    ...(isAll ? [""] : [data.progress ? "진행" : "중단"]),
    data.next ? "O" : "X",
    data.ad,
    data.agencyText,
    data.channelText,
    data.doctorName,
    Integer(data.roi, "0"),
    // Integer(data.month_count, "-"),
    Number(data.dbPerCost).toLocaleString() + "원",
    data.broadcastingTotalCount,
    data.weeklyRound,
    data.weeklyCount,
    Number(data.cost).toLocaleString() + "원",
    data.minute,
    data?.note?.slice(0, 3),
    <TableAction
      url={`/channel/addChannel/detail/${data.broadcasting_id}`}
      deleteItem={() => deleteItem(data.broadcasting_id)}
    />,
  ];

  const color = {
    11: "#375ca4",
  };

  const items = columnDataList.map((columnData, i) => {
    const style = {};

    if (color[i]) {
      style.color = color[i];
    }

    return (
      <div key={i} style={style}>
        {columnData}
      </div>
    );
  });

  const className = classNames("channelViewListItem", {
    disabledItem: isAll ? false : !data.progress,
  });

  return <div className={className}>{items}</div>;
}

export default function ChannelViewList({
  checkedItems,
  setCheckedItems,
  broadcastingList,
}) {
  const location = useLocation();
  const isAll = location.search.includes("date=all");

  const { mutateAsync: deleteBroadcasting } = useDeleteBroadcasting();
  const onCheckboxChange = React.useCallback(
    (event) => {
      const id = parseInt(event.target.name);
      const checked = event.target.checked;

      setCheckedItems(
        produce((draft) => {
          if (checked && !draft.includes(id)) {
            draft.push(id);
          } else if (!checked && draft.includes(id)) {
            draft.splice(draft.indexOf(id), 1);
          }
        }),
      );
    },
    [setCheckedItems],
  );

  const deleteItem = React.useCallback(
    async (id) => {
      await deleteBroadcasting(id);
      broadcastingList.refetch();
    },
    [deleteBroadcasting, broadcastingList],
  );

  const items = broadcastingList.data?.list?.map((broadcasting, i) => {
    return (
      <ChannelViewListItem
        key={i}
        data={broadcasting}
        isChecked={checkedItems.includes(broadcasting.broadcasting_id)}
        onCheckboxChange={onCheckboxChange}
        deleteItem={deleteItem}
        isAll={isAll}
      />
    );
  });

  return (
    <div className="channelViewList">
      <ChannelViewListHeader isAll={isAll} />
      {items}
    </div>
  );
}
