import React from "react";
import { useHistory } from "react-router-dom";
import { Button, MenuItem } from "@material-ui/core";
import produce from "immer";
import SelectInput from "~ui/selectInput/SelectInput";
import { FaPlus } from "react-icons/fa";
import TextInput from "~ui/textInput/TextInput";
import { ko } from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import ReactTooltip from "react-tooltip";
import moment from "moment";

import { isCheckFn } from "~global/apis/channels";

import "./channelDataForm.scss";

export default function ChannelDataForm({ uiData, setUiData, channelList }) {
  const history = useHistory();
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  const dateRangeChange = () => setDateRangeOpen(!dateRangeOpen);

  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const onInputChange = React.useCallback(
    (event, getName) => {
      const name = event?.target ? event.target.name : getName;
      const type = event?.target ? event.target.type : "";
      const value = event?.target?.value
        ? type === "text" && name !== "channel.agency"
          ? Number(event.target.value.replace(/[^0-9]/g, "")) || 0
          : event.target.value
        : event;
      setUiData(
        produce((draft) => {
          const keys = name.split(".");
          let target = draft;
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (i === keys.length - 1) {
              target[key] = value;
            } else {
              target = target[key];
            }
          }
        }),
      );
    },
    [setUiData],
  );

  const DataRangeChangeEvent = (item) => {
    const range = item.selection;

    onInputChange(moment(range.startDate).format("YYYY-MM-DD"), "startDate");
    onInputChange(moment(range.endDate).format("YYYY-MM-DD"), "endDate");

    setState([item.selection]);
  };

  const onSelectChange = React.useCallback(
    (event) => {
      const e = event;
      const target = e.target;

      if (target.name === "channelText") {
        setUiData(
          produce((draft) => {
            const find = channelList.data.find(
              (x) => x.id == event.target.value,
            );
            draft.channel = find;
            draft.channelText = find.channelName;
          }),
        );
      } else {
        setUiData(
          produce((draft) => {
            draft[target.name] = target.value;
          }),
        );
      }
    },
    [channelList.data, setUiData],
  );

  return (
    <div className="channelDataForm">
      <div className="channelDataFormItem">
        <span>광고명</span>
        <div>
          <SelectInput
            className="selectName"
            name="ad"
            value={uiData.ad}
            onChange={onSelectChange}
            placeholder="- 광고명 -"
            style={{
              width: "15.5rem",
              height: "3rem",
            }}
          >
            {["TV 광고", "업체광고", "오프라인광고", "HYDJ"].map(
              (channel, i) => (
                <MenuItem key={i} style={{ fontSize: "1rem" }} value={channel}>
                  {channel}
                </MenuItem>
              ),
            )}
          </SelectInput>
          <Button
            variant="contained"
            color="primary"
            className="addName"
            onClick={() => {
              history.push(`/channel/addChannel/add`);
            }}
          >
            <span style={{ marginRight: "0.3rem" }}>광고명 추가</span>
            <FaPlus size="0.6rem" />
          </Button>
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>주수</span>
        <div>
          <input
            type="text"
            name="weeklyCount"
            value={String(uiData.weeklyCount)}
            onChange={onInputChange}
          />
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>업체</span>
        <div>
          <SelectInput
            className="selectName"
            name="agencyText"
            value={uiData.agencyText}
            onChange={onSelectChange}
            placeholder="- 업체 -"
            style={{
              width: "15.5rem",
              height: "3rem",
            }}
          >
            {isCheckFn(
              uiData.ad
                ? channelList.data.filter((channel) => {
                    return channel.ad === uiData.ad;
                  })
                : [],
              "agency",
            ).map((channel, i) => (
              <MenuItem
                key={i}
                style={{ fontSize: "1rem" }}
                value={channel.agency}
              >
                {channel.agency}
              </MenuItem>
            ))}
          </SelectInput>

          <Button
            variant="contained"
            color="primary"
            className="addName"
            onClick={() => {
              history.push(`/channel/addChannel/add`);
            }}
          >
            <span style={{ marginRight: "0.3rem" }}>업체명 추가</span>
            <FaPlus size="0.6rem" />
          </Button>
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>비용</span>
        <div>
          <input
            type="text"
            name="cost"
            value={(
              String(uiData.cost).replace(/[^0-9]/g, "") * 1
            ).toLocaleString()}
            onChange={onInputChange}
          />
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>채널별</span>
        <div>
          <SelectInput
            className="selectName"
            name="channelText"
            value={uiData?.channel && uiData.channel.id}
            onChange={onSelectChange}
            placeholder="- 채널 -"
            style={{
              width: "15.5rem",
              height: "3rem",
            }}
          >
            {isCheckFn(
              uiData.agencyText
                ? channelList.data.filter((channelList) => {
                    return channelList.agency == uiData.agencyText;
                  })
                : [],
              "channelName",
            ).map((channel, i) => (
              <MenuItem key={i} style={{ fontSize: "1rem" }} value={channel.id}>
                {channel.channelName}
              </MenuItem>
            ))}
          </SelectInput>
          <Button
            variant="contained"
            color="primary"
            className="addName"
            onClick={() => {
              history.push(`/channel/addChannel/add`);
            }}
          >
            <span style={{ marginRight: "0.3rem" }}>채널명 추가</span>
            <FaPlus size="0.6rem" />
          </Button>
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>기간</span>
        <div>
          <div className="inputs">
            <div data-tip data-for="daterange" data-event="click">
              <TextInput readOnly value={uiData.startDate} />
              <p>~</p>
              <TextInput readOnly value={uiData.endDate} />
            </div>
            <ReactTooltip
              id="daterange"
              place="bottom"
              effect="solid"
              type="light"
              globalEventOff="click"
              clickable
            >
              <DateRange
                ranges={state}
                onChange={DataRangeChangeEvent}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                direction="horizontal"
                locale={ko}
              />
            </ReactTooltip>
          </div>
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>시간 (Min)</span>
        <div>
          <input
            type="text"
            name="minute"
            value={String(uiData.minute)}
            onChange={onInputChange}
          />
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>주회차</span>
        <div>
          <input
            type="text"
            name="weeklyRound"
            value={String(uiData.weeklyRound)}
            onChange={onInputChange}
          />
        </div>
      </div>

      <div className="channelDataFormItem">
        <span>참고사항</span>
        <div>
          <textarea
            rows="2"
            name="note"
            value={String(uiData.note)}
            onChange={onInputChange}
          />
        </div>
      </div>
    </div>
  );
}
