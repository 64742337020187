import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

// Head 추가
export const useAddHead = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/Heads", data);

      return res;
    },
    {
      loadingMessage: "실장 정보를 추가 중입니다",
      successMessage: "실장 정보가 추가되었습니다",
    },
  );
};

// Head 삭제
export const useDeleteHead = () => {
  return useLoadingMutation(
    async (HeadID) => {
      const res = await axios.delete(`/Heads/${HeadID}`);

      return res;
    },
    {
      loadingMessage: "실장을 삭제 중입니다",
      successMessage: "실장이 삭제 되었습니다",
    },
  );
};

// Head 수정
export const useUpdateHead = (HeadID) => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.put(`/Heads/${HeadID}`, data);
      return res;
    },
    {
      loadingMessage: "실장 정보를 수정 중입니다",
      successMessage: "실장이 수정되었습니다",
    },
  );
};

// Head 아이템 조회
export const useGetHeadItem = (queryString = "", options) => {
  return useQuery(
    ["Head", queryString],
    async () => {
      const data = await axios.get(
        `/Heads${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Head 목록 조회
export const useGetHeadList = (queryString = "", options) => {
  return useQuery(
    ["Head", queryString],
    async () => {
      const data = await axios.get(
        `/Heads${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// Head 목록 조회
export const useGetHeadListCount = (queryString = "", options) => {
  return useQuery(
    ["HeadCount", queryString],
    async () => {
      const data = await axios.get(
        `/Heads/count${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 통계
export const useGetDailyStatistics = (queryString = "", options) => {
  return useQuery(
    ["DailyStatistics", queryString],
    async () => {
      if (queryString === null) {
        return null;
      }

      const data = await axios.get(
        `/Heads/dailyStatistics${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 통계
export const useGetWeeklyStatistics = (queryString = "", options) => {
  return useQuery(
    ["WeeklyStatistics", queryString],
    async () => {
      if (queryString === null) {
        return null;
      }

      const data = await axios.get(
        `/Heads/weeklyStatistics${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 통계
export const useGetMonthlyStatistics = (queryString = "", options) => {
  return useQuery(
    ["MonthlyStatistics", queryString],
    async () => {
      if (queryString === null) {
        return null;
      }

      const data = await axios.get(
        `/Heads/monthlyStatistics${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};


export const useGetYearlyStatistics = (queryString = "", options) => {
  return useQuery(
    ["YearlyStatistics", queryString],
    async () => {
      if (queryString === null) {
        return null;
      }

      const data = await axios.get(
        `/Heads/yearlyStatistics${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};
