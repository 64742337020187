import axios from "axios";
import regexEscape from "regex-escape";
import { useMutation } from "react-query";

import useOverlayLoading from "~hooks/useOverlayLoading";
import useToast from "~hooks/useToast";

export const API_HOST_DEV =
  process.env.REACT_APP_DEV_API_HOST || window.location.origin;
export const API_HOST_PRO = "https://standardcrmapi.gabia.io";
export const API_HOST =
  process.env.NODE_ENV === "development" ? API_HOST_DEV : API_HOST_PRO;
export const API_PATH_PREFIX = process.env.REACT_APP_API_PATH_PREFIX || "";

/*
 * axios 인스턴스 생성
 */
const instanceAxios = axios.create({
  baseURL: API_HOST + API_PATH_PREFIX,
  withCredentials: false,
});

/*
 * axios 요청 전 인터셉터
 */
instanceAxios.interceptors.request.use(
  (config) => {
    if (new RegExp(`^${regexEscape(API_HOST)}`).test(config.baseURL)) {
      const token =
        window.localStorage["user.token"] ||
        window.sessionStorage["user.token"];

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

/*
 * axios 응답 전 인터셉터
 */
instanceAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => Promise.reject(error.response),
);

/*
 * react-query의 useMutation함수를 로딩 효과 포함해서 작동하도록 감싼 함수
 */
export const useLoadingMutation = (
  mutationFn,
  options = {
    loadingMessage: "잠시만 기다려주세요",
    successMessage: "",
    mutationOptions: {},
  },
) => {
  const { loadingMessage, successMessage, mutationOptions } = options;
  const [open, close] = useOverlayLoading();
  const { toast } = useToast();

  return useMutation(async (...params) => {
    open(loadingMessage);
    try {
      const result = await mutationFn(...params);

      // 로딩 닫기
      await close();

      // 성공시 알림 메시지
      if (successMessage) {
        toast(successMessage);
      }

      return result;
    } catch (error) {
      await close();
      throw error;
    }
  }, mutationOptions);
};

export default instanceAxios;
