import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { IoIosSend } from "react-icons/io";
import { RiFileExcel2Fill } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import { CSVLink } from "react-csv";

import "./pageControls.scss";

export default function PageControls({
  onClickDelete,
  onClickApply,
  onClickCounseling,
  onClickCopy,
  getCsvData,
  editcheck,
  phoneClick,
  isEdit,
}) {
  return (
    <div className="pageControls">
      <div>
        <Button variant="contained" color="primary" className="excelDownButton">
          <CSVLink
            data={getCsvData()}
            filename={"download.csv"}
            target="_blank"
            className="csvLink"
          >
            <RiFileExcel2Fill size="1.5rem" />
            <span style={{ fontSize: "1.05rem" }}>Excel 다운</span>
          </CSVLink>
        </Button>

        <Link to="/counselor/counseling">
          <Button variant="contained" color="primary" className="backButton">
            <FaClipboardList size="1.8rem" />
            <span>목록</span>
          </Button>
        </Link>

        {isEdit && (
          <>
            <Button
              variant="contained"
              color="primary"
              className="addButton"
              onClick={onClickCounseling}
            >
              <span>상담추가 +</span>
            </Button>

            <Button
              variant="contained"
              color="primary"
              className="addButton"
              onClick={onClickCopy}
            >
              <span>DB 복제</span>
            </Button>

            <Button
              variant="contained"
              color="primary"
              className="addButton"
              onClick={phoneClick}
            >
              <IoIosSend size="1.8rem" />
              <span>문자 전송</span>
            </Button>
          </>
        )}

        <Button
          variant="contained"
          color="primary"
          className="deleteButton"
          onClick={onClickDelete}
        >
          <MdClose size="1.8rem" />
          <span>삭제</span>
        </Button>
      </div>

      <Button
        variant="contained"
        color="primary"
        className="dropButton widthauto"
        onClick={onClickApply}
      >
        <IoIosSend size="1.8rem" />
        <span>{!editcheck ? "등록하기" : "변경사항 저장"}</span>
      </Button>
    </div>
  );
}
