import React from "react";
import moment from "moment";

import { Button } from "@material-ui/core";
import jsonToCSV from "~/global/utils/jsonToCsv";

import "./salesStatisticsList.scss";

const HEADER_COLUMNS = [
  "년/월",
  "월/일",
  "상담진행 환자 수",
  "진행환자 수",
  "총 상담금액",
  "총 계약금액",
  "임플란트",
  "교정",
  "심미보철",
  "일반",
];

function findSalesData(datas, salesData, type) {
  return datas.filter((doctorVal, i) => {
    return salesData
      .map((salesVal) => {
        const dd = salesVal?.[type];
        if (type === "heads") {
        }

        return dd
          ? Object.values(dd)
              .map((vv) => vv.id)
              .includes(doctorVal.id)
          : false;
      })
      .some((someVal) => someVal);
  });
}

function SalesStatisticsListHeader({ doctors, heads, salesData }) {
  const items = HEADER_COLUMNS.map((column, i) => (
    <div key={i}>
      <span>{column}</span>
    </div>
  ));

  const doctorNames = doctors.map((x) => x.name);
  const headNames = heads.map((x) => x.name);

  const doctorsColumn = findSalesData(doctors, salesData, "doctors")
    .map((x) => x.name)
    .map((column, i) => (
      <div key={i}>
        <span style={{ color: "#009ad6" }}>{column}</span>
      </div>
    ));

  const headsColumn = findSalesData(heads, salesData, "heads")
    .map((x) => x.name)
    .map((column, i) => (
      <div key={i}>
        <span style={{ color: "#fc9700" }}>{column}</span>
      </div>
    ));

  return (
    <div className="salesStatisticsListHeader">
      {items}
      <div className="dynamicColumn">{doctorsColumn}</div>
      <div className="dynamicColumn">{headsColumn}</div>
    </div>
  );
}

function UpDownArrow({ arrow }) {
  if (arrow === 1) {
    return (
      <svg
        className="arrowUp"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 16 16"
      >
        <path d="M0 16l16 0 -8-16z"></path>
      </svg>
    );
  } else if (arrow === -1) {
    return (
      <svg
        className="arrowDown"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 16 16"
      >
        <path d="M0 0l8 16 8-16z"></path>
      </svg>
    );
  } else return <></>;
}

function SalesStatisticsListItem({ data, doctors, heads, salesData }) {
  const doctorNames = doctors.map((x) => x.name);
  const doctorItems = findSalesData(doctors, salesData, "doctors")
    .map((x) => x.name)
    .map((doctorName, i) => {
      const doctor = data.doctors && data.doctors[doctorName];
      if (doctor) {
        return (
          <div key={i}>
            <span>{doctor.count} / <span style={{color: "blue"}}>{doctor.pr_count}</span> 명</span>
            <span
              className={
                1 === 1 ? "percentUp" : true === -1 ? "percentDown" : ""
              }
            >
              ({doctor.percent.toFixed()}%)
            </span>
          </div>
        );
      } else {
        return <div key={i}>-</div>;
      }
    });

  const headNames = heads.map((x) => x.name);
  const headItems = findSalesData(heads, salesData, "heads")
    .map((x) => x.name)
    .map((headName, i) => {
      const head = data.heads && data.heads[headName];
      if (head) {
        return (
          <div key={i}>
            <span>{head.count}  / <span style={{color: "blue"}}>{head.pr_count}</span> 명</span>
            <span
              className={
                1 === 1 ? "percentUp" : true === -1 ? "percentDown" : ""
              }
            >
              ({head.percent.toFixed()}%)
            </span>
          </div>
        );
      } else {
        return <div key={i}>-</div>;
      }
    });

  const columnDataList = [
    `${data.year}. ${data.month}`,
    `${data.week_of_month}주 (${moment(data.week_start).format(
      "MM.DD",
    )}~${moment(data.week_end).format("MM.DD")})`,
    <>
      <span>{data.counseling_count}명</span>
      <UpDownArrow arrow={data.counseling_count_updown} />
    </>,
    <>
      <span>{data.progress_count}명</span>
      <UpDownArrow arrow={data.progress_count_updown} />
    </>,
    <>
      <span>{(data.counseling_amount || 0).toLocaleString("ko-KR")}</span>
      <UpDownArrow arrow={data.counseling_amount_updown} />
    </>,
    <>
      <span>
        {(data.progress_amount ? +data.progress_amount : 0).toLocaleString(
          "ko-KR",
        )}
      </span>
      <UpDownArrow arrow={data.progress_amount_updown} />
    </>,
    data.category1_count,
    data.category2_count,
    data.category3_count,
    data.category4_count,
  ];

  const items = columnDataList.map((columnData, i) => (
    <div key={i}>{columnData}</div>
  ));

  return (
    <div className="salesStatisticsListItem">
      {items}
      <div className="dynamicColumn">{doctorItems}</div>
      <div className="dynamicColumn">{headItems}</div>
    </div>
  );
}

function SalesStatisticsListTotal({ doctors, heads, salesData }) {
  const itemDataList = Array(10).fill("");
  itemDataList.fill(0, 2, 6);
  salesData.forEach((x) => {
    itemDataList[2] += parseInt(x.counseling_count) || 0;
    itemDataList[3] += parseInt(x.progress_count) || 0;
    itemDataList[4] += parseInt(x.counseling_amount) || 0;
    itemDataList[5] += parseInt(x.progress_amount) || 0;
  });

  itemDataList[2] = `${itemDataList[2]}명`;
  itemDataList[3] = `${itemDataList[3]}명`;
  itemDataList[4] = itemDataList[4].toLocaleString("ko-KR");
  itemDataList[5] = itemDataList[5].toLocaleString("ko-KR");

  const items = itemDataList.map((itemData, i) => (
    <div key={i}>{itemData}</div>
  ));

  const dynamicItems = Array(
    findSalesData(doctors, salesData, "doctors").length +
      findSalesData(heads, salesData, "heads").length,
  )
    .fill("")
    .map((_, i) => <div key={i}></div>);

  return (
    <div className="salesStatisticsListTotal">
      {items}
      <div className="dynamicColumn">{dynamicItems}</div>
    </div>
  );
}

export default function SalesStatisticsList({
  doctorList,
  headList,
  monthlySalesStatistics,
}) {
  const items = monthlySalesStatistics.data.map((salesData, i) => (
    <SalesStatisticsListItem
      key={i}
      data={salesData}
      salesData={monthlySalesStatistics.data}
      doctors={doctorList.data}
      heads={headList.data}
    />
  ));

  const excelDown = (getData) => {
    const salesData = getData.salesData;
    const targetDataset = getData.targetDataset;

    const findSalesHeads = findSalesData(getData.heads, salesData, "heads");
    const findSalesDoctor = findSalesData(
      getData.doctors,
      salesData,
      "doctors",
    );

    const k = [
      ...HEADER_COLUMNS,
      ...Object.values(findSalesDoctor).map((x) => x.name),
      ...Object.values(findSalesHeads).map((x) => x.name),
    ];

    const listMap = salesData.map((data) => {
      return [
        `${data.year}. ${data.month}`,
        `${data.week_of_month}주 (${moment(data.week_start).format(
          "MM.DD",
        )}~${moment(data.week_end).format("MM.DD")})`,
        data.counseling_count,
        data.progress_count,
        (data.counseling_amount || 0).toLocaleString("ko-KR"),
        (data.payment ? +data.payment : 0).toLocaleString("ko-KR"),
        data.category1_count,
        data.category2_count,
        data.category3_count,
        data.category4_count,
        ...findSalesDoctor
          .map((x) => x.name)
          .map((doctorName) => {
            const doctor = data.doctors && data.doctors[doctorName];
            if (doctor) {
              return `${doctor.count} / ${doctor.pr_count}` + "명";
            } else {
              return "";
            }
          }),
        ...findSalesHeads
          .map((x) => x.name)
          .map((headName) => {
            const head = data.heads && data.heads[headName];
            if (head) {
              return `${head.count} / ${head.pr_count}` + "명";
            } else {
              return "";
            }
          }),
      ].reduce(
        (acc, dataVal, dataKey) => ({ ...acc, [k[dataKey]]: dataVal }),
        {},
      );
    });

    jsonToCSV(listMap, "통계", true);
  };

  return (
    <div className="salesStatisticsList">
      <div className="top">
        <p>원장</p>
        <p>실장</p>
      </div>
      <SalesStatisticsListHeader
        salesData={monthlySalesStatistics.data}
        doctors={doctorList.data}
        heads={headList.data}
      />
      {items}
      <SalesStatisticsListTotal
        doctors={doctorList.data}
        heads={headList.data}
        salesData={monthlySalesStatistics.data}
      />

      <div className="ControlsButton">
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            excelDown({
              doctors: doctorList.data,
              heads: headList.data,
              salesData: monthlySalesStatistics.data,
            })
          }
        >
          Excel 다운
        </Button>
      </div>
    </div>
  );
}
