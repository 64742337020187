import React from "react";
import produce from "immer";
import { useRecoilState } from "recoil";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import "./alert.scss";
import { alertState } from "~global/stores/ui";

import Button from "~ui/button/Button";

export default function Alert() {
  const [state, setState] = useRecoilState(alertState);

  const handleOk = () => {
    setState(
      produce((v) => {
        v.open = false;
        v.result = true;
      }),
    );
  };

  const handleCancel = () => {
    setState(
      produce((v) => {
        v.open = false;
        v.result = false;
      }),
    );
  };

  return (
    <Dialog open={state.open} className="UI-alert" maxWidth="xs" fullWidth>
      <DialogTitle>{state.title}</DialogTitle>

      <DialogContent>
        {state.content.split("\n").map((text, i) => (
          <React.Fragment key={i}>
            {text}
            <br />
          </React.Fragment>
        ))}
      </DialogContent>

      <DialogActions>
        <Button color="primary" transparent onClick={handleOk}>
          확인
        </Button>

        {state.type === "confirm" && (
          <Button transparent onClick={handleCancel}>
            취소
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
