import React from "react";
import DateInput from "~ui/dateInput/DateInput";
import Button from "@material-ui/core/Button";
import { FaSearch } from "react-icons/fa";
import produce from "immer";

import koLocale from "date-fns/locale/ko";
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "./searchBar.scss";

export default function SearchBar({
  searchQuery,
  setSearchQuery,
  onClickSearch,
}) {
  const onDateInputChange = React.useCallback(
    (event, getName) => {
      const name = event?.target ? event?.target?.name : getName;
      const value = event?.target ? event?.target?.value : event;
      setSearchQuery(
        produce((draft) => {
          draft[name] = value;
        }),
      );
    },
    [setSearchQuery],
  );

  return (
    <div className="searchBar">
      <span>기간검색</span>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>

        <DatePicker
          format="yyyy/MM/dd"
          onChange={(e) => onDateInputChange(e, "startDate")}
          className="startDateInput"
          value={searchQuery.startDate}
          style={{ width: "16.5rem",  color: "white" }}
        />
      
        <span style={{ margin: "0 1rem" }}>~</span>
        <DatePicker
          format="yyyy/MM/dd"
          onChange={(e) => onDateInputChange(e, "endDate")}
          className="endDateInput"
          value={searchQuery.endDate}
          style={{ width: "16.5rem",  color: "white", marginLeft: "0" }}
        />
      </MuiPickersUtilsProvider>
      <Button
        variant="contained"
        color="secondary"
        className="searchButton"
        onClick={onClickSearch}
      >
        <span>Search</span>
        <FaSearch size="2.2rem" />
      </Button>
    </div>
  );
}
