import React, { useEffect } from "react";
import instanceAxios from "~apis/_util";

import { Button } from "@material-ui/core";
import { useFormik } from "formik";

import { FaSearch } from "react-icons/fa";

import moment from "moment";

// import { DateRangePicker, DateRange } from "materialui-daterange-picker";

import ReactTooltip from "react-tooltip";
import { ko } from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";

import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
// import CloseIcon from '@material-ui/icon/Close';
//
import { useUpdateCounseling } from "~apis/counselings";

import SendRequestLists from "../../../components/sms/sendRequest/sendRequestLists";
import SendRequestPageBtn from "../../../components/sms/sendRequest/sendRequestPageBtn";
// 추가

import division from "~json/administrative_division.json";
import TextInput from "~/global/components/ui/textInput/TextInput";

import "./sendRequest.scss";

export default function SendRequest() {
  const ls = window.localStorage.getItem("sendRequestDate");

  const defaultNowDate = ls ? new Date(JSON.parse(ls).startDate) : new Date();
  const defaultEndDate = ls ? new Date(JSON.parse(ls).endDate) : new Date();
  defaultEndDate.setDate(defaultNowDate.getDate() + 31);

  const [sendData, setSendData] = React.useState({});
  const [sendPage, setSendPage] = React.useState(1);

  const [state, setState] = React.useState([
    {
      startDate: defaultNowDate,
      endDate: defaultEndDate,
      key: "selection",
    },
  ]);

  const searchAxiosPost = React.useCallback(async (page = false) => {
    const v = searchQuery.values;

    const [sDate, eDate] = [v.startDate, v.endDate];

    const limitDate =
      (new Date(eDate).getTime() - new Date(sDate).getTime()) /
      (1000 * 60 * 60 * 24);

    const dataPostRequest = await instanceAxios.post("/visits/sms_request", {
      startDate: sDate.replace(/-/g, ""),
      limits: limitDate,
      pages: page || sendPage,
    });

    // 자세한 결과
    // if (dataPostRequest) {
    //   for (let value of dataPostRequest.list) {
    //     value.detail = await instanceAxios.post("/visits/sms_request_detail", {
    //       mid: value.mid,
    //     });
    //   }
    // }

    return dataPostRequest;
  });

  const reSearch = React.useCallback(async (page) => {
    const data = await searchAxiosPost(page);
    setSendData(data);
  });

  const searchQuery = useFormik({
    initialValues: {
      startDate: moment(defaultNowDate).format("YYYY-MM-DD"),
      endDate: moment(defaultEndDate).format("YYYY-MM-DD"),
    },

    onSubmit: (e) => {
      setSendPage((x) => 1);
      reSearch(1);
    },
  });

  const DataRangeChangeEvent = (item) => {
    const range = item.selection;

    const [startDate, endDate] = [
      moment(range.startDate).format("YYYY-MM-DD"),
      moment(range.endDate).format("YYYY-MM-DD"),
    ];

    searchQuery.setFieldValue("startDate", startDate);
    searchQuery.setFieldValue("endDate", endDate);

    window.localStorage.setItem(
      "sendRequestDate",
      JSON.stringify({
        startDate,
        endDate,
        key: "selection",
      }),
    );

    setState([item.selection]);
  };

  useEffect(async () => {
    if (ls) {
      const parse = JSON.parse(ls);

      setState((v) => {
        v.startDate = parse.startDate;
        v.endDate = parse.endDate;

        return v;
      });
    }

    reSearch();
  }, []);

  const prevClick = () => {
    setSendPage((x) => sendPage - 1);
    reSearch(sendPage - 1);
  };
  const nextClick = () => {
    setSendPage((x) => sendPage + 1);
    reSearch(sendPage + 1);
  };

  return (
    <div className="sendRequest">
      <span className="viewTitle">전송결과 보기</span>

      <div className="dbStateManagement">
        <div>
          <div>
            <span>기간검색</span>
          </div>

          <div className="DateGroup">
            <div className="DateInputs">
              <div className="inputs">
                <div data-tip data-for="daterange" data-event="click">
                  <TextInput readOnly value={searchQuery.values.startDate} />
                  <p>~</p>
                  <TextInput readOnly value={searchQuery.values.endDate} />
                </div>
                <ReactTooltip
                  id="daterange"
                  place="bottom"
                  effect="solid"
                  type="light"
                  globalEventOff="click"
                  clickable
                >
                  <DateRange
                    ranges={state}
                    onChange={DataRangeChangeEvent}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    direction="horizontal"
                    locale={ko}
                  />
                </ReactTooltip>
              </div>
              <div className="dateRangePicker-box">
                {/* <DateRangePicker
                  initialDateRange={{
                    startDate: searchQuery.values.startDate,
                    endDate: searchQuery.values.endDate,
                  }}
                  closeOnClickOutside={false}
                  open={dateRangeOpen}
                  onChange={rangeChange}
                /> */}
              </div>
            </div>

            <Button
              variant="contained"
              color="secondary"
              className="searchButton"
              onClick={searchQuery.handleSubmit}
            >
              <span>Search</span>
              <FaSearch size="1.7rem" />
            </Button>
          </div>
        </div>
      </div>
      {/* <SearchBox></SearchBox> */}

      <SendRequestLists sendData={sendData} />
      <div className="requestControllBtn">
        {sendData ? (
          <SendRequestPageBtn
            sendData={sendData}
            sendPage={sendPage}
            setSendPage={setSendPage}
            reSearch={reSearch}
            prevClick={prevClick}
            nextClick={nextClick}
          />
        ) : null}
      </div>
      <div className="Controls">
        {/* <Pazing
          pages={pages}
          // searchQuery={searchQuery}
          // setSearchQuery={setSearchQuery}
        /> */}
      </div>
    </div>
  );
}
