import React from "react";
import produce from "immer";
import DateInput from "~ui/dateInput/DateInput";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import Checkbox from "@material-ui/core/Checkbox";
import TextInput from "~ui/textInput/TextInput";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import { getNextMonth } from "~utils/date";
import useAlert from "~hooks/useAlert";
import { Button } from "@material-ui/core";
import { IoIosSend } from "react-icons/io";
import { useHistory } from "react-router-dom";

import instanceAxios from "~apis/_util";

import "./dbInfo.scss";
import division from "~json/administrative_division.json";
import moment from "moment";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  padding-left: 0.2rem;
  border: 1px solid rgba(0, 0, 0, 0.5);

  .MuiSelect-root {
    color: black;
    font-size: 0.75rem;
    font-weight: normal;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 1rem;
    justify-content: center;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    transform: scale(1.3) translate(0.1rem, 0);
  }
`;

const HEADER_COLUMNS = [
  ["년도", "월", "일", "광고별 분류", "업체별 분류", "채널별 분류"],
  [
    "이름",
    "전화번호",
    "나이",
    "시",
    "구",
    "신규여부",
    "가망",
    "문자거부",
    // "리콜/문자 필요여부",
    "TM",
    "원장",
  ],
  [
    // "내원예약날짜",
    "차트번호",
    "내원여부",
    "치료진행여부",
  ],
];

function DbInfoHeader() {
  const items = HEADER_COLUMNS.map((columnList, i) => (
    <div key={i} className="columnWrapper">
      {columnList.map((column, j) => (
        <div key={j}>
          <span>{column}</span>
        </div>
      ))}
    </div>
  ));

  return <div className="dbInfoHeader">{items}</div>;
}

function DbInfoItem({
  uiData,
  setUiData,
  onChange,
  onClick,
  channelList,
  csList,
  doctorList,
}) {
  const data = uiData.visit;
  const history = useHistory();

  const [sidoListData, setSidoListData] = React.useState(Object.keys(division));

  const [sigunguListData, setSigunguList] = React.useState(
    division[data.address1] ? Object.keys(division[data.address1]) : [],
  );
  const [eupmyundongListData, setEupmyundongList] = React.useState(
    division[data.address1] ? division[data.address1][data.address2] : [],
  );
  const onChangeAddress = React.useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      if (name === "address1") {
        setSigunguList(division[value] ? Object.keys(division[value]) : []);
        setEupmyundongList([]);
        setUiData(
          produce((draft) => {
            draft.visit.address2 = "";
            draft.visit.address3 = "";
          }),
        );
      }
      if (name === "address2") {
        setEupmyundongList(
          division[data.address1][value] ? division[data.address1][value] : [],
        );
        setUiData(
          produce((draft) => {
            draft.visit.address3 = "";
          }),
        );
      }
      onChange(event);
    },
    [data, setUiData, setSigunguList, setEupmyundongList, onChange],
  );

  const ckChartnumber = async () => {
    if (!data.chartNumber.trim()) return alert("차트번호를 입력해주세요.");
    const chartData = await instanceAxios(
      `/visits?chartNumber=${data.chartNumber}`,
    );

    if (chartData) {
      if (
        window.confirm(
          "해당 차트번호로 등록된 환자 정보가 있습니다.\n확인시 실장팀과 TM팀 둘다 볼 수 있는 DB로 전환 및 상세정보로 이동합니다.",
        )
      ) {
        await instanceAxios.put(`/visits/${chartData[0].id}`, { type: "all" });
        history.push(`/tm/totalManagement/detail/${chartData[0].id}`);
      }
    }
  };

  const sidoList = sidoListData.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const sigunguList = sigunguListData.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  // const eupmyundongList = eupmyundongListData.map((name, i) => (
  //   <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
  //     {name}
  //   </MenuItem>
  // ));

  const dbTypeList = [
    ["신규DB", false],
    ["재상담DB", true],
  ].map((x, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={x[1]}>
      {x[0]}
    </MenuItem>
  ));

  const channelData = channelList.data;

  const routeList = Array.from(
    new Set(["TV 광고", "업체광고", "오프라인광고", "HYDJ"]),
  ).map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const companyList = data?.inflowRoute
    ? Array.from(
        new Set(
          channelData
            ?.filter((listValue) => listValue.ad === data.inflowRoute)
            .map((listValue) => listValue.agency),
        ),
      )
    : [];

  const inflowRouteDetailList = companyList.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const contentArrayList = data?.inflowRouteDetail
    ? Array.from(
        new Set(
          channelData
            ?.filter((listValue) => listValue.agency === data.inflowRouteDetail)
            .map((listValue) => listValue.channelName),
        ),
      )
    : [];

  const inflowRouteDetailContentList = contentArrayList.map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const tmNameList = [
    {
      name: "없음",
      id: -1,
    },
    ...(csList.data || []),
  ].map((cs, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={cs.id}>
      {cs.name}
    </MenuItem>
  ));

  const doctorNameList = (doctorList?.data || []).map((doctor, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={doctor.id}>
      {doctor.name}
    </MenuItem>
  ));

  const itemColumnList = [
    [
      <TextInput
        name="inflowYear"
        inputClassName="numberInput year"
        value={data.inflowYear || ""}
        onChange={onChange}
      />,
      <TextInput
        name="inflowMonth"
        inputClassName="numberInput month"
        value={data.inflowMonth || ""}
        onChange={onChange}
      />,
      <TextInput
        name="inflowFullDate"
        inputClassName="numberInput day"
        value={data.inflowFullDate || ""}
        onChange={onChange}
      />,
      <StyledSimpleSelectInput
        className="routeSelectInput"
        name="inflowRoute"
        value={data.inflowRoute || ""}
        onChange={(e) => {
          setUiData(
            produce((draft) => {
              draft.visit.inflowRouteDetail = "";
              draft.visit.company = "";
            }),
          );
          onChange(e);
        }}
        fontSize="0.75rem"
        placeholder="--"
      >
        {routeList}
      </StyledSimpleSelectInput>,

      <StyledSimpleSelectInput
        className="routeDetailSelectInput"
        name="inflowRouteDetail"
        value={data.inflowRouteDetail}
        onChange={(e) => {
          setUiData(
            produce((draft) => {
              draft.visit.inflowRouteDetail = "";
            }),
          );

          onChange(e);
        }}
        fontSize="0.75rem"
        placeholder="--"
      >
        {inflowRouteDetailList}
      </StyledSimpleSelectInput>,
      <StyledSimpleSelectInput
        className="routeSelectInput"
        name="inflowRouteDetailContent"
        value={data.inflowRouteDetailContent || ""}
        onChange={onChange}
        fontSize="0.75rem"
        placeholder="--"
      >
        {inflowRouteDetailContentList}
      </StyledSimpleSelectInput>,
    ],
    [
      <TextInput name="name" value={data.name || ""} onChange={onChange} />,
      <TextInput
        name="phone"
        inputClassName="phoneNumberInput"
        value={data.phone || ""}
        onChange={onChange}
      />,
      <TextInput
        name="age"
        inputClassName="numberInput"
        value={data.age || ""}
        onChange={onChange}
      />,
      <StyledSimpleSelectInput
        className="sidoSelectInput"
        name="address1"
        value={data.address1 || ""}
        onChange={onChangeAddress}
        fontSize="0.75rem"
      >
        {sidoList}
      </StyledSimpleSelectInput>,
      <StyledSimpleSelectInput
        className="sigunguSelectInput"
        name="address2"
        value={data.address2 || ""}
        onChange={onChangeAddress}
        fontSize="0.75rem"
      >
        {sigunguList}
      </StyledSimpleSelectInput>,
      <StyledSimpleSelectInput
        className="dbTypeSelectInput"
        name="isRepeatVisit"
        value={data.isRepeatVisit ? true : false}
        onChange={onChange}
        fontSize="0.75rem"
      >
        {dbTypeList}
      </StyledSimpleSelectInput>,
      <TextInput
        name="possibility"
        inputClassName="numberInput percent"
        value={data.possibility}
        onChange={() => {}}
      />,
      <Checkbox
        name="smsSetting"
        checked={data.smsSetting}
        onChange={onChange}
        color="secondary"
      />,
      // <div className="recallRequired" onClick={onClick}>
      //   {uiData.recalls.length ? "리콜진행취소" : "리콜관리"}
      // </div>,
      <StyledSimpleSelectInput
        className="tmNameSelectInput"
        name="counselor"
        value={data.counselor || -1}
        onChange={onChange}
        fontSize="0.75rem"
      >
        {tmNameList}
      </StyledSimpleSelectInput>,
      <StyledSimpleSelectInput
        className="doctorSelectInput"
        name="doctor"
        value={data.doctor || ""}
        onChange={onChange}
        fontSize="0.75rem"
        placeholder="--"
      >
        {doctorNameList}
      </StyledSimpleSelectInput>,
    ],
    [
      // <DateInput
      //   className="reservationDateInput"
      //   value={data.reservationDate || ""}
      //   name="reservationDate"
      //   onChange={() => {}}
      //   style={{ height: "2rem" }}
      // />,

      <form
        onSubmit={(e) => {
          e.preventDefault();

          ckChartnumber();
        }}
      >
        <TextInput
          name="chartNumber"
          inputClassName="numberInput"
          value={data.chartNumber || ""}
          onChange={onChange}
          placeholder="-"
        />
        {!data.id && (
          <Button
            variant="contained"
            style={{ marginTop: "0.7rem" }}
            color="primary"
            type="submit"
            className="backButton"
          >
            <span>차트번호로 DB불러오기</span>
          </Button>
        )}
      </form>,
      <Checkbox
        name="visitDate"
        checked={data.visitDate != null}
        onChange={() => {}}
        color="secondary"
      />,
      <Checkbox
        name="cure"
        checked={data.cure == "진행"}
        onChange={(e) => {
          // setUiData(
          //   produce((draft) => {
          //     draft.visit.cure = e.target.checked ? "진행" : "미진행";
          //   }),
          // );
        }}
        color="secondary"
      />,
    ],
  ];

  const items = itemColumnList.map((itemDataList, i) => (
    <div key={i} className="columnWrapper">
      {itemDataList.map((itemData, j) => (
        <div key={j}>{itemData}</div>
      ))}
    </div>
  ));

  return <div className="dbInfoItem">{items}</div>;
}

export default function DbInfo({
  uiData,
  setUiData,
  deleteRecall,
  addRecall,
  channelList,
  csList,
  openAlert,
  visitDetailTm,
  onClickApply,
  isEdit,
  doctorList,
}) {
  const { openConfirm } = useAlert();

  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const type = event.target.type;
      const classList = event.target.classList;
      let value = event.target.value;

      if (name === "phone")
        value = String(value)
          .replace(/[^0-9]/g, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

      if (type === "checkbox") value = event.target.checked;
      else if (classList && classList.contains("numberInput")) {
        value = Number(value.replace(/[^0-9]/g, ""));
        if (classList.contains("year")) {
          value = Math.max(0, Math.min(9999, value));
        } else if (classList.contains("month")) {
          value = Math.max(1, Math.min(12, value));
        } else if (classList.contains("day")) {
          value = Math.max(1, Math.min(31, value));
        } else if (classList.contains("percent")) {
          value = Math.max(0, Math.min(100, value));
        }
        value = String(value);
      } else if (classList && classList.contains("phoneNumberInput"))
        value = value.replace(/[^0-9|-]/g, "");

      setUiData(
        produce((draft) => {
          draft.visit[name] = value;
        }),
      );
    },
    [setUiData],
  );

  const onClick = React.useCallback(
    async (event) => {
      if (uiData.recalls.length) {
        const confirm = await openConfirm(
          "정말 리콜 정보를 삭제하시겠습니까?",
          "경고",
        );

        if (confirm) {
          for (const recall of uiData.recalls) {
            if (recall.id) {
              await deleteRecall(recall.id);
            }
          }
          setUiData(
            produce((draft) => {
              draft.recalls.length = 0;
            }),
          );
        }
      } else {
        if (!uiData.visit.id) {
          openAlert("먼저 DB를 등록해야합니다");
          return;
        }
        const newData = {
          visit: uiData.visit.id,
          head: null,
          callCount: 1,
          result: false,
          etc: "",
        };
        await addRecall(newData);
        visitDetailTm.refetch();
      }
    },
    [
      uiData,
      setUiData,
      openConfirm,
      openAlert,
      deleteRecall,
      addRecall,
      visitDetailTm,
    ],
  );

  return (
    <div>
      <div className="dbInfo">
        <div className="splitBlocks dbInfo">
          <div className="splitBlock">DB 유입경로</div>
          <div className="splitBlock">DB 고객 정보</div>
          <div className="splitBlock">내원 기록</div>
        </div>
        <DbInfoHeader />
        <DbInfoItem
          {...{
            uiData,
            setUiData,
            onChange,
            onClick,
            channelList,
            csList,
            doctorList,
          }}
        />
      </div>

      {isEdit && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: ".8rem .8rem 0 0 ",
          }}
        >
          {/* <Button
            variant="contained"
            color="secondary"
            className="applyButton"
            onClick={onClickApply}
          >
            <IoIosSend size="1.6rem" />
            <span>수정</span>
          </Button> */}
        </div>
      )}
    </div>
  );
}
