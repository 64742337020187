import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

export const updateCounselingPossibility = async (
  visitID,
  ignoreRecallPossibility = true,
) => {
  const visit = await axios.get(`/visits/${visitID}`);

  if (ignoreRecallPossibility && visit.possibility < 80) {
    return false;
  }

  const count = await axios.get(
    `/counselings/count?visit=${visitID}&recallRequestDate_null=false`,
  );

  if (count && visit.possibility !== 80) {
    await axios.put(`/visits/${visitID}`, { possibility: 80 });
  } else if (!count && visit.possibility !== 100) {
    await axios.put(`/visits/${visitID}`, { possibility: 100 });
  }
};

// 추가
export const useAddCounseling = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/counselings", data);
      await axios.put(`/visits/${res.visit.id}`, { lastCounselingID: res.id });
      await updateCounselingPossibility(res.visit.id);

      return res;
    },
    {
      loadingMessage: "상담 정보를 추가 중입니다",
      successMessage: "상담 정보가 추가되었습니다",
    },
  );
};

// 삭제
export const useDeleteCounseling = () => {
  return useLoadingMutation(
    async (counselingId) => {
      const res = await axios.delete(`/counselings/${counselingId}`);

      const visitID = res.visit.id;

      const [item] = await axios.get(
        `/counselings?visit=${visitID}&_sort=id:DESC&_limit=1`,
      );

      const lastCounselingID = item ? item.id : null;

      await axios.put(`/visits/${visitID}`, { lastCounselingID });

      await updateCounselingPossibility(res.visit.id);

      return res;
    },
    {
      loadingMessage: "상담을 삭제 중입니다",
      successMessage: "상담이 삭제 되었습니다",
    },
  );
};

// 수정
export const useUpdateCounseling = () => {
  return useLoadingMutation(
    async ([counselingId, data]) => {
      const res = await axios.put(`/counselings/${counselingId}`, data);
      await updateCounselingPossibility(res.visit.id);
      return res;
    },
    {
      loadingMessage: "상담 정보를 수정 중입니다",
      successMessage: "상담이 수정되었습니다",
    },
  );
};

// 조회
export const useGetCounselingItem = (queryString = "", options) => {
  return useQuery(
    ["Counseling", queryString],
    async () => {
      const data = await axios.get(
        `/counselings${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};
