import React from "react";
import { Line, Bar } from "react-chartjs-2";
import { chartColors } from "~utils/chart";
import moment from "moment";

import "./statisticsChart_2.scss";

function StatisticsChart_2({ salesData, targetDataset }) {
  const counselingStatistics = [];
  const progressStatistics = [];
  const counselingAmountStatistics = [];
  const progressAmountStatistics = [];
  const sorted = [...salesData].sort(
    (a, b) => Date.parse(a.date) - Date.parse(b.date),
  );

  const multiYear =
    sorted.length &&
    moment(sorted[0].date).year() !==
      moment(sorted[sorted.length - 1].date).year();
  for (const data of sorted) {
    const date = moment(data.date);

    const labelStr =
      (multiYear ? `${date.year()}년 ` : "") +
      `${date.month() + 1}월` +
      (targetDataset === "daily"
        ? `${date.date()}일`
        : targetDataset === "weekly"
        ? `${data.week_of_month}주`
        : "");

    counselingStatistics.push([labelStr, parseInt(data.counseling_count)]);
    progressStatistics.push([labelStr, parseInt(data.progress_count)]);
    counselingAmountStatistics.push([
      labelStr,
      parseInt(data.counseling_amount),
    ]);
    progressAmountStatistics.push([labelStr, parseInt(data.progress_amount)]);
  }

  const lineChartData_1 = {
    datasets: [
      {
        label: "상담환자수",
        data: counselingStatistics.map((x) => x[1]),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        pointBackgroundColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
    labels: counselingStatistics.map((x) => x[0]),
  };

  const lineChartData_2 = {
    datasets: [
      {
        label: "진행환자수",
        data: progressStatistics.map((x) => x[1]),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        pointBackgroundColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
    labels: progressStatistics.map((x) => x[0]),
  };

  const barChartData_1 = {
    datasets: [
      {
        label: "총 상담금액",
        data: counselingAmountStatistics.map((x) => x[1]),
        backgroundColor: "rgba(255, 205, 86, 0.25)",
        borderColor: "rgba(255, 205, 86, 0.75)",
        borderWidth: 1,
      },
      {
        label: "총 계약금액",
        data: progressAmountStatistics.map((x) => x[1]),
        backgroundColor: "rgba(54, 162, 235, 0.25)",
        borderColor: "rgba(54, 162, 235, 0.75)",
        borderWidth: 1,
      },
    ],
    labels: counselingAmountStatistics.map((x) => x[0]),
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: "12",
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const barCartOptions = {
    ...options,
    scales: {
      x: {
        offset: true,
        padding: 20,
      },
    },
  };

  return (
    <div className="statisticsChart_2">
      <div className="lineChart_1">
        <span>상담환자수</span>
        <Line data={lineChartData_1} options={options} />
      </div>
      <div className="lineChart_2">
        <span>진행환자수</span>
        <Line data={lineChartData_2} options={options} />
      </div>
      <div className="barChart_1">
        <span>총 상담금액 / 총 계약금액</span>
        <Bar data={barChartData_1} options={barCartOptions} />
      </div>
    </div>
  );
}

export default React.memo(StatisticsChart_2);
