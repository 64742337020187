import React from "react";
import clsx from "clsx";
import { useRecoilValue } from "recoil";

import { overlayLoadingState } from "~global/stores/ui";

import "./overlayLoading.scss";
import LoadingIcon from "~icon/loading.gif";

export default function OverlayLoading({ className, dark }) {
  const state = useRecoilValue(overlayLoadingState);

  return (
    <div
      className={clsx(
        "UI-overlayLoading",
        dark && "UI-overlayLoading_darkMode",
        !state.open && "hide",
        className,
      )}
    >
      <img src={LoadingIcon} alt="icon" />

      {state.text && (
        <div className="text" style={{ textAlign: "center" }}>
          {state.text.split("\n").map((v, i) => (
            <p key={i}>{v}</p>
          ))}
        </div>
      )}

      {state.subText && (
        <div
          className="text"
          style={{ textAlign: "center", fontSize: "1rem", opacity: ".4" }}
        >
          ({state.subText})
        </div>
      )}
    </div>
  );
}
