import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import "./topNavigationItem.scss";

export default function TopNavigationItem({ text, basePath, to }) {
  const location = useLocation();
  const className = classNames("topNavigationItem", {
    active: location.pathname.startsWith(basePath),
  });

  return (
    <div className={className}>
      <Link to={to}>{text}</Link>
    </div>
  );
}
