import React, { useEffect } from "react";
import instanceAxios from "~apis/_util";

import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";

import "./introduceStatistics.scss";
import Pazing from "~/global/components/tamplete/pazing/Pazing";
import SearchBox from "../../components/introduceStatistics/searchBox/SearchBox";

import Table from "~/global/components/tamplete/table/table";

import moment from "moment";

import { useFormik } from "formik";

import { Button } from "@material-ui/core";
import qs from "qs";

export default function IntroduceStatistics() {
  const [introInfo, setIntroInfo] = React.useState([]);

  const history = useHistory();
  const location = useLocation();
  const { chartNumber = null } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { chNumber = null } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { startDate = null } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { endDate = null } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { moneySort = null } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const { introSort = null } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [ch_people, setCh_people] = React.useState([]);

  const { data = [] } = useQuery(
    [chartNumber, chNumber, startDate, endDate, moneySort, introSort],
    async () => {
      const res = await instanceAxios.post(`/visits/introduce_people`, {
        chartNumber,
        chNumber,
        startDate,
        endDate,
        moneySort,
        introSort,
      });
      return res;
    },
  );

  const { chartNumberPeople = [] } = useQuery([chartNumber], async () => {
    const res = await instanceAxios.get(`/visits?chartNumber=${chartNumber}`);
    setCh_people(res);
    return res;
  });

  const { zxcz = [] } = useQuery([chartNumber, "asdasd"], async () => {
    const res = await instanceAxios.post(`/visits/intro_info`, {
      chartNumber,
    });

    setIntroInfo(res);
    return res;
  });

  const introFormik = useFormik({
    initialValues: {
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      chNumber: "",
    },
    onSubmit: (e) => {
      const v = Object.entries(introFormik.values).filter((x) => x[1]);
      const getSqlString = v.map((x) => `${x[0]}=${x[1]}`).join("&");

      history.push(
        `/counselor/introduceStatistics?${getSqlString}${
          chartNumber ? `&chartNumber=${chartNumber}` : ""
        }`,
      );
    },
  });

  const introduceBtnClick = (x) => {
    const nowUrl = new URL(window.location.href);
    const startDateUrl = nowUrl.searchParams.get("startDate");
    const endDateUrl = nowUrl.searchParams.get("endDate");
    const chNumber = nowUrl.searchParams.get("chNumber");

    const arr = [
      ["startDate", startDateUrl],
      ["endDate", endDateUrl],
      ["chNumber", chNumber],
    ].filter((v) => v[1]);

    history.push(
      `/counselor/introduceStatistics?chartNumber=${x.chartNumber}${
        arr.length ? `&${arr.map((v) => `${v[0]}=${v[1]}`).join("&")}` : ""
      }`,
    );
  };

  const TableTitle = chartNumber
    ? [
        "",
        "내원날짜",
        "차트번호",
        "이름",
        "지역",
        "내원경로",
        "실장",
        "상담총액",
      ]
    : [
        "",
        "순위",
        "소개환자성함",
        "환자차트번호",
        "환자 내원경로",
        "소개환자상담금액",
        "소개한 환자수",
        "보기",
      ];

  // "상담총액", x?.referTotal && (x.referTotal * 1).toLocaleString(),
  const TableData = data.map((x, key) => {
    return chartNumber
      ? [
          "",
          moment(x?.visitDate).format("YYYY-MM-DD"),
          x?.chartNumber,
          x?.name,
          x?.address1,
          x?.referralPatientVisitRoute,
          x?.doctor?.name,
          x?.counselingTotal,
        ]
      : [
          "",
          key + 1,
          x?.name,
          x.chartNumber,
          x?.referralPatientVisitRoute,
          `${x?.referTotal && (x.referTotal * 1).toLocaleString()}원`,
          x?.referCount && `${(x.referCount * 1).toLocaleString()}명`,
          <td>
            <span className="introduceBtn">
              <Button onClick={() => introduceBtnClick(x)}>
                소개한 환자들 보기
              </Button>
            </span>
          </td>,
        ];
  });
  const isFetching = !data.length;

  const sortEvent = (name) => {
    const data = { moneySort, introSort };
    data[name] = data[name] === "DESC" ? "ASC" : "DESC";
    const obj = {
      chartNumber,
      chNumber,
      startDate,
      endDate,
    };

    const f = Object.entries(obj).filter((x) => x[1]);
    const searchGet = `${name}=${data[name]}`;

    history.push(
      `/counselor/introduceStatistics?${searchGet}${
        f.length ? "&" + f.map((x) => `${x[0]}=${x[1]}`).join("&") : ""
      }`,
    );
  };

  return (
    <div className="counseling">
      <span className="viewTitle">소개환자 통계</span>
      <SearchBox introFormik={introFormik} introInfo={introInfo} />

      {chartNumber ? (
        <div>
          <p>소개환자 이름 : {ch_people?.[0]?.name}</p>
          <p>차트번호 : {ch_people?.[0]?.chartNumber}</p>
          <p>
            내원 경로 :{" "}
            {ch_people?.[0]?.ReferralPatientVisitRoute
              ? ch_people?.[0]?.ReferralPatientVisitRoute
              : "없음"}
          </p>
        </div>
      ) : null}

      <div className="sortButtons">
        <Button
          variant="contained"
          color="primary"
          onClick={() => sortEvent("moneySort")}
        >
          금액 정렬 {moneySort && (moneySort === "ASC" ? "▲" : "▼")}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => sortEvent("introSort")}
        >
          소개환자수 정렬 {introSort && (introSort === "ASC" ? "▲" : "▼")}
        </Button>
      </div>

      <div className="table introTableWidth">
        {isFetching ? (
          <p>데이터가 존재하지 않습니다.</p>
        ) : (
          <Table
            count={data.length}
            value={data}
            Type="TM"
            {...{ TableTitle, TableData, isFetching }}
          />
        )}
      </div>

      <div className="Controls">
        {/* <Pazing
          pages={pages}
          // searchQuery={searchQuery}
          // setSearchQuery={setSearchQuery}
        /> */}
      </div>
    </div>
  );
}
