import { useQuery } from "react-query";
import axios, { useLoadingMutation } from "./_util";

// 추가
export const useAddBroadcasting = () => {
  return useLoadingMutation(
    async (data) => {
      const res = await axios.post("/broadcastings", data);

      return res;
    },
    {
      loadingMessage: "채널 정보를 추가 중입니다",
      successMessage: "채널이 추가되었습니다",
    },
  );
};

// 삭제
export const useDeleteBroadcasting = () => {
  return useLoadingMutation(
    async (broadcastingID) => {
      const res = await axios.delete(`/broadcastings/${broadcastingID}`);

      return res;
    },
    {
      loadingMessage: "채널 삭제 중입니다",
      successMessage: "채널이 삭제 되었습니다",
    },
  );
};

// 수정
export const useUpdateBroadcasting = () => {
  return useLoadingMutation(
    async ([broadcastingID, data]) => {
      const res = await axios.put(`/broadcastings/${broadcastingID}`, data);
      return res;
    },
    {
      loadingMessage: "채널 정보를 수정 중입니다",
      successMessage: "채널이 수정되었습니다",
    },
  );
};

// 아이템 조회
export const useGetBroadcastingItem = (queryString = "", options) => {
  return useQuery(
    ["Broadcasting", queryString],
    async () => {
      const data = await axios.get(
        `/broadcastings${queryString.length ? `/${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 조회
export const useGetBroadcastingList = (queryString = "", options) => {
  return useQuery(
    ["BroadcastingList", queryString],
    async () => {
      const data = await axios.get(
        `/broadcastings/list${queryString.length ? `?${queryString}` : ""}`,
      );

      return data;
    },
    options,
  );
};

// 목록 아이템 수 조회
export const useGetBroadcastingListCount = (queryString = "", options) => {
  return useQuery(
    ["BroadcastingListCount", queryString],
    async () => {
      const data = await axios.get(
        `/broadcastings/list/count${
          queryString.length ? `?${queryString}` : ""
        }`,
      );

      return data;
    },
    options,
  );
};
