import { useFormik } from "formik";
import React, { useMemo, useState } from "react";
import DataGrid, { SelectColumn } from "react-data-grid";
import { useQuery } from "react-query";
import copy from "copy-text-to-clipboard";

import TextInput from "~ui/textInput/TextInput";
import Icon from "@material-ui/core/Icon";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Button } from "@material-ui/core";
// import { Button, Radio, FormControlLabel, Tooltip } from "@material-ui/core";

import SmsDialog from "../../components/sms/dialog/SmsDialog";

import strapiAxios from "~/global/apis/_util";
import "./sms.scss";

function rowKeyGetter(row) {
  return row.id;
}

function FilterRenderer(name, formik, type = "input") {
  return {
    headerCellClass: "filter-cell",
    headerRenderer: (props) => {
      return (
        <>
          <div>{props.column.name}</div>
          <div>
            <input name={name} onChange={formik.handleChange} />
          </div>
        </>
      );
    },
  };
}

function Sms() {
  // 모달 설정들
  // const [modalState, setMdoalState] = React.useState(false);

  // const handleClickOpen = () => {
  //   setMdoalState(true);
  // };

  // const handleClose = React.useCallback(() => {
  //   setMdoalState(false);
  // }, []);
  // 모달 설정들

  const { data: rows = [], isFetching } = useQuery("smsVisits", async () => {
    const res = await strapiAxios.get(
      "/visits?_limit=-1&_sort=created_at:DESC",
    );
    return res;
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      chartNumber: "",
      name: "",
      phone: "",
      age: "",
      address1: "",
      status: "",
      counselor: "",
      inflowRouteDetail: "",
    },
  });

  const [selectedRows, setSelectedRows] = useState(() => new Set());

  const columns = useMemo(
    () => [
      SelectColumn,
      { key: "id", name: "#", ...FilterRenderer("id", formik) },
      {
        key: "chartNumber",
        name: "차트번호",
        ...FilterRenderer("chartNumber", formik),
      },
      { key: "name", name: "이름", ...FilterRenderer("name", formik) },
      { key: "phone", name: "전화번호", ...FilterRenderer("phone", formik) },
      { key: "age", name: "나이", ...FilterRenderer("age", formik) },
      { key: "address1", name: "지역", ...FilterRenderer("address1", formik) },
      { key: "status", name: "상태", ...FilterRenderer("status", formik) },
      {
        key: "counselor.name",
        name: "담당자",
        ...FilterRenderer("counselor", formik),
        formatter: ({ row }) => row?.counselor?.name || "",
      },
      {
        key: "inflowRouteDetail",
        name: "세부경로",
        ...FilterRenderer("inflowRouteDetail", formik),
      },
    ],
    [],
  );

  const filterRows = rows.filter(
    (x) =>
      String(x.id).includes(formik.values.id) &&
      String(x.chartNumber).includes(formik.values.chartNumber) &&
      String(x.name).toLowerCase().includes(formik.values.name.toLowerCase()) &&
      String(x.phone).includes(formik.values.phone) &&
      String(x.age).includes(formik.values.age) &&
      String(x.address1).includes(formik.values.address1) &&
      String(x.status).includes(formik.values.status) &&
      String(x?.counselor?.name || "").includes(formik.values.counselor) &&
      String(x.inflowRouteDetail)
        .toLowerCase()
        .includes(formik.values.inflowRouteDetail.toLowerCase()),
  );

  const handleCopyPhone = () => {
    const phones = rows
      .reduce((acc, item) => {
        if (selectedRows.has(item.id)) {
          acc.push(item.phone);
        }
        return acc;
      }, [])
      .join("\n");

    copy(phones);

    alert("전화번호가 복사되었습니다.");
  };
  return (
    <>
      <div className="sms">
        <div className="viewTitle">문자 관리</div>

        {isFetching && (
          <div className="loading">
            잠시만 기다려주세요.
            <br />
            모든 데이터를 불러오는 중입니다.
          </div>
        )}

        {!isFetching && (
          <>
            <div className="selectedCount">
              선택된 환자: {selectedRows.size.toLocaleString()}명
            </div>
            <DataGrid
              className="dataGrid"
              columns={columns}
              rows={filterRows}
              rowKeyGetter={rowKeyGetter}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              headerRowHeight={70}
              enableVirtualization
            />

            {/* handleClickOpen */}

            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopyPhone}
              >
                선택한 환자 전화번호 복사하기
              </Button>
            </div>
          </>
        )}
      </div>

      {/* <SmsDialog 
    handleClose={handleClose}
    modalState={modalState}
    />
     */}
    </>
  );
}

export default Sms;
