import React from "react";
import produce from "immer";
import { useRecoilState } from "recoil";
import { alertState } from "~global/stores/ui";

let promiseResolver = null;

export default function useAlert() {
  const [state, setState] = useRecoilState(alertState);

  const openAlert = (content = "", title = "알림") =>
    new Promise((res) => {
      promiseResolver = res;

      setState({
        open: true,
        type: "alert",
        title,
        content,
      });
    });

  const openConfirm = (content = "", title = "확인해주세요!") =>
    new Promise((res) => {
      promiseResolver = res;

      setState({
        open: true,
        type: "confirm",
        title,
        content,
      });
    });

  const closeAlert = (result = true) => {
    setState(
      produce((v) => {
        v.open = false;
        v.result = result;
      }),
    );
  };

  React.useEffect(() => {
    if (!state.open && promiseResolver) {
      promiseResolver(state.result);
      promiseResolver = null;
    }
  }, [state.open]);

  return { openAlert, openConfirm, closeAlert };
}
