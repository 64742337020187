import React from "react";

import SelectInput from "~ui/selectInput/SelectInput";

import { useGetCSList } from "~apis/counselors";
import produce from "immer";
import moment from "moment";
import Table from "~/global/components/tamplete/table/table";
import Pazing from "~/global/components/tamplete/pazing/Pazing";
import { FaSearch } from "react-icons/fa";
import { Button, MenuItem } from "@material-ui/core";
import {
  useGetVisitsItem,
  useGetVisitsCount,
  useGetVisitsTotal,
} from "~apis/visits";
import ControlsButton from "~/global/components/tamplete/controlsbutton/ControlsButton";
import qs from "qs";
import { useGetHeadList } from "~apis/head";
import { useGetDoctorList } from "~apis/doctors";
import { useGetChannelList } from "~apis/channels";

import TextInput from "~ui/textInput/TextInput";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import koLocale from "date-fns/locale/ko";
import { isCheckFn } from "~global/apis/channels";

import "./StatisticsManagementDay2Day.scss";

const repeatVisitValue = {
  "": "",
  신환: false,
  구환: true,
  "신환+구환": "",
};

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") {
      delete obj[key];
    }
  });

  return obj;
};

const counselingList = [
  "임플란트",
  "교정",
  "인비절라인",
  "보철치료",
  "라미네이트",
  "일반진료",
];

const cureList = ["진행", "미진행", "보험비용"];

export default function StatisticsManagementDay2Day() {
  let value = [];

  const { data: csList = [] } = useGetCSList();
  const { data: doctorList = [] } = useGetDoctorList();
  const { data: headList = [] } = useGetHeadList();
  const { data: _channelList = [] } = useGetChannelList();

  const [detailData, setDetailData] = React.useState({
    startDate: moment().format("YYYY-MM-01"),
    endDate: moment().add(1, "month").format("YYYY-MM-01"),
    startMoney: "",
    endMoney: "",
    _sort: "",
    counseling: "",
    head: "",
    doctor: "",
    ad: "",
    agency: "",
    channel: "",
    cure: "",
    repeatVisit: "",
  });

  const adList = [...new Set(_channelList.map(({ ad }) => ad))];

  const agencyList = [
    ...new Set(
      _channelList
        .filter(({ ad }) => ad === detailData.ad)
        .map(({ agency }) => agency),
    ),
  ];

  const channelList = isCheckFn(
    _channelList.filter(({ agency }) => agency === detailData.agency),
    "channelName",
  ).map(({ channelName }) => channelName);

  const [searchQuery, setSearchQuery] = React.useState({
    page: 1,
  });

  const [searchData, setSearchData] = React.useState({
    startDate: detailData.startDate,
    endDate: detailData.endDate,
  });

  const onChange = React.useCallback((event, getName) => {
    const name = event?.target ? event?.target?.name : getName;
    const value = event?.target ? event?.target?.value : event;

    setDetailData(
      produce((draft) => {
        draft[name] = value;

        if (name === "agency") {
          draft.channel = "";
        }
      }),
    );
  }, []);

  const query = {
    visitDate_gte: searchData?.startDate,
    visitDate_lte: searchData?.endDate,
    counseling: searchData.counseling,
    head: searchData.head,
    doctor: searchData.doctor,
    inflowRoute: searchData.ad,
    inflowRouteDetail: searchData.agency,
    inflowRouteDetailContent: searchData.channel,
    cure: searchData.cure,
    isRepeatVisit: repeatVisitValue[searchData.repeatVisit],
    counselingTotal_gte: detailData?.startMoney,
    counselingTotal_lte: detailData?.endMoney,
    _sort: detailData._sort,
  };

  // counselingTotal:DESC
  const queryStr = qs.stringify({ ...removeEmpty(query) });

  const limitQueryStr = qs.stringify(
    removeEmpty({
      ...query,
      type: "head",
      _start: (searchQuery.page - 1) * 10,
      _limit: 10,
    }),
  );

  const { isFetching, isError, data, refetch } =
    useGetVisitsItem(limitQueryStr);

  const count = useGetVisitsCount(queryStr).data;
  const Total = useGetVisitsTotal(queryStr).data;

  const pagingData = useGetVisitsCount(queryStr).data;

  const pages = Math.ceil(pagingData / 20);

  const TableTitle = [
    "상태",
    "내원날짜",
    "차트번호",
    "성명",
    "나이",
    "지역",
    "광고",
    "업체",
    "채널",
    "상담항목",
    "Dr",
    "실장",
    "TM",
    "진행여부",
    "상담총액",
    "수납내역",
  ];
  const TableData = [];

  return (
    <div className="statisticsManagementDay2Day">
      <div className="viewTitle">기간별 DB 통계</div>
      <div className="UpSearchLayer">
        <div className="TotalTamplete">
          <div>
            <p>
              총 DB 개수{" "}
              <span>
                <b>{count}</b>개
              </span>
            </p>
          </div>
          {/* <div>
            <p>
              총 DB당 단가{" "}
              <span>
                <b>{Total?.avg.toLocaleString()}</b>원
              </span>
            </p>
          </div> */}
          <div>
            <p>
              총 상담금액{" "}
              <span>
                <b>{Total?.sumCT.toLocaleString()}</b>원
              </span>
            </p>
            <p>
              총 수납금액{" "}
              <span>
                <b>{Total?.sum.toLocaleString()}</b>원
              </span>
            </p>
          </div>
        </div>
        <div className="GlobalsearchBar" style={{ width: "100%" }}>
          <div>
            <span>기간검색</span>
          </div>

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={koLocale}>
            <DatePicker
              format="yyyy/MM"
              views={["year", "month"]}
              onChange={(e) => onChange(e, "startDate")}
              className="startDateInput"
              value={detailData.startDate}
              style={{ width: "16.5rem", color: "white" }}
            />

            <span style={{ margin: "0 1rem" }}>~</span>
            <DatePicker
              format="yyyy/MM"
              views={["year", "month"]}
              onChange={(e) => onChange(e, "endDate")}
              className="endDateInput"
              value={detailData.endDate}
              style={{ width: "16.5rem", color: "white" }}
            />
          </MuiPickersUtilsProvider>

          <div className="money-inputs">
            <TextInput
              placeholder="상담총액"
              name="startMoney"
              value={detailData.startMoney}
              onChange={(e) => onChange(e, "startMoney")}
            />
            <p>~</p>
            <TextInput
              placeholder="상담총액"
              name="endMoney"
              value={detailData.endMoney}
              onChange={(e) => onChange(e, "endMoney")}
            />
          </div>

          <Button
            variant="contained"
            color="secondary"
            className="searchButton"
            onClick={() => {
              setSearchData({ ...detailData });
              // setSearchData({ counselor: detailData.counselor })
            }}
          >
            <span>Search</span>
            <FaSearch size="2.2rem" />
          </Button>
        </div>

        <div
          className="GlobalsearchBar GlobalsearchBar-filter"
          style={{ width: "100%", marginTop: ".5rem" }}
        >
          <div>
            <SelectInput
              className="selectName"
              name="counselor"
              value={detailData.counselor}
              placeholder="-TM-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {csList.map((v, i) => (
                <MenuItem value={v.id}>{v.name}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="head"
              value={detailData.head}
              placeholder="-실장-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {headList.map((v, i) => (
                <MenuItem value={v.id}>{v.name}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="doctor"
              value={detailData.doctor}
              placeholder="-원장-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {doctorList.map((v, i) => (
                <MenuItem value={v.id}>{v.name}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="ad"
              value={detailData.ad}
              placeholder="-광고-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {adList.map((v, i) => (
                <MenuItem value={v}>{v}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="agency"
              value={detailData.agency}
              placeholder="-업체-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {agencyList.map((v, i) => (
                <MenuItem value={v}>{v}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="channel"
              value={detailData.channel}
              placeholder="-채널-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {channelList.map((v, i) => (
                <MenuItem value={v}>{v}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="counseling"
              value={detailData.counseling}
              placeholder="-상담항목-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {counselingList.map((v, i) => (
                <MenuItem value={v}>{v}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="cure"
              value={detailData.cure}
              placeholder="-결과-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              {cureList.map((v, i) => (
                <MenuItem value={v}>{v}</MenuItem>
              ))}
            </SelectInput>
          </div>

          <div>
            <SelectInput
              className="selectName"
              name="repeatVisit"
              value={detailData.repeatVisit}
              placeholder="-매출-"
              onChange={onChange}
              style={{
                height: "3rem",
              }}
            >
              <MenuItem value="신환">신환계약매출</MenuItem>
              <MenuItem value="구환">구환계약매출</MenuItem>
              <MenuItem value="신환+구환">신환+구환 계약매출</MenuItem>
            </SelectInput>
          </div>

          <Button
            variant="contained"
            color="secondary"
            className="searchButton"
            onClick={() => {
              setSearchData({ ...detailData });
              // setSearchData({ counselor: detailData.counselor })
            }}
          >
            <span>Search</span>
            <FaSearch size="2.2rem" />
          </Button>
        </div>
      </div>

      <div className="counseling_sortButtonDiv">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setDetailData(
              produce((draft) => {
                draft._sort =
                  detailData._sort === "counselingTotal:DESC"
                    ? "counselingTotal:ASC"
                    : "counselingTotal:DESC";

                return draft;
              }),
            );
          }}
        >
          상담 총액 정렬{" "}
          {detailData?._sort &&
            (detailData._sort === "counselingTotal:DESC" ? "▼" : "▲")}
        </Button>
      </div>

      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}

      {!isFetching && !isError && data && (
        <>
          {data.map((x) => {
            TableData.push([
              x.isRepeatVisit ? "구환" : "신환",
              x.visitDate,
              x.chartNumber,
              x.name,
              x.age ? `${x.age}세` : "",
              x.address1,
              x.inflowRoute,
              x.inflowRouteDetail,
              x.inflowRouteDetailContent || "",
              x.counseling,
              x?.doctor?.name || "-",
              x?.head?.name || "-",
              x?.counselor?.name || "-",
              x.cure,
              x.counselingTotal?.toLocaleString?.(),
              x.payment?.toLocaleString?.(),
            ]);
          })}

          <div className="Tables tm">
            <Table
              count={pagingData}
              value={value}
              Type="TMD2D"
              {...{ TableTitle, TableData }}
            />
          </div>

          <div className="Controls">
            <Pazing
              pages={pages}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />

            <ControlsButton
              ButtonFreeSet="Excel"
              type="TMD2D"
              tmQuery={{ query: qs.stringify({ ...removeEmpty(query) }) }}
            />
          </div>
        </>
      )}
    </div>
  );
}
