import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "~/global/components/tamplete/title/title";
import EditForm from "~/global/components/tamplete/editForm/EditForm";
import { useGetUserItem } from "~apis/users";

import "./AddUserEdit.scss";

export default function AddUserEdit() {
  const { id } = useParams();

  const { isFetching, isError, data } = useGetUserItem(id);

  if (isFetching) return <div style={{ marginTop: "2rem" }}>로딩 중...</div>;

  const inputs = [
    { label: "아이디", type: "text", name: "username", value: data.username },
    { label: "비밀번호", type: "password", name: "password", value: "" },
    { label: "이름", type: "text", name: "name", value: data.name },
    {
      label: "권한",
      type: "_select",
      name: "access",
      options: [
        { label: "총관리자", value: "총관리자" },
        { label: "원장", value: "원장" },
        { label: "실장", value: "실장" },
        { label: "마케팅팀", value: "마케팅팀" },
        { label: "CS팀", value: "CS팀" },
        { label: "TM팀장", value: "TM팀장" },
        { label: "TM팀A", value: "TM팀A" },
        { label: "TM팀B", value: "TM팀B" },
        { label: "TM팀C", value: "TM팀C" },
        // { label: "TM팀D", value: "TM팀D" },
        { label: "외부입력A", value: "외부입력A" },
        { label: "외부입력B", value: "외부입력B" },
      ],
      value: data.access || "총관리자",
    },
  ];

  const Form = id ? "editUser" : "addUser";

  return (
    !isError && (
      <div className="addUserEdit">
        <PageTitle title="사용자 추가" />
        <EditForm idx={id} form={Form} {...{ inputs }} />
      </div>
    )
  );
}
