import React from "react";
import { Button } from "@material-ui/core";
import StatisticsChart_1 from "~/doctor/components/channelEvaluation/statisticsChart_1/StatisticsChart_1";
import StatisticsChart_2 from "~/doctor/components/channelEvaluation/statisticsChart_2/StatisticsChart_2";
import SearchBar from "~/doctor/components/channelEvaluation/searchBar/SearchBar";
import ChannelEvaluationList from "~/doctor/components/channelEvaluation/channelEvaluationList/ChannelEvaluationList";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useGetChannelEvaluation } from "~apis/channels";
import { CSVLink } from "react-csv";
import qs from "qs";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { csvDataState } from "~/global/stores/recoil";

import "./channelEvaluation.scss";

export default function ChannelEvaluation() {
  const [searchQuery, setSearchQuery] = React.useState({
    year: moment().year(),
    month: moment().month() + 1,
    sortType: "ASC",
  });
  const [queryString, setQueryString] = React.useState("");

  const csvData = useRecoilValue(csvDataState);

  const channelEvaluation = useGetChannelEvaluation(queryString);

  const isFetching = channelEvaluation.isFetching;
  const isError = channelEvaluation.isError;

  React.useEffect(() => {
    setQueryString(
      qs.stringify({
        q_year: searchQuery.year,
        q_month: searchQuery.month,
        q_sortType: searchQuery.sortType,
      }),
    );
  }, []);

  const onClickSearch = React.useCallback(
    (sortType = "ASC") => {
      setQueryString(
        qs.stringify({
          q_year: searchQuery.year,
          q_month: searchQuery.month,
          q_sortType: sortType,
        }),
      );
      channelEvaluation.refetch();
    },
    [searchQuery, setQueryString, channelEvaluation],
  );

  return (
    <div className="channelEvaluation">
      <span className="viewTitle">채널 평가</span>
      {isFetching && <div style={{ marginTop: "2rem" }}>로딩 중...</div>}
      {!isFetching && !isError && (
        <>
          <StatisticsChart_1 evaluationData={channelEvaluation.data} />
          <StatisticsChart_2 evaluationData={channelEvaluation.data} />
          <div className="searchBarArea">
            <div>
              <SearchBar {...{ searchQuery, setSearchQuery, onClickSearch }} />
            </div>
            <Button
              variant="contained"
              color="primary"
              className="excelDownButton"
            >
              <CSVLink
                data={csvData}
                filename={"download.csv"}
                target="_blank"
                className="csvLink"
              >
                <RiFileExcel2Fill size="1.5rem" />
                <span>Excel 다운</span>
              </CSVLink>
            </Button>
          </div>
          <ChannelEvaluationList
            evaluationData={channelEvaluation.data}
            csvDataLength={csvData.length}
          />
        </>
      )}
    </div>
  );
}
