import React from "react";
import { useHistory } from "react-router";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { RiFileExcel2Fill } from "react-icons/ri";
import {
  useAddChannel,
  useUpdateChannel,
  useDeleteChannel,
} from "~apis/channels";
import { useAddDoctor, useUpdateDoctor, useDeleteDoctor } from "~apis/doctors";
import { useAddCS, useUpdateCS, useDeleteCS } from "~apis/counselors";
import { useAddHead, useUpdateHead, useDeleteHead } from "~apis/head";
import { useAddUser, useUpdateUser, useDeleteUser } from "~apis/users";
import { useGetVisitsItem } from "~apis/visits";
import { useDeleteVisits } from "~apis/visits";
import { CSVLink } from "react-csv";
import { IoIosSend } from "react-icons/io";
import { Link } from "react-router-dom";
import moment from "moment";

import qs from "qs";

import jsonToCSV from "~/global/utils/jsonToCsv";

import "./ControlsButton.scss";
import instanceAxios from "~/global/apis/_util";

export default function ControlsButton({
  ButtonFreeSet,
  onClick,
  idx,
  type,
  url,
  DelList,
  refetch,
  deleteCheckedItems,
  handleClickOpen,
  excelUploadBtnClick,
  counselingDeleteChecked,
  counselingExcelBtn,
  setCheckedItems,
  tmQuery = {},
}) {
  const history = useHistory();
  const access = window.localStorage.getItem("user.access");

  const [stateExcelData, setStateExcelData] = React.useState([]);

  let returnHtml = "";

  const { mutateAsync: addChannel } = useAddChannel();
  const { mutateAsync: editChannel } = useUpdateChannel(idx);
  const { mutateAsync: delChannel } = useDeleteChannel();

  const { mutateAsync: addDoctor } = useAddDoctor();
  const { mutateAsync: editDoctor } = useUpdateDoctor(idx);
  const { mutateAsync: delDoctor } = useDeleteDoctor();

  const { mutateAsync: addCS } = useAddCS();
  const { mutateAsync: editCS } = useUpdateCS(idx);
  const { mutateAsync: delCS } = useDeleteCS();

  const { mutateAsync: addHead } = useAddHead();
  const { mutateAsync: editHead } = useUpdateHead(idx);
  const { mutateAsync: delHead } = useDeleteHead();

  const { mutateAsync: addUser } = useAddUser();
  const { mutateAsync: editUser } = useUpdateUser(idx);
  const { mutateAsync: delUser } = useDeleteUser();

  const { mutateAsync: delTM } = useDeleteVisits();

  const { isFetching, data } = useGetVisitsItem();

  const excelData = [];

  const TMD2D = (e) => {
    const k = [
      "#",
      "내원날짜",
      "차트번호",
      "이름",
      "전화번호",
      "나이",
      "광고",
      "업체",
      "채널",
      "담당실장",
      "Doctor",
      "상태",
      "TM",
      "상담총액",
      "내원",
    ];

    instanceAxios.get(`/visits?${tmQuery.query}`).then((vava) => {
      const aaa = vava.map((x) => {
        return [
          x.id,
          x.visitDate ? x.visitDate : "-",
          x.chartNumber,
          x.name,
          x.phone,
          `${x.age}세`,
          x.inflowRoute,
          x.inflowRouteDetail,
          x.inflowRouteDetailContent,
          x?.head?.name ? x?.head?.name : "-",
          x?.doctor?.name ? x?.doctor?.name : "-",
          x.status,
          x?.counselor?.name,
          x.counselingTotal,
          x.visitDate ? "성공" : "-",
        ].reduce((acc, val, valKey) => ({ ...acc, [k[valKey]]: val }), {});
      });

      jsonToCSV(aaa, "통계 관리", true);
    });
  };

  const counselingExcelDown = (e) => {
    const k = [
      "#",
      // "리콜 요청 날짜",
      // "리콜진행수",
      "내원날짜",
      "차트번호",
      "상태",
      "이름",
      "지역",
      "전화번호",
      "상담항목",
      "세부경로",
      "유입경로",
      "상담총액",
      "수납금액",
      "실장",
      "Doctor",
      "치료진행여부",
      "Event DB",
      "나이",
      "비고 및 치료관련 메모",
      "재상담 날짜",
    ];


    instanceAxios.get(`/visits/list?${tmQuery.query}`).then((vava) => {
      const aaa = vava.map((x) => {
        return [
          x.id,
          // x.recall_request_date
          //   ? moment(x.recall_request_date).format("YYYY. MM. DD")
          //   : "-",
          // x.recalls_count || 0,
          x.visitDate ? moment(x.visitDate).format("YYYY. MM. DD") : "-",
          x.chartNumber || "-",
          x.isRepeatVisit ? "구환" : "신환",
          x.name || "-",
          x?.Address1 || "-",
          x.phone || "-",
          x.counseling,
          x.InflowRouteDetailContent || "-",
          x.Address3 || "-",
          x.counselingTotal === 0
            ? "-"
            : parseInt(x?.counselingTotal || 0).toLocaleString?.(),
          x.payment === 0 ? "-" : parseInt(x?.payment || 0).toLocaleString(),
          x.head_name || "",
          x.doctor_name || "-",
          x.cure || "-",
          x.isEventVisit ? "O" : "X",
          x.age || "-",
          x?.stopMemo || "-",
          x?.recallRequestDate ? moment(x.recallRequestDate).format("YYYY. MM. DD") : "-",
        ].reduce((acc, val, valKey) => ({ ...acc, [k[valKey]]: val }), {});
      });

      jsonToCSV(aaa, "실장팀 관리", true);
    });
  };

  const excelGet = (e) => {
    const k = [
      "#",
      "상담항목",
      "재상담",
      "가망여부",
      "상태",
      "예약날짜",
      "TM",
      "원장",
      "세부경로",
      "이름",
      "전화번호",
      "나이",
      "지역",
      "상담총액",
      "차트번호",
      "진행여부",
      "최초 DB 수집일자",
      "최근 상담일",
      "내원",
      "문자거부",
    ];

    instanceAxios.get(`/visits/list_tm?${tmQuery.query}`).then((vava) => {
      const aaa = vava.map((x) => {
        return [
          x.id,
          x.category || "-",
          x.recallRequestDate || "-",
          `${x.possibility}%`,
          x.reservationDate ? "예약완료" : x.status,
          x.reservationDate || "-",
          x.tm_name || "없음",
          x.doctor_name,
          x.inflowRouteDetailContent,
          x.name,
          x.phone,
          x.age ? `${x.age}세` : "-",
          x.address1 ? `${x.address1}` : "-",
          x.counselingTotal
            ? `${Number(x.counselingTotal).toLocaleString()}`
            : "-",
          x?.chartNumber || "-",
          x?.cure || "-",
          moment(x.inflowDate).format("YYYY-MM-DD"),
          x.updated_at_format
            ? moment(x.updated_at_format).format("YYYY-MM-DD")
            : "-",
          x.visitDate ? "내원" : "미내원",
          x.smsSetting ? "O" : "X",
        ].reduce((acc, val, valKey) => ({ ...acc, [k[valKey]]: val }), {});
      });
      //  json형식 파일이름 true
      jsonToCSV(aaa, "TM팀관리", true);
    });
  };

  async function AddEvent() {
    if (type == "addchannel") await addChannel(onClick);
    if (type == "adddoctor") await addDoctor(onClick);
    if (type == "addCS") await addCS(onClick);
    if (type == "addHead") await addHead(onClick);

    if (type == "addUser")
      await addUser({
        ...onClick,
        email: onClick.username + "@crm.standard-dent.com",
      });

    Goback();
  }

  async function EditEvent() {
    if (type == "editchannel") await editChannel(onClick);
    if (type == "editdoctor") await editDoctor(onClick);
    if (type == "editCS") await editCS(onClick);
    if (type == "editHead") await editHead(onClick);

    if (type == "editUser") {
      const data = { ...onClick };

      if (!data.password) {
        delete data.password;
      }

      await editUser(data);
    }

    Goback();
  }

  async function DelEvent() {
    if (type == "editchannel") await delChannel(idx);
    if (type == "editdoctor") await delDoctor(idx);
    if (type == "editCS") await delCS(idx);
    if (type == "editHead") await delHead(idx);
    if (type == "editUser") await delUser(idx);

    Goback();
  }

  function Goback() {
    let url = "";

    if (type == "editchannel" || type == "addchannel")
      url = "/channel/addChannel";
    if (type == "editdoctor" || type == "adddoctor") url = "/admin/addDoctor";
    if (type == "editCS" || type == "addCS") url = "/admin/addCS";
    if (type == "editHead" || type == "addHead") url = "/admin/addCounselor";
    if (type == "editUser" || type == "addUser") url = "/admin/addUser";

    history.push(url);
  }

  function DelArray() {

    if (!DelList.length) {
      return;
    } 

    DelList.map(async (x, i) => {
      if (type == "Channel") await delChannel(x);
      if (type == "Doctor") await delDoctor(x);
      if (type == "CS") await delCS(x);
      if (type == "Head") await delHead(x);
      if (type == "TM") await delTM(x);
      if (type == "User") await delUser(x);

      if (DelList.length == i + 1) refetch();
    });

    if (type == "Channel") setCheckedItems(() => []);
  }

  const excelDownBtnClick = (e) => {};

  switch (ButtonFreeSet) {
    case "1":
      returnHtml = (
        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            onClick={DelEvent}
            className="ControlButton"
          >
            <Icon>close</Icon>
            <span>삭제</span>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={EditEvent}
            className="ControlButton"
          >
            <Icon>description</Icon>
            <span>수정</span>
          </Button>
        </div>
      );
      break;

    case "add":
      returnHtml = (
        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            onClick={AddEvent}
            className="ControlButton"
          >
            <Icon>send</Icon>
            <span>등록</span>
          </Button>
        </div>
      );
      break;

    case "Excel&Seldel":
      returnHtml = (
        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            className="excelDownButton"
          >
            <CSVLink data={excelData} filename={"download.csv"} target="_blank">
              <RiFileExcel2Fill size="1.5rem" />
              <span style={{ fontSize: "0.95rem" }}>Excel 다운</span>
            </CSVLink>
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={deleteCheckedItems}
            className="ControlButton"
          >
            <Icon>close</Icon>
            <span>선택삭제</span>
          </Button>
        </div>
      );
      break;

    case "CounselingPage":
      returnHtml = (
        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            className="excelDownButton"
            onClick={counselingExcelDown}
          >
            <RiFileExcel2Fill size="1.5rem" />
            <span style={{ fontSize: "0.95rem" }}>Excel 다운</span>
          </Button>

          <Link to="/counselor/counseling/detail">
            <Button variant="contained" color="primary" className="addButton">
              <IoIosSend size="1.8rem" />
              <span>신규등록</span>
            </Button>
          </Link>

          {!access.includes("외부입력") && (
            <Button
              variant="contained"
              color="primary"
              onClick={counselingExcelBtn}
              className="addButton"
            >
              <RiFileExcel2Fill size="1.5rem" />
              <span>엑셀 업로드</span>
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            className="counselingDelete"
            onClick={counselingDeleteChecked}
          >
            <Icon size="1.5rem">close</Icon>
            <span style={{ fontSize: "0.95rem" }}>선택 삭제</span>
          </Button>
        </div>
      );
      break;

    case "Excel&Seldel&nameChange":
      returnHtml = (
        <div className="ControlsButton">
          <Link to="/tm/totalManagement/detail">
            <Button variant="contained" color="primary" className="addButton">
              <IoIosSend size="1.8rem" />
              <span>신규등록</span>
            </Button>
          </Link>

          {!["외부입력A", "외부입력B"].includes(access) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              className="ControlButton"
            >
              <span>다중 이름 변경</span>
            </Button>
          )}

          {!["TM팀B", "외부입력A", "외부입력B"].includes(access) && (
            <Button
              variant="contained"
              color="primary"
              className="excelDownButton"
              onClick={excelGet}
            >
              <RiFileExcel2Fill size="1.5rem" />
              <span style={{ fontSize: "0.95rem" }}>Excel 다운</span>
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            className="excelUploadButton"
            onClick={excelUploadBtnClick}
          >
            <RiFileExcel2Fill size="1.5rem" />
            <span style={{ fontSize: "0.95rem" }}>Excel DB 업로드</span>
          </Button>

          {access !== "TM팀C" && (
            <Button
              variant="contained"
              color="primary"
              onClick={deleteCheckedItems}
              className="ControlButton"
            >
              <Icon>close</Icon>
              <span>선택삭제</span>
            </Button>
          )}
        </div>
      );
      break;

    case "Excel":
      returnHtml = (
        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            className="excelDownButton"
            onClick={TMD2D}
          >
            <RiFileExcel2Fill size="1.5rem" />
            <span>Excel 다운</span>
          </Button>
        </div>
      );
      break;

    default:
      const addUrl = () => history.push(url);
      returnHtml = (
        <div className="ControlsButton">
          <Button
            variant="contained"
            color="primary"
            onClick={DelArray}
            className="ControlButton"
          >
            <Icon>close</Icon>
            <span>선택삭제</span>
          </Button>
          <Button
            variant="contained"
            onClick={addUrl}
            color="primary"
            className="ControlButton"
          >
            <Icon>add</Icon>
            <span>추가</span>
          </Button>
        </div>
      );
      break;
  }

  return returnHtml;
}
