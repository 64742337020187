import React from "react";
import { Line, Bar } from "react-chartjs-2";
import { chartColors } from "~utils/chart";

import "./statisticsChart_2.scss";

function StatisticsChart_2({ evaluationData }) {
  const lineChartData_1 = {
    datasets: [
      {
        label: "원장별 매출",
        data: Object.values(evaluationData.statistics.doctors),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        pointBackgroundColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
    labels: Object.keys(evaluationData.statistics.doctors),
  };

  const lineChartData_2 = {
    datasets: [
      {
        label: "실장별 매출",
        data: Object.values(evaluationData.statistics.heads),
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        pointBackgroundColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
    labels: Object.keys(evaluationData.statistics.heads),
  };

  const barChartData_1 = {
    datasets: [
      {
        label: "CS 개인별 매출",
        data: Object.values(evaluationData.statistics.counselors),
        backgroundColor: chartColors(
          Object.values(evaluationData.statistics.counselors).length,
          0.25,
        ),
        borderColor: chartColors(
          Object.values(evaluationData.statistics.counselors).length,
          0.75,
        ),
        borderWidth: 1,
      },
    ],
    labels: Object.keys(evaluationData.statistics.counselors),
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: "12",
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const barCartOptions = {
    ...options,
    scales: {
      x: {
        offset: true,
        padding: 20,
      },
    },
  };

  return (
    <div className="statisticsChart_2">
      <div className="lineChart_1">
        <span>원장별 매출</span>
        <Line data={lineChartData_1} options={options} />
      </div>
      <div className="lineChart_2">
        <span>실장별 매출</span>
        <Line data={lineChartData_2} options={options} />
      </div>
      <div className="barChart_1">
        <span>CS 개인별 매출</span>
        <Bar data={barChartData_1} options={barCartOptions} />
      </div>
    </div>
  );
}

export default React.memo(StatisticsChart_2);
