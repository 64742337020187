import React from "react";
import produce from "immer";
import SimpleSelectInput from "~ui/simpleSelectInput/SimpleSelectInput";
import TextInput from "~ui/textInput/TextInput";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";

import "./counselingInfo.scss";

const StyledSimpleSelectInput = styled(SimpleSelectInput)`
  .MuiSelect-root {
    padding-left: 2.6rem;
    color: black;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    transform: scale(1.3) translate(-0.1rem, 0);
  }
`;

const StyledTextarea = styled.textarea``;

export default function CounselingInfo({
  uiData,
  setUiData,
  headList,
  doctorList,
}) {
  const onChange = React.useCallback(
    (event) => {
      const name = event.target.name;
      const type = event.target.type;
      const classList = event.target.classList;
      let value = event.target.value;

      if (type === "checkbox") value = event.target.checked;
      else if (classList && classList.contains("numberInput")) {
        value = value.replace(/[^0-9]/g, "");
      } else if (classList && classList.contains("phoneNumberInput")) {
        value = value.replace(/[^0-9|-]/g, "");
      } else if (classList && classList.contains("paymentInput")) {
        value = value.replace(/[^0-9]/g, "");
      }

      setUiData(
        produce((draft) => {
          draft.visit[name] = value;
        }),
      );
    },
    [setUiData],
  );

  const progresslList = ["진행", "미진행", "보험비용", "리콜종료"].map(
    (name, i) => (
      <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
        {name}
      </MenuItem>
    ),
  );
  const counselingList = [
    "임플란트",
    "교정",
    "인비절라인",
    "보철치료",
    "라미네이트",
    "일반진료",
  ].map((name, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={name}>
      {name}
    </MenuItem>
  ));

  const counselorNameList = headList.data.map((head, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={head.id}>
      {head.name}
    </MenuItem>
  ));

  const doctorNameList = doctorList.data.map((doctor, i) => (
    <MenuItem style={{ fontSize: "0.75rem" }} key={i} value={doctor.id}>
      {doctor.name}
    </MenuItem>
  ));

  const data = uiData.visit;
  return (
    <div className="counselingInfo">
      <div style={{ gridColumn: "1/2", gridRow: "1/2" }}>치료진행여부</div>
      <div style={{ gridColumn: "2/3", gridRow: "1/2" }}>C.C</div>
      <div style={{ gridColumn: "3/4", gridRow: "1/2" }}>상담내용</div>
      <div style={{ gridColumn: "4/5", gridRow: "1/2" }}>상담총액</div>
      <div style={{ gridColumn: "5/6", gridRow: "1/2" }}>수납내역</div>
      <StyledSimpleSelectInput
        className="progressSelectInput"
        name="cure"
        value={(data.cure === "보험" ? "보험비용" : data.cure) || ""}
        onChange={onChange}
        fontSize="0.75rem"
        placeholder="--"
      >
        {progresslList}
      </StyledSimpleSelectInput>
      <StyledTextarea
        className="ccTextArea"
        name="cc"
        value={data.cc || ""}
        onChange={onChange}
      />
      <StyledTextarea
        className="cententTextArea"
        name="counselingContent"
        value={data.counselingContent || ""}
        onChange={onChange}
      />
      <TextInput
        name="counselingTotal"
        inputClassName="numberInput"
        value={
          data?.counselingTotal && Number(data.counselingTotal).toLocaleString()
        }
        onChange={onChange}
        style={{ gridColumn: "4/5", gridRow: "2/3" }}
      />
      <TextInput
        name="payment"
        inputClassName="paymentInput"
        value={data?.payment && Number(data.payment).toLocaleString()}
        onChange={onChange}
        style={{ gridColumn: "5/6", gridRow: "2/3" }}
      />
      <div style={{ gridColumn: "1/2", gridRow: "3/4" }}>상담항목</div>
      <div style={{ gridColumn: "4/5", gridRow: "3/4" }}>담당실장</div>
      <div style={{ gridColumn: "5/6", gridRow: "3/4" }}>Doctor</div>
      <StyledSimpleSelectInput
        className="progressSelectInput"
        name="counseling"
        value={data.counseling || ""}
        onChange={onChange}
        fontSize="0.75rem"
        placeholder="--"
        style={{ gridColumn: "1/2", gridRow: "4/4" }}
      >
        {counselingList}
      </StyledSimpleSelectInput>
      <StyledSimpleSelectInput
        className="counselorSelectInput"
        name="head"
        value={data.head || ""}
        onChange={onChange}
        fontSize="0.75rem"
        style={{ gridColumn: "4/5", gridRow: "4/5" }}
        placeholder="--"
      >
        {counselorNameList}
      </StyledSimpleSelectInput>
      <StyledSimpleSelectInput
        className="doctorSelectInput"
        name="doctor"
        value={data.doctor || ""}
        onChange={onChange}
        fontSize="0.75rem"
        style={{ gridColumn: "5/6", gridRow: "4/5" }}
        placeholder="--"
      >
        {doctorNameList}
      </StyledSimpleSelectInput>
    </div>
  );
}
