import React from "react";
import backgroundImage from "~background/background2.png";
import TextInput from "~ui/textInput/TextInput";
import SelectInput from "~ui/selectInput/SelectInput";
import ControlsButton from "~/global/components/tamplete/controlsbutton/ControlsButton";
import produce from "immer";
import MenuItem from "@material-ui/core/MenuItem";
import { ko } from "react-date-range/dist/locale";
import { DateRange } from "react-date-range";
import ReactTooltip from "react-tooltip";
import PlanningOptions from "~/admin/components/addChannelDetail/planningOptions/PlanningOptions";
import moment from "moment";

import { useLocation } from "react-router-dom";

import "./EditForm.scss";

export default function ChannelForm(data) {
  const location = useLocation();
  const isAdminPage = location.pathname.includes("/admin");

  const [state, setState] = React.useState({
    progress: false,
    next: false,
    ad: "",
    agency: "",
    channelName: "",
    weeklyRound: 0,
    weeklyCount: 0,
    cost: 0,
    minute: 0,
    note: "",
    content: "",
    start: null,
    end: null,
  });

  const [dateState, setDateState] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "range",
    },
  ]);

  const onChangeValue = (e, name) => {
    setState(
      produce((v) => {
        v[name] = e.target.value;
      }),
    );
  };

  const onChangeDateRange = ({ range }) => {
    setState({
      ...state,
      start: moment(range.startDate).format("YYYY-MM-DD"),
      end: moment(range.endDate).format("YYYY-MM-DD"),
    });
    setDateState([range]);
  };

  React.useEffect(() => {
    const draft = data.inputs.reduce(
      (a, b) => ({ ...a, [b.name]: b.value }),
      {},
    );

    try {
      draft.start = draft["start,end"][0];
      draft.end = draft["start,end"][1];

      delete draft["start,end"];
    } catch {}

    draft.next = data.next;
    draft.progress = data.progress;

    if (draft.start && draft.end) {
      setDateState([
        {
          startDate: new Date(draft.start),
          endDate: new Date(draft.end),
          key: "range",
        },
      ]);
    }

    setState(draft);
  }, [data]);

  const InputHtml = data.inputs.map((x, i) => (
    <div className="channelDataFormItem" key={i}>
      <span>{x.label}</span>
      {x.type === "_select" ? (
        <SelectInput
          style={{
            width: "20.1rem",
            height: "2.68rem",
          }}
          name={x.name}
          value={state[x.name] || x.value}
          onChange={(e) => onChangeValue(e, x.name)}
        >
          {x.options.map((item) => (
            <MenuItem value={item.value}>{item.label}</MenuItem>
          ))}
        </SelectInput>
      ) : x.type === "daterange" ? (
        <>
          <div
            style={{ display: "flex" }}
            data-tip
            data-for="daterange"
            data-event="click"
          >
            <TextInput
              style={{
                width: "9rem",
                height: "2.68rem",
              }}
              value={state.start}
              readOnly
            />

            <div
              style={{
                width: "2.1rem",
                textAlign: "center",
                lineHeight: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              ~
            </div>

            <TextInput
              style={{
                width: "9rem",
                height: "2.68rem",
              }}
              value={state.end}
              readOnly
            />
          </div>

          <ReactTooltip
            id="daterange"
            place="bottom"
            effect="solid"
            type="light"
            globalEventOff="click"
            clickable
          >
            <DateRange
              ranges={dateState}
              onChange={onChangeDateRange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              direction="horizontal"
              locale={ko}
            />
          </ReactTooltip>
        </>
      ) : x.type === "textarea" ? (
        <textarea
          rows={8}
          name={x.name}
          value={state[x.name]}
          onChange={(e) => onChangeValue(e, x.name)}
        />
      ) : (
        <TextInput
          style={{
            width: "20.1rem",
            height: "2.68rem",
          }}
          name={x.name}
          value={state[x.name]}
          onChange={(e) => onChangeValue(e, x.name)}
          type={x.type}
        />
      )}
    </div>
  ));

  return (
    <div>
      {!isAdminPage && (
        <PlanningOptions {...{ uiData: state, setUiData: setState }} />
      )}

      <div className="GlobalEditInner">
        <div className="GlobalEditInnerInputs">{InputHtml}</div>
        <img src={backgroundImage} alt="" className="backgroundImage" />
      </div>

      <ControlsButton
        ButtonFreeSet={data.form.includes("add") ? "add" : "1"}
        onClick={state}
        idx={data.idx ? data.idx : undefined}
        type={data.form}
      />
    </div>
  );
}
