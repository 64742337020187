import React from "react";

import "./toolsItem.scss";

export default function ToolsItem({ icon, text }) {
  return (
    <div className="toolsItem">
      {icon}
      <span>{text}</span>
    </div>
  );
}
