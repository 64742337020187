import produce from "immer";
import { useSetRecoilState } from "recoil";
import { overlayLoadingState } from "~global/stores/ui";

export default function useOverlayLoading() {
  const setState = useSetRecoilState(overlayLoadingState);

  const open = (text = "잠시만 기다려주세요", subText = "") => {
    setState({
      open: true,
      text,
      subText,
    });
  };

  const close = () => {
    setState(
      produce((v) => {
        v.open = false;
      }),
    );
  };

  // const close = () =>
  //   new Promise((res) => {
  //     setTimeout(() => {
  //       setState(
  //         produce((v) => {
  //           v.open = false;
  //         }),
  //       );

  //       res();
  //     }, 1000);
  //   });

  return [open, close];
}
